import Flatten from "@flatten-js/core";
import { maxBy } from "lodash";
import RBush from "rbush";
import { getColorFromPalette } from "../../../lib/color";
import { Coord, coordDist } from "../../../lib/coord";
import {
  angleDiffCWRad,
  getAreaM2,
  polygonIntersectionAreaM2,
} from "../../../lib/mathUtils/mathutils";
import {
  EPS,
  assertType,
  assertUnreachable,
  betterObjectValues,
  interpolateTable,
  parseCatalogNumberExact,
} from "../../../lib/utils";
import { GenericUFH } from "../../catalog/manufacturers/generic/generic-ufh";
import { PipeSpec } from "../../catalog/types";
import {
  BlendingValveModel,
  ComponentId,
  ManifoldModel,
  PumpPackModel,
  UFHPumpModel,
  componentIdEquals,
  componentIdToString,
} from "../../catalog/underfloor-heating/ufh-types";
import {
  getCompatibleActuators,
  getCompatibleBallValves,
  getCompatiblePumpPacks,
  lookupActuator,
  lookupBallValve,
  lookupBlendingValve,
  lookupUFHPump,
  manifoldHasUfhPump,
} from "../../catalog/underfloor-heating/utils";
import {
  evaluateFunctionByParts,
  functionByPartsInDomain,
} from "../../catalog/utils-function-object";
import CoreConduit from "../../coreObjects/coreConduit";
import CorePlant from "../../coreObjects/corePlant";
import CoreRoom from "../../coreObjects/coreRoom";
import {
  CoilMapping,
  RoomCalculation,
  UnderfloorHeatingCalc,
} from "../../document/calculations-objects/room-calculation";
import { UnderfloorHeatingLoopCalculation } from "../../document/calculations-objects/underfloor-heating-loop-calculation";
import { addWarning } from "../../document/calculations-objects/warnings";
import {
  HeatedAreaSegmentEntity,
  fillDefaultAreaSegmentFields,
  isHeatedAreaSegmentEntity,
} from "../../document/entities/area-segment-entity";
import { PipeConduitEntity } from "../../document/entities/conduit-entity";
import { fillPlantDefaults } from "../../document/entities/plants/plant-defaults";
import PlantEntity, {
  ManifoldPlantEntity,
  isHeatLoadPlant,
  plantRatingIsProvided,
} from "../../document/entities/plants/plant-entity";
import {
  ManifoldPlant,
  PlantType,
  PressureMethod,
} from "../../document/entities/plants/plant-types";
import {
  isManifoldPlant,
  isManifoldPlantEntity,
} from "../../document/entities/plants/utils";
import {
  RoomEntity,
  RoomRoomEntity,
  RoomType,
  UFHLoopDesignParameters,
  fillDefaultRoomFields,
  getRoomName,
  isRoomRoomEntity,
} from "../../document/entities/rooms/room-entity";
import { getUFHLoopDesignFlowSystem } from "../../document/entities/shared-fields/ufh-fields";
import { EntityType } from "../../document/entities/types";
import {
  isRoomLoopLayoutFrozen,
  roomLayoutFreezeStatus,
} from "../../document/entities/underfloor-heating/ufh-freeze-layouts";
import { getPipeManufacturerByMaterial } from "../../document/entities/utils";
import { FlowSystem } from "../../document/flow-systems";
import { getFlowSystem } from "../../document/utils";
import { SpatialIndex } from "../../types";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import { HeatLoadCalculations } from "../heatloss/heat-loss";
import { LinearGenerator } from "../heatloss/loop-generator/linear-generator";
import { LoopGenerationResult } from "../heatloss/loop-generator/loop-generator";
import {
  LoopObstacle,
  getRoomLoopObstacles,
} from "../heatloss/loop-generator/loop-obstacles";
import { LoopTestCaseGenerator } from "../heatloss/loop-generator/loop-test-case-generator";
import { SerpentineGenerator } from "../heatloss/loop-generator/serpentine-generator";
import { SpiralGenerator } from "../heatloss/loop-generator/spiral-generator";
import TransitGenerator from "../heatloss/transit-generator/transit-generator";
import { PressureDropCalculations } from "../pressure-drops";
import { PumpCalculations } from "../pump-calculations";
import {
  DEFAULT_AVERAGE_RETURN_C,
  DEFAULT_HEATING_RETURN_DELTA_C,
  DEFAULT_ROOM_TEMP_C,
  MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
} from "../returns";
import { CoreContext } from "../types";
import { CoilCoord3D } from "./coil-coords";
import {
  UFH_DEFAULT_COLOR_PALETTE,
  UFH_DEFAULT_TRANSIT_SPACING_MM,
  UFH_FLOOR_TEMP_LIMIT_DIFF,
} from "./consts";
import { loopBreakdown2D } from "./loop-breakdown";
import {
  calculateLoopPressureDropKPA,
  getLoopLengthMM,
  getLoopVelocityMS,
  getLoopVolumeL,
} from "./loop-stats";
import {
  calculateUFHArea,
  calculateUFHLoopLength,
  findOptimalSpacing,
  getUnderfloorSettings,
  interpolateUFHData,
} from "./utils";

export type UFHRoomCalcRecord = Omit<UnderfloorHeatingCalc, "loopsStats">;

export type LoopSite = {
  entity: RoomRoomEntity | HeatedAreaSegmentEntity;
  underfloorHeating: UFHLoopDesignParameters;
};

export class UnderfloorHeatingCalcs {
  static prefillLoopsStats(context: CalculationEngine) {
    for (const o of context.networkObjects()) {
      if (
        isRoomRoomEntity(o.entity) &&
        isRoomLoopLayoutFrozen(o.entity, context.featureAccess)
      ) {
        const calc = context.globalStore.getOrCreateCalculation(o.entity);
        calc.underfloorHeating.loopsStats =
          o.entity.room.underfloorHeating.frozenLoopsStats;
      }
    }
  }

  @TraceCalculation("Generate underfloor heating loop")
  static generateLoops(
    context: CalculationEngine,
    uaCache: Map<string, RBush<SpatialIndex>>,
    initialObstacles: LoopObstacle[],
    site: LoopSite,
    transitSpacingMM: number,
    record: UFHRoomCalcRecord,
    loopsStats: UnderfloorHeatingCalc["loopsStats"],
    debugObj?: RoomCalculation["debug"],
  ) {
    for (const stat of loopsStats) {
      if (!stat.center || !stat.normal) {
        addWarning(context, "UFH_NO_LOOP_SOLUTION", [site.entity], {
          mode: "mechanical",
        });
        continue;
      }
      if (!record.loopSpacingMM) {
        addWarning(context, "UFH_NO_LOOP_SPACING", [site.entity], {
          mode: "mechanical",
        });
        continue;
      }

      const countBefore = stat.roomLoop.length;

      const center = Flatten.point(stat.center.x, stat.center.y);
      const normal = Flatten.vector(stat.normal.x, stat.normal.y);

      const roomObstacles = getRoomLoopObstacles(
        context,
        uaCache,
        site.entity,
        initialObstacles,
      );
      const allObstacles: LoopObstacle[] =
        roomObstacles.concat(initialObstacles);

      if (context.isFeatureFlagEnabled("debug-loops")) {
        LoopTestCaseGenerator.generateTestCase(
          roomObstacles,
          initialObstacles,
          site.entity.entityName ?? "Unknown",
          Flatten.point(stat.center.x, stat.center.y),
          stat.normal,
          record.loopSpacingMM,
        );
      }

      let result: LoopGenerationResult;

      switch (site.underfloorHeating.loopShape) {
        case "linear":
          result = LinearGenerator.generateLinearLoop(
            allObstacles,
            site,
            transitSpacingMM,
            record.loopSpacingMM,
            normal,
            center,
            stat.fromManifold,
            stat.toManifold,
            debugObj,
          );
          break;
        case "serpentine":
          result = SerpentineGenerator.generateSerpentineLoop(
            allObstacles,
            site,
            transitSpacingMM,
            record.loopSpacingMM,
            normal,
            center,
            stat.fromManifold,
            stat.toManifold,
            debugObj,
          );
          break;
        case "spiral":
          result = SpiralGenerator.generateSpiralLoop(
            allObstacles,
            site,
            transitSpacingMM,
            record.loopSpacingMM,
            site.underfloorHeating.chirality === "clockwise" ? "cw" : "ccw",
            normal,
            center,
            stat.fromManifold,
            stat.toManifold,
            debugObj,
          );
          break;
        default:
          throw new Error(
            `Unknown loop shape ${site.underfloorHeating.loopShape}`,
          );
      }

      stat.roomLoop = result.roomLoop;
      stat.fromManifold = result.fromManifold;
      stat.toManifold = result.toManifold;

      const countAfter = stat.roomLoop.length;

      if (countBefore === countAfter) {
        addWarning(context, "UFH_NO_LOOP_SOLUTION", [site.entity], {
          mode: "mechanical",
        });
      }

      // TODO: SEED-1339 do algorithm for multiple loops in a room
      break;
    }
  }

  static makeRoomsByManifoldCache(
    context: CalculationEngine,
  ): Map<string, CoreRoom[]> {
    const result = new Map<string, CoreRoom[]>();
    for (const obj of context.networkObjects()) {
      if (
        obj.type !== EntityType.ROOM ||
        obj.entity.room.roomType !== RoomType.ROOM
      ) {
        continue;
      }
      const manifoldUid = obj.entity.room.underfloorHeating.manifoldUid;
      const manifold = context.globalStore.get<CorePlant>(manifoldUid!);
      if (!manifoldUid || !manifold) {
        continue;
      }
      if (!result.has(manifoldUid)) {
        result.set(manifoldUid, []);
      }
      result.get(manifoldUid)!.push(obj);
    }
    return result;
  }

  @TraceCalculation("Assign manifold ratings")
  static assignManifoldRating(context: CalculationEngine) {
    const manifoldUidToRoomUIds = new Map<string, [string, number][]>();

    // assign manifold ratings (if it is not already overridden by the user)
    for (const obj of context.networkObjects()) {
      if (
        obj.type === EntityType.ROOM &&
        obj.entity.room.roomType === RoomType.ROOM &&
        obj.entity.room.underfloorHeating.manifoldUid
      ) {
        const manifold = context.globalStore.get<CorePlant>(
          obj.entity.room.underfloorHeating.manifoldUid,
        );

        if (manifold) {
          const room = context.globalStore.get<CoreRoom>(obj.uid);
          const roomCalc = context.globalStore.getOrCreateCalculation(
            room.entity,
          );

          const manifoldOutputWatt =
            roomCalc.underfloorHeating.heatOutputW ?? 0;

          if (!manifoldUidToRoomUIds.has(manifold.uid)) {
            manifoldUidToRoomUIds.set(manifold.uid, []);
          }

          manifoldUidToRoomUIds
            .get(manifold.uid)!
            .push([room.uid, manifoldOutputWatt]);

          if (
            isHeatLoadPlant(manifold.entity.plant) &&
            !plantRatingIsProvided(manifold.entity.plant)
          ) {
            const manifoldCalc = context.globalStore.getOrCreateCalculation(
              manifold.entity,
            );
            manifoldCalc.heatingRatingKW =
              (manifoldCalc.heatingRatingKW ?? 0) + manifoldOutputWatt / 1000;
          }
        }
      }
    }

    // assign manifold unique IDs
    let idx = 1;
    for (const obj of context.networkObjects()) {
      if (
        obj.entity.type === EntityType.PLANT &&
        obj.entity.plant.type === PlantType.MANIFOLD
      ) {
        const calc = context.globalStore.getOrCreateCalculation(obj.entity);
        const lvlUid = context.globalStore.levelOfEntity.get(obj.uid) ?? "G";
        const lvlName = context.drawing.levels[lvlUid]?.abbreviation ?? "G";
        calc.manifoldId = `${lvlName}-${idx}`;
        idx++;
      }
    }
  }

  @TraceCalculation("Calculate unheated/heated area for each room")
  static calculateRoomUnheatedArea(
    context: CalculationEngine,
    cacheRoomPolygon: Map<string, Coord[]>,
    roomsRTree: Map<string, RBush<SpatialIndex>>,
  ) {
    for (const o of context.networkObjects()) {
      if (o.type !== EntityType.AREA_SEGMENT) continue;
      if (o.entity.areaType === "unheated-area") {
        const lvlUid = context.globalStore.levelOfEntity.get(o.uid);
        if (!lvlUid) continue;

        const unheatedPolygon = o
          .collectVerticesInOrder()
          .map((v) => v.toWorldCoord());

        const unheatedCalc = context.globalStore.getOrCreateCalculation(
          o.entity,
        );
        unheatedCalc.areaM2 = getAreaM2(unheatedPolygon);

        for (const [uid, roomPolygon] of cacheRoomPolygon.entries()) {
          if (
            context.globalStore.levelOfEntity.get(uid) !==
            context.globalStore.levelOfEntity.get(o.uid)
          )
            continue;
          const room = context.globalStore.get<CoreRoom>(uid);
          if (room.entity.room.roomType !== RoomType.ROOM) continue;

          const intersect = polygonIntersectionAreaM2(
            roomPolygon,
            unheatedPolygon,
          );

          const calc = context.globalStore.getOrCreateCalculation(room.entity);
          calc.underfloorHeating.unheatedAreaM2 += intersect;
        }
      } else if (o.entity.areaType === "heated-area") {
        // we have to associate it with a room.
        const lvlUid = context.globalStore.levelOfEntity.get(o.uid);
        const tree = roomsRTree.get(lvlUid!);
        if (!tree) continue;
        const calc = context.globalStore.getOrCreateCalculation(o.entity);
        const heatedPolygon = o
          .collectVerticesInOrder()
          .map((v) => v.toWorldCoord());
        calc.areaM2 = getAreaM2(heatedPolygon);
        const box = o.shape.box;
        const candidates = tree.search({
          minX: box.xmin,
          minY: box.ymin,
          maxX: box.xmax,
          maxY: box.ymax,
        });

        let mostCoveredRoom: CoreRoom | null = null;
        let mostCoveredArea = 0;
        for (const candidate of candidates) {
          const room = context.globalStore.get<CoreRoom>(candidate.uid);
          if (room.entity.room.roomType !== RoomType.ROOM) continue;
          const roomCalc = context.globalStore.getOrCreateCalculation(
            room.entity,
          );

          const roomPolygon = cacheRoomPolygon.get(room.uid);
          if (!roomPolygon) continue;

          const intersect = polygonIntersectionAreaM2(
            roomPolygon,
            o.collectVerticesInOrder().map((v) => v.toWorldCoord()),
          );

          roomCalc.underfloorHeating.excludedAreaM2 += intersect;

          if (intersect > mostCoveredArea) {
            mostCoveredRoom = room;
            mostCoveredArea = intersect;
          }
        }

        if (mostCoveredRoom && mostCoveredArea > 0) {
          calc.roomUid = mostCoveredRoom.uid;
        }
      }
    }
  }

  static calculateLoops(
    context: CalculationEngine,
    skipLoopGeneration: boolean = false,
  ): UnderfloorHeatingCalc[] {
    const ret: UnderfloorHeatingCalc[] = [];
    const loopIdxByLvlUid = new Map<string, number>();
    const loopCountByLvlUid = new Map<string, number>();

    const uaCache = this.makeAreaSegmentBoundaryCache(context);
    const manifoldCache = this.makeRoomsByManifoldCache(context);

    for (const room of context.networkObjects()) {
      if (!isRoomRoomEntity(room.entity)) continue;

      const rFilled = fillDefaultRoomFields(context, room.entity);

      const manifoldUid = room.entity.room.underfloorHeating.manifoldUid;
      if (!manifoldUid) continue;

      const manifold = context.globalStore.get<CorePlant>(manifoldUid);
      if (!manifold) continue;
      assertType<ManifoldPlant>(manifold.entity.plant);

      const ufhSettings = getUnderfloorSettings(context, manifold.entity.plant);
      if (!ufhSettings) continue;

      const mwt = this.getManifoldWaterTempsC(context, manifold);
      const roomT = rFilled.room.roomTemperatureC ?? DEFAULT_ROOM_TEMP_C;
      const diameterMM = rFilled.room.underfloorHeating.pipeDiameterMM ?? 0;
      const finish = rFilled.room.underfloorHeating.floorFinish!;
      const material = rFilled.room.underfloorHeating.pipeMaterial!;

      const rCalc = context.globalStore.getOrCreateCalculation(room.entity);
      rCalc.underfloorHeating.meanWaterTempC = mwt.meanWaterTempC;
      rCalc.underfloorHeating.returnTempC = mwt.returnTempC;
      rCalc.underfloorHeating.deltaT = mwt.deltaT;
      rCalc.underfloorHeating.floorFinish = finish;
      rCalc.underfloorHeating.roomName = getRoomName(context, rFilled);
      rCalc.underfloorHeating.designTemperatureC =
        rFilled.room.roomTemperatureC;
      rCalc.underfloorHeating.manifoldUid = manifoldUid;

      const allData = GenericUFH[finish][material]![diameterMM];

      const data = interpolateUFHData(allData, roomT, mwt.meanWaterTempC);

      if (data) {
        const heatingOverride = room.entity.room.underfloorHeating.heatOutputW;
        const spacingOverride =
          room.entity.room.underfloorHeating.loopSpacingMM;

        const roomAreaM2 =
          (rCalc.areaM2 ?? 1) - (rCalc.underfloorHeating.unheatedAreaM2 ?? 0);
        const WperM2 =
          (heatingOverride ?? rCalc.totalHeatLossWatt ?? 0) / roomAreaM2;
        const optimal = findOptimalSpacing(
          data,
          WperM2,
          spacingOverride,
          ufhSettings.maxPipeSpacingMM,
        );

        if (optimal) {
          rCalc.underfloorHeating.loopSpacingMM = optimal.pipeSpacingMM;
          rCalc.underfloorHeating.floorTempC = optimal.floorTempC;
          rCalc.underfloorHeating.outputWattPerM2 = optimal.outputWperM2;
          rCalc.underfloorHeating.pipeSizeMM = diameterMM;

          const cappedHeatOutputW = Math.min(
            optimal.outputWperM2 * roomAreaM2,
            rCalc.totalHeatLossWatt ?? 0,
          );

          rCalc.underfloorHeating.heatOutputW =
            heatingOverride ?? cappedHeatOutputW;

          rCalc.totalHeatLossAddressedWATT =
            (rCalc.totalHeatLossAddressedWATT ?? 0) +
            rCalc.underfloorHeating.heatOutputW;

          rCalc.underfloorHeating.loopMode = manifold.entity.plant.ufhMode;

          if (isRoomLoopLayoutFrozen(room.entity, context.featureAccess)) {
            ret.push(rCalc.underfloorHeating);
          } else {
            rCalc.underfloorHeating.loopsStats = this.getLoopsStats(
              context,
              rCalc.underfloorHeating.loopMode,
              room.entity,
              rFilled,
              null,
              null,
              calculateUFHLoopLength(roomAreaM2, optimal.pipeSpacingMM),
              loopIdxByLvlUid,
              loopCountByLvlUid,
            );
            ret.push(rCalc.underfloorHeating);
          }
        }
      }

      this.addUFHWarnings(context, room.entity, rCalc);
    }

    // calculate loops stats for heated areas
    for (const area of context.networkObjects()) {
      if (area.type !== EntityType.AREA_SEGMENT) continue;
      if (area.entity.areaType !== "heated-area") continue;

      const aFilled = fillDefaultAreaSegmentFields(context, area.entity);

      const manifoldUid = aFilled.underfloorHeating.manifoldUid;
      if (!manifoldUid) continue;

      const manifold = context.globalStore.get<CorePlant>(manifoldUid);
      if (!manifold) continue;
      assertType<ManifoldPlant>(manifold.entity.plant);

      const ufhSettings = getUnderfloorSettings(context, manifold.entity.plant);
      if (!ufhSettings) continue;

      const calc = context.globalStore.getOrCreateCalculation(area.entity);
      const roomUid = calc.roomUid;
      if (!roomUid) continue;

      const room = context.globalStore.get<CoreRoom>(roomUid);
      const roomCalc = context.globalStore.getOrCreateCalculation(room.entity);
      if (!room || !roomCalc || !isRoomRoomEntity(room.entity)) continue;
      if (!roomCalc.underfloorHeating.loopSpacingMM) continue;

      const rFilled = fillDefaultRoomFields(context, room.entity);

      // TODO (SEED-1745): Loop Spacing is not fully hooked up and is read only
      calc.loopSpacingMM = roomCalc.underfloorHeating.loopSpacingMM;
      calc.loopsStats = this.getLoopsStats(
        context,
        roomCalc.underfloorHeating.loopMode,
        room.entity,
        rFilled,
        area.entity,
        aFilled,
        100, // doesn't matter, this is moot in v3
        loopIdxByLvlUid,
        loopCountByLvlUid,
      );
    }

    // v3 calculations (full loop layout)
    // generated transit pipes
    // generates loops
    if (
      !skipLoopGeneration &&
      context.featureAccess.fullUnderfloorHeatingLoops
    ) {
      const { manifoldLoopLayout, obstaclesByRoom: manifoldLoopObstacles } =
        TransitGenerator.generateManifoldLoopConnections(
          context,
          manifoldCache,
          roomLayoutFreezeStatus(context),
        );

      for (const obj of context.networkObjects()) {
        // we need the root room for the loop spacing calc.
        let room: RoomRoomEntity | null = null;
        let loopSite: LoopSite | null = null;
        let loopsStats: UnderfloorHeatingCalc["loopsStats"] | null = null;

        if (isRoomRoomEntity(obj.entity)) {
          room = obj.entity;
          const filled = fillDefaultRoomFields(context, room);
          loopSite = {
            entity: room,
            underfloorHeating: filled.room.underfloorHeating,
          };
        } else if (isHeatedAreaSegmentEntity(obj.entity)) {
          const asCalc = context.globalStore.getOrCreateCalculation(obj.entity);
          const roomUid = asCalc.roomUid;
          if (!roomUid) continue;
          room = context.globalStore.get<CoreRoom>(roomUid)
            ?.entity as RoomRoomEntity;
          const filled = fillDefaultAreaSegmentFields(context, obj.entity);
          loopSite = {
            entity: obj.entity,
            underfloorHeating: filled.underfloorHeating,
          };
          loopsStats = asCalc.loopsStats;
        }

        if (!room || !loopSite) {
          continue;
        }

        const rCalc = context.globalStore.getOrCreateCalculation(room);

        if (!rCalc.underfloorHeating.manifoldUid) continue;

        const manifold = context.globalStore.get<CorePlant>(
          rCalc.underfloorHeating.manifoldUid,
        );
        if (!manifold) continue;
        assertType<ManifoldPlant>(manifold.entity.plant);

        if (rCalc.underfloorHeating.loopMode === "approximate") {
          continue;
        }

        const ufhSettings = getUnderfloorSettings(
          context,
          manifold.entity.plant,
        );
        if (!ufhSettings) continue;

        const transitSpacingMM =
          ufhSettings.transitSpacingMM ?? UFH_DEFAULT_TRANSIT_SPACING_MM;

        if (!loopsStats) {
          loopsStats = rCalc.underfloorHeating.loopsStats;
        }

        rCalc.debug = rCalc.debug ?? [];
        if (rCalc.underfloorHeating.loopSpacingMM) {
          if (!isRoomLoopLayoutFrozen(room, context.featureAccess)) {
            this.generateLoops(
              context,
              uaCache,
              manifoldLoopObstacles.get(room.uid) ?? [],
              loopSite,
              transitSpacingMM,
              rCalc.underfloorHeating,
              loopsStats,
              rCalc.debug,
            );
          }
        }
      }

      this.collectLoopStats(context, manifoldLoopLayout);
    }

    return ret;
  }

  // moves all the intermediate loopStat calculations in heated areas to the parent room
  // calculation, and sorts out the generated pipe length counts.
  static collectLoopStats(
    context: CalculationEngine,
    manifoldLoopLayout: Map<string, string[]>,
  ) {
    // TODO: when we do multiple loops per site, these should be loopIds already.
    const site2loopIds = new Map<string, string[]>();

    // collect all loops per room
    for (const obj of context.networkObjects()) {
      if (isRoomRoomEntity(obj.entity)) {
        const rCalc = context.globalStore.getOrCreateCalculation(obj.entity);

        site2loopIds.set(
          obj.uid,
          rCalc.underfloorHeating.loopsStats.map((l) => l.loopId),
        );
      }
    }

    // collect heated areas loopsStats and put it into the rooms
    for (const obj of context.networkObjects()) {
      if (isHeatedAreaSegmentEntity(obj.entity)) {
        const asCalc = context.globalStore.getOrCreateCalculation(obj.entity);
        const roomUid = asCalc.roomUid;
        if (!roomUid) continue;
        const room = context.globalStore.get<CoreRoom>(roomUid);

        if (!isRoomRoomEntity(room.entity)) continue;

        // skip heated area calculation if loops are frozen
        if (isRoomLoopLayoutFrozen(room.entity, context.featureAccess))
          continue;

        if (room) {
          const rCalc = context.globalStore.getOrCreateCalculation(room.entity);
          rCalc.underfloorHeating.loopsStats.push(...asCalc.loopsStats);
        }
        site2loopIds.set(
          obj.uid,
          asCalc.loopsStats.map((l) => l.loopId),
        );
      }
    }

    // calculate each loop's length and total pipe length
    for (const obj of context.networkObjects()) {
      if (isRoomRoomEntity(obj.entity)) {
        const rCalc = context.globalStore.getOrCreateCalculation(obj.entity);

        if (
          rCalc.underfloorHeating.loopMode === "full" &&
          context.featureAccess.fullUnderfloorHeatingLoops
        ) {
          // we need to calculate the total pipe length.
          for (const loop of rCalc.underfloorHeating.loopsStats) {
            loop.lengthM = getLoopLengthMM(loop) / 1000;
          }
        }
        rCalc.underfloorHeating.totalPipeLengthM =
          rCalc.underfloorHeating.loopsStats.reduce(
            (acc, loop) => acc + loop.lengthM!,
            0,
          );
      }
    }

    // generate manifold loops order
    for (const [manifoldUid, siteUids] of manifoldLoopLayout.entries()) {
      const manifold = context.globalStore.get<CorePlant>(manifoldUid);
      if (!manifold) {
        continue;
      }
      const manifoldCalc = context.globalStore.getOrCreateCalculation(
        manifold.entity,
      );

      for (const siteUid of siteUids) {
        manifoldCalc.manifoldLoopOrder.push(
          ...(site2loopIds.get(siteUid) || []),
        );
      }
    }
  }

  static getLoopCenter2D(roomLoop: CoilCoord3D[]): Flatten.Point | null {
    let xSum = 0;
    let ySum = 0;
    let totWeight = 0;
    const breakdown = loopBreakdown2D(roomLoop);
    for (const segment of breakdown) {
      let thisWeight = 0;
      let thisX = 0;
      let thisY = 0;
      if (segment.type === "bend") {
        const totalAngle = angleDiffCWRad(
          segment.startAngleRAD,
          segment.endAngleRAD,
        );
        const radius = segment.radiusMM;
        thisWeight = totalAngle * radius;
        thisX = segment.center.x;
        thisY = segment.center.y;
      } else if (segment.type === "straight") {
        thisWeight = coordDist(segment.coords[0], segment.coords[1]);
        thisX = (segment.coords[0].x + segment.coords[1].x) / 2;
        thisY = (segment.coords[0].y + segment.coords[1].y) / 2;
      }
      xSum += thisX * thisWeight;
      ySum += thisY * thisWeight;
      totWeight += thisWeight;
    }
    if (totWeight === 0) {
      return null;
    }
    return Flatten.point(xSum / totWeight, ySum / totWeight);
  }

  static calculateLoopHydraulics(
    context: CalculationEngine,
    ufhCalcs: UnderfloorHeatingCalc[],
  ) {
    // heat output per loop
    for (const ufh of ufhCalcs) {
      for (const loop of ufh.loopsStats) {
        loop.heatOutputW =
          ((ufh.heatOutputW ?? 0) * loop.lengthM!) / ufh.totalPipeLengthM!;
      }
    }

    // Flow rate. Per manifold kind of thing.
    // Two modes. The manifold has a delta T (pump) or we use manifold's flow rate (directly connected - no pump)
    const loopsByManifold = new Map<
      string,
      UnderfloorHeatingLoopCalculation[]
    >();
    for (const ufh of ufhCalcs) {
      for (const loop of ufh.loopsStats) {
        if (!loop.manifoldUid) {
          continue;
        }
        if (!loopsByManifold.has(loop.manifoldUid)) {
          loopsByManifold.set(loop.manifoldUid, []);
        }
        loopsByManifold.get(loop.manifoldUid)!.push(loop);
      }
    }

    for (const [manifoldUid, loops] of loopsByManifold.entries()) {
      const manifold = context.globalStore.get<CorePlant>(manifoldUid);
      const manifoldCalcs = context.globalStore.getOrCreateCalculation(
        manifold.entity,
      );
      const filled = fillPlantDefaults(context, manifold.entity);

      if (filled.plant.type !== PlantType.MANIFOLD) {
        continue;
      }
      assertType<ManifoldPlant>(manifold.entity.plant);

      const flowTempC = filled.plant.ufhFlowTempC;
      const returnC = filled.plant.ufhReturnTempC;
      if (flowTempC === null || returnC === null) {
        continue;
      }

      for (let i = 0; i < loops.length; i++) {
        loops[i].manifoldIndex = i;
        loops[i].manifoldName = manifoldCalcs.manifoldId;
      }

      let totalHeatLossW = 0;
      for (const loop of loops) {
        totalHeatLossW += loop.heatOutputW!;
      }

      let totalFlowRateLS = 0;
      if (filled.plant.hasRecirculationPump) {
        // use own delta T and calculate flow rate separately
        const deltaC = flowTempC - returnC;

        const system = getFlowSystem(
          context.drawing,
          filled.plant.outletSystemUid,
        );
        if (!system) {
          continue;
        }
        const specificHeat = interpolateTable(
          context.catalog.fluids[system.fluid].specificHeatByTemperatureKJ_KGK,
          flowTempC,
        );

        if (!specificHeat) {
          continue;
        }

        totalFlowRateLS = totalHeatLossW / 1000 / (deltaC * specificHeat);
        for (const loop of loops) {
          loop.flowRateLS =
            totalFlowRateLS * (loop.heatOutputW! / totalHeatLossW);
        }
      } else {
        const connections = context.globalStore.getConnections(
          filled.inletUid!,
        );
        const otherPipeUid = connections.find((c) => c !== filled.inletUid);
        const otherPipe = context.globalStore.get<CoreConduit>(otherPipeUid!);
        const otherPipeCalc = context.globalStore.getOrCreateCalculation(
          otherPipe.entity as PipeConduitEntity,
        );

        totalFlowRateLS = otherPipeCalc.returnFlowRateLS!;
        if (totalFlowRateLS == null) {
          continue;
        }

        for (const loop of loops) {
          loop.flowRateLS =
            totalFlowRateLS * (loop.heatOutputW! / totalHeatLossW);
        }
      }

      manifoldCalcs.loopTotalFlowRateLS = totalFlowRateLS;

      let maxPressureDropKPA = 0;
      let maxPressureDropIndex = -1;
      const averageC = (flowTempC + returnC) / 2;

      const ufhSystem = getFlowSystem(
        context.drawing,
        filled.plant.ufhSystemUid,
      )!;

      for (let i = 0; i < loops.length; i++) {
        const loop = loops[i];
        loop.pressureDropKPA = calculateLoopPressureDropKPA(
          loop,
          // Have to default to water because some test projects don't have a ufh fluid before
          // the full release.
          context.catalog.fluids[ufhSystem?.fluid ?? "water"],
          context.drawing.metadata.calculationParams.gravitationalAcceleration,
          averageC,
        );
        if (loop.pressureDropKPA > maxPressureDropKPA) {
          maxPressureDropKPA = loop.pressureDropKPA;
          maxPressureDropIndex = i;
        }
      }

      manifoldCalcs.loopIndexPressureDropKPA = maxPressureDropKPA;
      if (maxPressureDropIndex >= 0) {
        const indexLoop = loops[maxPressureDropIndex];
        manifoldCalcs.loopIndexHasActuator = indexLoop.hasActuator;
        manifoldCalcs.loopIndexVelocityMS = getLoopVelocityMS(indexLoop);
      }

      const sortedLoops = loops.slice().sort((a, b) => {
        return (
          manifoldCalcs.manifoldLoopOrder.indexOf(a.loopId) -
          manifoldCalcs.manifoldLoopOrder.indexOf(b.loopId)
        );
      });

      const flowSystem = getUFHLoopDesignFlowSystem(context, manifoldUid);

      let totalLoopVolumeL = 0;
      sortedLoops.forEach((loop, manifoldPortNumber) => {
        const colorPalette = flowSystem?.palette ?? UFH_DEFAULT_COLOR_PALETTE;

        // We need sorted loops to infer nice colors
        loop.loopId = `${
          manifoldCalcs.manifoldId
        }(${manifoldPortNumber}${String.fromCharCode(97 + loop.index)})`;
        loop.color =
          loop.color ?? getColorFromPalette(manifoldPortNumber, colorPalette);

        const loopVolumeL = getLoopVolumeL(loop);
        loop.volumeL = loopVolumeL;
        totalLoopVolumeL += loopVolumeL;

        manifoldCalcs.manifoldLoopStats.push({
          loopId: loop.loopId,
          roomUid: loop.roomUid,
          pressureDropKPA: loop.pressureDropKPA,
          volumeL: loopVolumeL,
          balancingValveKPA:
            maxPressureDropKPA -
            loop.pressureDropKPA! +
            MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
          flowRateLS: loop.flowRateLS,
          lengthM: loop.lengthM,
          heatOutputW: loop.heatOutputW,
          heatingAreaM2: loop.heatingAreaM2,
          color: loop.color,
        });
      });

      const transitSpacingMM =
        flowSystem?.transitSpacingMM ?? UFH_DEFAULT_TRANSIT_SPACING_MM;
      const impliedInternalVolumeL =
        0.012 *
        0.012 *
        ((transitSpacingMM / 1000) * (loops.length + 2)) *
        2 *
        Math.PI;
      manifoldCalcs.internalVolumeL =
        // Make sure to get the manifold, not filled, for the user overridden value.
        manifold.entity.plant.internalVolumeL ?? impliedInternalVolumeL;
      manifoldCalcs.volumeL =
        manifoldCalcs.internalVolumeL +
        (manifold.entity.plant.volumeL ?? totalLoopVolumeL);
      manifoldCalcs.loopIndexCircuitLengthM =
        maxBy(loops, (l) => l.pressureDropKPA)?.lengthM ?? null;
    }
  }

  static makeAreaSegmentBoundaryCache(
    context: CalculationEngine,
  ): Map<string, RBush<SpatialIndex>> {
    const result = new Map<string, RBush<SpatialIndex>>();
    for (const o of context.networkObjects()) {
      if (o.type !== EntityType.AREA_SEGMENT) continue;
      // Heated areas must be included as well because they form boundaries
      // for loops starting in bare rooms.
      if (
        o.entity.areaType !== "unheated-area" &&
        o.entity.areaType !== "heated-area"
      )
        continue;
      const level = context.globalStore.levelOfEntity.get(o.uid);
      if (!level) continue;
      if (!result.has(level)) {
        result.set(level, new RBush());
      }

      const rBush = result.get(level)!;
      const box = o.shape.box;
      rBush.insert({
        minX: box.xmin,
        minY: box.ymin,
        maxX: box.xmax,
        maxY: box.ymax,
        uid: o.uid,
        levelUid: level,
      });
    }

    return result;
  }

  static getManifoldWaterTempsC(
    context: CalculationEngine,
    manifold: CorePlant,
  ): {
    returnTempC: number;
    meanWaterTempC: number;
    flowTempC: number;
    deltaT: number;
  } {
    if (manifold?.entity.plant.type !== PlantType.MANIFOLD) {
      return {
        meanWaterTempC: 0,
        returnTempC: 0,
        flowTempC: 0,
        deltaT: 0,
      };
    }
    const calcs = context.globalStore.getOrCreateCalculation(manifold.entity);

    let flowTempC =
      (calcs.returnAverageC ?? DEFAULT_AVERAGE_RETURN_C) +
      (calcs.returnDeltaC ?? DEFAULT_HEATING_RETURN_DELTA_C) / 2;
    let returnTempC =
      (calcs.returnAverageC ?? DEFAULT_AVERAGE_RETURN_C) -
      (calcs.returnDeltaC ?? DEFAULT_HEATING_RETURN_DELTA_C) / 2;

    if (manifold.entity.plant.ufhFlowTempC !== null) {
      flowTempC = manifold.entity.plant.ufhFlowTempC;
    }

    if (manifold.entity.plant.ufhReturnTempC !== null) {
      returnTempC = manifold.entity.plant.ufhReturnTempC;
    }

    return {
      meanWaterTempC: (flowTempC + returnTempC) / 2,
      returnTempC,
      flowTempC,
      deltaT: returnTempC - flowTempC,
    };
  }

  static getUfhPipeSpec(
    context: CoreContext,
    ufhLoopSettings: UFHLoopDesignParameters,
  ): PipeSpec | null {
    const { catalog, drawing } = context;
    const { pipeMaterial, pipeDiameterMM } = ufhLoopSettings;

    if (!pipeMaterial) {
      console.error("No pipe material found for underfloor", {
        ufhLoopSettings,
      });
      return null;
    }

    if (!pipeDiameterMM) {
      console.error("No pipe of size found for underfloor", { pipeDiameterMM });
      return null;
    }

    const manuf = getPipeManufacturerByMaterial(drawing, pipeMaterial);
    const materialSpec = catalog.pipes[pipeMaterial].pipesBySize[manuf];
    return materialSpec[pipeDiameterMM];
  }

  static getLoopsStats(
    context: CoreContext,
    loopMode: "approximate" | "full" | null,
    rUnfilled: RoomRoomEntity,
    rFilled: RoomRoomEntity,
    aUnfilled: HeatedAreaSegmentEntity | null,
    aFilled: HeatedAreaSegmentEntity | null,
    totalInteriorPipeLengthM: number,
    loopIdxByLvlUid: Map<string, number>,
    loopCountByLvlUid: Map<string, number>,
  ): UnderfloorHeatingCalc["loopsStats"] {
    // TODO: SEED-1229 reconcile v3 and v2 here. In v3 we need to dictate the loop length results
    // instead of have it be specified. For now just cope with this mess.
    const res: UnderfloorHeatingCalc["loopsStats"] = [];
    let remainingInternalPipeLength = totalInteriorPipeLengthM;
    const loopDesignParameters =
      aFilled?.underfloorHeating ?? rFilled.room.underfloorHeating;
    const maxLoopLengthM = loopDesignParameters.maxLoopLengthM!;
    const exteriorLengthM = rFilled.room.underfloorHeating.exteriorLoopLengthM!;
    const heatOutputW = rFilled.room.underfloorHeating.heatOutputW!;

    if (exteriorLengthM >= maxLoopLengthM) {
      throw new Error(
        `Exterior pipe length (${exteriorLengthM}) must be less than max loop length (${maxLoopLengthM})`,
      );
    }

    const lvlUid = context.globalStore.levelOfEntity.get(rFilled.uid) ?? "G";

    let loopIdx = loopIdxByLvlUid.get(lvlUid) ?? 1;
    let currLoopCount = loopCountByLvlUid.get(lvlUid) ?? 0;
    let j = 0;

    const isExplicit =
      loopMode === "full" && context.featureAccess.fullUnderfloorHeatingLoops;

    // Has to be unfilled, otherwise we pick up the defaults from filled entity calculations
    const coilColors =
      aUnfilled?.underfloorHeating?.coilColors ??
      rUnfilled.room.underfloorHeating.coilColors ??
      [];
    const palette = coilColors.map((x) => x.hex);

    const pipeSpec = UnderfloorHeatingCalcs.getUfhPipeSpec(
      context,
      loopDesignParameters,
    );

    if (isExplicit) {
      // only push one loop.
      res.push({
        lengthM: remainingInternalPipeLength, // not applicable.
        roomUid: rFilled.uid,
        areaUid: aFilled?.uid ?? null,
        loopId: `INFERRED_FROM_TRANSIT_CALCULATIONS`,
        heatingAreaM2: calculateUFHArea(
          remainingInternalPipeLength,
          loopDesignParameters.loopSpacingMM ?? 0,
        ),
        maxLoopLengthM,
        color: palette.at(j) ?? null,
        lvlUid,
        roomName: rFilled.entityName ?? "",
        index: j,
        manifoldUid: loopDesignParameters.manifoldUid,
        flowRateLS: null,
        volumeL: null,
        pipeSizeMM: loopDesignParameters.pipeDiameterMM!,
        internalPipeDiameterMM: parseCatalogNumberExact(
          pipeSpec?.diameterInternalMM,
        ),
        colebrookWhiteCoefficient: parseCatalogNumberExact(
          pipeSpec?.colebrookWhiteCoefficient,
        ),
        pressureDropKPA: null,
        normal: null,
        center: null,
        heatOutputW,
        manifoldIndex: null,
        manifoldName: null,
        fromManifold: [],
        roomLoop: [],
        toManifold: [],
        hasActuator:
          aFilled?.underfloorHeating.hasActuator ??
          rFilled.room.underfloorHeating.hasActuator,
      });
    } else {
      while (remainingInternalPipeLength > EPS) {
        const thisLengthM = Math.min(
          remainingInternalPipeLength + exteriorLengthM,
          maxLoopLengthM,
        );
        res.push({
          lengthM: thisLengthM,
          maxLoopLengthM,
          roomUid: rFilled.uid,
          areaUid: aFilled?.uid ?? null,
          loopId: `INFERRED_FROM_TRANSIT_CALCULATIONS`,
          heatingAreaM2: calculateUFHArea(
            thisLengthM - exteriorLengthM,
            loopDesignParameters.loopSpacingMM ?? 0,
          ),
          color: palette.at(j) ?? null,
          lvlUid,
          roomName: rFilled.entityName ?? "",
          index: j,
          manifoldUid: loopDesignParameters.manifoldUid,
          flowRateLS: null,
          volumeL: null,
          pipeSizeMM: loopDesignParameters.pipeDiameterMM!,
          internalPipeDiameterMM: parseCatalogNumberExact(
            pipeSpec?.diameterInternalMM,
          ),
          colebrookWhiteCoefficient: parseCatalogNumberExact(
            pipeSpec?.colebrookWhiteCoefficient,
          ),
          pressureDropKPA: null,
          normal: null,
          center: null,
          heatOutputW,
          manifoldIndex: null,
          manifoldName: null,
          fromManifold: [],
          roomLoop: [],
          toManifold: [],
          hasActuator:
            aFilled?.underfloorHeating.hasActuator ??
            rFilled.room.underfloorHeating.hasActuator,
        });
        remainingInternalPipeLength -= thisLengthM - exteriorLengthM;
        currLoopCount++;
        j++;
      }
    }

    loopIdx++;
    loopIdxByLvlUid.set(lvlUid, loopIdx);
    loopCountByLvlUid.set(lvlUid, currLoopCount);

    return res;
  }

  static addUFHWarnings(
    context: CalculationEngine,
    roomEntity: RoomEntity,
    roomCalc: RoomCalculation,
  ) {
    const reqW = roomCalc.totalHeatLossWatt ?? 0;
    const suppliedW = roomCalc.underfloorHeating.heatOutputW ?? 0;

    if (suppliedW < reqW) {
      addWarning(context, "UFH_INSUFFICIENT", [roomEntity], {
        mode: "mechanical",
      });
    }

    const heating = roomCalc.underfloorHeating.heatOutputW;
    if (heating === null || isNaN(heating)) {
      addWarning(context, "UFH_NO_SOLUTION", [roomEntity], {
        mode: "mechanical",
      });
    }

    const floorTempC = roomCalc.underfloorHeating.floorTempC;

    if (floorTempC !== null) {
      const rFilled = fillDefaultRoomFields(context, roomEntity);
      assertType<RoomRoomEntity>(rFilled);
      const roomTempC = rFilled.room.roomTemperatureC!;

      if (floorTempC > roomTempC + UFH_FLOOR_TEMP_LIMIT_DIFF) {
        addWarning(context, "UFH_FLOOR_TEMP_TOO_HIGH", [roomEntity], {
          mode: "mechanical",
        });
      }
    }
  }

  static calculateUFHCoils(
    context: CalculationEngine,
    underfloorCalcs: UnderfloorHeatingCalc[],
  ) {
    if (underfloorCalcs.length === 0) return;

    // TODO: have a solution for multiple coil length sets from different
    // ufh systems.
    const manifoldUidsByOccur = new Map<string, number>();
    let mostCommonManifoldUid: string | null = null;
    let mostCommonLength = -1;
    for (const ufh of underfloorCalcs) {
      if (!ufh.manifoldUid) continue;
      const newVal =
        (manifoldUidsByOccur.get(ufh.manifoldUid) ?? 0) +
        (ufh.totalPipeLengthM ?? 0);
      if (newVal > mostCommonLength) {
        mostCommonManifoldUid = ufh.manifoldUid;
        mostCommonLength = newVal;
      }

      manifoldUidsByOccur.set(ufh.manifoldUid, newVal);
    }

    if (!mostCommonManifoldUid) return;
    const manifold = context.globalStore.get<CorePlant>(mostCommonManifoldUid);
    assertType<ManifoldPlant>(manifold.entity.plant);
    const ufhSettings = getUnderfloorSettings(context, manifold.entity.plant);
    if (!ufhSettings) return;

    const loops = underfloorCalcs.flatMap((c) => c.loopsStats);
    const coilLengthsM = ufhSettings.rollLengthsM;

    if (!coilLengthsM || coilLengthsM.length === 0) {
      throw new Error("No coil lengths provided for underfloor heating");
    }

    const optimal = calculateOptimalCoilMapping(loops, coilLengthsM);
    const room = getSingleUFHRoom(context);
    const rCalc = context.globalStore.getOrCreateCalculation(room);
    rCalc.underfloorHeating.coils = optimal.mapping;
  }

  private static loopsByManifold(
    ufhCalcs: UnderfloorHeatingCalc[],
  ): Map<string, UnderfloorHeatingCalc[]> {
    const result = new Map<string, UnderfloorHeatingCalc[]>();

    for (const ufh of ufhCalcs) {
      if (!ufh.manifoldUid) continue;

      if (!result.has(ufh.manifoldUid)) {
        result.set(ufh.manifoldUid, []);
      }

      result.set(ufh.manifoldUid, [...result.get(ufh.manifoldUid)!, ufh]);
    }

    return result;
  }

  static calculateHeatEmitterStats(context: CalculationEngine) {
    const manifoldUidToRoomUIds = new Map<string, string[]>();

    for (const obj of context.networkObjects()) {
      if (
        obj.type === EntityType.ROOM &&
        obj.entity.room.roomType === RoomType.ROOM &&
        obj.entity.room.underfloorHeating.manifoldUid
      ) {
        const maniUid = obj.entity.room.underfloorHeating.manifoldUid;
        if (!manifoldUidToRoomUIds.has(maniUid)) {
          manifoldUidToRoomUIds.set(maniUid, []);
        }

        manifoldUidToRoomUIds.get(maniUid)?.push(obj.uid);
      }
    }

    for (const [manifoldUid, rooms] of manifoldUidToRoomUIds.entries()) {
      const manifold = context.globalStore.get<CorePlant>(manifoldUid);

      if (manifold) {
        for (const roomUid of rooms) {
          const room = context.globalStore.get<CoreRoom>(roomUid);
          const roomCalc = context.globalStore.getOrCreateCalculation(
            room.entity,
          );
          const manifoldCalc = context.globalStore.getOrCreateCalculation(
            manifold.entity,
          );
          const filledManifold: PlantEntity = fillPlantDefaults(
            context,
            manifold.entity,
            true,
          );

          if (isManifoldPlant(filledManifold.plant)) {
            const manifoldName =
              "Manifold " +
              manifoldCalc.manifoldId +
              (filledManifold.name === "Manifold"
                ? ""
                : ` (${filledManifold.name})`);
            const manifoldSpecs = HeatLoadCalculations.getPlantSpecs(
              context,
              filledManifold,
              filledManifold.plant,
              room.uid,
              manifoldName,
              filledManifold.name,
              manifoldCalc,
              roomCalc,
              (manifoldCalc.heatingRatingKW ?? 0) * 1000,
              null,
              roomCalc.totalHeatLossWatt ?? 1,
            );
            roomCalc.heatEmittersStats[manifoldUid] = manifoldSpecs.specs;
          }
        }
      }
    }
  }

  static calculateManifoldComponents(
    context: CalculationEngine,
    ufhCalcs: UnderfloorHeatingCalc[],
  ) {
    const loopsByManifold = this.loopsByManifold(ufhCalcs);

    for (const ufh of ufhCalcs) {
      if (!ufh.manifoldUid) continue;

      const manifold = context.globalStore.get<CorePlant>(ufh.manifoldUid);
      const filled = fillPlantDefaults(context, manifold.entity);
      if (!isManifoldPlantEntity(filled)) continue;
      assertType<ManifoldPlantEntity>(manifold.entity);
      const ufhSystem = getFlowSystem(
        context.drawing,
        filled.plant.ufhSystemUid,
      );

      // TODO we need to respect user model overrides
      this.calculateManifoldManufacturer(context, filled, loopsByManifold);
      this.calculateBallValveManufacturer(context, filled);
      this.calculateActuatorManufacturer(context, filled);
      this.calculatePumpPackManufacturer(context, filled, manifold.entity);
      this.finalizeManifoldPressureDrop(context, filled, ufhSystem);
      this.addManifoldWarnings(context, filled);
    }
  }

  private static calculatePumpPackManufacturer(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
    original: ManifoldPlantEntity,
  ) {
    const manuf = filled.plant.pumpPackManufacturer;

    if (!manuf || manuf === "generic") return;

    const data = context.catalog.underfloorHeating.pumpPack.datasheet;
    if (!data) return;

    const calc = context.globalStore.getOrCreateCalculation(filled);
    const model = calc.manifoldManufacturers.manifoldModel;
    const manifoldManuf = filled.plant.manifoldManufacturer;
    if (!model || !manifoldManuf) return;

    const models = getCompatiblePumpPacks(data, [manifoldManuf, model]);
    const modelsForManuf = models[manuf];
    const suitableModels = betterObjectValues(modelsForManuf);

    let pumpPackModel = original.plant.pumpPackModel;
    let pumpModel = original.plant.pumpModel;
    let pumpManufacturer = original.plant.pumpManufacturer;
    let blendingValveModel = original.plant.blendingValveModel;
    let blendingValveManufacturer = original.plant.blendingValveManufacturer;

    let pumpId =
      pumpModel && pumpManufacturer
        ? ([pumpManufacturer, pumpModel] as const)
        : null;
    let blendingValveId =
      blendingValveModel && blendingValveManufacturer
        ? ([blendingValveManufacturer, blendingValveModel] as const)
        : null;

    if (
      pumpPackModel == null ||
      // This case should have been caught by validation
      !suitableModels.find((m) => m.model === pumpPackModel)
    ) {
      const result = this.calculatePumpPackModel(
        context,
        filled,
        suitableModels,
      );
      if (result) {
        pumpPackModel = result.model;
      }
    }

    if (!pumpPackModel) {
      // We are done.
      return;
    }
    const pumpPack = suitableModels.find((m) => m.model === pumpPackModel)!;

    calc.manifoldManufacturers.pumpPackModel = pumpPackModel;

    if (
      pumpId == null ||
      // This case should have been caught by validation
      !pumpPack.pumps.find((p) => componentIdEquals(p, pumpId!))
    ) {
      const result = this.getUFHPumpInPack(context, filled, pumpPack);
      if (result) {
        pumpId = result;
      }
    }

    if (pumpId != null) {
      calc.manifoldManufacturers.pumpId = pumpId;
      // TODO: warnings
    }

    if (
      blendingValveId == null ||
      // This case should have been caught by validation
      !pumpPack.blendingValves.find((b) =>
        componentIdEquals(b, blendingValveId!),
      )
    ) {
      const result = this.calculateBlendingValveInPack(
        context,
        filled,
        pumpPack,
      );
      if (result) {
        blendingValveId = result;
      }
    }

    if (blendingValveId != null) {
      calc.manifoldManufacturers.blendingValveId = blendingValveId;
      // TODO: warnings
    }
  }

  private static calculateActuatorManufacturer(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
  ) {
    const manuf = filled.plant.actuatorManufacturer;

    if (!manuf || manuf === "generic") return;

    const data = context.catalog.underfloorHeating.actuator.datasheet;
    if (!data) return;

    const calc = context.globalStore.getOrCreateCalculation(filled);
    const model = calc.manifoldManufacturers.manifoldModel;
    const manifoldManuf = filled.plant.manifoldManufacturer;
    if (!model || !manifoldManuf) return;

    const models = getCompatibleActuators(data, [manifoldManuf, model]);
    const modelsForManuf = models[manuf];
    const suitableModels = betterObjectValues(modelsForManuf);

    if (suitableModels.length) {
      calc.manifoldManufacturers.actuatorModel = suitableModels[0].model;
    }
  }

  private static calculateBallValveManufacturer(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
  ) {
    const needsPumpPack = filled.plant.hasRecirculationPump;
    const manuf = filled.plant.ballValveManufacturer;

    if (!manuf || manuf === "generic") return;

    const data = context.catalog.underfloorHeating.ballValve.datasheet;
    if (!data) return;

    const calc = context.globalStore.getOrCreateCalculation(filled);
    const model = calc.manifoldManufacturers.manifoldModel;
    const manifoldManuf = filled.plant.manifoldManufacturer;
    if (!model || !manifoldManuf) return;

    const models = getCompatibleBallValves(data, [manifoldManuf, model]);
    const modelsForManuf = models[manuf];

    const suitableModels = betterObjectValues(modelsForManuf).filter(
      (m) => m.needsPumpPack === needsPumpPack,
    );

    if (suitableModels.length) {
      calc.manifoldManufacturers.ballValveModel = suitableModels[0].model;
    }
  }

  private static calculateManifoldManufacturer(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
    loopsByManifold: Map<string, UnderfloorHeatingCalc[]>,
  ) {
    // skip generic manifold model calcs
    if (filled.plant.manifoldManufacturer === "generic") return;

    const model = this.calculateManifoldModel(
      context,
      filled.uid,
      loopsByManifold,
    );
    if (model === null) {
      addWarning(context, "UFH_MANIFOLD_MODEL_NOT_FOUND", [filled], {
        mode: "mechanical",
      });
      return;
    }

    const calc = context.globalStore.getOrCreateCalculation(filled);
    calc.manifoldManufacturers.manifoldModel = model.model;
    calc.widthMM = model.widthMM;
    calc.depthMM = model.depthMM;
    calc.heightMM = model.heightMM;
  }

  private static calculateManifoldModel(
    context: CalculationEngine,
    manifoldUid: string,
    loopsByManifold: Map<string, UnderfloorHeatingCalc[]>,
  ): ManifoldModel | null {
    const manifold = context.globalStore.get<CorePlant>(manifoldUid);
    const filled = fillPlantDefaults(context, manifold.entity);
    assertType<ManifoldPlant>(filled.plant);
    assertType<ManifoldPlant>(manifold.entity.plant);

    const { manifoldManufacturer, manifoldRange } = filled.plant;
    const data = context.catalog.underfloorHeating.manifold.datasheet;

    if (manifold.entity.plant.manifoldModel === null) {
      const models = data[manifoldManufacturer!][manifoldRange!];

      const suitableModels = betterObjectValues(models)
        .filter(
          (model) =>
            model.outletsCount >=
            (loopsByManifold.get(manifoldUid!)?.length ?? 0),
        )
        .sort((a, b) => a.outletsCount! - b.outletsCount!);

      if (suitableModels.length === 0) {
        addWarning(context, "UFH_MANIFOLD_MODEL_NOT_FOUND", [filled], {
          mode: "mechanical",
        });
        return null;
      }

      return suitableModels[0];
    } else {
      // user has overridden the model
      const overriddenModel = manifold.entity.plant.manifoldModel;
      const model =
        data[manifoldManufacturer!][manifoldRange!][overriddenModel!];
      return model ?? null;
    }
  }

  private static calculatePumpPackModel(
    context: CalculationEngine,
    filledManifold: ManifoldPlantEntity,
    suitableModels: PumpPackModel[],
  ): PumpPackModel | null {
    for (const model of suitableModels) {
      const suitablePump = this.getUFHPumpInPack(
        context,
        filledManifold,
        model,
      );
      const suitableBlendingValve = this.calculateBlendingValveInPack(
        context,
        filledManifold,
        model,
      );
      if (suitablePump && suitableBlendingValve) {
        return model;
      }
    }

    // No suitable for both? Try just pump. We will leave the blending valve to be
    // incorrect and give a warning.
    for (const model of suitableModels) {
      const suitablePump = this.getUFHPumpInPack(
        context,
        filledManifold,
        model,
      );
      if (suitablePump) {
        return model;
      }
    }
    return null;
  }

  private static getUFHPumpInPack(
    context: CalculationEngine,
    filledManifold: ManifoldPlantEntity,
    model: PumpPackModel,
  ): ComponentId | null {
    const calc = context.globalStore.getOrCreateCalculation(filledManifold);

    const pressureDropKPA = calc.loopIndexPressureDropKPA;
    const flowRateLS = calc.loopTotalFlowRateLS;

    if (pressureDropKPA == null || flowRateLS == null) {
      return null;
    }

    for (const pumpId of model.pumps) {
      const pump = lookupUFHPump(context, pumpId);
      if (!pump) {
        continue;
      }

      if (this.checkUFHPump(pump, flowRateLS, pressureDropKPA).success) {
        return pumpId;
      }
    }

    return null;
  }

  private static checkUFHPump(
    pump: UFHPumpModel,
    flowRateLS: number,
    pressureDropKPA: number,
  ):
    | {
        success: true;
      }
    | {
        success: false;
        reason: "FLOW_RATE_TOO_HIGH";
        maxFlowRateLS: number;
      }
    | {
        success: false;
        reason: "PRESSURE_DROP_TOO_HIGH";
        maxPressureDropKPA: number;
      } {
    if (pump.maxFlowRateLS < flowRateLS - EPS) {
      return {
        success: false,
        reason: "FLOW_RATE_TOO_HIGH",
        maxFlowRateLS: pump.maxFlowRateLS,
      };
    }
    if (pump.maxHeadKPA < pressureDropKPA - EPS) {
      return {
        success: false,
        reason: "PRESSURE_DROP_TOO_HIGH",
        maxPressureDropKPA: pump.maxHeadKPA,
      };
    }
    if (pump.pressureDropKPAByFlowRateLS) {
      if (
        !functionByPartsInDomain(pump.pressureDropKPAByFlowRateLS, flowRateLS)
      ) {
        return {
          success: false,
          reason: "FLOW_RATE_TOO_HIGH",
          maxFlowRateLS: pump.maxFlowRateLS,
        };
      }
      const maxPressureDropKPA = evaluateFunctionByParts(
        pump.pressureDropKPAByFlowRateLS,
        flowRateLS,
      )!;
      if (maxPressureDropKPA! < pressureDropKPA) {
        return {
          success: false,
          reason: "PRESSURE_DROP_TOO_HIGH",
          maxPressureDropKPA,
        };
      }
    }

    return { success: true };
  }

  private static calculateBlendingValveInPack(
    context: CalculationEngine,
    filledManifold: ManifoldPlantEntity,
    model: PumpPackModel,
  ): ComponentId | null {
    const calc = context.globalStore.getOrCreateCalculation(filledManifold);

    const pressureDropKPA = calc.loopIndexPressureDropKPA;
    const flowRateLS = calc.loopTotalFlowRateLS;
    const heatOutputKW = calc.heatingRatingKW;
    const flowTempC = filledManifold.plant.ufhFlowTempC;

    if (pressureDropKPA == null || flowRateLS == null) {
      return null;
    }
    if (heatOutputKW == null || flowTempC == null) {
      return null;
    }

    for (const valveId of model.blendingValves) {
      const valve = lookupBlendingValve(context, valveId);
      if (!valve) {
        continue;
      }

      const result = this.checkBlendingValve(
        valve,
        flowRateLS,
        pressureDropKPA,
        heatOutputKW,
        flowTempC,
      );
      if (result.success === true) {
        return valveId;
      }
    }

    return null;
  }

  private static checkBlendingValve(
    valve: BlendingValveModel,
    flowRateLS: number,
    pressureDropKPA: number,
    heatOutputKW: number,
    flowTempC: number,
  ):
    | {
        success: true;
      }
    | {
        success: false;
        reason: "HEAT_OUTPUT_TOO_HIGH";
        maxHeatOutputKW: number;
      }
    | {
        success: false;
        reason: "FLOW_TEMP_TOO_HIGH";
        maxFlowTempC: number;
      }
    | {
        success: false;
        reason: "FLOW_TEMP_TOO_LOW";
        minFlowTempC: number;
      } {
    if (
      valve.maxHeatOutputKW != null &&
      valve.maxHeatOutputKW < heatOutputKW - EPS
    ) {
      return {
        success: false,
        reason: "HEAT_OUTPUT_TOO_HIGH",
        maxHeatOutputKW: valve.maxHeatOutputKW,
      };
    }

    if (valve.maxFlowTempC != null && flowTempC > valve.maxFlowTempC + EPS) {
      return {
        success: false,
        reason: "FLOW_TEMP_TOO_HIGH",
        maxFlowTempC: valve.maxFlowTempC,
      };
    }

    if (valve.minFlowTempC != null && flowTempC < valve.minFlowTempC - EPS) {
      return {
        success: false,
        reason: "FLOW_TEMP_TOO_LOW",
        minFlowTempC: valve.minFlowTempC,
      };
    }

    return { success: true };
  }

  private static finalizeManifoldPressureDrop(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
    flowSystem?: FlowSystem,
  ) {
    const calcs = context.globalStore.getOrCreateCalculation(filled);

    calcs.manifoldComponentPressureDropKPA = 0;

    const actuator = lookupActuator(context, [
      filled.plant.actuatorManufacturer!,
      calcs.manifoldManufacturers.actuatorModel!,
    ]);
    if (actuator && calcs.loopIndexHasActuator) {
      // TODO: this is so minor but it's still vulnerable to the edge case where
      // the second highest pressure drop loop actually becomes the highest pressure drop loop
      // after the actuator is added.
      calcs.manifoldComponentPressureDropKPA +=
        PressureDropCalculations.kValueToPressureDropKPA(
          context,
          // in order to support the old UFH system before we have added the ufh flow
          // systems, we will default to water when it's missing.
          flowSystem?.fluid ?? "water",
          actuator.kValue,
          calcs.loopIndexVelocityMS!,
        );
    }

    const ballValve = lookupBallValve(context, [
      filled.plant.ballValveManufacturer!,
      calcs.manifoldManufacturers.ballValveModel!,
    ]);

    // TODO
    if (ballValve) {
      // calcs.manifoldComponentPressureDropKPA +=
      //   PressureDropCalculations.kValueToPressureDropKPA(
      //     context,
      //     flowSystem.fluid,
      //     ballValve.kValue,
      //     ballValve.si
      //   );
    }

    if (
      calcs.manifoldManufacturers.blendingValveId &&
      filled.plant.hasRecirculationPump
    ) {
      const blendingValve = lookupBlendingValve(
        context,
        calcs.manifoldManufacturers.blendingValveId,
      );
      if (blendingValve && blendingValve.pressureDropKPAByFlowRateLS) {
        const pressureDropKPA = evaluateFunctionByParts(
          blendingValve.pressureDropKPAByFlowRateLS,
          calcs.loopTotalFlowRateLS!,
        );
        calcs.manifoldComponentPressureDropKPA += pressureDropKPA ?? 0;
      }
    }

    if (!filled.plant.hasRecirculationPump) {
      if (
        filled.plant.pressureLoss.pressureMethod ===
          PressureMethod.FIXED_PRESSURE_LOSS &&
        filled.plant.pressureLoss.pressureLossKPA != null
      ) {
        calcs.pressureDropKPA = filled.plant.pressureLoss.pressureLossKPA;
      } else {
        if (
          calcs.loopIndexPressureDropKPA == null ||
          calcs.manifoldComponentPressureDropKPA == null
        ) {
          calcs.pressureDropKPA = null;
        } else {
          calcs.pressureDropKPA =
            calcs.loopIndexPressureDropKPA +
            calcs.manifoldComponentPressureDropKPA;
        }
      }
    } else {
      if (
        calcs.loopIndexPressureDropKPA != null &&
        calcs.manifoldComponentPressureDropKPA != null
      ) {
        calcs.pumpDutyKPA =
          calcs.loopIndexPressureDropKPA +
          calcs.manifoldComponentPressureDropKPA;
        PumpCalculations.setPumpDutyFields(
          context,
          null,
          calcs,
          calcs.loopTotalFlowRateLS,
          true,
          componentIdToString(calcs.manifoldManufacturers.pumpId) ?? undefined,
        );
      }
    }
  }

  private static addManifoldWarnings(
    context: CalculationEngine,
    filled: ManifoldPlantEntity,
  ) {
    const calcs = context.globalStore.getOrCreateCalculation(filled);
    if (manifoldHasUfhPump(context, filled)) {
      const pump = lookupUFHPump(context, calcs.manifoldManufacturers.pumpId);
      if (pump) {
        const result = this.checkUFHPump(
          pump,
          calcs.loopTotalFlowRateLS ?? 0,
          calcs.loopIndexPressureDropKPA ?? 0,
        );
        if (result.success === false) {
          if (result.reason === "FLOW_RATE_TOO_HIGH") {
            addWarning(context, "MANIFOLD_PUMP_FLOW_RATE_TOO_HIGH", [filled], {
              mode: "mechanical",
              params: {
                flowRateLS: calcs.loopTotalFlowRateLS!,
                maxFlowRateLS: result.maxFlowRateLS!,
              },
              replaceSameWarnings: true,
            });
          } else if (result.reason === "PRESSURE_DROP_TOO_HIGH") {
            addWarning(context, "MANIFOLD_PUMP_PRESSURE_TOO_HIGH", [filled], {
              mode: "mechanical",
              params: {
                dutyKPA: calcs.loopIndexPressureDropKPA!,
                maxKPA: result.maxPressureDropKPA!,
                flowRateLS: calcs.loopTotalFlowRateLS!,
              },
              replaceSameWarnings: true,
            });
          } else {
            assertUnreachable(result);
          }
        }
      }
    }

    if (calcs.manifoldManufacturers.blendingValveId) {
      const valve = lookupBlendingValve(
        context,
        calcs.manifoldManufacturers.blendingValveId,
      );
      if (valve) {
        const result = this.checkBlendingValve(
          valve,
          calcs.loopTotalFlowRateLS ?? 0,
          calcs.loopIndexPressureDropKPA ?? 0,
          calcs.heatingRatingKW ?? 0,
          filled.plant.ufhFlowTempC!,
        );
        if (result.success === false) {
          if (result.reason === "HEAT_OUTPUT_TOO_HIGH") {
            addWarning(
              context,
              "MANIFOLD_VALVE_HEAT_OUTPUT_TOO_HIGH",
              [filled],
              {
                mode: "mechanical",
                params: {
                  heatOutputKW: calcs.heatingRatingKW!,
                  maxHeatOutputKW: result.maxHeatOutputKW!,
                },
                replaceSameWarnings: true,
              },
            );
          } else if (result.reason === "FLOW_TEMP_TOO_HIGH") {
            addWarning(context, "MANIFOLD_VALVE_FLOW_TEMP_TOO_HIGH", [filled], {
              mode: "mechanical",
              params: {
                flowTempC: filled.plant.ufhFlowTempC!,
                maxFlowTempC: result.maxFlowTempC!,
              },
              replaceSameWarnings: true,
            });
          } else if (result.reason === "FLOW_TEMP_TOO_LOW") {
            addWarning(context, "MANIFOLD_VALVE_FLOW_TEMP_TOO_LOW", [filled], {
              mode: "mechanical",
              params: {
                flowTempC: filled.plant.ufhFlowTempC!,
                minFlowTempC: result.minFlowTempC!,
              },
              replaceSameWarnings: true,
            });
          } else {
            assertUnreachable(result);
          }
        }
      }
    }
  }
}

function getSingleUFHRoom(context: CalculationEngine) {
  for (const obj of context.networkObjects()) {
    if (isRoomRoomEntity(obj.entity)) {
      const calc = context.globalStore.getOrCreateCalculation(obj.entity);
      if (calc.underfloorHeating.loopMode) {
        return obj.entity;
      }
    }
  }
  throw new Error("No room found");
}

// algorithm based on bitmask dynamic programming.
// approximation is used to reduce the number of subsets from O(2^n) to <= NUM_OF_BUCKETS
// if too slow, change the NUM_OF_BUCKETS to a smaller number
export function calculateOptimalCoilMapping(
  loops: UnderfloorHeatingCalc["loopsStats"],
  availableCoilsLengthsM: number[],
): { totalLength: number; mapping: CoilMapping[] } {
  loops.sort((a, b) => a.lengthM! - b.lengthM!);
  availableCoilsLengthsM = availableCoilsLengthsM
    .map((a) => Number(a))
    .sort((u, v) => u - v);
  const result: CoilMapping[] = [];
  const maxCoil: number =
    availableCoilsLengthsM[availableCoilsLengthsM.length - 1];

  for (let i = loops.length - 1; i >= 0; i--) {
    if (loops[i].lengthM! > maxCoil) {
      result.push({
        coilLengthM: maxCoil,
        loopsCovered: [loops[i]],
      });
      loops.splice(i, 1);
    }
  }

  while (loops.length > 14) {
    const NUM_OF_BUCKETS = Math.max(
      50,
      Math.min(2000000, 2000000 / loops.length / loops.length),
    );
    const eps: number = maxCoil / NUM_OF_BUCKETS;
    let obtainable: [number, number[]][] = [[0, []]];
    for (let i = 0; i < loops.length; i++) {
      obtainable = obtainable.concat(
        obtainable.map(([w, l]: [number, number[]]) => [
          w + loops[i].lengthM!,
          [...l, i],
        ]),
      );
      obtainable.sort(
        (x: [number, number[]], y: [number, number[]]) => x[0] - y[0],
      );
      const newObtainable: [number, number[]][] = [];
      obtainable.forEach(([w, l]: [number, number[]]) => {
        if (
          newObtainable.length === 0 ||
          w - newObtainable[newObtainable.length - 1][0] > eps
        ) {
          newObtainable.push([w, l]);
        }
      });
      obtainable = newObtainable;
    }
    let optimalTakeOut: number[] = [];
    let minWaste: number = maxCoil;
    let coilChoice: number = maxCoil;
    let curr = 0;
    obtainable.forEach(([w, l]: [number, number[]]) => {
      while (
        curr < availableCoilsLengthsM.length &&
        availableCoilsLengthsM[curr] < w
      )
        curr++;
      if (curr === availableCoilsLengthsM.length) return;
      if (availableCoilsLengthsM[curr] - w < minWaste) {
        optimalTakeOut = l;
        minWaste = availableCoilsLengthsM[curr] - w;
        coilChoice = availableCoilsLengthsM[curr];
      }
    });
    result.push({
      coilLengthM: coilChoice,
      loopsCovered: optimalTakeOut.map((i: number) => loops[i]),
    });
    [...optimalTakeOut]
      .sort((a: number, b: number) => a - b)
      .reverse()
      .forEach((i: number) => loops.splice(i, 1));
  }

  if (loops.length > 0) {
    const bitdp: [number, number, number][] = new Array(1 << loops.length); // [minWaste, lastChoiceMask, lastCoilLength]
    const maskSum: number[] = new Array(1 << loops.length);
    maskSum[0] = 0;
    for (let hb = 0; hb < loops.length; hb++)
      for (let i = 0; i < 1 << hb; i++)
        maskSum[(1 << hb) + i] = maskSum[i] + loops[hb].lengthM!;
    bitdp[0] = [0, -1, -1];
    for (let hb = 0; hb < loops.length; hb++) {
      const pairs: [number, number][] = [];
      for (let i = 0; i < 1 << hb; i++) {
        bitdp[(1 << hb) + i] = [maxCoil * 2, -1, -1];
        pairs.push([(1 << hb) + i, maskSum[(1 << hb) + i]]);
      }
      pairs.sort((u: [number, number], v: [number, number]) => u[1] - v[1]);
      let curr = 0;
      for (const [pairMask, pairSum] of pairs) {
        while (
          curr < availableCoilsLengthsM.length &&
          availableCoilsLengthsM[curr] < pairSum
        )
          curr++;
        if (curr === availableCoilsLengthsM.length) break;
        for (let i = pairMask; i < 2 << hb; i = (i + 1) | pairMask) {
          const replaceWith: [number, number, number] = [
            bitdp[i - pairMask][0] + availableCoilsLengthsM[curr] - pairSum,
            pairMask,
            availableCoilsLengthsM[curr],
          ];
          if (replaceWith[0] < bitdp[i][0]) bitdp[i] = replaceWith;
        }
      }
    }
    for (let i = bitdp.length - 1; i > 0; ) {
      const toPush: CoilMapping = {
        coilLengthM: bitdp[i][2],
        loopsCovered: [],
      };
      for (let j = 0; j < loops.length; j++)
        if ((bitdp[i][1] >> j) & 1) {
          toPush.loopsCovered.push(loops[j]);
        }
      result.push(toPush);
      i -= bitdp[i][1];
    }
  }
  return {
    totalLength: result
      .map((l) => l.coilLengthM)
      .reduce((u: number, v: number) => u + v, 0),
    mapping: result,
  };
}

export function getCoilsData(context: CoreContext): CoilMapping[] | null {
  for (const o of context.globalStore.values()) {
    if (
      o.type === EntityType.ROOM &&
      o.entity.room.roomType === RoomType.ROOM
    ) {
      const rCalc = context.globalStore.getOrCreateCalculation(o.entity);
      if (rCalc.underfloorHeating.coils.length > 0) {
        return rCalc.underfloorHeating.coils;
      }
    }
  }

  return null;
}
