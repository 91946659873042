import Flatten from "@flatten-js/core";
import {
  canonizeAngleRad,
  isParallelRad,
} from "../../../../lib/mathUtils/mathutils";
import { EPS, assertUnreachable } from "../../../../lib/utils";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { CoilCoord3D } from "../../underfloor-heating/coil-coords";
import { UFH_BELOW_FLOOR_HEIGHT_MM } from "../../underfloor-heating/consts";
import { LoopSite } from "../../underfloor-heating/underfloor-heating";
import {
  IGNORE_COLLISION_EPS,
  IntentionNode,
  LoopGenerator,
  TraceResult,
  foreachIntention,
} from "./loop-generator";
import { LoopObstacle } from "./loop-obstacles";

const MAX_SPIRAL_EXTENSION_ITERATIONS = 20;
const SPIRAL_LOOP_MAX_ITER = 100;

export type SpiralExtension = {
  type: "side";
  startCoord: Flatten.Point;
  direction: Flatten.Vector;
  intention: IntentionNode;
  // How much closer we had to go within the obstacle radii
  // in order to make it.
  firstLength: number;
  intentionNumber: number;
};

export function serializeSpiralExtension(ext: SpiralExtension): string {
  return `${ext.type} ${ext.startCoord.x} ${ext.startCoord.y} ${ext.direction.x} ${ext.direction.y}`;
}

export class SpiralGenerator {
  static generateSpiralLoop(
    allObstacles: LoopObstacle[],
    site: LoopSite,
    transitSpacingMM: number,
    loopSpacingMM: number,
    dir: "cw" | "ccw",
    normal: Flatten.Vector,
    center: Flatten.Point,
    fromManifold: CoilCoord3D[],
    toManifold: CoilCoord3D[],
    debugObj?: RoomCalculation["debug"],
  ) {
    const { intentionTree } = this.generateSpiralIntentionTree(
      allObstacles,
      center,
      normal,
      loopSpacingMM,
      transitSpacingMM,
      dir,
      debugObj,
    );

    const incidentLines: Flatten.Line[] =
      LoopGenerator.getIncidentLines(intentionTree);

    // Remove consecutive parallel lines
    for (let i = incidentLines.length - 1; i > 0; i--) {
      if (incidentLines[i].parallelTo(incidentLines[i - 1])) {
        incidentLines.splice(i, 1);
      }
    }

    const roomLoop: CoilCoord3D[] = [];

    for (let i = 0; i < incidentLines.length - 1; i++) {
      const intersection = incidentLines[i].intersect(incidentLines[i + 1]);

      if (intersection.length > 0) {
        roomLoop.push({
          x: intersection[0].x,
          y: intersection[0].y,
          z: UFH_BELOW_FLOOR_HEIGHT_MM,
          turnRadiusMM: loopSpacingMM / 2,
        });
      }
    }

    return LoopGenerator.smoothOutWholeLoop(
      {
        roomLoop,
        toManifold,
        fromManifold,
      },
      {
        minRadiusMM: site.underfloorHeating.minBendRadiusMM!,
        avoidCenterBulb: true,
        loopSpacingMM: loopSpacingMM,
      },
    );
  }

  static generateSpiralIntentionTree(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    startNormal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    dir: "cw" | "ccw", // cw / ccw wrt our coordinate system with x right and y down,
    debugObj?: RoomCalculation["debug"],
  ): {
    intentionTree: IntentionNode;
    debug: {
      color: string;
      chain: Flatten.Point[];
    }[];
  } {
    let firstStep: Flatten.Point = center.translate(
      startNormal.multiply(loopSpacingMM),
    );

    let currDirection: Flatten.Vector =
      dir == "cw" ? startNormal.rotate90CW() : startNormal.rotate90CCW();
    let brokenFromFirstStep = false;
    for (let _fs = 50; _fs--; ) {
      const tte: TraceResult | null = LoopGenerator.traceToEnd({
        curr: firstStep,
        radius: loopSpacingMM,
        obstacles,
        direction: currDirection,
        hitLenienceMM: loopSpacingMM * 0.5,
        dontLikeHeadOnHit: true,
      });
      if (tte == null) {
        firstStep = firstStep.translate(
          startNormal.multiply(loopSpacingMM * 0.05),
        );
      } else {
        brokenFromFirstStep = true;
        break;
      }
    }

    if (!brokenFromFirstStep) {
      firstStep = center.translate(startNormal.multiply(loopSpacingMM / 2));
      const tte = LoopGenerator.traceToEnd({
        curr: firstStep,
        radius: transitSpacingMM,
        obstacles,
        direction: startNormal,
        hitLenienceMM: transitSpacingMM * 0.5,
        nextRadius: loopSpacingMM,
        avoidLeafJunctions: true,
        side: dir,
      });
      if (tte == null) {
        return {
          intentionTree: {
            coord: center,
            next: [],
            spacingMM: transitSpacingMM,
          },
          debug: [],
        };
      } else {
        firstStep = firstStep.translate(startNormal.multiply(tte.length));
        if (tte.type === "hit") {
          currDirection = Flatten.vector(
            tte.hit.segment.start,
            tte.hit.segment.end,
          ).normalize();
          if (
            (dir == "cw" && currDirection.cross(startNormal) > 0) ||
            (dir == "ccw" && currDirection.cross(startNormal) < 0)
          ) {
            currDirection = currDirection.multiply(-1);
          }
        } else if (tte.type === "junction") {
          if (!tte.adjacent) {
            currDirection = currDirection.rotate90CW();
          } else {
            currDirection = Flatten.vector(
              tte.adjacent!.segment.start,
              tte.adjacent!.segment.end,
            ).normalize();
          }
          if (
            (dir == "cw" && currDirection.cross(startNormal) < 0) ||
            (dir == "ccw" && currDirection.cross(startNormal) > 0)
          ) {
            currDirection = currDirection.multiply(-1);
          }
        }
      }
    }

    const intentionTree: IntentionNode = {
      coord: center,
      next: [{ coord: firstStep, next: [], spacingMM: transitSpacingMM }],
      spacingMM: transitSpacingMM,
    };

    const extraObstacles: LoopObstacle[] = [
      {
        segment: Flatten.segment(
          intentionTree.coord,
          intentionTree.next[0].coord,
        ),

        // Spacing is actually transitSpacingMM, but unfortunately
        // that tight spacing creates edge cases when the loop comes around
        // gets too close and encounters the first full width obstacle.
        radiusMM: transitSpacingMM,
      },
    ];

    obstacles.push(extraObstacles[0]);

    const initialObstacles = obstacles.slice();

    const currNode: IntentionNode = intentionTree.next[0];

    this.generateSpiralPart(
      currNode,
      currDirection,
      dir,
      obstacles,
      loopSpacingMM,
    );

    // Try serpentine extensions for now.

    const seenExtensions = new Set<string>();

    const extensions: SpiralExtension[] = [];
    const compareExtensions = (a: SpiralExtension, b: SpiralExtension) => {
      if (a.intentionNumber !== b.intentionNumber) {
        // Higher is better - we want to backtrack and start close to the end.
        return b.intentionNumber - a.intentionNumber;
      }
      if (a.firstLength !== b.firstLength) {
        // Longer travel is better.
        return b.firstLength - a.firstLength;
      }
      return 0;
    };
    for (let i = 0; i < 1000; i++) {
      extensions.splice(0, extensions.length);
      extensions.push(
        ...this.getSpiralExtensions(
          intentionTree,
          obstacles,
          loopSpacingMM,
          dir,
        ).filter((ext) => {
          const serialized = serializeSpiralExtension(ext);
          if (seenExtensions.has(serialized)) {
            return false;
          }
          return true;
        }),
      );

      extensions.sort(compareExtensions);

      // console.log("Extensions", extensions);

      if (i >= MAX_SPIRAL_EXTENSION_ITERATIONS) {
        break;
      }

      if (extensions.length === 0) {
        break;
      }

      for (const currExtension of extensions) {
        let parent: IntentionNode | null = null;
        foreachIntention(
          intentionTree,
          (from: IntentionNode, to: IntentionNode) => {
            if (to === currExtension.intention) {
              parent = from;
            }
          },
        );

        // We know parent is not null, but TypeScript can't know it.
        parent = parent as unknown as IntentionNode;
        if (
          parent != null &&
          currExtension.startCoord.distanceTo(parent.coord)[0] +
            currExtension.startCoord.distanceTo(
              currExtension.intention.coord,
            )[0] <
            currExtension.intention.coord.distanceTo(parent.coord)[0] + EPS
        ) {
          const newIntention: IntentionNode = {
            coord: currExtension.intention.coord,
            next: currExtension.intention.next,
            spacingMM: loopSpacingMM,
          };
          currExtension.intention.coord = currExtension.startCoord;
          currExtension.intention.next = [newIntention];
          const generated = this.generateSpiralPart(
            currExtension.intention,
            currExtension.direction,
            dir,
            obstacles,
            loopSpacingMM,
          );

          if (generated) {
            seenExtensions.add(serializeSpiralExtension(currExtension));
            break;
          } else {
            // backtrack the intention split
            currExtension.intention.coord = newIntention.coord;
            currExtension.intention.next = newIntention.next;
          }
        } else {
          const newIntention: IntentionNode = {
            coord: currExtension.startCoord,
            next: [],
            spacingMM: loopSpacingMM,
          };
          const closerPoint: IntentionNode =
            parent.coord.distanceTo(currExtension.startCoord)[0] <
            currExtension.intention.coord.distanceTo(
              currExtension.startCoord,
            )[0]
              ? parent
              : currExtension.intention;
          closerPoint.next.push(newIntention);
          const generated = this.generateSpiralPart(
            newIntention,
            currExtension.direction,
            dir,
            obstacles,
            loopSpacingMM,
          );

          if (generated) {
            seenExtensions.add(serializeSpiralExtension(currExtension));
            break;
          } else {
            // backtrack the intention split
            closerPoint.next.pop();
          }
        }
      }
    }

    foreachIntention(intentionTree, (from, to) => {
      debugObj?.push({
        color: to.color ?? "#FF00FF",
        chain: [from.coord, to.coord],
        role: "loop",
      });
    });

    return {
      intentionTree: LoopGenerator.sanitizeIntentionTree(intentionTree),
      debug: extensions
        .map((ext) => ({
          color: "#777700",
          chain: [
            ext.startCoord,
            ext.startCoord.translate(ext.direction.normalize().multiply(200)),
          ],
        }))
        .concat(
          initialObstacles.map((obs) => ({
            color: "#FF0000",
            chain: [obs.segment.start, obs.segment.end],
          })),
        ),
    };
  }

  static getSpiralExtensions(
    intentionNode: IntentionNode,
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
    dir: "cw" | "ccw",
  ) {
    // There's only really one type of extension. Running along the inside side of existing obstacles.
    // that will cause loops to be generated the right way.

    // TODO: second type of extension, refer to peanut 2 bottom right corner. And imagine the loop going
    // opposite direction but via the same pipes. There needs to be something else to fill that gap.
    const result: SpiralExtension[] = [];
    const dirMul = dir === "cw" ? -1 : 1;

    let i = 0;
    foreachIntention(intentionNode, (from, to) => {
      if (
        from.coord.distanceTo(to.coord)[0] < EPS ||
        from.coord.equalTo(to.coord)
      ) {
        return;
      }
      i++;
      const myVector = Flatten.vector(from.coord, to.coord);
      const mySegment = Flatten.segment(from.coord, to.coord);
      const myLine = Flatten.line(from.coord, to.coord);
      for (const guide of obstacles) {
        const spacing = loopSpacingMM + guide.radiusMM;
        const guideVector = Flatten.vector(
          guide.segment.start,
          guide.segment.end,
        );
        if (guideVector.length === 0) {
          continue;
        }
        if (isParallelRad(0, guideVector.angleTo(myVector), 0.01)) {
          continue;
        }
        const dist = guide.segment.distanceTo(mySegment)[0];

        if (dist > spacing + loopSpacingMM * 2) {
          continue;
        }

        const guideNormal = guideVector.rotate90CCW().normalize();

        const pA = guide.segment.start.translate(guideNormal.multiply(spacing));
        const pB = guide.segment.start.translate(
          guideNormal.multiply(-spacing),
        );
        const lineA = Flatten.line(pA, pA.translate(guideVector));
        const lineB = Flatten.line(pB, pB.translate(guideVector));

        const intersectionA = myLine.intersect(lineA)[0];
        const intersectionB = myLine.intersect(lineB)[0];

        const dotA = Flatten.vector(from.coord, intersectionA).dot(
          myVector.normalize(),
        );
        const dotB = Flatten.vector(from.coord, intersectionB).dot(
          myVector.normalize(),
        );

        const farDot = Math.max(dotA, dotB);
        const nearDot = Math.min(dotA, dotB);

        // We'll just pick. For the +1 dirMul case, the far one must go to the
        // right and the near one to the left.
        const startVec = Flatten.vector(from.coord, guide.segment.start);
        const endVec = Flatten.vector(from.coord, guide.segment.end);
        const startCross = myVector.cross(startVec);
        const endCross = myVector.cross(endVec);

        const hasRight = startCross * dirMul > 100 || endCross * dirMul > 100;
        const hasLeft = startCross * dirMul < -100 || endCross * dirMul < -100;

        const ALLOWED_SHAVE_LENGTH_MM = loopSpacingMM;

        const guideSign = myVector.cross(guideVector) > 0 ? 1 : -1;
        if (hasRight) {
          const effectiveDot = Math.min(
            Math.max(
              farDot,
              from === intentionNode ? 0 : -ALLOWED_SHAVE_LENGTH_MM,
            ),
            myVector.length + ALLOWED_SHAVE_LENGTH_MM,
          );
          // changed a lot of things
          // recording `shavedLength` in the extension object doesn't make sense at all
          // as long as it is within a range and is valid,
          // we don't care about it when comparing extensions
          const shavedLength = Math.abs(farDot - effectiveDot);
          if (shavedLength < EPS) {
            const startCoord = from.coord.translate(
              myVector.normalize().multiply(effectiveDot),
            );
            const startToGuideA = Flatten.vector(
              startCoord,
              guide.segment.start,
            );
            const startToGuideB = Flatten.vector(startCoord, guide.segment.end);
            const dotA = startToGuideA.dot(myVector.normalize());
            const dotB = startToGuideB.dot(myVector.normalize());
            const guideDot = Math.max(dotA, dotB);

            if (guideDot > loopSpacingMM * 0.5) {
              result.push({
                type: "side",
                startCoord,
                // startCoord: intersectionA,
                direction: guideVector.normalize().multiply(guideSign * dirMul),
                intention: to,
                firstLength: 0,
                intentionNumber: i,
              });
            }
          }
        }
        if (hasLeft) {
          const effectiveDot = Math.min(
            Math.max(
              nearDot,
              from === intentionNode ? 0 : -ALLOWED_SHAVE_LENGTH_MM,
            ),
            myVector.length + ALLOWED_SHAVE_LENGTH_MM,
          );
          const shavedLength = Math.abs(effectiveDot - nearDot);
          if (shavedLength < EPS) {
            const startCoord = from.coord.translate(
              myVector.normalize().multiply(effectiveDot),
            );
            const startToGuideA = Flatten.vector(
              startCoord,
              guide.segment.start,
            );
            const startToGuideB = Flatten.vector(startCoord, guide.segment.end);
            const dotA = startToGuideA.dot(myVector.normalize());
            const dotB = startToGuideB.dot(myVector.normalize());
            const guideDot = Math.max(dotA, dotB);

            if (guideDot > loopSpacingMM * 0.5) {
              result.push({
                type: "side",
                startCoord: from.coord.translate(
                  myVector.normalize().multiply(effectiveDot),
                ),
                // startCoord: intersectionB,
                direction: guideVector
                  .normalize()
                  .multiply(-guideSign * dirMul),
                intention: to,
                firstLength: 0,
                intentionNumber: i,
              });
            }
          }
        }
      }
    });

    // deduplicate and filter extensions
    const seenExtensions = new Set<string>();
    const deduped: SpiralExtension[] = [];
    for (const ext of result) {
      const serialized = serializeSpiralExtension(ext);
      if (!seenExtensions.has(serialized)) {
        seenExtensions.add(serialized);
        deduped.push(ext);
      }
    }

    const filtered = deduped
      .filter((ext) => {
        // Needs to be able to move forward a bit.
        const result = LoopGenerator.traceToEnd({
          // TODO: This might cause problems. in sol #2, we shouldn't ignore short length sometimes
          curr: ext.startCoord,
          radius: loopSpacingMM,
          obstacles,
          direction: ext.direction,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.5,
          minLengthMM: loopSpacingMM * 1.5,
        });
        if (result && result.type !== "miss") {
          ext.firstLength = result.length;
          return true;
        }
      })
      .filter((ext) => {
        if (
          ext.startCoord.distanceTo(ext.intention.coord)[0] >
          IGNORE_COLLISION_EPS
        ) {
          return true;
        }
        // Avoid acute angles around the corners
        const allVectors = ext.intention.next.map((n) =>
          Flatten.vector(ext.startCoord, n.coord),
        );
        allVectors.push(ext.direction.multiply(-1));

        for (const vec of allVectors) {
          if (vec.length < EPS) {
            return false;
          }
          const angle = Math.abs(canonizeAngleRad(vec.angleTo(ext.direction)));
          if (angle < Math.PI * 0.5) {
            return false;
          }
        }
        return true;
      });

    return filtered;
  }

  static generateSpiralPart(
    intentionNode: IntentionNode,
    initialDirection: Flatten.Vector,
    dir: "cw" | "ccw", // cw / ccw wrt our coordinate system with x right and y down
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
    print?: boolean,
  ): number {
    let currNode = intentionNode;
    let currDirection = initialDirection;
    let lastDirection = initialDirection;
    let state: "outside" | "straight" | "inside" = "outside";

    let generated = 0;
    const origObstacleLength = obstacles.length;
    for (let i = 0; i < SPIRAL_LOOP_MAX_ITER; i++) {
      const tte = LoopGenerator.traceToEnd({
        curr: currNode.coord,
        radius: loopSpacingMM,
        obstacles,
        direction: currDirection,
        hitLenienceMM: loopSpacingMM * 0.51,
        minLengthMM: loopSpacingMM * 0.5,
        nextRadius: loopSpacingMM,
        onlyBlocks: false,
        side: dir,
        dontLikeHeadOnHit: true,
        avoidLeafJunctions: true,
        print,
      });

      console.log({ tte });

      if (tte == null) {
        if (state === "outside") {
          state = "straight";
          currDirection = lastDirection;
          continue;
        } else {
          // Although it is possible for straight to continue by turning left,
          // it is hard to calculate the angle from here. However, the plan is to get
          // extensions capable enough to continue the loop correctly. So here we can just
          // exit. Doing the case above though is still necesary to make sure that
          // we never have to extend straight.
          break;
        }
      }

      let nextDirection: Flatten.Vector | null = null;
      if (state === "straight") {
        currNode.coord = currNode.coord.translate(
          currDirection.multiply(tte.length),
        );
        const o = obstacles[obstacles.length - 1];
        o.segment = Flatten.segment(o.segment.start, currNode.coord);
      } else {
        const nextNode: IntentionNode = {
          coord: currNode.coord.translate(currDirection.multiply(tte.length)),
          next: [],
          spacingMM: loopSpacingMM,
        };
        currNode.next.push(nextNode);
        obstacles.push({
          segment: Flatten.segment(currNode.coord, nextNode.coord),
          radiusMM: loopSpacingMM,
        });
        currNode = nextNode;
      }
      if (tte.type === "hit") {
        if (tte.hitType === "lateral") {
          nextDirection = Flatten.vector(
            tte.hit.segment.start,
            tte.hit.segment.end,
          ).normalize();
          state = "inside";
          if (
            (dir == "cw" && nextDirection.cross(currDirection) > 0) ||
            (dir == "ccw" && nextDirection.cross(currDirection) < 0)
          ) {
            nextDirection = nextDirection.multiply(-1);
          }
        } else if (tte.hitType === "head-on") {
          // This shouldn't really be happening, if it does idk, keep going forward?
          const seg = tte.point.distanceTo(tte.hit.segment)[1];
          nextDirection = Flatten.vector(seg.start, seg.end)
            .normalize()
            .rotate90CW();
          state = "inside";
          if (
            (dir == "cw" && nextDirection.cross(currDirection) > 0) ||
            (dir == "ccw" && nextDirection.cross(currDirection) < 0)
          ) {
            nextDirection = nextDirection.multiply(-1);
          }
        } else {
          assertUnreachable(tte.hitType);
        }
      } else if (tte.type === "junction") {
        if (tte.adjacent != null) {
          nextDirection = Flatten.vector(
            tte.adjacent.segment.start,
            tte.adjacent.segment.end,
          ).normalize();
          state = "outside";
          if (
            (dir == "cw" && nextDirection.cross(currDirection) < 0) ||
            (dir == "ccw" && nextDirection.cross(currDirection) > 0)
          ) {
            nextDirection = nextDirection.multiply(-1);
          }
        }
      } else {
        break;
      }

      if (!nextDirection) {
        break;
      }

      generated += tte.length;
      lastDirection = currDirection;
      currDirection = nextDirection;
    }

    //     currNode.next.push({
    //       coord: currNode.coord.translate(
    //         currDirection.multiply(loopSpacingMM * 10)
    //       ),
    //       next: [],
    //       color: "#FF0000",
    //     });

    if (!generated) {
      obstacles.splice(origObstacleLength);
    }
    return generated;
  }
}
