import { cloneDeep } from "lodash";
import { Color } from "../../lib/color";
import { Currency, CurrencySymbol } from "../../lib/currency";
import {
  AreaMeasurementSystem,
  FlowRateMeasurementSystem,
  GasEnergyMeasurementSystem,
  MeasurementSystem,
  MechanicalEnergyMeasurementSystem,
  PressureDropMeasurementSystem,
  PressureMeasurementSystem,
  RainfallMeasurementSystem,
  VelocityMeasurementSystem,
  VolumeMeasurementSystem,
  f2C,
  ft2M,
  in2MM,
  psi2KPA,
} from "../../lib/measurements";
import {
  Choice,
  DeepPartial,
  assertUnreachable,
  betterObjectKeys,
  betterObjectValues,
  cloneSimple,
} from "../../lib/utils";
import {
  DualSystemMechanicalNodeProps,
  FireNodeProps,
  MechanicalNodeProps,
} from "../../models/CustomEntity";
import { ReportingStatus } from "../../reporting/ReportingFilter";
import {
  EnergyPerformanceCertificateInformation,
  ExistingHeatingSystem,
  HeatingSystemAgeType,
  HeatingSystemType,
} from "../calculations/heatloss/heat-loss-result-type";
import {
  UFH_DEFAULT_COLOR_PALETTE,
  UFH_DEFAULT_TRANSIT_SPACING_MM,
} from "../calculations/underfloor-heating/consts";
import {
  AirChangeRateStandards,
  AirChangeRateValueSpec,
  DeprecatedAirChangeDevYear,
  DeprecatedTemperatureDevYear,
  HeatLoadItem,
  HeatLossMaterialSpec,
} from "../catalog/heatload/types";
import { GenericUFH } from "../catalog/manufacturers/generic/generic-ufh";
import { PriceTable } from "../catalog/price-table";
import { FloorFinish } from "../catalog/types";
import {
  ComponentPressureLossMethod,
  DUCT_SIZING_METHODS,
  EN12056FrequencyFactor,
  InsulationJackets,
  InsulationMaterials,
  PIPE_SIZING_METHODS,
  PipePhysicalMaterial,
  RingMainCalculationMethod,
  StandardFlowSystemUids,
  SupportedDistirctHeating,
  SupportedDrainageMethods,
  SupportedDwellingStandards,
  SupportedGasCalculationMethods,
  SupportedPsdStandards,
  isDrainage,
  isHeating,
  isVentilation,
} from "../config";
import { DEFAULT_LINES } from "../linetypes";
import { Lines } from "../linetypes/types";
import { SupportedLocales } from "../locale";
import { isHeatedAreaSegmentEntity } from "./entities/area-segment-entity";
import { DrawableEntityConcrete } from "./entities/concrete-entity";
import { DoorType, WindowType } from "./entities/fenestration-entity";
import { VentilationNode } from "./entities/load-node-entity";
import { isDualSystemNode } from "./entities/plants/utils";
import RiserEntity from "./entities/riser-entity";
import {
  INITIAL_ROOF_SPEC,
  RoofSpec,
  RoofType,
} from "./entities/rooms/roof-type";
import { RoomRoomEntity } from "./entities/rooms/room-entity";
import { INITIAL_INTERNAL_HEATSOURCE } from "./entities/rooms/room-type";
import { WithID } from "./entities/simple-entities";
import {
  DrainagePipeSizingProperties,
  DrainageProperties,
  FireFlowSystem,
  FlowSystem,
  GasFlowSystem,
  MechanicalFlowSystem,
  PressureFlowSystem,
  SewerFlowSystem,
  StormwaterFlowSystem,
  UnderfloorHeatingFlowSystem,
  VentilationFlowSystem,
  VentilationNetworkType,
  isDuctFlowSystem,
  isPipeFlowSystem,
} from "./flow-systems";
import { MetaPDFData } from "./meta-pdf-types";

export interface Level {
  entities: { [key: string]: DrawableEntityConcrete };
  floorHeightM: number;
  name: string;
  abbreviation: string;
  uid: string;
}

// TODO (SEED-1622): Include UFH)
export type FlowSystemsMetadata = {
  [T in Exclude<
    StandardFlowSystemUids,
    StandardFlowSystemUids.UnderfloorHeating
  >]: {
    [StandardFlowSystemUids.ColdWater]: PressureFlowSystem;
    [StandardFlowSystemUids.RainwaterReuse]: PressureFlowSystem;
    [StandardFlowSystemUids.ReverseOsmosis]: PressureFlowSystem;
    [StandardFlowSystemUids.HotWater]: PressureFlowSystem;
    [StandardFlowSystemUids.WarmWater]: PressureFlowSystem;
    [StandardFlowSystemUids.Gas]: GasFlowSystem;
    [StandardFlowSystemUids.FireHydrant]: FireFlowSystem;
    [StandardFlowSystemUids.FireHoseReel]: FireFlowSystem;
    [StandardFlowSystemUids.FireSprinkler]: FireFlowSystem;
    [StandardFlowSystemUids.StormWater]: StormwaterFlowSystem;
    [StandardFlowSystemUids.BackupStormWater]: StormwaterFlowSystem;
    [StandardFlowSystemUids.SewerDrainage]: SewerFlowSystem;
    [StandardFlowSystemUids.SanitaryPlumbing]: SewerFlowSystem;
    [StandardFlowSystemUids.GreaseWaste]: SewerFlowSystem;
    [StandardFlowSystemUids.TradeWaste]: SewerFlowSystem;
    [StandardFlowSystemUids.RisingMain]: SewerFlowSystem;
    [StandardFlowSystemUids.Heating]: MechanicalFlowSystem;
    [StandardFlowSystemUids.Chilled]: MechanicalFlowSystem;
    [StandardFlowSystemUids.Condenser]: MechanicalFlowSystem;
    [StandardFlowSystemUids.VentilationSupply]: VentilationFlowSystem;
    [StandardFlowSystemUids.VentilationExtract]: VentilationFlowSystem;
    [StandardFlowSystemUids.VentilationIntake]: VentilationFlowSystem;
    [StandardFlowSystemUids.VentilationExhaust]: VentilationFlowSystem;
    [StandardFlowSystemUids.VentilationFanExhaust]: VentilationFlowSystem;
  }[T];
} & Record<string, FlowSystem>; // we also allow dynamic keys because users can add arbitrary flow systems

export type Workflows = {
  heat_gain: {
    enabled: boolean;
  };
  heat_loss: {
    enabled: boolean;
  };
  mech_heating: {
    enabled: boolean;
  };
  mech_ventilation: {
    enabled: boolean;
  };
  mech_underfloor_heating: {
    enabled: boolean;
  };
  mech_chilled: {
    enabled: boolean;
  };
  plumbing_water: {
    enabled: boolean;
  };
  plumbing_gas: {
    enabled: boolean;
  };
  plumbing_wastewater: {
    enabled: boolean;
  };
  plumbing_stormwater: {
    enabled: boolean;
  };
  fire_sprinklers: {
    enabled: boolean;
  };
  fire_hydrants: {
    enabled: boolean;
  };
  fire_hosereels: {
    enabled: boolean;
  };
};
export type WorkflowKeys = keyof Workflows;

export type DrawingStateMetadata = {
  generalInfo: GeneralInfo;
  units: UnitsParameters;
  flowSystems: FlowSystemsMetadata;
  flowSystemUidsInOrder: Array<StandardFlowSystemUids | string>;
  calculationParams: CalculationParameters;
  availableFixtures: string[];
  nodes: {
    mechanical: MechanicalNodeProps[];
    fire: FireNodeProps[];
    // TODO: migrate custom nodes to here instead of stored in database.
    // Actual proper TODO: migrate custom nodes to organization-level store.
  };
  catalog: MetadataCatalog;
  heatLoss: HeatLoss;
  priceTable: DeepPartial<PriceTable>;
  lines: Lines;
  annotations: Annotations;
  roomResultsSettings: RoomResultsSettings;
  // stores the marketplace item uids that have been added to this drawing
  marketplaceItems: string[];
  workflows: Workflows;
  metaPdf: MetaPDFData;
};

/**
 * A drawing is a snapshot of a drawing - its shapes, pipes, fixtures, entities, title, etc, as is.
 */
export interface DrawingState {
  metadata: DrawingStateMetadata;
  levels: { [key: string]: Level };
  shared: { [key: string]: RiserEntity };
}

export interface CurrencySystem {
  symbol: CurrencySymbol;
  multiplierPct: number;
}
export interface UnitsParameters {
  lengthMeasurementSystem: MeasurementSystem;
  pressureMeasurementSystem: PressureMeasurementSystem;
  ventilationPressureMeasurementSystem: PressureMeasurementSystem;
  velocityMeasurementSystem: VelocityMeasurementSystem;
  temperatureMeasurementSystem: MeasurementSystem;
  volumeMeasurementSystem: VolumeMeasurementSystem;
  gasEnergyMeasurementSystem: GasEnergyMeasurementSystem;
  mechanicalEnergyMeasurementSystem: MechanicalEnergyMeasurementSystem;
  pressureDropMeasurementSystem: PressureDropMeasurementSystem;
  rainfallMeasurementSystem: RainfallMeasurementSystem;
  areaMeasurementSystem: AreaMeasurementSystem;
  flowRateMeasurementSystem: FlowRateMeasurementSystem;
  ventilationFlowRateMeasurementSystem: FlowRateMeasurementSystem;
  currency: CurrencySystem;
}

export interface RoomResultsSettings {
  roomName: boolean;
  roomTemperature: boolean;
  roomHeight: boolean;
  roomArea: boolean;
  roomVolume: boolean;
  roomPerimeter: boolean;
  heatLoss: boolean;
  heatSupplied: boolean;
  heatLoadPerArea: boolean;
  heatGain: boolean;
  coolingSupplied: boolean;
  ventAirChanges: boolean;
  heatingAirChanges: boolean;
  underfloorHeating: boolean;
  unheatedArea: boolean;
}

export const DEFAULT_ROOM_RESULTS_SETTINGS: RoomResultsSettings = {
  roomName: true,
  roomTemperature: true,
  roomHeight: true,
  roomArea: true,
  roomVolume: true,
  roomPerimeter: true,
  heatLoss: true,
  heatSupplied: true,
  heatLoadPerArea: true,
  heatGain: true,
  coolingSupplied: true,
  ventAirChanges: true,
  heatingAirChanges: false,
  underfloorHeating: true,
  unheatedArea: false,
};

export const ROOM_RESULTS_SETTINGS_CHOICES: Choice<
  keyof RoomResultsSettings
>[] = [
  { name: "Room Name", key: "roomName" },
  { name: "Room Temperature", key: "roomTemperature" },
  { name: "Room Height", key: "roomHeight" },
  { name: "Room Area", key: "roomArea" },
  { name: "Room Volume", key: "roomVolume" },
  { name: "Room Perimeter", key: "roomPerimeter" },
  { name: "Ventilation Air Changes", key: "ventAirChanges" },
  { name: "Heating Air Changes", key: "heatingAirChanges" },
  { name: "Heat Loss", key: "heatLoss" },
  { name: "Heat Supplied", key: "heatSupplied" },
  { name: "Heat Gain", key: "heatGain" },
  { name: "Cooling Supplied", key: "coolingSupplied" },
  { name: "Heat Gain/Loss by Area ", key: "heatLoadPerArea" },
  { name: "Underfloor Heating", key: "underfloorHeating" },
  { name: "Unheated Area", key: "unheatedArea" },
];

export const LENGTH_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (mm)", key: MeasurementSystem.METRIC },
  { name: "Imperial (in, ft)", key: MeasurementSystem.IMPERIAL },
];

export const PRESSURE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (kPa)", key: PressureMeasurementSystem.METRIC },
  { name: "Metric - Small (Pa)", key: PressureMeasurementSystem.METRIC_SMALL },
  { name: "Imperial (psi)", key: PressureMeasurementSystem.IMPERIAL },
  { name: "UK (bar)", key: PressureMeasurementSystem.UK },
];

export const TEMPERATURE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (\u00B0C)", key: MeasurementSystem.METRIC },
  { name: "Imperial (\u00B0F)", key: MeasurementSystem.IMPERIAL },
];

export const VELOCITY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (m/s)", key: VelocityMeasurementSystem.METRIC },
  { name: "Imperial (ft/s)", key: VelocityMeasurementSystem.IMPERIAL },
];

export const VOLUME_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (L)", key: VolumeMeasurementSystem.METRIC },
  { name: "UK Imperial (L)", key: VolumeMeasurementSystem.IMPERIAL },
  { name: "US Imperial (US gal)", key: VolumeMeasurementSystem.US },
];

export const GAS_ENERGY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Megajoules (mj)", key: GasEnergyMeasurementSystem.METRIC },
  { name: "Therms (Btu)", key: GasEnergyMeasurementSystem.IMPERIAL },
  { name: "Kilowatts (Kw)", key: GasEnergyMeasurementSystem.UNIVERSAL },
];

export const MECHANICAL_ENERGY_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Therms (Btu)", key: MechanicalEnergyMeasurementSystem.IMPERIAL },
  { name: "Kilowatts (Kw)", key: MechanicalEnergyMeasurementSystem.UNIVERSAL },
];

export const PRESSURE_DROP_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (kPa/m)", key: PressureMeasurementSystem.METRIC },
  {
    name: "Metric - Small (Pa/m)",
    key: PressureMeasurementSystem.METRIC_SMALL,
  },
  { name: "Imperial (psi/100ft)", key: PressureMeasurementSystem.IMPERIAL },
  { name: "UK (Pa/m)", key: PressureMeasurementSystem.UK },
];

export const FLOW_RATE_MEASUREMENT_CHOICES: Choice[] = [
  { name: "Metric (L/s)", key: FlowRateMeasurementSystem.METRIC },
  {
    name: "Metric (L/min)",
    key: FlowRateMeasurementSystem.METRIC_MIN,
  },
  {
    name: "Imperial (ft³/min)",
    key: FlowRateMeasurementSystem.IMPERIAL_CFM,
  },
  { name: "UK Imperial (gal/min)", key: FlowRateMeasurementSystem.IMPERIAL },
  { name: "US Imperial(US gal/min)", key: FlowRateMeasurementSystem.US },
];

export const AIR_CHANGE_RATE_STANDARD_CHOICES: Choice<AirChangeRateStandards>[] =
  [
    { name: "CIBSE 2016", key: "CIBSE2016" },
    { name: "MCS Pre 2000", key: "MCSPre2000" },
    { name: "MCS Post 2000", key: "MCSPost2000" },
    { name: "MCS Post 2006", key: "MCSPost2006" },
    {
      name: "UK Building Regs 2021 (Part F) Dwellings",
      key: "UKBuildingRegs2021Dwellings",
    },
    {
      name: "UK Building Regs 2021 (Part F) Non Dwellings",
      key: "UKBuildingRegs2021NonDwellings",
    },
    { name: "ASHRAE 62.1 2022", key: "ASHRAE62.1" },
    { name: "AS 1668.2-2012", key: "AS1668.2-2012" },
  ];

export function airChangeStandardToLabel(
  standard: AirChangeRateStandards,
): string {
  const s = AIR_CHANGE_RATE_STANDARD_CHOICES.find((e) => e.key === standard);
  return s?.name ?? "";
}

export type ProjectAddress = {
  formattedAddress: string;
  latitude: number | null;
  longitude: number | null;
  country: string;
};

export interface GeneralInfo {
  title: string;
  projectNumber: string;
  projectStage: string;
  designer: string;
  reviewed: string;
  approved: string;
  revision: number;
  client: string;
  description: string;
  reportingStatus?: ReportingStatus;
  projectAddress: ProjectAddress;
}

export const RADIATOR_CAPACITY_LKW = 11;
export const UFH_CAPACITY_LKW = 23;
export const MANIFOLD_CAPACITY_LKW = 23;
export const FCU_CAPACITY_LKW = 11;
export const AHU_CAPACITY_LKW = 11;

export const AHU_HEIGHT_ABOVE_FLOOR_MM = 1000;
export const AHU_HEIGHT_ABOVE_FLOOR_MM_US = in2MM(40);
export const AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA = 10;
export const AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA = 10;

export interface NetworkParamsV8 {
  velocityMS: number;
  spareCapacityPCT: number;
  pressureDropKPAM: number;
  material: PipePhysicalMaterial;
  minimumPipeSize: number;
}

export interface HorizontalPipeSizingV10 {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
  gradePCT: number;
}

export interface VentSizingV10 {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
}

export interface StackPipeSizingV10 {
  minUnits: number;
  maxUnits: number;
  sizeMM: number;
  maximumUnitsPerLevel: number;
}

export interface DrainagePropertiesV10 {
  ventColor: Color;

  stackSizeDiminish: boolean;
  stackDedicatedVent: boolean;
  maxUnventedLengthM: { [key: number]: number | undefined };
  maxUnventedCapacityWCs: { [key: number]: number | undefined };

  horizontalPipeSizing: HorizontalPipeSizingV10[];
  availablePipeSizesMM: number[];
  ventSizing: VentSizingV10[];
  stackPipeSizing: StackPipeSizingV10[];
  stackVentPipeSizing: VentSizingV10[];
}

export interface FlowSystemParametersV8 extends WithID {
  name: string;
  temperature: number;
  color: Color;
  fluid: string;

  networks: {
    [key in "RISERS" | "RETICULATIONS" | "CONNECTIONS"]: NetworkParamsV8;
  };
}

export interface FlowSystemParametersV9 extends WithID {
  name: string;
  temperature: number;
  color: Color;
  fluid: string;

  hasReturnSystem: boolean;
  returnIsInsulated: boolean;
  returnMaxVelocityMS: number;
  insulationMaterial: InsulationMaterials;
  insulationThicknessMM: number;

  networks: {
    [key in "RISERS" | "RETICULATIONS" | "CONNECTIONS"]: NetworkParamsV8;
  };
}

export interface HeatingProperties {
  minLoopPressureKPA: number;
  maxLoopPressureKPA: number;
}

export type ReturnFlowSystemRole =
  | "water"
  | "heating"
  | "chilled"
  | "condenser"
  | "ventilation";

export const RETURN_SYSTEM_ROLE_CHOICES: Choice[] = [
  { name: "Water", key: "water" },
  { name: "Heating", key: "heating" },
  { name: "Chilled", key: "chilled" },
  { name: "Condenser", key: "condenser" },
];

export interface FlowSystemParametersV10 extends WithID {
  name: string;
  temperature: number;
  color: Color;
  fluid: string;
  defaultPipeHeightM: number;

  hasReturnSystem: boolean;
  returnColor: Color;

  returnRole: ReturnFlowSystemRole;

  heatingProps: HeatingProperties;

  // preferredHeightM: number; // TODO
  // preferredHeightAbove: "floor" | "ceiling";
  returnIsInsulated: boolean;
  returnMaxVelocityMS: number;
  insulationMaterial: InsulationMaterials;
  insulationJacket: InsulationJackets;
  insulationThicknessMM: number;

  drainageProperties: DrainagePropertiesV10;

  networks: {
    [key in "RISERS" | "RETICULATIONS" | "CONNECTIONS"]: NetworkParamsV8;
  };
}

export type PipeSizingMethod = "velocity" | "pressure" | "both";
export type DuctSizingMethod = "velocity" | "pressure" | "both";

export function isVelocitySizingEnabled(
  drawing: DrawingState,
  flowSystem: FlowSystem,
) {
  if (isPipeFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.pipeSizingMethod) {
      case "velocity":
      case "both":
        return true;
      case "pressure":
        return false;
      default:
        assertUnreachable(drawing.metadata.calculationParams.pipeSizingMethod);
    }
  }

  if (isDuctFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.ductSizingMethod) {
      case "velocity":
      case "both":
        return true;
      case "pressure":
        return false;
      default:
        assertUnreachable(drawing.metadata.calculationParams.ductSizingMethod);
    }
  }
  return false;
}

export function isRectangularVentShape(
  fs: FlowSystem,
  networkKey: VentilationNetworkType,
) {
  return (
    fs.type === "ventilation" && fs.networks[networkKey].shape === "rectangular"
  );
}

export function isCircularVentShape(
  fs: FlowSystem,
  networkKey: VentilationNetworkType,
) {
  return (
    fs.type === "ventilation" && fs.networks[networkKey].shape === "circular"
  );
}

export function isPiecedVent(
  fs: FlowSystem,
  networkKey: VentilationNetworkType,
) {
  return (
    fs.type === "ventilation" &&
    fs.networks[networkKey].circularElbow === "multi-piece"
  );
}

export function isRectangularSmoothWithVanesVent(
  fs: FlowSystem,
  networkKey: VentilationNetworkType,
) {
  return (
    fs.type === "ventilation" &&
    fs.networks[networkKey].shape === "rectangular" &&
    fs.networks[networkKey].rectangularElbow === "smooth-vanes"
  );
}

export function isMiteredElbowVent(
  fs: FlowSystem,
  networkKey: VentilationNetworkType,
) {
  return (
    fs.type === "ventilation" &&
    (isRectangularVentShape(fs, networkKey)
      ? fs.networks[networkKey].rectangularElbow === "square"
      : fs.networks[networkKey].circularElbow === "square")
  );
}

export function isPressureSizingEnabled(
  drawing: DrawingState,
  flowSystem: FlowSystem,
) {
  if (isPipeFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.pipeSizingMethod) {
      case "velocity":
        return false;
      case "both":
      case "pressure":
        return true;
      default:
        assertUnreachable(drawing.metadata.calculationParams.pipeSizingMethod);
    }
  }

  if (isDuctFlowSystem(flowSystem)) {
    switch (drawing.metadata.calculationParams.ductSizingMethod) {
      case "velocity":
        return false;
      case "both":
      case "pressure":
        return true;
      default:
        assertUnreachable(drawing.metadata.calculationParams.ductSizingMethod);
    }
  }
  return false;
}

export interface CalculationParameters {
  psdMethod: SupportedPsdStandards;
  loadingUnitVariant: string;
  dwellingMethod: SupportedDwellingStandards | null;
  drainageMethod: SupportedDrainageMethods;
  drainageSystem:
    | "drainageSystem1"
    | "drainageSystem2"
    | "drainageSystem3"
    | "drainageSystem4";
  en12056FrequencyFactor: EN12056FrequencyFactor;
  ringMainCalculationMethod: RingMainCalculationMethod;
  ductSizingMethod: DuctSizingMethod;
  pipeSizingMethod: PipeSizingMethod;
  componentPressureLossMethod: ComponentPressureLossMethod;
  pipePressureLossAddOnPCT: number;

  roomTemperatureC: number;
  rainfallIntensityMm_H: number;
  windSpeedForHeatLossMS: number;
  apartmentNum: number;
  occupantNum: number;
  gravitationalAcceleration: number;
  combineLUs: boolean;
  districtHeating: SupportedDistirctHeating | null;
  gasCalcMethod: SupportedGasCalculationMethods | null;
}

export type WindowSpec = {
  [WindowType.WINDOW]: {
    heightM: number;
    lengthM: number;
  };
  [WindowType.VELUX]: {
    widthM: number;
    lengthM: number;
  };
};

export type DoorSpec = {
  heightM: number;
  lengthM: {
    [key in DoorType]: number;
  };
};

export type WallSpec = {
  roomHeightM: number;
  internalWidthMM: number;
  externalWidthMM: number;
  partyWidthMM: number;
};

export type Cost = {
  value: number;
  currency: Currency;
};

export interface CustomRoomSpecV1 {
  name: string;
  attributes: {
    [key in DeprecatedTemperatureDevYear]: {
      defaultTemperatureC: number;
      airChangeRate: number;
    };
  };
}

export interface CustomRoomSpecV2 {
  name: string;
  defaultTemperatureC: Record<DeprecatedTemperatureDevYear, number>;
  airChangeRate: Record<DeprecatedAirChangeDevYear, number>;
}

export interface CustomRoomSpec {
  name: string;
  defaultTemperatureC: number;
  airChangeRate: AirChangeRateValueSpec[];
}

export interface SolarRadiation {
  top: number;
  bottom: number;
  left: number;
  right: number;
  leftBottom: number;
  leftTop: number;
  rightTop: number;
  rightBottom: number;
}

export interface HeatLoss {
  material: {
    [key in HeatLoadItem]: {
      [key in string]: SelectedMaterialManufacturer;
    };
  };
  defaultMaterial: {
    [key in HeatLoadItem]: string;
  };
  defaultColor: {
    [key in HeatLoadItem]: Color;
  };
  customMaterial: {
    [key in HeatLoadItem]: {
      [key in string]: HeatLossMaterialSpec;
    };
  };
  customRoom: {
    [key in string]: CustomRoomSpec;
  };
  roofSpec: RoofSpec;
  defaultRoofType: RoofType;
  doorSpec: DoorSpec;
  windowSpec: WindowSpec;
  wallSpec: WallSpec;
  ventAirChangesRateStandard: AirChangeRateStandards;
  heatingAirChangesRateStandard: AirChangeRateStandards;
  defaultRoomTemperatureC: number;
  externalWinterTemperatureC: number;
  groundTemperatureC: number;
  thermalBridgingCoefficient: number;
  ventHeatLossRecoveryPct: number; // 0 -> 100
  grilleFlowRateType: VentilationNode["flowRateType"];

  spareHeatLossPrecent: number; // typo
  spareHeatGainPrecent: number; // typo

  externalSummerTemperatureC: number;
  solarHeatGainCoefficient: number;
  solarRadiationWPerMin: SolarRadiation;

  internalHeatSource: InternalHeatSource;

  baseTemperatureC: number;
  heatingDegreeDays: number;
  coolingDegreeDays: number;
  winterTempCutoffPctThreshold: number;
  summerTempCutoffPctThreshold: number;
  winterPartyTemperatureC: number;
  summerPartyTemperatureC: number;
  SCOP: number;

  roomsBelowTransparentPct: number;

  // Heat Pump System Performance Report
  energyPerformanceCertificateInformation: EnergyPerformanceCertificateInformation;
  existingHeatingSystem: ExistingHeatingSystem;

  electricityCostPerUnit: Cost;

  // Underfloor Heating settings
  // TODO: remove this.
}

export const DEFAULT_SCOP = 4;
export const DEFAULT_LEGIONELLA_PURGE_TEMPERATURE_C = 70;
export const DEFAULT_DIARY_DOMESTIC_WATER = 60;
export const DEFAULT_SPF = 4;
export const DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT = 30;

export const DEFAULT_EPC_INFORMATION = {
  isDraft: false,
  epcNumber: "",
};
export const DEFAULT_EXISTING_HEATING_SYSTEM: ExistingHeatingSystem = {
  fuelHeatingSystemType: HeatingSystemType.GAS,
  hotWaterHeatingSystemType: HeatingSystemType.GAS,
  heatingSystemAge: HeatingSystemAgeType["1994_2007"],
};

export const DEFAULT_SPARE_HEAT_CAP = 0;

export type InternalHeatSourceEntry = {
  heatSourceName: string;
  heatSourceWatts: number;
};

export type internalHeatLoadItem =
  | "Occupant"
  | "Lighting"
  | "Computers"
  | "Laptops"
  | "Monitors"
  | "Printers"
  | "Photocopiers"
  | "Kitchen stoves"
  | "Oven"
  | "Microwaves"
  | "Refrigerators"
  | "Freezers"
  | "Water coolers"
  | "Dishwashers"
  | "Televisions"
  | "Electric fans"
  | "Server racks"
  | "Air handling units"
  | "Small kitchen appliances"
  | "Elevator motors";
export type InternalHeatSource = {
  [key in string]: InternalHeatSourceEntry;
};

export interface Annotations {
  fontSize: number;
}

export const INTERNAL_WALL_WIDTH_MM = 150;
export const PARTY_WALL_WIDTH_MM = 150;
export const EXTERNAL_WALL_WIDTH_MM = 200;
export interface MetadataCatalog {
  pipes: SelectedPipeMaterialManufacturer[];
  backflowValves: SelectedMaterialManufacturer[];
  mixingValves: SelectedMaterialManufacturer[];
  prv: SelectedMaterialManufacturer[];
  balancingValves: SelectedMaterialManufacturer[];
  hotWaterPlant: SelectedMaterialManufacturer[];
  fixtures: SelectedMaterialManufacturer[];
  greaseInterceptorTrap?: SelectedMaterialManufacturer[];
  floorWaste: SelectedMaterialManufacturer[];
  inspectionOpening: SelectedMaterialManufacturer[];
  pump: SelectedMaterialManufacturer[];
  pumpTank: SelectedMaterialManufacturer[];
  heatEmitters: SelectedMaterialManufacturer[];
  underfloorHeating: SelectedMaterialManufacturer[];
  filters: SelectedMaterialManufacturer[];
  roPlant: SelectedMaterialManufacturer[];
  ducts: SelectedMaterialManufacturer[];
  [key: string]: SelectedMaterialManufacturer[] | undefined;
}

export interface SelectedMaterialManufacturer {
  uid: string;
  manufacturer: string;
  selected: string | null;
}

export interface SelectedPipeMaterialManufacturer
  extends SelectedMaterialManufacturer {
  excludedSizes?: Partial<Record<string, boolean>>;
}

export const initialDrainageProperties: DrainageProperties = {
  ventColor: { hex: "#ff7755" },
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 165, sizeMM: 100, gradePCT: 1.65 },
    { minUnits: 166, maxUnits: 855, sizeMM: 150, gradePCT: 1 },
    { minUnits: 856, maxUnits: 1310, sizeMM: 150, gradePCT: 1.65 },
    { minUnits: 1311, maxUnits: 4500, sizeMM: 225, gradePCT: 1 },
    { minUnits: 4501, maxUnits: 11400, sizeMM: 300, gradePCT: 1 },
  ],
  maxUnventedCapacityWCs: {
    40: 2,
    50: 2,
    65: 2,
    80: 2,
    100: 2,
    125: 2,
    150: 2,
    225: 2,
    300: 2,
    375: 2,
  },
  maxUnventedLengthM: {
    40: 10,
    50: 10,
    65: 10,
    80: 10,
    100: 10,
    125: 10,
    150: 10,
    225: 10,
    300: 10,
    375: 10,
  },
  stackDedicatedVent: false,
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 500, sizeMM: 100, maximumUnitsPerLevel: 125 },
    { minUnits: 501, maxUnits: 1000, sizeMM: 125, maximumUnitsPerLevel: 250 },
    { minUnits: 1001, maxUnits: 2400, sizeMM: 150, maximumUnitsPerLevel: 600 },
    { minUnits: 2401, maxUnits: 7000, sizeMM: 225, maximumUnitsPerLevel: 1750 },
  ],
  stackSizeDiminish: false,
  stackVentPipeSizing: [
    { minUnits: 0, maxUnits: 56, sizeMM: 65 },
    { minUnits: 57, maxUnits: 80, sizeMM: 80 },
    { minUnits: 81, maxUnits: 500, sizeMM: 100 },
    { minUnits: 501, maxUnits: 1100, sizeMM: 125 },
    { minUnits: 1101, maxUnits: 2400, sizeMM: 150 },
    { minUnits: 2401, maxUnits: 7000, sizeMM: 225 },
  ],
  ventSizing: [
    { minUnits: 0, maxUnits: 10, sizeMM: 40 },
    { minUnits: 11, maxUnits: 30, sizeMM: 50 },
    { minUnits: 31, maxUnits: 175, sizeMM: 65 },
    { minUnits: 176, maxUnits: 400, sizeMM: 80 },
    { minUnits: 401, maxUnits: 600, sizeMM: 100 },
  ],
};

export const initialStormwaterPropertiesV10: DrainageProperties = {
  ventColor: { hex: "#ff7755" },
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, gradePCT: 1 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, gradePCT: 1 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, gradePCT: 1 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, gradePCT: 1 },
  ],
  // Placeholder, Won't use these
  maxUnventedCapacityWCs: {},
  maxUnventedLengthM: {},
  stackDedicatedVent: false,
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, maximumUnitsPerLevel: 0 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, maximumUnitsPerLevel: 0 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, maximumUnitsPerLevel: 0 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, maximumUnitsPerLevel: 0 },
  ],
  stackSizeDiminish: false,
  stackVentPipeSizing: [],
  ventSizing: [],
};

export const initialStormwaterProperties: DrainagePipeSizingProperties = {
  availablePipeSizesMM: [40, 50, 65, 80, 100, 125, 150, 225, 300, 375],
  horizontalPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, gradePCT: 1 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, gradePCT: 1 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, gradePCT: 1 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, gradePCT: 1 },
  ],
  stackPipeSizing: [
    { minUnits: 0, maxUnits: 7, sizeMM: 100, maximumUnitsPerLevel: 0 },
    { minUnits: 7, maxUnits: 20, sizeMM: 150, maximumUnitsPerLevel: 0 },
    { minUnits: 20, maxUnits: 50, sizeMM: 225, maximumUnitsPerLevel: 0 },
    { minUnits: 50, maxUnits: 125, sizeMM: 300, maximumUnitsPerLevel: 0 },
  ],
};

export const initialHeatingProperties: () => HeatingProperties = () => ({
  minLoopPressureKPA: 100,
  maxLoopPressureKPA: 300,
});

export const DEFAULT_RETURN_COLOR_HEX = "#ff8800";
export const DEFAULT_BASE_TEMPERATURE_C = 15.5;
export const DEFAULT_HEATING_DEGREE_DAYS = 2222;
export const DEFAULT_COOLING_DEGREE_DAYS = 2222;
export const DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD = 99;
export const DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD = 99;
export const DEFAULT_WINTER_PARTY_TEMPERATURE_C = 10;
export const DEFAULT_SUMMER_PARTY_TEMPERATURE_C = 20;
export const VENTILATION_SYSTEM = (): VentilationFlowSystem[] => [
  {
    name: "Supply",
    type: "ventilation",
    role: "vent-supply",
    temperatureC: 20,
    color: { hex: "#2c6ae6" },
    uid: StandardFlowSystemUids.VentilationSupply,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Extract",
    type: "ventilation",
    role: "vent-extract",
    temperatureC: 20,
    color: { hex: "#d94c4e" },
    uid: StandardFlowSystemUids.VentilationExtract,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Intake",
    type: "ventilation",
    role: "vent-intake",
    temperatureC: 20,
    color: { hex: "#68BC00" },
    uid: StandardFlowSystemUids.VentilationIntake,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Exhaust",
    type: "ventilation",
    role: "vent-exhaust",
    temperatureC: 20,
    color: { hex: "#666665" },
    uid: StandardFlowSystemUids.VentilationExhaust,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
  {
    name: "Fan Exhaust",
    type: "ventilation",
    role: "vent-fan-exhaust",
    temperatureC: 20,
    color: { hex: "#BC974F" },
    uid: StandardFlowSystemUids.VentilationFanExhaust,
    fluid: "air",
    defaultPipeHeightM: 2.4,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      mains: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "carbonSteel",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      branches: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,
        shape: "rectangular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        sizingMode: "discrete",
        sizingIncrementMM: 50,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.001,
        material: "flexibleDuct",
        minimumDuctDiameterSizeMM: 50,
        minimumDuctWidthSizeMM: 50,
        minimumDuctHeightSizeMM: 50,

        sizingMode: "discrete",
        sizingIncrementMM: 50,
        shape: "circular",
        rectangleWHRatio: 2,
        smoothElbowRadiusRatio: 1.5,

        transitionAngle: 45,

        circularElbow: "square",
        rectangularElbow: "square",
        elbowVanes: 1,
        pieces: 3,

        rectRectTee: "square",
        rectCircTee: "square",
        circCircTee: "square",
        shoeLengthRatio: 0.5,

        circSymmetrical: "y-piece",
        rectSymmetrical: "square",
      },
    },
  },
];

export const STORMWATER_SYSTEM = (): StormwaterFlowSystem[] => [
  {
    type: "stormwater",
    role: "stormwater",
    name: "Stormwater",
    color: { hex: "#1d5ca3" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.StormWater,
    fluid: "stormwater",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialStormwaterProperties),
  },
  {
    type: "stormwater",
    role: "stormwater",
    name: "Backup Stormwater",
    color: { hex: "#6786a8" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.BackupStormWater,
    fluid: "stormwater",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialStormwaterProperties),
  },
];

export const DRAINAGE_FLOW_SYSTEMS = (): SewerFlowSystem[] => [
  {
    type: "sewer",
    role: "sewer",
    name: "Sewer Drainage",
    color: { hex: "#119911" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.SewerDrainage,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Sanitary Plumbing",
    color: { hex: "#66FF66" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.SanitaryPlumbing,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Grease Waste",
    color: { hex: "#90403e" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.GreaseWaste,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "hdpeSdr11Drainage",
      },
      pipes: {
        material: "hdpeSdr11Drainage",
      },
      vents: {
        material: "hdpeSdr11Drainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Trade Waste",
    color: { hex: "#a10000" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.TradeWaste,
    fluid: "sewage",
    defaultPipeHeightM: -1,

    networks: {
      stacks: {
        material: "stainlessSteelDrainage",
      },
      pipes: {
        material: "stainlessSteelDrainage",
      },
      vents: {
        material: "stainlessSteelDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },

  {
    type: "sewer",
    role: "sewer",
    name: "Rising Main",
    color: { hex: "#7100c2" },
    temperatureC: 20,
    uid: StandardFlowSystemUids.RisingMain,
    fluid: "sewage",
    defaultPipeHeightM: 3,

    networks: {
      stacks: {
        material: "uPVCDrainage",
      },
      pipes: {
        material: "uPVCDrainage",
      },
      vents: {
        material: "uPVCDrainage",
      },
    },

    ...cloneSimple(initialDrainageProperties),
  },
];

export const UNDERFLOOR_FLOW_SYSTEMS = (): UnderfloorHeatingFlowSystem[] => {
  return [
    {
      type: "underfloor",
      role: "underfloor-heating",
      uid: StandardFlowSystemUids.UnderfloorHeating,
      color: {
        hex: "#9F0500",
      },
      defaultPipeHeightM: 0,
      fluid: "water",
      name: "Underfloor Heating",
      networks: {
        pipes: {},
      },
      temperatureC: 40,
      floorFinish: betterObjectKeys<FloorFinish>(GenericUFH)[0],
      pipeDiameterMM: 16,
      maxLoopLengthM: 100,
      maxPipeSpacingMM: 200,
      pipeMaterial: "pexSdr74",
      rollLengthsM: [80, 100, 150, 200],
      manufacturer: "generic",
      loopShape: "serpentine",
      spiralChirality: "clockwise",
      minBendRadiusMM: 16 * 5,
      loopDirectionDEG: 0,
      returnTemperatureC: 35,
      transitSpacingMM: UFH_DEFAULT_TRANSIT_SPACING_MM,
      palette: UFH_DEFAULT_COLOR_PALETTE.slice(0, 4),
    },
  ];
};

const MECHANICAL_FLOW_SYSTEMS = (): MechanicalFlowSystem[] => [
  {
    type: "mechanical",
    role: "heating",
    name: "Heating",
    temperatureC: 40,
    color: { hex: "#F44E3B" },
    uid: StandardFlowSystemUids.Heating,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,
    ...initialHeatingProperties(),

    return: {
      color: { hex: "#007BFF" },

      maxVelocityMS: 1e10,
      insulated: true,
      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },
  },
  {
    type: "mechanical",
    role: "chilled",
    name: "Chilled",
    temperatureC: 5,
    color: { hex: "#009CE0" },
    uid: StandardFlowSystemUids.Chilled,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,

    ...initialHeatingProperties(),
    return: {
      color: { hex: "#73D8FF" },

      maxVelocityMS: 1e10,
      insulated: true,
      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 50,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },
  },
  {
    type: "mechanical",
    role: "condenser",
    name: "Condenser",
    temperatureC: 30,
    color: { hex: "#68BC00" },
    uid: StandardFlowSystemUids.Condenser,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,
    ...initialHeatingProperties(),

    return: {
      color: { hex: "#DBDF00" },
      maxVelocityMS: 1e10,

      insulated: true,

      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 50,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },
  },
];

export function INITIAL_VENT_AHU_NODES(): DualSystemMechanicalNodeProps[] {
  return [
    {
      name: "AHU (Indoor)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Indoor) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (Outdoor) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: false,
      hasExhaustIO: false,
      hasExtractIO: false,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR)",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: false,
      hasChilledIO: false,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
    {
      name: "AHU (MVHR) w/ H/C",
      depthMM: 600,
      widthMM: 1000,
      heightMM: 1000,

      hasIntakeIO: true,
      hasExhaustIO: true,
      hasExtractIO: true,
      hasHeatingIO: true,
      hasChilledIO: true,

      supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
      extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

      supplyHeightAboveFloorMM: 1000,
      extractHeightAboveFloorMM: 1000,

      heatingRatingKw: 0,
      heatingPressureDropKPA: 6,
      heatingHeightAboveFloorMM: 1000,
      heatingCapacityRateLKW: AHU_CAPACITY_LKW,

      chilledRatingKw: 0,
      chilledPressureDropKPA: 6,
      chilledHeightAboveFloorMM: 1000,
      chilledCapacityRateLKW: AHU_CAPACITY_LKW,
      type: "air-handling-vent",
    },
  ];
}

function getInitialMechanicalNodeInOrder(): MechanicalNodeProps[] {
  let nodes: MechanicalNodeProps[] = [
    {
      name: "Radiator",
      ratingKW: 0.25,
      pressureDropKPA: 5,
      widthMM: 700,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 0.5,
      pressureDropKPA: 5,
      widthMM: 850,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 0.75,
      pressureDropKPA: 6,
      widthMM: 1000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 1,
      pressureDropKPA: 6,
      widthMM: 1000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 1.5,
      pressureDropKPA: 8,
      widthMM: 1250,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },

    {
      name: "Radiator",
      ratingKW: 2,
      pressureDropKPA: 10,
      widthMM: 1500,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 2.5,
      pressureDropKPA: 12,
      widthMM: 1750,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 3,
      pressureDropKPA: 30,
      widthMM: 1850,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    {
      name: "Radiator",
      ratingKW: 3.5,
      pressureDropKPA: 35,
      widthMM: 2000,
      depthMM: 150,
      heightMM: 1000,
      inletHeightAboveFloorMM: 10,
      outletHeightAboveFloorMM: 10,
      capacityRateLKW: RADIATOR_CAPACITY_LKW,
      type: "radiator",
    },
    ...INITIAL_VENT_AHU_NODES(),
  ];

  nodes.sort((a, b) => {
    let aRating = isDualSystemNode(a) ? a.heatingRatingKw : a.ratingKW;
    let bRating = isDualSystemNode(b) ? b.heatingRatingKw : b.ratingKW;

    return `${a.name} ${aRating}`.localeCompare(`${b.name} ${bRating}`);
  });
  return nodes;
}
export const INITIAL_MECHANICAL_NODE: MechanicalNodeProps[] =
  getInitialMechanicalNodeInOrder();

export function initialDrawing(
  locale: SupportedLocales,
  isFirstProject: boolean = false,
): DrawingState {
  const result = initialAustralianDrawing();
  switch (locale) {
    case SupportedLocales.AU:
      result.metadata.catalog = {
        ...result.metadata.catalog,
        pipes: [
          { uid: "copperTypeB", manufacturer: "generic", selected: null },
          { uid: "pexSdr74", manufacturer: "generic", selected: null },
          { uid: "stainlessSteel", manufacturer: "generic", selected: null },
          {
            uid: "stainlessSteelDrainage",
            manufacturer: "generic",
            selected: null,
          },
          {
            manufacturer: "allmach",
            uid: "gmsMedium",
            selected: null,
          },
        ],
        mixingValves: [
          { uid: "tmv", manufacturer: "generic", selected: null },
          { uid: "temperingValve", manufacturer: "caleffi", selected: null },
        ],
        greaseInterceptorTrap: [
          {
            uid: "greaseInterceptorTrap",
            manufacturer: "viking",
            selected: null,
          },
        ],
        pump: [
          {
            manufacturer: "generic",
            uid: "pump",
            selected: null,
          },
        ],
      };

      result.metadata.units.gasEnergyMeasurementSystem =
        GasEnergyMeasurementSystem.METRIC;
      result.metadata.units.mechanicalEnergyMeasurementSystem =
        MechanicalEnergyMeasurementSystem.UNIVERSAL;
      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.AUD;
      result.metadata.heatLoss.ventAirChangesRateStandard = "AS1668.2-2012";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "AS1668.2-2012";
      break;
    case SupportedLocales.UK:
      result.metadata.calculationParams = {
        ...result.metadata.calculationParams,
        psdMethod: SupportedPsdStandards.bs806,
        drainageMethod: SupportedDrainageMethods.EN1205622000DischargeUnits,
        drainageSystem: "drainageSystem3",
        combineLUs: true,
      };
      // 0 index is cold water.
      (
        result.metadata.flowSystems[
          StandardFlowSystemUids.ColdWater
        ] as PressureFlowSystem
      ).temperatureC = 10;
      (
        result.metadata.flowSystems[StandardFlowSystemUids.Gas] as GasFlowSystem
      ).networks.risers.material = "stainlessSteel";
      (
        result.metadata.flowSystems[StandardFlowSystemUids.Gas] as GasFlowSystem
      ).networks.reticulations.material = "stainlessSteel";

      result.metadata.units.currency = {
        symbol: CurrencySymbol.POUNDS,
        multiplierPct: 100,
      };

      // after converting to and from bar, and 1 vs 100m, it would be the same.
      // for (const fs of result.metadata.flowSystems) {
      //   for (const network of Object.values(fs.networks)) {
      //     network.pressureDropKPAM = bar2kpa(0.35) / 100;
      //   }
      // }

      // default pipes manufacturers
      result.metadata.catalog = {
        ...result.metadata.catalog,
        pipes: [
          { manufacturer: "bsen1057Cu", uid: "copperTypeB", selected: null },
          { manufacturer: "enIso15875pex", uid: "pexSdr74", selected: null },
          { manufacturer: "bs1387ss", uid: "stainlessSteel", selected: null },
          {
            manufacturer: "harmerSmlCICL",
            uid: "castIronDrainage",
            selected: null,
          },
          { manufacturer: "aliaxis", uid: "cpvc", selected: null },
          { manufacturer: "fluxo", uid: "mlcp", selected: null },
          { manufacturer: "generic", uid: "gmsMedium", selected: null },
        ],
        pump: [
          {
            manufacturer: "generic",
            uid: "pump",
            selected: null,
          },
        ],
        pumpTank: [
          {
            manufacturer: "generic",
            uid: "pumpTank",
            selected: null,
          },
        ],
        mixingValves: [
          { manufacturer: "caleffi", uid: "temperingValve", selected: null },
          { manufacturer: "caleffi", uid: "tmv", selected: null },
        ],
        hotWaterPlant: [
          { uid: "circulatingPumps", manufacturer: "generic", selected: null },
          { uid: "hotWaterPlant", manufacturer: "generic", selected: null },
        ],
        heatEmitters: [
          { uid: "radiators", manufacturer: "stelrad", selected: null },
        ],
        filters: [
          { uid: "softener", manufacturer: "generic", selected: null },
          { uid: "backwash", manufacturer: "generic", selected: null },
          { uid: "cartridge", manufacturer: "generic", selected: null },
          { uid: "uv", manufacturer: "generic", selected: null },
          { uid: "backwashRainwater", manufacturer: "generic", selected: null },
        ],
        roPlant: [{ uid: "roPlant", manufacturer: "generic", selected: null }],
        greaseInterceptorTrap: [],
      };

      result.metadata.units.pressureMeasurementSystem =
        PressureMeasurementSystem.UK;
      result.metadata.units.pressureDropMeasurementSystem =
        PressureDropMeasurementSystem.UK;
      result.metadata.units.gasEnergyMeasurementSystem =
        GasEnergyMeasurementSystem.UNIVERSAL;
      result.metadata.units.mechanicalEnergyMeasurementSystem =
        MechanicalEnergyMeasurementSystem.UNIVERSAL;

      result.metadata.heatLoss.ventAirChangesRateStandard = "MCSPost2000";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "MCSPost2000";

      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.GBP;
      break;
    case SupportedLocales.US:
      result.metadata.units = {
        gasEnergyMeasurementSystem: GasEnergyMeasurementSystem.IMPERIAL,
        mechanicalEnergyMeasurementSystem:
          MechanicalEnergyMeasurementSystem.IMPERIAL,
        lengthMeasurementSystem: MeasurementSystem.IMPERIAL,
        ventilationPressureMeasurementSystem:
          PressureMeasurementSystem.IMPERIAL,
        pressureMeasurementSystem: PressureMeasurementSystem.IMPERIAL,
        temperatureMeasurementSystem: MeasurementSystem.IMPERIAL,
        velocityMeasurementSystem: VelocityMeasurementSystem.IMPERIAL,
        volumeMeasurementSystem: VolumeMeasurementSystem.US,
        pressureDropMeasurementSystem: PressureDropMeasurementSystem.IMPERIAL,
        rainfallMeasurementSystem: RainfallMeasurementSystem.IMPERIAL,
        areaMeasurementSystem: AreaMeasurementSystem.IMPERIAL,
        flowRateMeasurementSystem: FlowRateMeasurementSystem.US,
        ventilationFlowRateMeasurementSystem:
          FlowRateMeasurementSystem.IMPERIAL_CFM,
        currency: {
          symbol: CurrencySymbol.DOLLARS,
          multiplierPct: 75,
        },
      };

      result.metadata.calculationParams = {
        ...result.metadata.calculationParams,
        psdMethod: SupportedPsdStandards.upc2018FlushTanks,
        drainageMethod: SupportedDrainageMethods.UPC2018DrainageFixtureUnits,
        combineLUs: true,
      };

      const coldWater = result.metadata.flowSystems[
        StandardFlowSystemUids.ColdWater
      ] as PressureFlowSystem;
      coldWater.temperatureC = f2C(70);
      coldWater.networks.connections.velocityMS =
        coldWater.networks.risers.velocityMS =
        coldWater.networks.reticulations.velocityMS =
          ft2M(8);

      const hotWater = result.metadata.flowSystems[
        StandardFlowSystemUids.HotWater
      ] as PressureFlowSystem;
      hotWater.temperatureC = f2C(140);
      hotWater.return.insulation.thicknessMM = in2MM(1);
      hotWater.return.insulation.material = InsulationMaterials.mineralWool;
      hotWater.return.maxVelocityMS = ft2M(3);
      hotWater.networks.risers.velocityMS =
        hotWater.networks.reticulations.velocityMS =
        hotWater.networks.connections.velocityMS =
          ft2M(5);

      const heating = result.metadata.flowSystems[
        StandardFlowSystemUids.Heating
      ] as MechanicalFlowSystem;
      heating.temperatureC = f2C(104);
      heating.return.insulation.thicknessMM = in2MM(1);
      heating.return.insulation.material = InsulationMaterials.mineralWool;
      heating.networks.risers.velocityMS =
        heating.networks.reticulations.velocityMS = ft2M(3);

      const warmWater = result.metadata.flowSystems[
        StandardFlowSystemUids.WarmWater
      ] as PressureFlowSystem;
      warmWater.temperatureC = f2C(120);
      warmWater.networks.risers.velocityMS =
        warmWater.networks.reticulations.velocityMS =
        warmWater.networks.connections.velocityMS =
          ft2M(5);

      const gas = result.metadata.flowSystems[
        StandardFlowSystemUids.Gas
      ] as GasFlowSystem;
      gas.networks.risers.velocityMS =
        gas.networks.reticulations.velocityMS =
        gas.networks.connections.velocityMS =
          ft2M(60);

      const fireHydrant = result.metadata.flowSystems[
        StandardFlowSystemUids.FireHydrant
      ] as FireFlowSystem;
      fireHydrant.networks.risers.velocityMS =
        fireHydrant.networks.reticulations.velocityMS =
        fireHydrant.networks.connections.velocityMS =
          ft2M(10);
      fireHydrant.temperatureC = f2C(70);

      const fireHoseReel = result.metadata.flowSystems[
        StandardFlowSystemUids.FireHoseReel
      ] as FireFlowSystem;
      fireHoseReel.networks.risers.velocityMS =
        fireHoseReel.networks.reticulations.velocityMS =
        fireHoseReel.networks.connections.velocityMS =
          ft2M(8);
      fireHoseReel.temperatureC = f2C(70);

      const sewageAll = Object.values(result.metadata.flowSystems).filter(
        (item) => isDrainage(item),
      ) as SewerFlowSystem[];
      for (const sewage of sewageAll) {
        sewage.temperatureC = f2C(70);
      }

      for (const fs of Object.values(result.metadata.flowSystems)) {
        for (const network of Object.values(fs.networks)) {
          if (network && "pressureDropKPAM" in network) {
            network.pressureDropKPAM = psi2KPA(3) / ft2M(100);
          }
        }
      }

      const chilled = result.metadata.flowSystems[
        StandardFlowSystemUids.Chilled
      ] as MechanicalFlowSystem;

      chilled.temperatureC = f2C(40);
      chilled.networks.risers.velocityMS =
        chilled.networks.reticulations.velocityMS = ft2M(4);
      chilled.networks.risers.pressureDropKPAM =
        chilled.networks.reticulations.pressureDropKPAM =
          psi2KPA(1.7) / ft2M(100);
      chilled.return.insulation.thicknessMM = in2MM(2);

      const condenser = result.metadata.flowSystems[
        StandardFlowSystemUids.Condenser
      ] as MechanicalFlowSystem;

      condenser.temperatureC = f2C(85);
      condenser.networks.risers.velocityMS =
        condenser.networks.reticulations.velocityMS = ft2M(4);
      condenser.return.insulation.thicknessMM = in2MM(2);

      for (const flowSystem of Object.values(result.metadata.flowSystems)) {
        if (isHeating(flowSystem) || isDrainage(flowSystem)) {
          flowSystem.defaultPipeHeightM = ft2M(-1);
        } else {
          flowSystem.defaultPipeHeightM = ft2M(9);
        }
      }

      for (const flowSystem of Object.values(result.metadata.flowSystems)) {
        if (flowSystem.type === "sewer") {
          // tslint:disable-next-line:forin
          for (const j in flowSystem.maxUnventedLengthM) {
            flowSystem.maxUnventedLengthM[j] = ft2M(30);
          }
        }
      }

      for (const fs of Object.values(result.metadata.flowSystems)) {
        if (isVentilation(fs)) {
          fs.networks.risers.velocityMS =
            fs.networks.mains.velocityMS =
            fs.networks.branches.velocityMS =
            fs.networks.connections.velocityMS =
              ft2M(12);
          fs.networks.risers.pressureDropKPAM =
            fs.networks.mains.pressureDropKPAM =
            fs.networks.branches.pressureDropKPAM =
            fs.networks.connections.pressureDropKPAM =
              psi2KPA(0.1) / ft2M(100);
        }
      }

      result.metadata.catalog.mixingValves = [
        { manufacturer: "caleffi", uid: "temperingValve", selected: null },
        { manufacturer: "caleffi", uid: "tmv", selected: null },
      ];
      result.metadata.catalog.pipes.push(
        { manufacturer: "atsmB88Cu", uid: "copperTypeB", selected: null },
        { manufacturer: "atsmF877pex", uid: "pexSdr74", selected: null },
        { manufacturer: "generic", uid: "stainlessSteel", selected: null },
        { manufacturer: "generic", uid: "castIronDrainage", selected: null },
        { manufacturer: "generic", uid: "plainCarbonSteel", selected: null },
        { manufacturer: "generic", uid: "gmsMedium", selected: null },
      );
      result.metadata.catalog.hotWaterPlant = [
        { uid: "circulatingPumps", manufacturer: "generic", selected: null },
        { uid: "hotWaterPlant", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.pump = [
        { manufacturer: "generic", uid: "pump", selected: null },
      ];
      result.metadata.catalog.filters = [
        { uid: "softener", manufacturer: "generic", selected: null },
        { uid: "backwash", manufacturer: "generic", selected: null },
        { uid: "cartridge", manufacturer: "generic", selected: null },
        { uid: "uv", manufacturer: "generic", selected: null },
        { uid: "backwashRainwater", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.roPlant = [
        { uid: "roPlant", manufacturer: "generic", selected: null },
      ];
      result.metadata.catalog.greaseInterceptorTrap = [];

      result.metadata.heatLoss.ventAirChangesRateStandard = "ASHRAE62.1";
      result.metadata.heatLoss.heatingAirChangesRateStandard = "ASHRAE62.1";

      result.levels = {
        ground: {
          entities: {},
          floorHeightM: 0,
          name: "Level 1",
          abbreviation: "L1",
          uid: "ground",
        },
      };

      const ventNodePropAll = result.metadata.nodes.mechanical.filter(
        (node) => node.type === "air-handling-vent",
      ) as DualSystemMechanicalNodeProps[];
      for (const ventNode of ventNodePropAll) {
        ventNode.supplyHeightAboveFloorMM =
          ventNode.extractHeightAboveFloorMM =
          ventNode.heatingHeightAboveFloorMM =
          ventNode.chilledHeightAboveFloorMM =
            AHU_HEIGHT_ABOVE_FLOOR_MM_US;
      }
      result.metadata.heatLoss.electricityCostPerUnit.currency = Currency.USD;
      break;
    default:
      assertUnreachable(locale);
  }
  return result;
}

export const initialFlowSystems: FlowSystem[] = [
  // TODO: these values should get got from the database.
  {
    type: "pressure",
    role: "coldwater",
    name: "Cold Water",
    temperatureC: 20,
    color: { hex: "#009CE0" },
    uid: "cold-water",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "hotwater",
    name: "Hot Water",
    temperatureC: 65,
    color: { hex: "#F44E3B" },
    uid: "hot-water",
    fluid: "water",
    hasReturnSystem: true,
    defaultPipeHeightM: 3,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: true,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "warmwater",
    name: "Warm Water",
    temperatureC: 50,
    color: { hex: "#F49000" },
    uid: "warm-water",
    fluid: "water",
    hasReturnSystem: false,
    defaultPipeHeightM: 3,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "gas",
    role: "gas",
    name: "Gas",
    color: { hex: "#FCDC00" },
    uid: StandardFlowSystemUids.Gas,
    temperatureC: 20,
    fluid: "naturalGas",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        velocityMS: 20,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        velocityMS: 20,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      connections: {
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "pexSdr74",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Hydrant",
    temperatureC: 20,
    color: { hex: "#9F0500" },
    uid: StandardFlowSystemUids.FireHydrant,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 100,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Sprinkler",
    temperatureC: 20,
    color: { hex: "#653294" },
    uid: StandardFlowSystemUids.FireSprinkler,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
    },
  },
  {
    type: "fire",
    role: "fire",
    name: "Fire Hose Reel",
    temperatureC: 20,
    color: { hex: "#FCDC00" },
    uid: StandardFlowSystemUids.FireHoseReel,
    fluid: "water",
    defaultPipeHeightM: 3,

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 25,
      },
    },
  },
  {
    type: "pressure",
    role: "coldwater",
    name: "Rainwater Reuse",
    temperatureC: 20,
    color: { hex: "#bf00ff" },
    uid: "rainwater-reuse",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  },
  {
    type: "pressure",
    role: "coldwater",
    name: "Reverse Osmosis",
    temperatureC: 20,
    color: { hex: "#999999" },
    uid: "reverse-osmosis",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  },
  ...MECHANICAL_FLOW_SYSTEMS(),
  ...UNDERFLOOR_FLOW_SYSTEMS(),
  ...DRAINAGE_FLOW_SYSTEMS(),
  ...STORMWATER_SYSTEM(),
  ...VENTILATION_SYSTEM(),
];

export const initialAustralianDrawing = (): DrawingState => ({
  metadata: {
    generalInfo: {
      title: "Untitled",
      projectNumber: "",
      projectStage: "",
      designer: "",
      reviewed: "",
      approved: "",
      revision: 1,
      client: "",
      description: "",
      projectAddress: {
        formattedAddress: "",
        latitude: null,
        longitude: null,
        country: "",
      },
    },
    units: {
      lengthMeasurementSystem: MeasurementSystem.METRIC,
      volumeMeasurementSystem: VolumeMeasurementSystem.METRIC,
      velocityMeasurementSystem: VelocityMeasurementSystem.METRIC,
      pressureMeasurementSystem: PressureMeasurementSystem.METRIC,
      ventilationPressureMeasurementSystem:
        PressureMeasurementSystem.METRIC_SMALL,
      temperatureMeasurementSystem: MeasurementSystem.METRIC,
      gasEnergyMeasurementSystem: GasEnergyMeasurementSystem.METRIC,
      mechanicalEnergyMeasurementSystem:
        MechanicalEnergyMeasurementSystem.UNIVERSAL,
      pressureDropMeasurementSystem: PressureDropMeasurementSystem.METRIC,
      rainfallMeasurementSystem: RainfallMeasurementSystem.METRIC,
      areaMeasurementSystem: AreaMeasurementSystem.METRIC,
      flowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
      ventilationFlowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
      currency: {
        symbol: CurrencySymbol.DOLLARS,
        multiplierPct: 100,
      },
    },
    flowSystems: cloneSimple(initialFlowSystems).reduce(
      (p, c) => ({ ...p, [c.uid]: c }),
      {},
    ) as FlowSystemsMetadata,
    flowSystemUidsInOrder: initialFlowSystems.map((s) => s.uid),

    calculationParams: {
      psdMethod: SupportedPsdStandards.as35002021LoadingUnits,
      loadingUnitVariant: "low",
      dwellingMethod: null,
      drainageMethod: SupportedDrainageMethods.AS2021FixtureUnits,
      drainageSystem: "drainageSystem1",
      en12056FrequencyFactor: EN12056FrequencyFactor.CongestedUse,
      ringMainCalculationMethod: RingMainCalculationMethod.ISOLATION_CASES,
      ductSizingMethod: DUCT_SIZING_METHODS[0].key as DuctSizingMethod,
      pipeSizingMethod: PIPE_SIZING_METHODS[0].key as PipeSizingMethod,
      componentPressureLossMethod: ComponentPressureLossMethod.INDIVIDUALLY,
      pipePressureLossAddOnPCT: 0,

      roomTemperatureC: 20,
      windSpeedForHeatLossMS: 0,
      apartmentNum: 1,
      occupantNum: 1,
      gravitationalAcceleration: 9.80665,
      rainfallIntensityMm_H: 300,
      combineLUs: false,

      districtHeating: SupportedDistirctHeating.CIBSECP1Objective3_2,
      gasCalcMethod:
        SupportedGasCalculationMethods.internationalFuelGasCodeEquation2018,
    },
    availableFixtures: [
      "basin",
      "bath",
      "shower",
      "kitchenSink",
      "wc",
      "washingMachine",
      "laundryTrough",
    ],
    nodes: {
      mechanical: cloneSimple(INITIAL_MECHANICAL_NODE),
      fire: [
        {
          name: "Fire Hydrant",
          customEntityId: "Fire Hydrant",

          subGroups: [
            {
              name: "5 Liters",
              subGroupId: "fire_hydrant_default_5",
              maxPressureKPA: 1300,
              minPressureKPA: 200,
              continuousFlowRateLS: 5,
              nodeGroupHex: "#FF5733",
              maxiumumSimutaneousNode: 2,
              kvValue: 0.3,
            },
            {
              name: "10 Liters",
              subGroupId: "fire_hydrant_default_10",
              maxPressureKPA: 1300,
              minPressureKPA: 200,
              continuousFlowRateLS: 10,
              nodeGroupHex: "#DDF611",
              maxiumumSimutaneousNode: 2,
              kvValue: 0.3,
            },
          ],
        },
        {
          name: "Fire Hose Reel",
          customEntityId: "Fire Hose Reel",
          subGroups: [
            {
              name: "Default",
              subGroupId: "fire_hose_reel_default",
              maxPressureKPA: 1000,
              minPressureKPA: 250,
              continuousFlowRateLS: 0.33,
              nodeGroupHex: "#DAF7A6",
              maxiumumSimutaneousNode: 2,
              kvValue: 0.3,
            },
          ],
        },
        {
          name: "Safety Shower",
          customEntityId: "Safety Shower",
          subGroups: [
            {
              name: "Default",
              subGroupId: "safety_shower_default",
              maxPressureKPA: 500,
              minPressureKPA: 210,
              continuousFlowRateLS: 1.25,
              nodeGroupHex: "#FFC300",
              maxiumumSimutaneousNode: 2,
              kvValue: 0.3,
            },
          ],
        },
        {
          name: "Fire Sprinkler",
          customEntityId: "Fire Sprinkler",
          subGroups: [
            {
              name: "Light Hazard",
              subGroupId: "fire_sprinkler_light_hazard_default",
              maxPressureKPA: 1200,
              minPressureKPA: 70,
              continuousFlowRateLS: 0.8,
              nodeGroupHex: "#C70039",
              maxiumumSimutaneousNode: 6,
              kvValue: 0.3,
            },
            {
              name: "Medium Hazard",
              subGroupId: "fire_sprinkler_medium_hazard_default",
              maxPressureKPA: 1200,
              minPressureKPA: 70,
              continuousFlowRateLS: 1,
              nodeGroupHex: "#900C3F",
              maxiumumSimutaneousNode: 18,
              kvValue: 0.3,
            },
            {
              name: "High Hazard",
              subGroupId: "fire_sprinkler_high_hazard_default",
              maxPressureKPA: 1200,
              minPressureKPA: 70,
              continuousFlowRateLS: 1,
              nodeGroupHex: "#581845",
              maxiumumSimutaneousNode: 30,
              kvValue: 0.3,
            },
          ],
        },
      ],
    },
    catalog: {
      pipes: [
        {
          manufacturer: "blucherSS",
          uid: "stainlessSteelDrainage",
          selected: null,
        },
      ],
      backflowValves: [
        { manufacturer: "apolloRpzd", uid: "RPZD", selected: null },
      ],
      mixingValves: [
        { manufacturer: "caleffi", uid: "temperingValve", selected: null },
      ],
      prv: [{ manufacturer: "caleffi", uid: "prv", selected: null }],
      balancingValves: [
        { manufacturer: "cimberio", uid: "balancingValves", selected: null },
      ],
      hotWaterPlant: [
        { uid: "circulatingPumps", manufacturer: "grundfos", selected: null },
        { uid: "hotWaterPlant", manufacturer: "rheem", selected: null },
      ],
      fixtures: [],
      floorWaste: [
        { manufacturer: "blucher", uid: "floorWaste", selected: null },
      ],
      inspectionOpening: [
        { manufacturer: "blucher", uid: "inspectionOpening", selected: null },
      ],
      pump: [{ manufacturer: "dab", uid: "pump", selected: null }],
      pumpTank: [{ manufacturer: "generic", uid: "tank", selected: null }],
      heatLoadMaterial: [
        { manufacturer: "generic", uid: "heatLoad", selected: null },
      ],
      heatEmitters: [
        { manufacturer: "generic", uid: "radiators", selected: null },
      ],
      underfloorHeating: [
        { manufacturer: "generic", uid: "manifold", selected: null },
      ],
      filters: [
        { manufacturer: "southland", uid: "softener", selected: null },
        { manufacturer: "southland", uid: "backwash", selected: null },
        { manufacturer: "southland", uid: "cartridge", selected: null },
        { manufacturer: "southland", uid: "uv", selected: null },
        { manufacturer: "southland", uid: "backwashRainwater", selected: null },
      ],
      roPlant: [{ manufacturer: "southland", uid: "roPlant", selected: null }],
      ducts: [],
    },
    priceTable: {},
    lines: cloneDeep(DEFAULT_LINES),
    heatLoss: {
      material: {
        "External Wall": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick": {
            uid: "Solid Brick",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick Insulated": {
            uid: "Solid Brick Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone": {
            uid: "Solid Stone",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone Insulated": {
            uid: "Solid Stone Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete": {
            uid: "Solid Concrete",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete Insulated": {
            uid: "Solid Concrete Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Uninsulated": {
            uid: "Cavity Wall Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Insulated": {
            uid: "Cavity Wall Insulated",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Uninsulated": {
            uid: "Partition Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Insulated": {
            uid: "Partition Insulated",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Internal Wall": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick": {
            uid: "Solid Brick",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick Insulated": {
            uid: "Solid Brick Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone": {
            uid: "Solid Stone",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone Insulated": {
            uid: "Solid Stone Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete": {
            uid: "Solid Concrete",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete Insulated": {
            uid: "Solid Concrete Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Uninsulated": {
            uid: "Cavity Wall Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Insulated": {
            uid: "Cavity Wall Insulated",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Uninsulated": {
            uid: "Partition Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Insulated": {
            uid: "Partition Insulated",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Party Wall": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick": {
            uid: "Solid Brick",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Brick Insulated": {
            uid: "Solid Brick Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone": {
            uid: "Solid Stone",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Stone Insulated": {
            uid: "Solid Stone Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete": {
            uid: "Solid Concrete",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Concrete Insulated": {
            uid: "Solid Concrete Insulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Uninsulated": {
            uid: "Cavity Wall Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Cavity Wall Insulated": {
            uid: "Cavity Wall Insulated",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          Plasterboard: {
            uid: "Plasterboard",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Uninsulated": {
            uid: "Partition Uninsulated",
            manufacturer: "generic",
            selected: null,
          },
          "Partition Insulated": {
            uid: "Partition Insulated",
            manufacturer: "generic",
            selected: null,
          },
        },
        Window: {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Single Glazed": {
            uid: "Metal Single Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Double Glazed": {
            uid: "Metal Double Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Triple Glazed": {
            uid: "Metal Triple Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Single Glazed": {
            uid: "Wood Single Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Double Glazed": {
            uid: "Wood Double Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Triple Glazed": {
            uid: "Wood Triple Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "PVC Single Glazed": {
            uid: "PVC Single Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "PVC Double Glazed": {
            uid: "PVC Double Glazed",
            manufacturer: "generic",
            selected: null,
          },
          "PVC Triple Glazed": {
            uid: "PVC Triple Glazed",
            manufacturer: "generic",
            selected: null,
          },
        },
        "External Door": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Wood Door": {
            uid: "Solid Wood Door",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Single": {
            uid: "Glazed Wood Single",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Double": {
            uid: "Glazed Wood Double",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Triple": {
            uid: "Glazed Wood Triple",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Single": {
            uid: "Metal Single",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Double": {
            uid: "Metal Double",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Triple": {
            uid: "Metal Triple",
            manufacturer: "generic",
            selected: null,
          },
          "Insulated Internal": {
            uid: "Insulated Internal",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Internal Door": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Solid Wood Door": {
            uid: "Solid Wood Door",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Single": {
            uid: "Glazed Wood Single",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Double": {
            uid: "Glazed Wood Double",
            manufacturer: "generic",
            selected: null,
          },
          "Glazed Wood Triple": {
            uid: "Glazed Wood Triple",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Single": {
            uid: "Metal Single",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Double": {
            uid: "Metal Double",
            manufacturer: "generic",
            selected: null,
          },
          "Metal Triple": {
            uid: "Metal Triple",
            manufacturer: "generic",
            selected: null,
          },
          "Insulated Internal": {
            uid: "Insulated Internal",
            manufacturer: "generic",
            selected: null,
          },
        },
        Roof: {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          "Aerated Concrete": {
            uid: "Aerated Concrete",
            manufacturer: "generic",
            selected: null,
          },
          Aerated: {
            uid: "Aerated",
            manufacturer: "generic",
            selected: null,
          },
          "Felt/Bitumen": {
            uid: "Felt/Bitumen",
            manufacturer: "generic",
            selected: null,
          },
          Screed: {
            uid: "Screed",
            manufacturer: "generic",
            selected: null,
          },
          "Stone Chippings": {
            uid: "Stone Chippings",
            manufacturer: "generic",
            selected: null,
          },
          "Clay Tiles": {
            uid: "Clay Tiles",
            manufacturer: "generic",
            selected: null,
          },
          "Concrete Tiles": {
            uid: "Concrete Tiles",
            manufacturer: "generic",
            selected: null,
          },
          "Wood/Wool": {
            uid: "Wood/Wool",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Bottom Floor": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          Concrete: {
            uid: "Concrete",
            manufacturer: "generic",
            selected: null,
          },
          Steel: {
            uid: "Steel",
            manufacturer: "generic",
            selected: null,
          },
          Screed: {
            uid: "Screed",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Blocks": {
            uid: "Wood Blocks",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Suspended Floor": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          Concrete: {
            uid: "Concrete",
            manufacturer: "generic",
            selected: null,
          },
          Steel: {
            uid: "Steel",
            manufacturer: "generic",
            selected: null,
          },
          Screed: {
            uid: "Screed",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Blocks": {
            uid: "Wood Blocks",
            manufacturer: "generic",
            selected: null,
          },
        },
        "Party Floor": {
          "Building Regs 2022 Limit": {
            uid: "Building Regs 2022 Limit",
            manufacturer: "generic",
            selected: null,
          },
          Concrete: {
            uid: "Concrete",
            manufacturer: "generic",
            selected: null,
          },
          Steel: {
            uid: "Steel",
            manufacturer: "generic",
            selected: null,
          },
          Screed: {
            uid: "Screed",
            manufacturer: "generic",
            selected: null,
          },
          Softwood: {
            uid: "Softwood",
            manufacturer: "generic",
            selected: null,
          },
          Hardwood: {
            uid: "Hardwood",
            manufacturer: "generic",
            selected: null,
          },
          "Wood Blocks": {
            uid: "Wood Blocks",
            manufacturer: "generic",
            selected: null,
          },
        },
      },
      defaultMaterial: {
        "External Wall": "Building Regs 2022 Limit",
        "Internal Wall": "Building Regs 2022 Limit",
        "Party Wall": "Building Regs 2022 Limit",
        Window: "Building Regs 2022 Limit",
        "External Door": "Building Regs 2022 Limit",
        "Internal Door": "Building Regs 2022 Limit",
        Roof: "Building Regs 2022 Limit",
        "Bottom Floor": "Building Regs 2022 Limit",
        "Suspended Floor": "Building Regs 2022 Limit",
        "Party Floor": "Building Regs 2022 Limit",
      },
      defaultColor: {
        "External Wall": {
          hex: "#000000",
        },
        "Internal Wall": {
          hex: "#969696",
        },
        "Party Wall": {
          hex: "#d3d3d3",
        },
        Window: {
          hex: "#000000",
        },
        "External Door": {
          hex: "#000000",
        },
        "Internal Door": {
          hex: "#000000",
        },
        Roof: {
          hex: "#ffffff",
        },
        "Bottom Floor": {
          hex: "#ffffff",
        },
        "Suspended Floor": {
          hex: "#ffffff",
        },
        "Party Floor": {
          hex: "#ffffff",
        },
      },
      customMaterial: {
        "External Wall": {},
        "Internal Wall": {},
        "Party Wall": {},
        Window: {},
        "External Door": {},
        "Internal Door": {},
        Roof: {},
        "Bottom Floor": {},
        "Suspended Floor": {},
        "Party Floor": {},
      },
      customRoom: {},
      roofSpec: cloneSimple(INITIAL_ROOF_SPEC),
      defaultRoofType: RoofType.Flat,
      ventAirChangesRateStandard: "CIBSE2016",
      heatingAirChangesRateStandard: "CIBSE2016",
      wallSpec: {
        roomHeightM: 2.5,
        internalWidthMM: INTERNAL_WALL_WIDTH_MM,
        externalWidthMM: EXTERNAL_WALL_WIDTH_MM,
        partyWidthMM: PARTY_WALL_WIDTH_MM,
      },
      defaultRoomTemperatureC: 20,
      externalWinterTemperatureC: -2.2,
      groundTemperatureC: 5,
      thermalBridgingCoefficient: 0.05,
      spareHeatGainPrecent: DEFAULT_SPARE_HEAT_CAP,
      spareHeatLossPrecent: DEFAULT_SPARE_HEAT_CAP,
      externalSummerTemperatureC: 28,
      solarHeatGainCoefficient: 0.6,
      roomsBelowTransparentPct: DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT,
      solarRadiationWPerMin: {
        top: 500,
        bottom: 500,
        left: 500,
        right: 500,
        rightTop: 500,
        rightBottom: 500,
        leftTop: 500,
        leftBottom: 500,
      },
      windowSpec: {
        [WindowType.WINDOW]: {
          heightM: 1.37,
          lengthM: 0.85,
        },
        [WindowType.VELUX]: {
          widthM: 1,
          lengthM: 1,
        },
      },
      doorSpec: {
        heightM: 2.03,
        lengthM: {
          [DoorType.SINGLE]: 0.81,
          [DoorType.BI_FOLDING]: 1.82,
        },
      },
      internalHeatSource: cloneSimple(INITIAL_INTERNAL_HEATSOURCE),
      baseTemperatureC: DEFAULT_BASE_TEMPERATURE_C,
      heatingDegreeDays: DEFAULT_HEATING_DEGREE_DAYS,
      coolingDegreeDays: DEFAULT_COOLING_DEGREE_DAYS,
      winterTempCutoffPctThreshold: DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD,
      summerTempCutoffPctThreshold: DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD,
      winterPartyTemperatureC: DEFAULT_WINTER_PARTY_TEMPERATURE_C,
      summerPartyTemperatureC: DEFAULT_SUMMER_PARTY_TEMPERATURE_C,
      SCOP: DEFAULT_SCOP,
      energyPerformanceCertificateInformation: cloneSimple(
        DEFAULT_EPC_INFORMATION,
      ),
      existingHeatingSystem: cloneSimple(DEFAULT_EXISTING_HEATING_SYSTEM),
      electricityCostPerUnit: {
        value: 0,
        currency: Currency.AUD,
      },
      ventHeatLossRecoveryPct: 0,
      grilleFlowRateType: "room",
    },
    annotations: {
      fontSize: 150,
    },
    roomResultsSettings: {
      ...DEFAULT_ROOM_RESULTS_SETTINGS,
    },
    marketplaceItems: [],
    workflows: {
      heat_gain: {
        enabled: true,
      },
      heat_loss: {
        enabled: true,
      },
      mech_heating: {
        enabled: true,
      },
      mech_ventilation: {
        enabled: true,
      },
      mech_underfloor_heating: {
        enabled: true,
      },
      mech_chilled: {
        enabled: true,
      },
      plumbing_water: {
        enabled: true,
      },
      plumbing_gas: {
        enabled: true,
      },
      plumbing_wastewater: {
        enabled: true,
      },
      plumbing_stormwater: {
        enabled: true,
      },
      fire_sprinklers: {
        enabled: true,
      },
      fire_hydrants: {
        enabled: true,
      },
      fire_hosereels: {
        enabled: true,
      },
    },
    metaPdf: {},
  },
  levels: {
    ground: {
      entities: {},
      floorHeightM: 0,
      name: "Ground Floor",
      abbreviation: "G",
      uid: "ground",
    },
  },
  shared: {},
});

export type AddressData = {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  route: string;
  street_number: string;
  newVal: string;
  oldVal: string;
};

export type PlaceResult = {
  formatted_address: string;
};

export function getAHUSupplyExtractHeightAboveFloorMM(
  locale: SupportedLocales,
) {
  switch (locale) {
    case SupportedLocales.AU:
    case SupportedLocales.UK:
      return AHU_HEIGHT_ABOVE_FLOOR_MM;
    case SupportedLocales.US:
      return AHU_HEIGHT_ABOVE_FLOOR_MM_US;
  }
  assertUnreachable(locale);
}

export function getAHUHeatingChilledHeightAboveFloorMM(
  locale: SupportedLocales,
) {
  switch (locale) {
    case SupportedLocales.AU:
    case SupportedLocales.UK:
      return 2400;
    case SupportedLocales.US:
      return AHU_HEIGHT_ABOVE_FLOOR_MM_US;
  }
  assertUnreachable(locale);
}

export function findEntitiesOfTypeOnLevel<T extends DrawableEntityConcrete>(
  drawing: DrawingState,
  levelUid: string,
  f: (e: DrawableEntityConcrete) => e is T,
): T[] {
  const entities = betterObjectValues(drawing.levels[levelUid].entities);
  return entities.filter(f);
}

export function findHeatingAreasForRoom(
  drawing: DrawingState,
  level: string | null,
  room: RoomRoomEntity,
) {
  if (!level) {
    return [];
  }

  const heatingAreas = findEntitiesOfTypeOnLevel(
    drawing,
    level,
    isHeatedAreaSegmentEntity,
  );
  return heatingAreas.filter((x) => x.roomUid === room.uid);
}
