/*
import {
    DRAINAGE_FLOW_SYSTEMS,
    DrawingState,
    initialAustralianDrawing,
    initialDrainageProperties
} from "./document/drawing";
import { EntityType } from "./document/entities/types";
import { InsulationJackets, InsulationMaterials, StandardFlowSystemUids, SupportedPsdStandards } from "./config";
import { PlantType } from "./document/entities/plants/plant-types";
import { cloneSimple } from "../lib/utils";
import { FlowSourceEntityV11 } from "./document/entities/flow-source-entity";
import { v4 as uuidv4 } from 'uuid';
import { FlowConfiguration, SystemNodeEntity } from "./document/entities/big-valve/big-valve-entity";
import { ValveType } from "./document/entities/directed-valves/valve-types";

// This file is for managing upgrades between versions.
// Remember to copy this directory before developing a major change, and bump the api version number, then
// implement the upgrade method below.
// Remember to also add this function to the upgrade function in default.

// upgrade 9 to 10
// insulation jackets in flow systems

export function operations_upgrade9to10(original: DrawingState) {
    for (const fs of original.metadata.flowSystems) {
        if (fs.insulationJacket === undefined) {
            fs.insulationJacket = InsulationJackets.allServiceJacket;
        }
    }
}

export function operations_upgrade10to11(original: DrawingState) {
    if (original.metadata.units === undefined) {
        original.metadata.units = cloneSimple(initialAustralianDrawing.metadata.units);
    }
}

export function operations_upgrade11to12(original: DrawingState) {
    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            if (e.type === EntityType.FLOW_SOURCE) {
                if (e.minPressureKPA === undefined || e.maxPressureKPA === undefined && (e as any as FlowSourceEntityV11).pressureKPA !== undefined) {
                    e.minPressureKPA = e.maxPressureKPA = (e as any as FlowSourceEntityV11).pressureKPA;
                    delete (e as any as FlowSourceEntityV11).pressureKPA;
                }
            }
        }
    }
}

// add min and max pressures to load nodes.
// rename bs6700 to cibse Guide G
export function operations_upgrade12to13(original: DrawingState) {
    if (original.metadata.calculationParams.psdMethod === 'bs6700' as any) {
        original.metadata.calculationParams.psdMethod = SupportedPsdStandards.cibseGuideG;
    }

    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            if (e.type === EntityType.LOAD_NODE) {
                if (e.minPressureKPA === undefined) {
                    e.minPressureKPA = null;
                }
                if (e.maxPressureKPA === undefined) {
                    e.maxPressureKPA = null;
                }
            }
        }
    }
}

export function operations_upgrade13to14(original: DrawingState) {
    if (original.metadata.catalog === undefined) {
        original.metadata.catalog = cloneSimple(initialAustralianDrawing.metadata.catalog);
    }
}

export function operations_upgrade14to15(original: DrawingState) {
    if (original.metadata.priceTable === undefined) {
        original.metadata.priceTable = cloneSimple(initialAustralianDrawing.metadata.priceTable);
    }
}

export function operations_upgrade15to16(original: DrawingState) {
    if (!original.metadata.flowSystems.find((f) => f.uid === StandardFlowSystemUids.Gas)) {
        original.metadata.flowSystems.push(
            {
                name: "Gas",
                temperature: 20,
                color: { hex: "#FCDC00" },
                uid: StandardFlowSystemUids.Gas,
                fluid: "naturalGas",
                hasReturnSystem: false,
                returnIsInsulated: false,
                returnMaxVelocityMS: 1,
                insulationMaterial: InsulationMaterials.calciumSilicate,
                insulationJacket: InsulationJackets.allServiceJacket,
                insulationThicknessMM: 25,

                networks: {
                    RISERS: {
                        spareCapacityPCT: 0,
                        velocityMS: 20,
                        material: "copperTypeB",
                        minimumPipeSize: 15,
                    },
                    RETICULATIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 20,
                        material: "copperTypeB",
                        minimumPipeSize: 15,
                    },
                    CONNECTIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 3,
                        material: "pexSdr74",
                        minimumPipeSize: 16,
                    }
                }
            } as any,
        );
    }

    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            if (e.type === EntityType.PLANT) {
                if (e.plant.type === PlantType.RETURN_SYSTEM) {
                    // Add the missing gas entity
                    if (!e.plant.gasNodeUid) {
                        const newUid = uuidv4();

                        const newEntity: SystemNodeEntity = {
                            center: {
                                x: (-e.widthMM / 2) * (e.rightToLeft ? -1 : 1),
                                y: (e.heightMM / 4)
                            },
                            parentUid: e.uid,
                            type: EntityType.SYSTEM_NODE,
                            calculationHeightM: null,
                            systemUid: StandardFlowSystemUids.Gas,
                            uid: newUid,
                            allowAllSystems: false,
                            configuration: FlowConfiguration.INPUT
                        };

                        level.entities[newUid] = newEntity;
                    }

                    if (e.plant.gasConsumptionMJH === undefined) {
                        e.plant.gasConsumptionMJH = null;
                    }

                    if (e.plant.gasPressureKPA === undefined) {
                        e.plant.gasPressureKPA = null;
                    }
                }
            } else if (e.type === EntityType.DIRECTED_VALVE) {
                if (e.valve.type === ValveType.WATER_METER) {
                    if (e.valve.pressureDropKPA === undefined) {
                        e.valve.pressureDropKPA = null;
                    }
                }
            } else if (e.type === EntityType.LOAD_NODE) {
                if (e.node.gasFlowRateMJH === undefined) {
                    e.node.gasFlowRateMJH = 0;
                }
                if (e.node.gasPressureKPA === undefined) {
                    e.node.gasPressureKPA = 0;
                }
            }
        }
    }
}

// Fix issue where the gasNodeId was not set for plants that were upgraded.
export function operations_upgrade16to17(original: DrawingState) {
    // Step 1. Set the gas id of any orphan gas nodes
    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            if (e.type === EntityType.SYSTEM_NODE) {
                if (e.systemUid === StandardFlowSystemUids.Gas) {
                    const parent = level.entities[e.parentUid];
                    if (parent && parent.type === EntityType.PLANT) {
                        if (parent.plant.type === PlantType.RETURN_SYSTEM && !parent.plant.gasNodeUid) {
                            parent.plant.gasNodeUid = e.uid;
                        }
                    }
                }
            }
        }
    }

    // Step 2. Add any remaining plant gas nodes, properly this time.
    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            if (e.type === EntityType.PLANT) {
                if (e.plant.type === PlantType.RETURN_SYSTEM) {
                    // Add the missing gas entity
                    if (!e.plant.gasNodeUid) {
                        const newUid = uuidv4();

                        const newEntity: SystemNodeEntity = {
                            center: {
                                x: (-e.widthMM / 2) * (e.rightToLeft ? -1 : 1),
                                y: (e.heightMM / 4)
                            },
                            parentUid: e.uid,
                            type: EntityType.SYSTEM_NODE,
                            calculationHeightM: null,
                            systemUid: StandardFlowSystemUids.Gas,
                            uid: newUid,
                            allowAllSystems: false,
                            configuration: FlowConfiguration.INPUT
                        };

                        level.entities[newUid] = newEntity;
                        e.plant.gasNodeUid = newEntity.uid;
                    }
                }
            }
        }
    }
}

export function operations_upgrade17to18(original: DrawingState) {
    original.metadata.flowSystems.forEach(system => {
        system.networks.RISERS.minimumPipeSize = 15;
        system.networks.RETICULATIONS.minimumPipeSize = 15;
        system.networks.CONNECTIONS.minimumPipeSize = 16;
    });
}

export function operations_upgrade18to19(original: DrawingState) {
    if (!original.metadata.flowSystems.find((f) => f.uid === StandardFlowSystemUids.FireHydrant)) {
        original.metadata.flowSystems.push(
            {
                name: "Fire Hydrant",
                temperature: 20,
                color: { hex: "#9F0500" },
                uid: StandardFlowSystemUids.FireHydrant,
                fluid: "water",
                hasReturnSystem: false,
                returnIsInsulated: false,
                returnMaxVelocityMS: 1,
                insulationMaterial: InsulationMaterials.calciumSilicate,
                insulationJacket: InsulationJackets.allServiceJacket,
                insulationThicknessMM: 25,

                networks: {
                    RISERS: {
                        spareCapacityPCT: 0,
                        velocityMS: 4,
                        material: "gmsMedium",
                        minimumPipeSize: 100,
                    },
                    RETICULATIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 4,
                        material: "gmsMedium",
                        minimumPipeSize: 100,
                    },
                    CONNECTIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 4,
                        material: "gmsMedium",
                        minimumPipeSize: 100,
                    }
                }
            } as any,
        );
    }

    if (!original.metadata.flowSystems.find((f) => f.uid === StandardFlowSystemUids.FireHoseReel)) {
        original.metadata.flowSystems.push(
            {
                name: "Fire Hose Reel",
                temperature: 20,
                color: { hex: "#FCDC00" },
                uid: StandardFlowSystemUids.FireHoseReel,
                fluid: "water",
                hasReturnSystem: false,
                returnIsInsulated: false,
                returnMaxVelocityMS: 1,
                insulationMaterial: InsulationMaterials.calciumSilicate,
                insulationJacket: InsulationJackets.allServiceJacket,
                insulationThicknessMM: 25,

                networks: {
                    RISERS: {
                        spareCapacityPCT: 0,
                        velocityMS: 1.5,
                        material: "copperTypeB",
                        minimumPipeSize: 25,
                    },
                    RETICULATIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 1.5,
                        material: "copperTypeB",
                        minimumPipeSize: 25,
                    },
                    CONNECTIONS: {
                        spareCapacityPCT: 0,
                        velocityMS: 1.5,
                        material: "copperTypeB",
                        minimumPipeSize: 25,
                    }
                }
            } as any,
        );
    }
}

// Then, we have to add fixtureUnits to load nodes.
// Add variant field

// Add drainage rough in to fixtures.
// Vent colour setting to flow systems


const newSewerNodeOf: { [key: string]: string } = {};

export function operations_upgrade19to20and21(original: DrawingState) {
    if (!original.metadata.flowSystems.find((s) => s.uid === StandardFlowSystemUids.SewerDrainage)) {
        // Vent colour setting to flow systems
        for (const system of original.metadata.flowSystems) {
            system.drainageProperties = cloneSimple(initialDrainageProperties);
        }

        // We have to add the sewage flow systems.
        original.metadata.flowSystems.push(
            ...DRAINAGE_FLOW_SYSTEMS,
        );
    }


    for (const level of Object.values(original.levels)) {
        const entities = level.entities;
        for (const e of Object.values(entities)) {
            // Add drainage rough in to fixtures.
            if (e.type === EntityType.FIXTURE) {
                if (!e.roughInsInOrder.includes(StandardFlowSystemUids.SewerDrainage)) {
                    e.roughInsInOrder.unshift(StandardFlowSystemUids.SewerDrainage);

                    const newSystemNode: SystemNodeEntity = {
                        center: {
                            x: e.pipeDistanceMM * 0,
                            y: e.pipeDistanceMM * -0.2,
                        },
                        parentUid: e.uid,
                        type: EntityType.SYSTEM_NODE,
                        calculationHeightM: null,
                        allowAllSystems: false,
                        systemUid: StandardFlowSystemUids.SewerDrainage,
                        uid: newSewerNodeOf[e.uid] || uuidv4(),
                        configuration: FlowConfiguration.INPUT
                    };
                    newSewerNodeOf[e.uid] = newSystemNode.uid;
                    e.roughIns[StandardFlowSystemUids.SewerDrainage] = {
                        continuousFlowLS: null,
                        designFlowRateLS: null,
                        loadingUnits: null,
                        maxPressureKPA: null,
                        minPressureKPA: null,
                        allowAllSystems: false,
                        uid: newSystemNode.uid,
                    };
                    level.entities[newSystemNode.uid] = newSystemNode;
                }
            }

            // Add isVent option of riser.
            else if (e.type === EntityType.RISER) {
                if (e.isVent === undefined) {
                    e.isVent = false;
                }
            }

            if (e.type === EntityType.FIXTURE) {
                if (e.upcFixtureUnits === undefined) {
                    e.upcFixtureUnits = null;
                }
                if (e.asnzFixtureUnits === undefined) {
                    e.asnzFixtureUnits = null;
                }
                if (e.enDischargeUnits === undefined) {
                    e.enDischargeUnits = null;
                }
            } else if (e.type === EntityType.LOAD_NODE) {
                if (e.node.upcFixtureUnits === undefined) {
                    e.node.upcFixtureUnits = null;
                }
                if (e.node.asnzFixtureUnits === undefined) {
                    e.node.asnzFixtureUnits = null;
                }
                if (e.node.enDischargeUnits === undefined) {
                    e.node.enDischargeUnits = null;
                }
            }

        }
    }
}

export function operations_upgraded21to22(original: DrawingState) {
    // no-op.
    // Just exist here to invoke the compression.
}

*/

// Migration operations above are kept commented for future reference.
// They are not used anymore in code.

// export const DRAWING_UPGRADE_VERSION = 23;
// Before this version, documents are stored only as an Operation[] in the operations table.
// Upgrades need to be performed for each operation of a document.

// Starting with this version, documents are stored as:
// * Drawing in the drawing table - the full drawingstate
// * Operation[] in the operations table - used for history
// Any document upgrades from now on are performed on a per-drawing basis.
// Rules:
// * Upgrades need to traverse the document only once and do any kind of processing they need to do on the entities in the document only once.
// * Upgrades need to be reentrant and idempotent.
//   After a field partial execution the second execution should continue correctly.
//   Executing the same upgrade multiple times on a document will result the same document.
//   Basically, Upgrade to check if a chamnge has already been done on an entity.
// * All new data that the upgrade adds to the document needs to be added as operations as well in the database.
import uuid from "uuid";
import { goldenRatioColor } from "../lib/color";
import { Currency } from "../lib/currency";
import {
  AreaMeasurementSystem,
  FlowRateMeasurementSystem,
  GasEnergyMeasurementSystem,
  MechanicalEnergyMeasurementSystem,
  PressureMeasurementSystem,
  RainfallMeasurementSystem,
  f2C,
} from "../lib/measurements";
import { assertType, assertTypePure, cloneSimple } from "../lib/utils";
import {
  DualSystemMechanicalNodeProps,
  FireNodePropsV1,
} from "../models/CustomEntity";
import { Operation } from "../models/Operation";
import { UnderfloorHeatingSettings } from "./calculations/underfloor-heating/types";
import { ACRUnits, HeatLoadItem } from "./catalog/heatload/types";
import {
  CURRENT_VERSION,
  InsulationJackets,
  InsulationMaterials,
  PipePhysicalMaterial,
  StandardFlowSystemUids,
  SupportedDistirctHeating,
  SupportedDrainageMethods,
  SupportedDwellingStandards,
  SupportedGasCalculationMethods,
  SupportedPsdStandards,
} from "./config";
import {
  AHU_CAPACITY_LKW,
  AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,
  AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
  CustomRoomSpecV1,
  CustomRoomSpecV2,
  DEFAULT_BASE_TEMPERATURE_C,
  DEFAULT_COOLING_DEGREE_DAYS,
  DEFAULT_EPC_INFORMATION,
  DEFAULT_EXISTING_HEATING_SYSTEM,
  DEFAULT_HEATING_DEGREE_DAYS,
  DEFAULT_RETURN_COLOR_HEX,
  DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT,
  DEFAULT_SCOP,
  DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD,
  DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD,
  DrawingState,
  EXTERNAL_WALL_WIDTH_MM,
  FCU_CAPACITY_LKW,
  FlowSystemParametersV10,
  INITIAL_VENT_AHU_NODES,
  INTERNAL_WALL_WIDTH_MM,
  MANIFOLD_CAPACITY_LKW,
  RADIATOR_CAPACITY_LKW,
  SelectedMaterialManufacturer,
  UFH_CAPACITY_LKW,
  UNDERFLOOR_FLOW_SYSTEMS,
  VENTILATION_SYSTEM,
  initialAustralianDrawing,
  initialDrainageProperties,
  initialDrawing,
  initialHeatingProperties,
  initialStormwaterPropertiesV10,
} from "./document/drawing";
import { DrawableEntityConcreteV1 } from "./document/entities/concrete-entity";
import ConduitEntity, {
  PipeConduitEntity,
} from "./document/entities/conduit-entity";
import { ValveType } from "./document/entities/directed-valves/valve-types";
import { DoorType, FenType } from "./document/entities/fenestration-entity";
import { FittingEntity } from "./document/entities/fitting-entity";
import { FireNode, NodeType } from "./document/entities/load-node-entity";
import { DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING } from "./document/entities/plants/plant-defaults";
import {
  DHWCylinderPlant,
  ExplicitHeatLoadRating,
  HeatLoadRating,
  HeatPumpPlant,
  HotWaterImmersionUse,
  PlantShapes,
  PlantType,
  ReturnSystemType,
} from "./document/entities/plants/plant-types";
import {
  isDHWCylinderPlant,
  isDualSystemNodePlant,
  isPlantMVHR,
} from "./document/entities/plants/utils";
import RiserEntity, { RiserEntityV1 } from "./document/entities/riser-entity";
import { RoofType } from "./document/entities/rooms/roof-type";
import { RoomType } from "./document/entities/rooms/room-entity";
import { INITIAL_INTERNAL_HEATSOURCE } from "./document/entities/rooms/room-type";
import { EntityType, EntityTypeV1 } from "./document/entities/types";
import {
  FlowSystemRole,
  FlowSystemType,
  MechanicalFlowSystem,
  NetworkType,
  VentilationFlowSystem,
} from "./document/flow-systems";
import { OPERATION_NAMES } from "./document/operation-transforms";
import { applyDiffNative } from "./document/state-ot-apply";
import { isUuidLength } from "./document/utils";
import { DEFAULT_LINES } from "./linetypes";
import { SupportedLocales } from "./locale";

export function upgradeDrawing(
  drawing: DrawingState,
  version: number,
  targetVersion: number,
  locale: SupportedLocales,
) {
  // CAUTION: follow the pattern when adding new cases bellow
  // case N:
  //     drawing_upgradedNtoN+1(drawing);
  //     if (targetVersion === N+1) { break; }
  switch (version) {
    case 22:
      drawing_upgraded22to23(drawing);
      if (targetVersion === 23) {
        break;
      }
    case 23:
      drawing_upgraded23to24(drawing);
      if (targetVersion === 24) {
        break;
      }
    case 24:
      drawing_upgraded24to25(drawing);
      if (targetVersion === 25) {
        break;
      }
    case 25:
      drawing_upgraded25to26(drawing);
      if (targetVersion === 26) {
        break;
      }
    case 26:
      drawing_upgraded26to27(drawing);
      if (targetVersion === 27) {
        break;
      }
    case 27:
      drawing_upgraded27to28(drawing);
      if (targetVersion === 28) {
        break;
      }
    case 28:
      drawing_upgraded28to29(drawing);
      if (targetVersion === 29) {
        break;
      }
    case 29:
      drawing_upgraded29to30(drawing);
      if (targetVersion === 30) {
        break;
      }
    case 30:
      drawing_upgraded30to31(drawing);
      if (targetVersion === 31) {
        break;
      }
    case 31:
      drawing_upgraded31to32(drawing);
      if (targetVersion === 32) {
        break;
      }
    case 32:
      drawing_upgraded32to33(drawing);
      if (targetVersion === 33) {
        break;
      }
    case 33:
      drawing_upgraded33to34(drawing);
      if (targetVersion === 34) {
        break;
      }
    case 34:
      drawing_upgraded34to35(drawing);
      if (targetVersion === 35) {
        break;
      }
    case 35:
      drawing_upgrade35to36(drawing);
      if (targetVersion === 36) {
        break;
      }
    case 36:
      drawing_upgrade36to37(drawing);
      if (targetVersion === 37) {
        break;
      }
    case 37:
      drawing_upgrade37to38(drawing);
      if (targetVersion === 38) {
        break;
      }
    case 38:
      drawing_upgrade38to39(drawing);
      if (targetVersion === 39) {
        break;
      }
    case 39:
      drawing_upgrade39to40(drawing);
      if (targetVersion === 40) {
        break;
      }
    case 40:
      drawing_upgrade40to41(drawing, locale);
      if (targetVersion === 41) {
        break;
      }
    case 41:
      drawing_upgrade41to42(drawing);
      if (targetVersion === 42) {
        break;
      }
    case 42:
      drawing_upgrade42to43(drawing);
      if (targetVersion === 43) {
        break;
      }
    case 43:
      drawing_upgrade43to44(drawing);
      if (targetVersion === 44) {
        break;
      }
    case 44:
      drawing_upgrade44to45(drawing);
      if (targetVersion === 45) {
        break;
      }
    case 45:
      drawing_upgrade45to46(drawing, locale);
      if (targetVersion === 46) {
        break;
      }
    case 46:
      drawing_upgrade46to47(drawing, locale);
      if (targetVersion === 47) {
        break;
      }
    case 47:
      drawing_upgrade47to48(drawing);
      if (targetVersion === 48) {
        break;
      }
    case 48:
      drawing_upgrade48to49(drawing);
      if (targetVersion === 49) {
        break;
      }
    case 49:
      drawing_upgrade49to50(drawing);
      if (targetVersion === 50) {
        break;
      }
    case 50:
      drawing_upgrade50to51(drawing, locale);
      if (targetVersion === 51) {
        break;
      }
    case 51:
      drawing_upgrade51to52(drawing);
      if (targetVersion === 52) {
        break;
      }
    case 52:
      drawing_upgrade52to53(drawing);
      if (targetVersion === 53) {
        break;
      }
    case 53:
      drawing_upgrade53to54(drawing);
      if (targetVersion === 54) {
        break;
      }
    case 54:
      drawing_upgrade54to55(drawing);
      if (targetVersion === 55) {
        break;
      }
    case 55:
      // drawing_upgrade55to56(drawing);
      // In some deployments, 56 was released without being called.
      if (targetVersion === 56) {
        break;
      }
    case 56:
      drawing_upgrade56to57(drawing);
      if (targetVersion === 57) {
        break;
      }
    case 57:
      drawing_upgrade57to58(drawing);
      if (targetVersion === 58) {
        break;
      }
    case 58:
      drawing_upgrade58to59(drawing);
      if (targetVersion === 59) {
        break;
      }
    case 59:
      drawing_upgrade59to60(drawing, locale);
      if (targetVersion === 60) {
        break;
      }
    case 60:
      drawing_upgrade60to61(drawing);
      if (targetVersion === 61) {
        break;
      }
    case 61:
      drawing_upgrade61to62(drawing, locale);
      if (targetVersion === 62) {
        break;
      }
    case 62:
      drawing_upgrade62to63(drawing);
      if (targetVersion === 63) {
        break;
      }
    case 63:
      drawing_upgrade63to64(drawing, locale);
      if (targetVersion === 64) {
        break;
      }
    case 64:
      drawing_upgrade64to65(drawing, locale);
      if (targetVersion === 65) {
        break;
      }
    case 65:
      drawing_upgrade65to66(drawing, locale);
      if (targetVersion === 66) {
        break;
      }
    case 66:
      drawing_upgrade66to67(drawing, locale);
      if (targetVersion === 67) {
        break;
      }
    case 67:
      drawing_upgrade67to68(drawing);
      if (targetVersion === 68) {
        break;
      }
    case 68:
      drawing_upgrade68to69(drawing);
      if (targetVersion === 69) {
        break;
      }
    case 69:
      drawing_upgrade69to70(drawing, locale);
      if (targetVersion === 70) {
        break;
      }
    case 70:
      drawing_upgrade70to71(drawing, locale);
      if (targetVersion === 71) {
        break;
      }
    case 71:
      drawing_upgrade71to72(drawing);
      if (targetVersion === 72) {
        break;
      }
    case 72:
      drawing_upgrade72to73(drawing, locale);
      if (targetVersion === 73) {
        break;
      }
    case 73:
      drawing_upgrade73to74(drawing);
      if (targetVersion === 74) {
        break;
      }
    case 74:
      drawing_upgrade74to75(drawing);
      if (targetVersion === 75) {
        break;
      }
    case 75:
      drawing_upgrade75to76(drawing);
      if (targetVersion === 76) {
        break;
      }
    case 76:
      drawing_upgrade76to77(drawing, locale);
      if (targetVersion === 77) {
        break;
      }
    case 77:
      drawing_upgrade77to78(drawing);
      if (targetVersion === 78) {
        break;
      }
    case 78:
      drawing_upgrade78to79(drawing);
      if (targetVersion === 79) {
        break;
      }
    case 79:
      drawing_upgrade79to80(drawing);
      if (targetVersion === 80) {
        break;
      }
    case 80:
      drawing_upgrade80to81(drawing);
      if (targetVersion === 81) {
        break;
      }
    case 81:
      drawing_upgrade81to82(drawing);
      if (targetVersion === 82) {
        break;
      }
    case 82:
      drawing_upgrade82to83(drawing);
      if (targetVersion === 83) {
        break;
      }
    case 83:
      drawing_upgrade83to84(drawing);
      if (targetVersion === 84) {
        break;
      }
    case 84:
      drawing_upgrade84to85(drawing, locale);
      if (targetVersion === 85) {
        break;
      }
    case 85:
      drawing_upgrade85to86(drawing);
      if (targetVersion === 86) {
        break;
      }
    case 86:
      drawing_upgrade86to87(drawing);
      if (targetVersion === 87) {
        break;
      }
    case 87:
      drawing_upgrade87to88(drawing);
      if (targetVersion === 88) {
        break;
      }
    case 88:
      drawing_upgrade88to89(drawing);
      if (targetVersion === 89) {
        break;
      }
    case 89:
      drawing_upgrade89to90(drawing);
      if (targetVersion === 90) {
        break;
      }
    case 90:
      drawing_upgrade90to91(drawing);
      if (targetVersion === 91) {
        break;
      }
    case 91:
      drawing_upgrade91to92(drawing);
      if (targetVersion === 92) {
        break;
      }
    case 92:
      drawing_upgrade92to93(drawing);
      if (targetVersion === 93) {
        break;
      }
    case 93:
      drawing_upgrade93to94(drawing);
      if (targetVersion === 94) {
        break;
      }
    case 94:
      drawing_upgrade94to95(drawing);
      if (targetVersion === 95) {
        break;
      }
    case 95:
      drawing_upgrade95to96(drawing);
      if (targetVersion === 96) {
        break;
      }
    case 96:
      drawing_upgrade96to97(drawing);
      if (targetVersion === 97) {
        break;
      }
    case 97:
      drawing_upgrade97to98(drawing);
      if (targetVersion === 98) {
        break;
      }
    case 98:
      drawing_upgrade98to99(drawing);
      if (targetVersion === 99) {
        break;
      }
    case 99:
      drawing_upgrade99to100(drawing);
      if (targetVersion === 100) {
        break;
      }
    case 100:
      drawing_upgrade100to101(drawing);
      if (targetVersion === 101) {
        break;
      }
    case 101:
      drawing_upgrade101to102(drawing);
      if (targetVersion === 102) {
        break;
      }
    case 102:
      drawing_upgrade102to103(drawing);
      if (targetVersion === 103) {
        break;
      }
    case 103:
      drawing_upgrade103to104(drawing);
      if (targetVersion === 104) {
        break;
      }
    case 104:
      drawing_upgrade104to105(drawing);
      if (targetVersion === 105) {
        break;
      }
    case 105:
      drawing_upgrade105to106(drawing);
      if (targetVersion === 106) {
        break;
      }
    case 106:
      drawing_upgrade106to107(drawing);
      if (targetVersion === 107) {
        break;
      }
    case 107:
      drawing_upgrade107to108(drawing);
      if (targetVersion === 108) {
        break;
      }
    case 108:
      drawing_upgrade108to109(drawing);
      if (targetVersion === 109) {
        break;
      }
    case 109:
      drawing_upgrade109to110(drawing);
      if (targetVersion === 110) {
        break;
      }
    case 110:
      drawing_upgrade110to111(drawing);
      if (targetVersion === 111) {
        break;
      }
    case 111:
      drawing_upgrade111to112(drawing);
      if (targetVersion === 112) {
        break;
      }
    case 112:
      drawing_upgrade112to113(drawing);
      if (targetVersion === 113) {
        break;
      }
    case 113:
      drawing_upgrade113to114(drawing);
      if (targetVersion === 114) {
        break;
      }
    case 114:
      drawing_upgrade114to115(drawing);
      if (targetVersion === 115) {
        break;
      }
    case 115:
      drawing_upgrade115to116(drawing);
      if (targetVersion === 116) {
        break;
      }
    case 116:
      drawing_upgrade116to117(drawing);
      if (targetVersion === 117) {
        break;
      }
    case 117:
      drawing_upgrade117to118(drawing);
      if (targetVersion === 118) {
        break;
      }
    case targetVersion:
      break;
  }
}

export function reconstructDrawing(
  snapshot: DrawingState | null,
  startVersion: number,
  operations: Operation[],
  locale: SupportedLocales,
  targetVersion: number = CURRENT_VERSION,
) {
  console.log(
    `reconstructDrawing: Reconstructing drawing from version ${startVersion} to ${targetVersion}`,
  );
  // use empty object because upgrade functions are mutating.
  const drawing = cloneSimple(snapshot) || ({} as DrawingState);
  let lastVersion = startVersion;
  for (const operation of operations) {
    switch (operation.operation.type) {
      case OPERATION_NAMES.DIFF_OPERATION: {
        if (operation.version > lastVersion && lastVersion > 0) {
          console.log(
            `reconstructDrawing: Upgrading drawing from ${lastVersion} to ${operation.version}`,
          );
          upgradeDrawing(drawing, lastVersion, operation.version, locale);
        }
        lastVersion = operation.version;
        applyDiffNative(drawing, operation.operation.diff);
      }
    }
  }

  if (lastVersion < targetVersion) {
    console.log(
      "reconstructDrawing: Finally upgrading drawing from version " +
        lastVersion +
        " to " +
        targetVersion,
    );
    upgradeDrawing(drawing, lastVersion, targetVersion, locale);
  } else {
    console.log("reconstructDrawing: Drawing is already up to date");
  }
  return drawing;
}

export function drawing_upgraded22to23(_original: DrawingState) {
  // do nothing with this migration
}

export function drawing_upgraded23to24(original: any) {
  for (const level of Object.values(original.levels) as any) {
    const entities = level.entities;
    for (const entity of Object.values(entities) as any) {
      if (
        entity.type === EntityType.DIRECTED_VALVE &&
        entity.valve.type === ValveType.GAS_REGULATOR
      ) {
        if (entity.valve.downStreamPressureKPA === undefined) {
          entity.valve.downStreamPressureKPA = null;
        }
      }

      if (entity.type === EntityType.GAS_APPLIANCE) {
        if (entity.diversity === undefined) {
          entity.diversity = null;
        }
      }

      if (
        entity.type === EntityType.LOAD_NODE &&
        entity.node.type === NodeType.LOAD_NODE
      ) {
        if (entity.node.diversity === undefined) {
          entity.node.diversity = null;
        }
      }

      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          if (entity.plant.diversity === undefined) {
            entity.plant.diversity = null;
          }

          if (entity.plant.gasConsumptionMJH === undefined) {
            entity.plant.gasConsumptionMJH = null;
          }

          if (entity.plant.gasPressureKPA === undefined) {
            entity.plant.gasPressureKPA = null;
          }

          if (entity.plant.rheemVariant === undefined) {
            entity.plant.rheemVariant = null;
          }

          if (entity.plant.rheemPeakHourCapacity === undefined) {
            entity.plant.rheemPeakHourCapacity = null;
          }

          if (entity.plant.rheemMinimumInitialDelivery === undefined) {
            entity.plant.rheemMinimumInitialDelivery = null;
          }

          if (entity.plant.rheemkWRating === undefined) {
            entity.plant.rheemkWRating = null;
          }

          if (entity.plant.rheemStorageTankSize === undefined) {
            entity.plant.rheemStorageTankSize = null;
          }
        }

        if (entity.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP) {
          if (entity.plant.lengthMM === undefined) {
            entity.plant.lengthMM = null;
          }
        }

        if (entity.calculation === undefined) {
          entity.calculation = {
            model: null,
            dutyKPA: null,
            widthMM: null,
            depthMM: null,
          } as any;
        }
      }
    }
  }
}

export function drawing_upgraded24to25(original: DrawingState) {
  const target = original.metadata.catalog.hotWaterPlant.findIndex(
    (i) => i.uid === "hotWaterPlant" && i.manufacturer === "grundfos",
  );

  if (target !== -1) {
    original.metadata.catalog.hotWaterPlant[target].uid = "circulatingPumps";
  }
}

export function drawing_upgraded25to26(original: DrawingState) {
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(
      entities,
    ) as DrawableEntityConcreteV1[]) {
      if (
        entity.type === EntityType.FLOW_SOURCE ||
        entity.type === EntityTypeV1.PIPE ||
        entity.type === EntityType.BIG_VALVE ||
        entity.type === EntityType.DIRECTED_VALVE ||
        entity.type === EntityType.FIXTURE
      ) {
        if (entity.entityName === undefined) {
          entity.entityName = null;
        }
      }
    }
  }
  for (const entity of Object.values(original.shared)) {
    if (entity.type === EntityType.RISER) {
      if (entity.entityName === undefined) {
        entity.entityName = null;
      }
    }
  }
}

export function drawing_upgraded26to27(original: DrawingState) {
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.FITTING) {
        if (entity.entityName === undefined) {
          entity.entityName = null;
        }
      }
    }
  }
}

export function drawing_upgraded27to28(original: DrawingState) {
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (
        entity.type === EntityType.DIRECTED_VALVE &&
        entity.valve.type === ValveType.FLOOR_WASTE
      ) {
        if (entity.valve.variant === undefined) {
          entity.valve.variant = null;
        }

        if (entity.valve.bucketTrapSize === undefined) {
          entity.valve.bucketTrapSize = null;
        }
      }
    }
  }
}

export function drawing_upgraded28to29(original: DrawingState) {
  for (const riser of Object.values(original.shared)) {
    // @ts-ignore
    if (riser.isVent && riser.bottomHeightM === null) {
      // ground to roof
      const sortedLevels = Object.values(original.levels)
        .slice()
        .sort((a, b) => -(a.floorHeightM - b.floorHeightM))
        .reverse();

      const lowestConnectedLevel = sortedLevels.find(
        (l) =>
          !!Object.values(l.entities).find(
            (e: DrawableEntityConcreteV1) =>
              e.type === EntityTypeV1.PIPE && e.endpointUid.includes(riser.uid),
          ),
      );

      if (!!lowestConnectedLevel) {
        // @ts-ignore
        riser.bottomHeightM = lowestConnectedLevel.floorHeightM;
      }
    }
  }
}

export function drawing_upgraded29to30(original: DrawingState) {
  console.log("upgrading for heating");
  if (!original.metadata.nodes) {
    original.metadata.nodes = cloneSimple(
      initialAustralianDrawing().metadata.nodes,
    );
  }
  for (const flowSystem of original.metadata
    .flowSystems as any as FlowSystemParametersV10[]) {
    if (flowSystem.returnColor === undefined) {
      flowSystem.returnColor = { hex: DEFAULT_RETURN_COLOR_HEX };
    }
    if ((flowSystem as any).isHeating === undefined) {
      (flowSystem as any).isHeating =
        flowSystem.uid === StandardFlowSystemUids.Heating;
    }
    if (flowSystem.heatingProps === undefined) {
      flowSystem.heatingProps = initialHeatingProperties();
    }
  }

  // Add the returnType value of each plant.
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(
      level.entities,
    ) as DrawableEntityConcreteV1[]) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          if (entity.plant.returnType === undefined) {
            entity.plant.returnType = "pressure";
          }
        }
        if (entity.inletHeightAboveFloorM === undefined) {
          entity.inletHeightAboveFloorM =
            (entity as any).heightAboveFloorM || null;
        }
        // @ts-ignore
        if (entity.outletHeightAboveFloorM === undefined) {
          // @ts-ignore
          entity.outletHeightAboveFloorM =
            (entity as any).heightAboveFloorM || null;
        }
      } else if (entity.type === EntityTypeV1.PIPE) {
        if (entity.configurationCosmetic === undefined) {
          entity.configurationCosmetic = null;
        }
      }
    }
  }
}

export function drawing_upgraded30to31(original: DrawingState) {
  if (
    !(original.metadata.flowSystems as any as FlowSystemParametersV10[]).find(
      (s) => s.uid === StandardFlowSystemUids.Heating,
    )
  ) {
    (original.metadata.flowSystems as any as FlowSystemParametersV10[]).splice(
      0,
      0,
      {
        name: "Heating",
        temperature: 76,
        color: { hex: "#F44E3B" },
        uid: StandardFlowSystemUids.Heating,
        fluid: "water",
        defaultPipeHeightM: 3,

        hasReturnSystem: true,

        returnRole: "heating",
        returnColor: { hex: DEFAULT_RETURN_COLOR_HEX },
        heatingProps: initialHeatingProperties(),

        returnIsInsulated: true,
        returnMaxVelocityMS: 1e10,
        insulationMaterial: InsulationMaterials.mineralWool,
        insulationJacket: InsulationJackets.allServiceJacket,
        insulationThicknessMM: 25,

        networks: {
          RISERS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 1,
            pressureDropKPAM: 0.35,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          RETICULATIONS: {
            spareCapacityPCT: 0,
            velocityMS: 1,
            pressureDropKPAM: 0.35,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          CONNECTIONS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 3,
            pressureDropKPAM: 0.35,
            material: "pexSdr74",
            minimumPipeSize: 16,
          },
        },

        drainageProperties: cloneSimple(initialDrainageProperties),
      },
    );
  }
}

export function drawing_upgraded31to32(original: any) {
  for (const level of Object.values(original.levels) as any) {
    for (const entity of Object.values(level.entities) as any) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.PUMP ||
          entity.plant.type === PlantType.PUMP_TANK
        ) {
          if (entity.plant.manufacturer === undefined) {
            entity.plant.manufacturer = null;
          }
          if (entity.plant.configuration === undefined) {
            entity.plant.configuration = null;
          }
          if (entity.plant.model === undefined) {
            entity.plant.model = null;
          }
          if (entity.plant.targetPressureKPA === undefined) {
            entity.plant.targetPressureKPA = 0; // do not cause existing drawings to change their results.
          }
          if (entity.plant.inletMinimumPressureKPA === undefined) {
            entity.plant.inletMinimumPressureKPA = null;
          }
        }

        if (
          entity.plant.type === PlantType.TANK ||
          entity.plant.type === PlantType.PUMP_TANK
        ) {
          if (entity.plant.peakFlowRateStorageMinutes === undefined) {
            entity.plant.peakFlowRateStorageMinutes = null;
          }
          if (entity.plant.capacityL === undefined) {
            entity.plant.capacityL = null;
          }
          if (entity.plant.depthMM === undefined) {
            entity.plant.depthMM = null;
          }
        }

        if (!entity.calculation) {
          entity.calculation = {
            model: null,
            dutyKPA: null,
            widthMM: null,
            depthMM: null,
            capacityL: null,
            pressureSettingKPA: null,
          };
        }

        if (entity.calculation.model === undefined) {
          entity.calculation.model = null;
        }
        if (entity.calculation.capacityL === undefined) {
          entity.calculation.capacityL = null;
        }
        if (entity.calculation.dutyKPA === undefined) {
          entity.calculation.dutyKPA = null;
        }
        if (entity.calculation.pressureSettingKPA === undefined) {
          entity.calculation.pressureSettingKPA = null;
        }
      }
    }
  }
  if (original.metadata.catalog.pump === undefined) {
    original.metadata.catalog.pump = [
      {
        uid: "pump",
        manufacturer: "generic",
        selected: null,
      },
    ];
  }
  if (original.metadata.catalog.pumpTank === undefined) {
    original.metadata.catalog.pumpTank = [
      {
        uid: "tank",
        manufacturer: "generic",
        selected: null,
      },
    ];
  }
}

export function drawing_upgraded32to33(original: DrawingState) {
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.PUMP ||
          entity.plant.type === PlantType.PUMP_TANK
        ) {
          if (entity.plant.manufacturer === undefined) {
            // @ts-ignore
            entity.plant.manufacturer = "generic";
          }
          if (entity.plant.configuration === undefined) {
            // @ts-ignore
            entity.plant.configuration = "duty";
          }
        }
      }
    }
  }
}

// FUUUUU all production documents at this date have null value as they just
// got upgraded. reset as a fix. Then, remove this.
export function drawing_upgraded33to34(_original: DrawingState) {
  //     for (const level of Object.values(original.levels)) {
  //         for (const entity of Object.values(level.entities)) {
  //             if (entity.type === EntityType.PLANT) {
  //
  //                 if (entity.plant.type === PlantType.PUMP || entity.plant.type === PlantType.PUMP_TANK) {
  //                     if (entity.plant.manufacturer === null) {
  //                         entity.plant.manufacturer = 'generic';
  //                     }
  //                     if (entity.plant.configuration === null) {
  //                         entity.plant.configuration = 'duty';
  //                     }
  //                 }
  //             }
  //         }
  //     }
}

// FUUUU it has to be done again.
export function drawing_upgraded34to35(original: DrawingState) {
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.PUMP ||
          entity.plant.type === PlantType.PUMP_TANK
        ) {
          if (entity.plant.manufacturer === null) {
            entity.plant.manufacturer = "generic";
          }
          if (entity.plant.configuration === null) {
            entity.plant.configuration = "duty";
          }
        }
      }
    }
  }
}

export function drawing_upgrade35to36(original: DrawingState) {
  // SEED-93 Introducing new variables for the background
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.BACKGROUND_IMAGE) {
        entity.targetPaperSize =
          entity.targetPaperSize ?? cloneSimple(entity.paperSize);
        entity.targetScaleName = entity.targetScaleName ?? entity.scaleName;
      }
    }
  }

  // SEED-50 Renaming alumascCICL to harmerSmlCICL
  for (const pipe of Object.values(original.metadata.catalog.pipes)) {
    if (pipe.manufacturer === "alumascCICL") {
      pipe.manufacturer = "harmerSmlCICL";
    }
  }
}

export function drawing_upgrade36to37(original: DrawingState) {
  // SEED-53 Add line types
  original.metadata.lines = original.metadata.lines ?? DEFAULT_LINES;
}

export function drawing_upgrade37to38(original: DrawingState) {
  // SEED-130 Format Hot Water Plants Props
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          // @ts-ignore this is precisely why we need this document upgrade
          if (entity.plant.returnType === "boiler") {
            entity.plant.returnType = "heatSource";
          }
        }
      }
    }
  }
}

export function drawing_upgrade38to39(original: DrawingState) {
  // SEED-131 Add Shape to Return System plants
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          entity.plant.shape = PlantShapes.RECTANGULAR;
        } else {
          entity.plant.shape = null;
        }
      }
    }
  }
}

export function drawing_upgrade39to40(original: DrawingState) {
  // SEED-128 Update Hot Water Plant Inlets
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          entity.plant.addColdWaterInlet = true;
          entity.plant.addGasInlet = true;
          (entity.plant as any).addPreheatInlet = false;
          (entity.plant as any).preheatInletHeightAboveFloorM = null;
          // @ts-ignore
          entity.plant.ratingKW = null;
          (entity.plant as any).pressureDropKPA = null;
          (entity.plant as any).preHeatNodeOutletUid = null;
          (entity.plant as any).preHeatNodeInletUid = null;
        }
      }
    }
  }

  // SEED-129 Update Hot Water Plant Outlets
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          entity.plant.outlets = [
            {
              // @ts-ignore
              outletUid: entity.outletUid,
              // @ts-ignore
              outletSystemUid: entity.outletSystemUid,
              // @ts-ignore
              outletTemperatureC: entity.outletTemperatureC,
              // @ts-ignore
              heightAboveFloorM: entity.outletHeightAboveFloorM,
              // @ts-ignore
              outletReturnUid: entity.plant.returnUid,
              // @ts-ignore
              returnMinimumTemperatureC: entity.plant.returnMinimumTemperatureC,
              // @ts-ignore
              returnVelocityMS: entity.plant.returnVelocityMS,
              // @ts-ignore
              addReturnToPSDFlowRate: entity.plant.addReturnToPSDFlowRate,
              addRecirculation: true,
            },
          ];
        } else {
          // @ts-ignore
          entity.plant.outletUid = entity.outletUid;
          // @ts-ignore
          entity.plant.outletSystemUid = entity.outletSystemUid;
          // @ts-ignore
          entity.plant.outletTemperatureC = entity.outletTemperatureC;
          // @ts-ignore
          entity.plant.outletHeightAboveFloorM = entity.outletHeightAboveFloorM;
        }

        // @ts-ignore
        delete entity.outletUid;
        // @ts-ignore
        delete entity.outletSystemUid;
        // @ts-ignore
        delete entity.outletTemperatureC;
        // @ts-ignore
        delete entity.outletHeightAboveFloorM;
        // @ts-ignore
        delete entity.plant.returnUid;
        // @ts-ignore
        delete entity.plant.returnMinimumTemperatureC;
        // @ts-ignore
        delete entity.plant.returnVelocityMS;
        // @ts-ignore
        delete entity.plant.addReturnToPSDFlowRate;
      }
    }
  }
}

export function drawing_upgrade40to41(
  original: DrawingState,
  locale: SupportedLocales,
) {
  let model = initialDrawing(locale);
  for (const fs of original.metadata
    .flowSystems as any as FlowSystemParametersV10[]) {
    for (const [nid, network] of Object.entries(fs.networks)) {
      network.pressureDropKPAM =
        network.pressureDropKPAM ??
        ((
          (model.metadata.flowSystems[fs.uid] || model.metadata.flowSystems[0])
            .networks[nid as NetworkType] as any
        ).pressureDropKPAM ||
          0.35);
    }
  }
  original.metadata.units.pressureDropMeasurementSystem =
    original.metadata.units.pressureDropMeasurementSystem ??
    model.metadata.units.pressureDropMeasurementSystem;

  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(
      entities,
    ) as DrawableEntityConcreteV1[]) {
      if (entity.type === EntityTypeV1.PIPE) {
        entity.maximumPressureDropRateKPAM =
          entity.maximumPressureDropRateKPAM ?? null;
      }
    }
  }
  for (const entity of Object.values(original.shared)) {
    if (entity.type === EntityType.RISER) {
      (entity as any).maximumPressureDropRateKPAM =
        (entity as any).maximumPressureDropRateKPAM ?? null;
    }
  }
}

export function drawing_upgrade41to42(original: DrawingState) {
  // SEED-388 Create a way to align h2x and Revit origins, add revitOriginSettings to metadata
  // @ts-ignore old revit settings
  original.metadata.revitOriginSettings = {
    xM: 0,
    yM: 0,
    zM: 0,
    rotationDEG: 0,
    showOrigin: true,
  };
}

export function drawing_upgrade42to43(original: DrawingState) {
  // SEED-409 Introduce the functionality to pick flowsystems for gas and preheat inlets of plants
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          entity.plant.gasInletSystemUid = null;
          entity.plant.preheatSystemUid = null;

          // If the gas node exist we need to set its flow system
          if (entity.plant.gasNodeUid) {
            entity.plant.gasInletSystemUid = StandardFlowSystemUids.Gas;
          }

          // If either of the preheat nodes exist we need to set its flow system
          if (
            (entity.plant as any).preHeatNodeInletUid ||
            (entity.plant as any).preHeatNodeOutletUid
          ) {
            entity.plant.preheatSystemUid = StandardFlowSystemUids.Heating;
          }
        }
      }
    }
  }
}

export function drawing_upgrade43to44(original: DrawingState) {
  // SEED-51 Introduce capacityRate into radiator required to calculate total volume of return systems
  for (const level of Object.values(original.levels)) {
    const entities = level.entities;
    for (const entity of Object.values(entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RADIATOR) {
          if (entity.name?.match(/Radiator/i)) {
            entity.plant.capacityRateLKW = RADIATOR_CAPACITY_LKW;
          } else if (entity.name?.match(/Underfloor Heating/i)) {
            entity.plant.capacityRateLKW = UFH_CAPACITY_LKW;
          } else if (entity.name?.match(/Manifold/i)) {
            entity.plant.capacityRateLKW = MANIFOLD_CAPACITY_LKW;
          }
        } else if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          // @ts-ignore
          entity.plant.capacityRateLKW = null;
        }
      }
    }
  }
  // SEED-51 Update the Mechanical Nodes to have a default capacityRateLKW value
  for (const node of original.metadata.nodes.mechanical) {
    if (node.name?.match(/Radiator/i)) {
      // @ts-ignore this is refactored later
      node.capacityRateLKW = RADIATOR_CAPACITY_LKW;
    } else if (node.name?.match(/Underfloor Heating/i)) {
      // @ts-ignore this is refactored later
      node.capacityRateLKW = UFH_CAPACITY_LKW;
    } else if (node.name?.match(/Manifold/i)) {
      // @ts-ignore this is refactored later
      node.capacityRateLKW = MANIFOLD_CAPACITY_LKW;
    }
  }

  // SEED-409 Introduce the functionality to pick flowsystems for gas and preheat inlets of plants
  for (var systemTypes of original.metadata
    .flowSystems as any as FlowSystemParametersV10[]) {
    if (
      systemTypes.uid === StandardFlowSystemUids.Heating ||
      (systemTypes.hasReturnSystem && systemTypes.returnRole === "heating") ||
      systemTypes.fluid === "sewage"
    ) {
      systemTypes.defaultPipeHeightM = -0.3;
    } else {
      systemTypes.defaultPipeHeightM = 3;
    }
  }
}

export function drawing_upgrade44to45(original: DrawingState) {
  // SEED-62-improve-risers Change riser's field from heightM to floorRef
  for (const entity of Object.values(original.shared)) {
    if (entity.type === EntityType.RISER) {
      let levelSorted = Object.values(original.levels).sort((a, b) => {
        return a.floorHeightM - b.floorHeightM;
      });
      let groundIdx = levelSorted.findIndex((level) => {
        return level.uid === "ground";
      });

      let findCloestFloor = (height: number | null) => {
        if (height === null) return null;
        for (let i = 0; i < levelSorted.length; i++) {
          if (height < levelSorted[i].floorHeightM) {
            return i > 1 ? i - 1 : null;
          }
        }
        return null;
      };

      // @ts-ignore
      entity.bottomFloorRef =
        // @ts-ignore
        findCloestFloor(entity.bottomHeightM) !== null
          ? // @ts-ignore
            findCloestFloor(entity.bottomHeightM) - groundIdx
          : null;

      // @ts-ignore
      entity.topFloorRef =
        // @ts-ignore
        findCloestFloor(entity.topHeightM) !== null
          ? // @ts-ignore
            findCloestFloor(entity.topHeightM) - groundIdx
          : null;
      // Also add vent
      // @ts-ignore
      entity.ventHeightM = null;

      // @ts-ignore
      entity.bottomHeightM = undefined;
      // @ts-ignore
      entity.topHeightM = undefined;
    }
  }
}

export function drawing_upgrade45to46(
  original: DrawingState,
  _locale: SupportedLocales,
) {
  for (const system of Object.values(
    original.metadata.flowSystems as any as FlowSystemParametersV10[],
  )) {
    if ("isHeating" in system && !system.returnRole) {
      system.returnRole = (system as any).isHeating ? "heating" : "water";
      delete (system as any).isHeating;
    }
  }

  if (
    !(original.metadata.flowSystems as any as FlowSystemParametersV10[]).some(
      (s) => s.uid === StandardFlowSystemUids.Chilled,
    )
  ) {
    let idealIndex = (
      original.metadata.flowSystems as any as FlowSystemParametersV10[]
    ).findIndex((s) => s.uid === StandardFlowSystemUids.Heating);
    if (idealIndex === -1) {
      idealIndex =
        (original.metadata.flowSystems as any as FlowSystemParametersV10[])
          .length - 1;
    }
    (original.metadata.flowSystems as any as FlowSystemParametersV10[]).splice(
      idealIndex + 1,
      0,
      {
        name: "Chilled",
        temperature: 5,
        color: { hex: "#009CE0" },
        uid: StandardFlowSystemUids.Chilled,
        fluid: "water",
        defaultPipeHeightM: 3,

        hasReturnSystem: true,

        returnRole: "chilled",
        returnColor: { hex: "#73D8FF" },
        heatingProps: initialHeatingProperties(),

        returnIsInsulated: true,
        returnMaxVelocityMS: 1e10,
        insulationMaterial: InsulationMaterials.mineralWool,
        insulationJacket: InsulationJackets.allServiceJacket,
        insulationThicknessMM: 50,

        networks: {
          RISERS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          RETICULATIONS: {
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          CONNECTIONS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "pexSdr74",
            minimumPipeSize: 16,
          },
        },

        drainageProperties: cloneSimple(initialDrainageProperties),
      },
    );
  }

  if (
    !(original.metadata.flowSystems as any as FlowSystemParametersV10[]).some(
      (s) => s.uid === StandardFlowSystemUids.Condenser,
    )
  ) {
    let idealIndex = (
      original.metadata.flowSystems as any as FlowSystemParametersV10[]
    ).findIndex((s) => s.uid === StandardFlowSystemUids.Chilled);
    if (idealIndex === -1) {
      idealIndex =
        (original.metadata.flowSystems as any as FlowSystemParametersV10[])
          .length - 1;
    }
    (original.metadata.flowSystems as any as FlowSystemParametersV10[]).splice(
      idealIndex + 1,
      0,
      {
        name: "Condenser",
        temperature: 30,
        color: { hex: "#68BC00" },
        uid: StandardFlowSystemUids.Condenser,
        fluid: "water",
        defaultPipeHeightM: 3,

        hasReturnSystem: true,

        returnRole: "condenser",
        returnColor: { hex: "#DBDF00" },
        heatingProps: initialHeatingProperties(),

        returnIsInsulated: true,
        returnMaxVelocityMS: 1e10,
        insulationMaterial: InsulationMaterials.mineralWool,
        insulationJacket: InsulationJackets.allServiceJacket,
        insulationThicknessMM: 50,

        networks: {
          RISERS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          RETICULATIONS: {
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "copperTypeB",
            minimumPipeSize: 15,
          },
          CONNECTIONS: {
            // N/A For heating
            spareCapacityPCT: 0,
            velocityMS: 1.2,
            pressureDropKPAM: 0.4,
            material: "pexSdr74",
            minimumPipeSize: 16,
          },
        },

        drainageProperties: cloneSimple(initialDrainageProperties),
      },
    );
  }

  for (const node of original.metadata.nodes.mechanical) {
    if (!node.type) {
      // @ts-ignore this is refactored later
      if (/.*Radiator/i.test(node.name)) {
        // @ts-ignore this is refactored later
        node.type = "radiator";
        // @ts-ignore this is refactored later
      } else if (/.*Underfloor Heating/i.test(node.name)) {
        // @ts-ignore this is refactored later
        node.type = "underfloor-heating";
        // @ts-ignore this is refactored later
      } else if (/.*Manifold/i.test(node.name)) {
        // @ts-ignore this is refactored later
        node.type = "manifold";
      } else {
        // @ts-ignore this is refactored later
        node.type = "radiator";
      }
    }
  }

  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          for (const o of entity.plant.outlets) {
            if (o.returnLimitTemperatureC === undefined) {
              o.returnLimitTemperatureC =
                (o as any).returnMinimumTemperatureC || null;
            }
          }
        }
      }
    }
  }
}

export function drawing_upgrade46to47(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // the wrong temperature was being used for the chilled flow system in version 46.

  for (const system of Object.values(
    original.metadata.flowSystems as any as FlowSystemParametersV10[],
  )) {
    if (system.uid === StandardFlowSystemUids.Chilled) {
      system.temperature = locale === SupportedLocales.US ? f2C(40) : 5;
    }
  }
}

export function drawing_upgrade47to48(original: DrawingState) {
  // SEED-545 District heating new fields on plants

  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (entity.plant.type === PlantType.RETURN_SYSTEM) {
          if (entity.plant.domesticWaterLoadKW === undefined) {
            entity.plant.domesticWaterLoadKW = null;
          }
          if (entity.plant.isCibseDiversified === undefined) {
            entity.plant.isCibseDiversified = false;
          }
        }
      }
    }
  }
}

export function drawing_upgrade48to49(original: DrawingState) {
  // SEED-545 add District heating on settings
  console.log("48->49");
  if (original.metadata.calculationParams.districtHeating === undefined) {
    original.metadata.calculationParams.districtHeating =
      SupportedDistirctHeating.CIBSECP1Objective3_2;
  }

  // SEED-556 Remove the old revit base point settings on h2x
  // @ts-ignore old unused property on metadata
  delete original.metadata.revitOriginSettings;
}

export function drawing_upgrade49to50(original: DrawingState) {
  // SEED-558 add new fire system
  original.metadata.nodes.fire = [
    {
      name: "Fire Hydrant",
      customEntityId: "Fire Hydrant",
      maxPressureKPA: 1300,
      minPressureKPA: 200,
      continuousFlowOption: [5, 10],
      maxiumumSimutaneousNode: 2,
      nodeGroupHex: "#FF5733",
    },
    {
      name: "Fire Hose Reel",
      customEntityId: "Fire Hose Reel",
      maxPressureKPA: 1000,
      minPressureKPA: 250,
      continuousFlowOption: [0.33],
      maxiumumSimutaneousNode: 2,
      nodeGroupHex: "#DAF7A6",
    },
    {
      name: "Safety Shower",
      customEntityId: "Safety Shower",
      maxPressureKPA: 500,
      minPressureKPA: 200,
      continuousFlowOption: [1.25],
      maxiumumSimutaneousNode: 2,
      nodeGroupHex: "#FFC300",
    },
    {
      name: "Fire Sprinkler - Light Hazard",
      customEntityId: "Fire Sprinkler - light hazard",
      maxPressureKPA: 1200,
      minPressureKPA: 50,
      continuousFlowOption: [0.8],
      maxiumumSimutaneousNode: 6,
      nodeGroupHex: "#C70039",
    },
    {
      name: "Fire Sprinkler - Ordinary Hazard",
      customEntityId: "Fire Sprinkler - Ordinary hazard",
      maxPressureKPA: 1200,
      minPressureKPA: 50,
      continuousFlowOption: [1],
      maxiumumSimutaneousNode: 18,
      nodeGroupHex: "#900C3F",
    },
    {
      name: "Fire Sprinkler - High Hazard",
      customEntityId: "Fire Sprinkler - High hazard",
      maxPressureKPA: 1200,
      minPressureKPA: 50,
      continuousFlowOption: [1],
      maxiumumSimutaneousNode: 30,
      nodeGroupHex: "#581845",
    },
  ] as any;
}

// export function drawing_upgrade51to52(
//   original: DrawingState,
//   locale: SupportedLocales
// ) {
//   // flow system props material ids to drainage
//   function updateSewerMaterial(sewer: string): PipePhysicalMaterial {
//     switch (sewer as string) {
//       case "stainlessSteelSewer":
//         return "stainlessSteelDrainage";
//       case "uPVCSewer":
//         return "uPVCDrainage";
//       case "hdpeSdr11Sewer":
//         return "hdpeSdr11Drainage";
//       case "castIronSewer":
//         return "castIronDrainage";
//       default:
//         return sewer as PipePhysicalMaterial;
//     }
//   }
//
//   for (const level in original.levels) {
//     const levelEntities = original.levels[level].entities;
//     for (const entity in levelEntities) {
//       if (levelEntities[entity].type === EntityType.PIPE) {
//         const pipe = levelEntities[entity] as PipeEntity;
//         pipe.material = pipe.material
//           ? updateSewerMaterial(pipe.material)
//           : null;
//       }
//     }
//   }
//
//   for (const i in original.shared) {
//     const obj = original.shared[i];
//     obj.material = obj.material ? updateSewerMaterial(obj.material) : null;
//   }
//
//   // change the default material in flow system Sewer->Drainage
//   original.metadata.flowSystems.forEach((flow) => {
//     flow.networks.RISERS.material = updateSewerMaterial(
//       flow.networks.RISERS.material
//     );
//     flow.networks.CONNECTIONS.material = updateSewerMaterial(
//       flow.networks.CONNECTIONS.material
//     );
//     flow.networks.RETICULATIONS.material = updateSewerMaterial(
//       flow.networks.RETICULATIONS.material
//     );
//   });
//
//   // add storm water flow system
//   original.metadata.flowSystems.push(...STORMWATER_SYSTEM());
//
//   // add rainIntensityMmPerH
//   original.metadata.calculationParams.rainfallIntensityMmPerH = 300;
//   original.metadata.units.rainfallMeasurementSystem =
//     RainfallMeasurementSystem.METRIC;
//
//   // catalog/pipes/uid
//   original.metadata.catalog.pipes.forEach((pipe) => {
//     pipe.uid = updateSewerMaterial(pipe.uid);
//   });
// }

export function drawing_upgrade50to51(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // flow system props material ids to drainage
  function updateSewerMaterial(sewer: string): PipePhysicalMaterial {
    switch (sewer as string) {
      case "stainlessSteelSewer":
        return "stainlessSteelDrainage";
      case "uPVCSewer":
        return "uPVCDrainage";
      case "hdpeSdr11Sewer":
        return "hdpeSdr11Drainage";
      case "castIronSewer":
        return "castIronDrainage";
      default:
        return sewer as PipePhysicalMaterial;
    }
  }

  for (const level in original.levels) {
    const levelEntities = original.levels[level].entities;
    for (const entity of Object.values(
      levelEntities,
    ) as DrawableEntityConcreteV1[]) {
      if (entity.type === EntityTypeV1.PIPE) {
        entity.material = entity.material
          ? updateSewerMaterial(entity.material)
          : null;
      }
    }
  }

  for (const i in original.shared) {
    const obj = original.shared[i];
    (obj as any).material = (obj as any).material
      ? updateSewerMaterial((obj as any).material)
      : null;
  }

  // change the default material in flow system Sewer->Drainage
  (original.metadata.flowSystems as any as FlowSystemParametersV10[]).forEach(
    (flow) => {
      flow.networks.RISERS.material = updateSewerMaterial(
        flow.networks.RISERS.material,
      );
      flow.networks.CONNECTIONS.material = updateSewerMaterial(
        flow.networks.CONNECTIONS.material,
      );
      flow.networks.RETICULATIONS.material = updateSewerMaterial(
        flow.networks.RETICULATIONS.material,
      );
    },
  );

  // add storm water flow system
  (original.metadata.flowSystems as any as FlowSystemParametersV10[]).push(
    {
      name: "Stormwater",
      temperature: 20,
      color: { hex: "#1d5ca3" },
      uid: StandardFlowSystemUids.StormWater,
      fluid: "stormwater",
      hasReturnSystem: false,
      defaultPipeHeightM: -1,

      returnRole: "water",
      returnColor: { hex: DEFAULT_RETURN_COLOR_HEX },
      heatingProps: initialHeatingProperties(),

      returnIsInsulated: false,
      returnMaxVelocityMS: 1,
      insulationMaterial: InsulationMaterials.calciumSilicate,
      insulationJacket: InsulationJackets.allServiceJacket,
      insulationThicknessMM: 25,

      networks: {
        RISERS: {
          spareCapacityPCT: 0,
          velocityMS: 20,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 15,
        },
        RETICULATIONS: {
          spareCapacityPCT: 0,
          velocityMS: 20,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 15,
        },
        CONNECTIONS: {
          spareCapacityPCT: 0,
          velocityMS: 3,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 16,
        },
      },

      drainageProperties: cloneSimple(initialStormwaterPropertiesV10),
    },
    {
      name: "Backup Stormwater",
      temperature: 20,
      color: { hex: "#6786a8" },
      uid: StandardFlowSystemUids.BackupStormWater,
      fluid: "stormwater",
      hasReturnSystem: false,
      defaultPipeHeightM: -1,

      returnRole: "water",
      returnColor: { hex: DEFAULT_RETURN_COLOR_HEX },
      heatingProps: initialHeatingProperties(),

      returnIsInsulated: false,
      returnMaxVelocityMS: 1,
      insulationMaterial: InsulationMaterials.calciumSilicate,
      insulationJacket: InsulationJackets.allServiceJacket,
      insulationThicknessMM: 25,

      networks: {
        RISERS: {
          spareCapacityPCT: 0,
          velocityMS: 20,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 15,
        },
        RETICULATIONS: {
          spareCapacityPCT: 0,
          velocityMS: 20,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 15,
        },
        CONNECTIONS: {
          spareCapacityPCT: 0,
          velocityMS: 3,
          pressureDropKPAM: 0.35,
          material: "uPVCDrainage",
          minimumPipeSize: 16,
        },
      },

      drainageProperties: cloneSimple(initialStormwaterPropertiesV10),
    },
  );

  // add rainIntensityMmPerH
  original.metadata.calculationParams.rainfallIntensityMm_H = 300;

  switch (locale) {
    case SupportedLocales.AU:
    case SupportedLocales.UK:
      original.metadata.units.rainfallMeasurementSystem =
        RainfallMeasurementSystem.METRIC;
      break;
    case SupportedLocales.US:
      original.metadata.units.rainfallMeasurementSystem =
        RainfallMeasurementSystem.IMPERIAL;
  }

  // catalog/pipes/uid
  original.metadata.catalog.pipes.forEach((pipe) => {
    pipe.uid = updateSewerMaterial(pipe.uid);
  });
}

export function drawing_upgrade51to52(original: DrawingState) {
  // SEED-671 pipes to conduits
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(
      level.entities,
    ) as DrawableEntityConcreteV1[]) {
      if (entity.type === EntityTypeV1.PIPE) {
        const newEntity = entity as any as PipeConduitEntity;

        newEntity.type = EntityType.CONDUIT;
        newEntity.conduitType = "pipe";
        newEntity.conduit = {
          configurationCosmetic: entity.configurationCosmetic,
          diameterMM: entity.diameterMM,
          gradePCT: entity.gradePCT,
          material: entity.material,
          maximumPressureDropRateKPAM: entity.maximumPressureDropRateKPAM,
          maximumVelocityMS: entity.maximumVelocityMS,
          network: entity.network,
        };
        delete (entity as any).configurationCosmetic;
        delete (entity as any).diameterMM;
        delete (entity as any).gradePCT;
        delete (entity as any).material;
        delete (entity as any).maximumPressureDropRateKPAM;
        delete (entity as any).maximumVelocityMS;
        delete (entity as any).network;
      }
    }
  }
}

export function drawing_upgrade52to53(original: DrawingState) {
  // There was a mis-upgrade on 52.
  drawing_upgrade51to52(original);
}

export function drawing_upgrade53to54(original: DrawingState) {
  // Some production projects have pipes in the shared levels. Time to sort them out.
  for (const entity of Object.values(
    original.shared, // this was shared.entities and it caused problems.
  ) as DrawableEntityConcreteV1[]) {
    if (entity.type === EntityTypeV1.PIPE) {
      const newEntity = entity as any as PipeConduitEntity;

      newEntity.type = EntityType.CONDUIT;
      newEntity.conduitType = "pipe";
      newEntity.conduit = {
        configurationCosmetic: entity.configurationCosmetic,
        diameterMM: entity.diameterMM,
        gradePCT: entity.gradePCT,
        material: entity.material,
        maximumPressureDropRateKPAM: entity.maximumPressureDropRateKPAM,
        maximumVelocityMS: entity.maximumVelocityMS,
        network: entity.network,
      };
      delete (entity as any).configurationCosmetic;
      delete (entity as any).diameterMM;
      delete (entity as any).gradePCT;
      delete (entity as any).material;
      delete (entity as any).maximumPressureDropRateKPAM;
      delete (entity as any).maximumVelocityMS;
      delete (entity as any).network;
    }
  }
}

export function drawing_upgrade54to55(original: DrawingState) {
  // Code derp.
  for (const entity of Object.values(
    original.shared,
  ) as DrawableEntityConcreteV1[]) {
    if (entity.type === EntityTypeV1.PIPE) {
      const newEntity = entity as any as PipeConduitEntity;

      newEntity.type = EntityType.CONDUIT;
      newEntity.conduitType = "pipe";
      newEntity.conduit = {
        configurationCosmetic: entity.configurationCosmetic,
        diameterMM: entity.diameterMM,
        gradePCT: entity.gradePCT,
        material: entity.material,
        maximumPressureDropRateKPAM: entity.maximumPressureDropRateKPAM,
        maximumVelocityMS: entity.maximumVelocityMS,
        network: entity.network,
      };
      delete (entity as any).configurationCosmetic;
      delete (entity as any).diameterMM;
      delete (entity as any).gradePCT;
      delete (entity as any).material;
      delete (entity as any).maximumPressureDropRateKPAM;
      delete (entity as any).maximumVelocityMS;
      delete (entity as any).network;
    }
  }
}

export function drawing_upgrade56to57(original: DrawingState) {
  // SEED-558 add new fire system
  const newNodesNeeded = [
    {
      name: "Fire Hydrant",
      customEntityId: "Fire Hydrant",

      subGroups: [
        {
          name: "5 Liters",
          subGroupId: "fire_hydrant_default_5",
          maxPressureKPA: 1300,
          minPressureKPA: 200,
          continuousFlowRateLS: 5,
          nodeGroupHex: "#FF5733",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
        {
          name: "10 Liters",
          subGroupId: "fire_hydrant_default_10",
          maxPressureKPA: 1300,
          minPressureKPA: 200,
          continuousFlowRateLS: 10,
          nodeGroupHex: "#DDF611",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Fire Hose Reel",
      customEntityId: "Fire Hose Reel",
      subGroups: [
        {
          name: "Default",
          subGroupId: "fire_hose_reel_default",
          maxPressureKPA: 1000,
          minPressureKPA: 250,
          continuousFlowRateLS: 0.33,
          nodeGroupHex: "#DAF7A6",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Safety Shower",
      customEntityId: "Safety Shower",
      subGroups: [
        {
          name: "Default",
          subGroupId: "safety_shower_default",
          maxPressureKPA: 500,
          minPressureKPA: 210,
          continuousFlowRateLS: 1.25,
          nodeGroupHex: "#FFC300",
          maxiumumSimutaneousNode: 2,
          kvValue: 0.3,
        },
      ],
    },
    {
      name: "Fire Sprinkler",
      customEntityId: "Fire Sprinkler",
      subGroups: [
        {
          name: "Light Hazard",
          subGroupId: "fire_sprinkler_light_hazard_default",
          maxPressureKPA: 1200,
          minPressureKPA: 70,
          continuousFlowRateLS: 0.8,
          nodeGroupHex: "#C70039",
          maxiumumSimutaneousNode: 6,
          kvValue: 0.3,
        },
        {
          name: "Medium Hazard",
          subGroupId: "fire_sprinkler_medium_hazard_default",
          maxPressureKPA: 1200,
          minPressureKPA: 70,
          continuousFlowRateLS: 1,
          nodeGroupHex: "#900C3F",
          maxiumumSimutaneousNode: 18,
          kvValue: 0.3,
        },
        {
          name: "High Hazard",
          subGroupId: "fire_sprinkler_high_hazard_default",
          maxPressureKPA: 1200,
          minPressureKPA: 70,
          continuousFlowRateLS: 1,
          nodeGroupHex: "#581845",
          maxiumumSimutaneousNode: 30,
          kvValue: 0.3,
        },
      ],
    },
  ];

  const seenNodes: Record<string, boolean> = {};

  for (let i = 0; i < original.metadata.nodes.fire.length; i++) {
    const node = original.metadata.nodes.fire[i] as any as FireNodePropsV1;
    const newNode = newNodesNeeded.find(
      (newNode) => newNode.customEntityId === node.customEntityId,
    );
    if (newNode) {
      original.metadata.nodes.fire[i] = newNode;
      seenNodes[newNode.customEntityId] = true;
    } else {
      // defensively
      if (
        !original.metadata.nodes.fire[i].subGroups &&
        node.continuousFlowOption
      ) {
        if (original.metadata.nodes.fire[i].name.startsWith("Fire Sprinkler")) {
          // The original fire sprinklers are to be replaced by a single node with subgroups.
          // delete i
          original.metadata.nodes.fire.splice(i, 1);
          i--;
          continue;
        }

        original.metadata.nodes.fire[i] = {
          name: node.name,
          customEntityId: node.customEntityId,
          subGroups: node.continuousFlowOption.map((optionLS) => ({
            name: node.name + " " + optionLS + " L/s",
            subGroupId: node.customEntityId + "_" + optionLS,
            maxPressureKPA: node.maxPressureKPA,
            minPressureKPA: node.minPressureKPA,
            continuousFlowRateLS: optionLS,
            nodeGroupHex: node.nodeGroupHex,
            maxiumumSimutaneousNode: node.maxiumumSimutaneousNode,
            kvValue: 0.3,
          })),
        };
      }
    }
  }

  for (const newNode of newNodesNeeded) {
    if (!seenNodes[newNode.customEntityId]) {
      original.metadata.nodes.fire.push(newNode);
    }
  }

  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type !== EntityType.LOAD_NODE) {
        continue;
      }
      if (e.node.type !== NodeType.FIRE) {
        continue;
      }

      if (!e.node.subGroupId) {
        // find node and closest sub group
        const node = original.metadata.nodes.fire.find((node) => {
          assertType<FireNode>(e.node);
          return node.customEntityId === e.node.customEntityId;
        });
        if (!node) {
          console.warn("could not find node", e.node.customEntityId);
          continue;
        }

        const subGroup = node.subGroups.find(
          (subGroup) =>
            subGroup.continuousFlowRateLS === (e.node as any).continuousFlowLS,
        );

        if (subGroup) {
          e.node.subGroupId = subGroup.subGroupId;
        } else {
          e.node.subGroupId = node.subGroups[0]?.subGroupId;
        }
      }
    }
  }
}

export function drawing_upgrade57to58(original: DrawingState) {
  // SEED-734 FireNode Update
  if (
    (original.metadata.flowSystems as any as FlowSystemParametersV10[]).find(
      (fs) => fs.uid === StandardFlowSystemUids.FireSprinkler,
    )
  ) {
    return;
  }

  (original.metadata.flowSystems as any as FlowSystemParametersV10[]).push({
    name: "Fire Sprinkler",
    temperature: 20,
    color: { hex: "#653294" },
    uid: StandardFlowSystemUids.FireSprinkler,
    fluid: "water",
    hasReturnSystem: false,
    defaultPipeHeightM: 3,

    returnRole: "water",
    returnColor: { hex: DEFAULT_RETURN_COLOR_HEX },
    heatingProps: initialHeatingProperties(),

    returnIsInsulated: false,
    returnMaxVelocityMS: 1,
    insulationMaterial: InsulationMaterials.calciumSilicate,
    insulationJacket: InsulationJackets.allServiceJacket,
    insulationThicknessMM: 25,
    networks: {
      RISERS: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      RETICULATIONS: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
      CONNECTIONS: {
        spareCapacityPCT: 0,
        velocityMS: 4,
        pressureDropKPAM: 0.35,
        material: "gmsMedium",
        minimumPipeSize: 15,
      },
    },
    drainageProperties: cloneSimple(initialDrainageProperties),
  });

  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type !== EntityType.LOAD_NODE) {
        continue;
      }
      if (e.node.type !== NodeType.FIRE) {
        continue;
      }

      if (e.node.kvValue === undefined) {
        e.node.kvValue = null;
      }
    }
  }
}

export function drawing_upgrade58to59(original: DrawingState) {
  // SEED-759-flowsysten-order-hotfix
  let idxSprinkler = (
    original.metadata.flowSystems as any as FlowSystemParametersV10[]
  ).findIndex((fs) => fs.uid === StandardFlowSystemUids.FireSprinkler);

  // Find the index of FireHoseReel
  let idxHoseReel = (
    original.metadata.flowSystems as any as FlowSystemParametersV10[]
  ).findIndex((fs) => fs.uid === StandardFlowSystemUids.FireHoseReel);

  // Remove the Sprinkler
  (original.metadata.flowSystems as any as FlowSystemParametersV10[]).splice(
    idxSprinkler,
    1,
  );

  // Insert the newFireSprinklerSystem after FireHoseReel
  (original.metadata.flowSystems as any as FlowSystemParametersV10[]).splice(
    idxHoseReel,
    0,
    {
      name: "Fire Sprinkler",
      temperature: 20,
      color: { hex: "#653294" },
      uid: StandardFlowSystemUids.FireSprinkler,
      fluid: "water",
      hasReturnSystem: false,
      defaultPipeHeightM: 3,

      returnRole: "water",
      returnColor: { hex: DEFAULT_RETURN_COLOR_HEX },
      heatingProps: initialHeatingProperties(),

      returnIsInsulated: false,
      returnMaxVelocityMS: 1,
      insulationMaterial: InsulationMaterials.calciumSilicate,
      insulationJacket: InsulationJackets.allServiceJacket,
      insulationThicknessMM: 25,
      networks: {
        RISERS: {
          spareCapacityPCT: 0,
          velocityMS: 4,
          pressureDropKPAM: 0.35,
          material: "gmsMedium",
          minimumPipeSize: 15,
        },
        RETICULATIONS: {
          spareCapacityPCT: 0,
          velocityMS: 4,
          pressureDropKPAM: 0.35,
          material: "gmsMedium",
          minimumPipeSize: 15,
        },
        CONNECTIONS: {
          spareCapacityPCT: 0,
          velocityMS: 4,
          pressureDropKPAM: 0.35,
          material: "gmsMedium",
          minimumPipeSize: 15,
        },
      },
      drainageProperties: cloneSimple(initialDrainageProperties),
    },
  );
}

export function drawing_upgrade59to60(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-829-flow-rate-unit
  // add flow rate measurement system
  if (original.metadata.units.flowRateMeasurementSystem === undefined) {
    switch (locale) {
      case SupportedLocales.AU:
      case SupportedLocales.UK:
        original.metadata.units.flowRateMeasurementSystem =
          FlowRateMeasurementSystem.METRIC;
        break;
      case SupportedLocales.US:
        original.metadata.units.flowRateMeasurementSystem =
          FlowRateMeasurementSystem.US;
    }
  }
}

export function drawing_upgrade60to61(original: DrawingState) {
  // SEED-819 Create annotation tool and its metadata settings
  original.metadata["annotations"] = {
    fontSize: 150,
  };

  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.VERTEX) {
        e.vertexContext = "room";
        e.shape = "circle";
      }
    }
  }
}

export function drawing_upgrade61to62(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-843 Replacing capacityRateLKW with preHeatVolumeL in return system plants with preheat inlets

  console.log("Upgrading to 62");
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        {
          if (e.plant.type === PlantType.RETURN_SYSTEM) {
            // @ts-ignore these fields were required in the previous version
            if (e.plant.capacityRateLKW && e.plant.ratingKW) {
              (e.plant as any).preHeatVolumeL =
                // @ts-ignore these fields were required in the previous version
                e.plant.capacityRateLKW * e.plant.ratingKW;
            } else if ((e.plant as any).preHeatVolumeL == undefined) {
              (e.plant as any).preHeatVolumeL = null;
            }

            // @ts-ignore
            delete e.plant.capacityRateLKW;
          }
        }
      }
    }
  }

  // SEED-840 Made change to entity so it's bit fucked
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.FENESTRATION) {
        if (e.fenType === FenType.DOOR) {
          if (!e.fenestration.doorType) {
            e.fenestration.doorType = DoorType.SINGLE;
          }
        }
      }
      if (e.type === EntityType.ROOM) {
        if (e.room.roomType === RoomType.ROOF) {
          // @ts-ignore
          if (e.room.selectedRoofType === "flat") {
            e.room.selectedRoofType = RoofType.Flat;
          }
        }
      }
    }
  }

  if (!original.metadata.heatLoss) {
    original.metadata.heatLoss = initialDrawing(locale).metadata.heatLoss;
  } else {
    const { defaultMaterial, ...toKeep } = original.metadata.heatLoss;
    original.metadata.heatLoss = applyDiffNative(
      cloneSimple(initialDrawing(locale).metadata.heatLoss),
      toKeep,
    );
  }
}

export function drawing_upgrade62to63(original: DrawingState) {
  // This fixes incomplete upgrade from 61 and 62
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.VERTEX) {
        if (!(e as any).vertexType) {
          e.vertexType = "fixed";
        }
      }
      if (e.type === EntityType.ROOM) {
        if (e.room.roomType === RoomType.ROOM) {
          if (!e.room.underfloorHeating) {
            e.room.underfloorHeating = {
              entryFenUid: null,
              loops: [],
            } as any;
          }
        }
      }
      // SEED-870 heat loss L/S setting
      if (e.type === EntityType.PLANT) {
        if (
          e.plant.type === PlantType.RETURN_SYSTEM ||
          e.plant.type === PlantType.RADIATOR
        ) {
          if (!("rating" in (e.plant as any))) {
            e.plant.rating = {
              type: "energy",
              KW: (e.plant as any).ratingKW ?? null,
            };
          }
          delete (e.plant as any).ratingKW;
        }
      }
    }
  }
}

export function drawing_upgrade63to64(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-876 remove Grundfos as an option for UK and US project
  if (locale !== SupportedLocales.AU) {
    for (const plant of original.metadata.catalog.hotWaterPlant) {
      if (
        plant.uid === "circulatingPumps" &&
        plant.manufacturer === "grundfos"
      ) {
        plant.manufacturer = "generic";
      }
    }
  }
}

export function drawing_upgrade64to65(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // @ts-ignore
  if (original.metadata.heatLoss.defaultWallHeightM) {
    // @ts-ignore
    original.metadata.heatLoss.wallSpec = {
      // @ts-ignore
      roomHeightM: original.metadata.heatLoss.defaultWallHeightM,
      internalWidthMM: INTERNAL_WALL_WIDTH_MM,
      externalWidthMM: EXTERNAL_WALL_WIDTH_MM,
    };
    // @ts-ignore
    delete original.metadata.heatLoss.defaultWallHeightM;
  }

  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      // SEED-862: Add internal heat source
      if (e.type === EntityType.ROOM) {
        if (e.room.roomType === RoomType.ROOM) {
          if (!e.room.internalHeatSource) {
            e.room.internalHeatSource = {};
          }
          // @ts-ignore
          delete e.occupants;
        }
      }

      if (e.type === EntityType.PLANT) {
        if (e.plant.type === PlantType.RETURN_SYSTEM) {
          for (const outlet of e.plant.outlets) {
            if (outlet.pressureDropKPA == null) {
              outlet.pressureDropKPA = null;
            }
          }
        }
      }
    }
  }

  // Add internal heat source
  if (!original.metadata.heatLoss.internalHeatSource) {
    original.metadata.heatLoss.internalHeatSource = cloneSimple(
      INITIAL_INTERNAL_HEATSOURCE,
    );
  }

  // SEED-862 Introduce energy conversion in mechanic and gas context
  if (
    !original.metadata.units["gasEnergyMeasurementSystem"] &&
    // @ts-ignore
    original.metadata.units["energyMeasurementSystem"]
  ) {
    original.metadata.units["gasEnergyMeasurementSystem"] = original.metadata
      .units[
      // @ts-ignore
      "energyMeasurementSystem"
    ] as GasEnergyMeasurementSystem;
    original.metadata.units["mechanicalEnergyMeasurementSystem"] =
      MechanicalEnergyMeasurementSystem.UNIVERSAL;
    // @ts-ignore
    delete original.metadata.units["energyMeasurementSystem"];
  }

  // Get rid of regex in heat emitter's name
  const kwGrep = /([0-9.e-]*)\s*([[kK][wW])/;
  const btuGrep = /([0-9.e-]+)\s*[Bb][Tt][Uu]\/[hH][rR]?/;
  for (let mechanicalNode of original.metadata.nodes.mechanical) {
    if (mechanicalNode.name.match(kwGrep)) {
      mechanicalNode.name = mechanicalNode.name.replace(kwGrep, "");
      mechanicalNode.name = mechanicalNode.name.trim();
    }
    if (mechanicalNode.name.match(btuGrep)) {
      mechanicalNode.name = mechanicalNode.name.replace(btuGrep, "");
      mechanicalNode.name = mechanicalNode.name.trim();
    }
  }
}

export function drawing_upgrade65to66(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-865 Create specify radiators

  // Upgrade all radiators to the new schema
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        if (e.depthMM === undefined) {
          // @ts-ignore new field
          e.depthMM = e.heightMM;
          if (e.plant.type === PlantType.RADIATOR) {
            e.plant.radiatorType = "fixed";

            // @ts-ignore new field
            e.plant.depthMM = e.heightMM;
            e.plant.heightMM = null;
          }
          // @ts-ignore delete old field
          delete e.heightMM;
        }

        if (e.plant.type === PlantType.RETURN_SYSTEM) {
          for (const outlet of e.plant.outlets) {
            if (outlet.pressureDropKPA === undefined) {
              outlet.pressureDropKPA = 0;
            }
          }
        }
      }
    }
  }

  // Area measurement system missing from upgrades last time
  if (!original.metadata.units.areaMeasurementSystem) {
    switch (locale) {
      case SupportedLocales.AU:
      case SupportedLocales.UK:
        original.metadata.units.areaMeasurementSystem =
          AreaMeasurementSystem.METRIC;
        break;
      case SupportedLocales.US:
        original.metadata.units.areaMeasurementSystem =
          AreaMeasurementSystem.IMPERIAL;
    }
  }

  // ... and gasCalcMethod
  if (!original.metadata.calculationParams.gasCalcMethod) {
    original.metadata.calculationParams.gasCalcMethod =
      SupportedGasCalculationMethods.internationalFuelGasCodeEquation2018;
  }
}

export function drawing_upgrade66to67(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-887 add returnSystemType field to return system plant
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        if (
          e.plant.type === PlantType.RETURN_SYSTEM &&
          e.plant.returnSystemType === undefined
        ) {
          let returnSystemType = ReturnSystemType.CUSTOM;
          // Search for default name of return system plant
          if (e.name?.includes("Heat Source"))
            returnSystemType = ReturnSystemType.HEAT_SOURCE;
          if (e.name?.includes("Heat Pump")) {
            //@ts-ignore due to depreciated type
            returnSystemType = ReturnSystemType.HEAT_PUMP;
            //@ts-ignore
            e.plant.noiseReportSetting = DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING;
          }
          if (e.name?.includes("Gas Boiler"))
            returnSystemType = ReturnSystemType.GAS_BOILER;
          if (e.name?.includes("DHW Cylinder"))
            returnSystemType = ReturnSystemType.DHW_CYLINDER;
          if (e.name?.includes("DHW Cylinder w/ Storage"))
            returnSystemType = ReturnSystemType.DHW_CYLINDER_W_STORAGE;
          if (e.name?.includes("Header"))
            returnSystemType = ReturnSystemType.HEADER;
          if (e.name?.includes("Buffer Tank"))
            returnSystemType = ReturnSystemType.BUFFER_TANK;
          if (e.name?.includes("Chiller"))
            returnSystemType = ReturnSystemType.CHILLER;
          if (e.name?.includes("Cooling Tower"))
            returnSystemType = ReturnSystemType.COOLING_TOWER;
          if (e.name?.includes("Hot Water Plant"))
            returnSystemType = ReturnSystemType.HOT_WATER_PLANT_W_RETURN;
          //@ts-ignore
          e.plant.returnSystemType = returnSystemType;

          //@ts-ignore
          e.plant.SCOP = null;
          //@ts-ignore
          e.plant.dairyDomesticWaterLoadL = null;
          //@ts-ignore
          e.plant.legionellaPurgeTemperatureC = null;
          //@ts-ignore
          e.plant.legionellaPurgeFrequency = "Daily";
          //@ts-ignore
          e.plant.SPF = null;
        }
      }
    }
  }

  if (!original.metadata.heatLoss.heatingDegreeDays) {
    original.metadata.heatLoss.heatingDegreeDays = DEFAULT_HEATING_DEGREE_DAYS;
  }

  if (!original.metadata.heatLoss.SCOP) {
    original.metadata.heatLoss.SCOP = DEFAULT_SCOP;
  }
}

export function drawing_upgrade67to68(original: DrawingState) {
  // SEED-883 split mechanical nodes into separate plant types
  // also introduce dual system plants with AHU and FCU
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        if (e.plant.type === PlantType.RADIATOR) {
          if (
            e.name?.toLowerCase().includes("radiator") ||
            e.plant.radiatorType === "specify"
          ) {
            // nothing it's all good!
          } else if (e.name?.toLowerCase().includes("manifold")) {
            // @ts-ignore new type
            e.plant.type = PlantType.MANIFOLD;
            // @ts-ignore new field
            delete e.plant.radiatorType;
          } else if (e.name?.toLowerCase().includes("underfloor heating")) {
            // @ts-ignore new type
            e.plant.type = PlantType.UFH;
            // @ts-ignore new field
            delete e.plant.radiatorType;
          } else if (
            e.name?.toLowerCase().includes("ahu") ||
            e.name?.toLowerCase().includes("fcu") ||
            (
              original.metadata.flowSystems as any as FlowSystemParametersV10[]
            ).find((fs) => e.inletSystemUid === fs.uid)?.returnRole ===
              "chilled" ||
            (
              original.metadata.flowSystems as any as FlowSystemParametersV10[]
            ).find((fs) => e.inletSystemUid === fs.uid)?.returnRole ===
              "condenser"
          ) {
            // change the type
            if (e.name?.toLowerCase().includes("ahu")) {
              // @ts-ignore new type
              e.plant.type = PlantType.AHU;
            } else {
              // @ts-ignore new type
              e.plant.type = PlantType.FCU;
            }

            // @ts-ignore new field
            e.plant.chilledInletUid = e.inletUid;
            // @ts-ignore new field
            e.plant.chilledOutletUid = e.plant.outletUid;
            // @ts-ignore new field
            e.plant.chilledSystemUid = e.inletSystemUid;
            // @ts-ignore new field
            e.plant.chilledHeightAboveFloorM = e.inletHeightAboveFloorM;
            // @ts-ignore new field
            e.plant.chilledRating = e.plant.rating;
            // @ts-ignore new field
            e.plant.chilledCapacityRateLKW = e.plant.capacityRateLKW;
            // @ts-ignore new field
            e.plant.chilledPressureLoss = e.plant.pressureLoss;

            const inletUid = uuid();
            const outletUid = uuid();
            // @ts-ignore new field
            e.plant.heatingInletUid = inletUid;
            // @ts-ignore new field
            e.plant.heatingOutletUid = outletUid;
            // @ts-ignore new field
            e.plant.heatingSystemUid = StandardFlowSystemUids.Heating;
            // @ts-ignore new field
            e.plant.heatingHeightAboveFloorM = e.inletHeightAboveFloorM;
            // @ts-ignore new field
            e.plant.heatingRating = e.plant.rating;
            // @ts-ignore new field
            e.plant.heatingCapacityRateLKW = e.plant.capacityRateLKW;
            // @ts-ignore new field
            e.plant.heatingPressureLoss = e.plant.pressureLoss;

            // @ts-ignore new field
            delete e.plant.outletUid;
            // @ts-ignore new field
            delete e.plant.outletSystemUid;
            // @ts-ignore new field
            delete e.plant.outletHeightAboveFloorM;
            // @ts-ignore new field
            delete e.plant.outletTemperatureC;
            // @ts-ignore new field
            delete e.plant.capacityRateLKW;
            // @ts-ignore new field
            delete e.plant.pressureLoss;
            // @ts-ignore new field
            delete e.plant.rating;
            // @ts-ignore new field
            delete e.plant.radiatorType;

            // create the missing new system nodes
            const uids = [inletUid, outletUid];
            for (const uid of uids) {
              level.entities[uid] = {
                type: EntityType.SYSTEM_NODE,
                center: { x: 0, y: 0 },
                parentUid: e.uid,
                systemUid: StandardFlowSystemUids.Heating,
                uid,
                allowAllSystems: false,
                configuration: 0,
                calculationHeightM: null,
              };
            }
          } else {
            // keeping fallback as keeping the type as radiator is the safest option
          }
        }
      }
    }
  }

  // SEED-849 Upgrade all roof to include window material
  // For existing roof type, we can just assign name in random order, it only for better display in report
  let idx = 1;
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.ROOM) {
        if (
          e.room.roomType === RoomType.ROOF &&
          // @ts-ignore
          e.room.windowMaterialUid === undefined
        ) {
          // @ts-ignore
          e.room.windowMaterialUid = null;

          if (!e.entityName || /Room\d/.test(e.entityName)) {
            e.entityName = "Roof" + idx;
            idx++;
          }
        }
      }
    }
  }

  if (original.metadata.heatLoss.windowSpec.Velux === undefined) {
    // @ts-ignore
    original.metadata.heatLoss.windowSpec.Window = cloneSimple(
      original.metadata.heatLoss.windowSpec,
    );
    original.metadata.heatLoss.windowSpec.Velux = {
      widthM: 1,
      lengthM: 1,
    };
  }
}

export function drawing_upgrade68to69(original: DrawingState) {
  // SEED-920 Change AS3500 from 2018 to 2021
  if (
    // @ts-ignore changing the old value
    original.metadata.calculationParams.psdMethod === "as35002018LoadingUnits"
  ) {
    original.metadata.calculationParams.psdMethod =
      SupportedPsdStandards.as35002021LoadingUnits;
  }
  if (
    // @ts-ignore changing the old value
    original.metadata.calculationParams.dwellingMethod === "as35002018Dwellings"
  ) {
    original.metadata.calculationParams.dwellingMethod =
      SupportedDwellingStandards.as35002021Dwellings;
  }

  if (!original.metadata.heatLoss.customRoom) {
    original.metadata.heatLoss.customRoom = cloneSimple({});
  }

  // SEED-919 some mechanical nodes slipped through the cracks and are missing fields
  for (const n of original.metadata.nodes.mechanical) {
    if (n.type === "radiator") {
      if (n.capacityRateLKW === undefined) {
        n.capacityRateLKW = RADIATOR_CAPACITY_LKW;
      }
      // @ts-ignore type has changed
    } else if (n.type === "underfloor-heating") {
      // @ts-ignore type has changed
      if (n.capacityRateLKW === undefined) {
        // @ts-ignore type has changed
        n.capacityRateLKW = UFH_CAPACITY_LKW;
      }
      // @ts-ignore type has changed
    } else if (n.type === "air-handling") {
      // @ts-ignore
      if (n.chilledCapacityRateLKW === undefined) {
        // @ts-ignore
        n.chilledCapacityRateLKW = AHU_CAPACITY_LKW;
      }
      // @ts-ignore
      if (n.heatingCapacityRateLKW === undefined) {
        // @ts-ignore
        n.heatingCapacityRateLKW = AHU_CAPACITY_LKW;
      }
      // @ts-ignore type has changed
    } else if (n.type === "fan-coil") {
      // @ts-ignore type has changed
      if (n.chilledCapacityRateLKW === undefined) {
        // @ts-ignore type has changed
        n.chilledCapacityRateLKW = FCU_CAPACITY_LKW;
      }
      // @ts-ignore type has changed
      if (n.heatingCapacityRateLKW === undefined) {
        // @ts-ignore type has changed
        n.heatingCapacityRateLKW = FCU_CAPACITY_LKW;
      }
      // @ts-ignore type has changed
    } else if (n.type === "manifold") {
      // @ts-ignore type has changed
      if (n.capacityRateLKW === undefined) {
        // @ts-ignore type has changed
        n.capacityRateLKW = MANIFOLD_CAPACITY_LKW;
      }
    }
  }
}

function getLegacyFlowSystemType(ofs: FlowSystemParametersV10): FlowSystemType {
  switch (ofs.uid) {
    case StandardFlowSystemUids.ColdWater:
    case StandardFlowSystemUids.HotWater:
    case StandardFlowSystemUids.WarmWater:
      return "pressure";

    case StandardFlowSystemUids.Gas:
      return "gas";

    case StandardFlowSystemUids.FireHydrant:
    case StandardFlowSystemUids.FireHoseReel:
    case StandardFlowSystemUids.FireSprinkler:
      return "fire";

    case StandardFlowSystemUids.StormWater:
    case StandardFlowSystemUids.BackupStormWater:
      return "stormwater";

    case StandardFlowSystemUids.SewerDrainage:
    case StandardFlowSystemUids.SanitaryPlumbing:
    case StandardFlowSystemUids.GreaseWaste:
    case StandardFlowSystemUids.TradeWaste:
    case StandardFlowSystemUids.RisingMain:
      return "sewer";

    case StandardFlowSystemUids.Heating:
    case StandardFlowSystemUids.Chilled:
    case StandardFlowSystemUids.Condenser:
      return "mechanical";
  }

  if (ofs.fluid === "sewer") return "sewer";
  if (ofs.fluid === "stormwater") return "stormwater";
  if (ofs.fluid === "LPG" || ofs.fluid === "naturalGas") return "gas";
  if (
    ofs.hasReturnSystem &&
    (ofs.returnRole === "heating" ||
      ofs.returnRole === "chilled" ||
      ofs.returnRole === "condenser")
  )
    return "mechanical";
  // For fire, the UID checks should be enough.
  return "pressure";
}

function getLegacyFlowSystemRole(ofs: FlowSystemParametersV10): FlowSystemRole {
  switch (ofs.uid) {
    case StandardFlowSystemUids.ColdWater:
      return "coldwater";
    case StandardFlowSystemUids.HotWater:
      return "hotwater";
    case StandardFlowSystemUids.WarmWater:
      return "warmwater";

    case StandardFlowSystemUids.Gas:
      return "gas";

    case StandardFlowSystemUids.FireHydrant:
    case StandardFlowSystemUids.FireHoseReel:
    case StandardFlowSystemUids.FireSprinkler:
      return "fire";

    case StandardFlowSystemUids.StormWater:
    case StandardFlowSystemUids.BackupStormWater:
      return "stormwater";

    case StandardFlowSystemUids.SewerDrainage:
    case StandardFlowSystemUids.SanitaryPlumbing:
    case StandardFlowSystemUids.GreaseWaste:
    case StandardFlowSystemUids.TradeWaste:
    case StandardFlowSystemUids.RisingMain:
      return "sewer";

    case StandardFlowSystemUids.Heating:
      return "heating";
    case StandardFlowSystemUids.Chilled:
      return "chilled";
    case StandardFlowSystemUids.Condenser:
      return "condenser";
  }

  switch (getLegacyFlowSystemType(ofs)) {
    case "pressure":
      if (ofs.temperature < 35) {
        return "coldwater";
      }
      if (ofs.temperature < 52) {
        return "warmwater";
      }
      return "hotwater";
    case "fire":
      return "fire";
    case "gas":
      return "gas";
    case "mechanical":
      if (ofs.returnRole === "chilled") {
        return "chilled";
      }
      if (ofs.returnRole === "condenser") {
        return "condenser";
      }
      return "heating";
    case "sewer":
      return "sewer";
    case "stormwater":
      return "stormwater";
    case "ventilation":
      // N/A in older documetns
      return "vent-exhaust";
  }

  return "coldwater";
}

export function drawing_upgrade69to70(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-901 introduce filter plants
  const filtersManufacturers: SelectedMaterialManufacturer[] = [
    {
      uid: "softener",
      manufacturer: locale === SupportedLocales.AU ? "southland" : "generic",
      selected: null,
    },
  ];

  if (original.metadata.catalog.filters === undefined) {
    original.metadata.catalog.filters = filtersManufacturers;
  }

  // SEED-911 update radiators catalog schema
  const heatEmittersManufacturers: SelectedMaterialManufacturer[] = [
    {
      uid: "radiators",
      manufacturer: "generic",
      selected: null,
    },
  ];
  if (original.metadata.catalog.heatEmitters === undefined) {
    original.metadata.catalog.heatEmitters = heatEmittersManufacturers;
  }
}

export function drawing_upgrade70to71(
  original: DrawingState,
  locale: SupportedLocales,
) {
  const origFlowSystems = original.metadata
    .flowSystems as any as FlowSystemParametersV10[];

  // SEED-921 flow system refactor
  if (!original.metadata.flowSystemUidsInOrder) {
    original.metadata.flowSystemUidsInOrder = origFlowSystems.map(
      (fs) => fs.uid,
    );
    //@ts-ignore
    original.metadata.flowSystems = {};

    for (const ofs of origFlowSystems) {
      // we have to categorize now.
      let type = getLegacyFlowSystemType(ofs);
      switch (type) {
        case "pressure": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "pressure",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            hasReturnSystem: ofs.hasReturnSystem,
            temperatureC: ofs.temperature,

            return: {
              color: ofs.returnColor,
              insulated: ofs.returnIsInsulated,
              maxVelocityMS: ofs.returnMaxVelocityMS,
              insulation: {
                jacket: ofs.insulationJacket,
                material: ofs.insulationMaterial,
                thicknessMM: ofs.insulationThicknessMM,
              },
            },

            networks: {
              risers: ofs.networks.RISERS,
              reticulations: ofs.networks.RETICULATIONS,
              connections: ofs.networks.CONNECTIONS,
            },
          };
          break;
        }
        case "gas": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "gas",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            temperatureC: ofs.temperature,

            networks: {
              risers: {
                velocityMS: ofs.networks.RISERS.velocityMS,
                pressureDropKPAM: ofs.networks.RISERS.pressureDropKPAM,
                material: ofs.networks.RISERS.material,
                minimumPipeSize: ofs.networks.RISERS.minimumPipeSize,
              },
              reticulations: {
                velocityMS: ofs.networks.RETICULATIONS.velocityMS,
                pressureDropKPAM: ofs.networks.RETICULATIONS.pressureDropKPAM,
                material: ofs.networks.RETICULATIONS.material,
                minimumPipeSize: ofs.networks.RETICULATIONS.minimumPipeSize,
              },
              connections: {
                velocityMS: ofs.networks.CONNECTIONS.velocityMS,
                pressureDropKPAM: ofs.networks.CONNECTIONS.pressureDropKPAM,
                material: ofs.networks.CONNECTIONS.material,
                minimumPipeSize: ofs.networks.CONNECTIONS.minimumPipeSize,
              },
            },
          };
          break;
        }
        case "fire": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "fire",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            temperatureC: ofs.temperature,

            networks: {
              risers: ofs.networks.RISERS,
              reticulations: ofs.networks.RETICULATIONS,
              connections: ofs.networks.CONNECTIONS,
            },
          };
          break;
        }
        case "mechanical": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "mechanical",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            hasReturnSystem: true,
            temperatureC: ofs.temperature,
            maxLoopPressureKPA: ofs.heatingProps.maxLoopPressureKPA,
            minLoopPressureKPA: ofs.heatingProps.minLoopPressureKPA,

            return: {
              color: ofs.returnColor,
              insulated: ofs.returnIsInsulated,
              maxVelocityMS: ofs.returnMaxVelocityMS,

              insulation: {
                jacket: ofs.insulationJacket,
                material: ofs.insulationMaterial,
                thicknessMM: ofs.insulationThicknessMM,
              },
            },

            networks: {
              risers: ofs.networks.RISERS,
              reticulations: ofs.networks.RETICULATIONS,
            },
          };
          break;
        }
        case "sewer": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "sewer",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            temperatureC: ofs.temperature,

            ventColor: ofs.drainageProperties.ventColor,
            ventSizing: ofs.drainageProperties.ventSizing,

            availablePipeSizesMM: ofs.drainageProperties.availablePipeSizesMM,
            horizontalPipeSizing: ofs.drainageProperties.horizontalPipeSizing,
            maxUnventedCapacityWCs:
              ofs.drainageProperties.maxUnventedCapacityWCs,
            maxUnventedLengthM: ofs.drainageProperties.maxUnventedLengthM,
            stackDedicatedVent: ofs.drainageProperties.stackDedicatedVent,
            stackPipeSizing: ofs.drainageProperties.stackPipeSizing,
            stackSizeDiminish: ofs.drainageProperties.stackSizeDiminish,
            stackVentPipeSizing: ofs.drainageProperties.stackVentPipeSizing,

            networks: {
              stacks: {
                material: ofs.networks.RISERS.material,
              },
              pipes: {
                material: ofs.networks.RETICULATIONS.material,
              },
              vents: {
                material: ofs.networks.CONNECTIONS.material,
              },
            },
          };
          break;
        }
        case "stormwater": {
          original.metadata.flowSystems[ofs.uid] = {
            uid: ofs.uid,
            type: "stormwater",
            role: getLegacyFlowSystemRole(ofs),
            name: ofs.name,
            color: ofs.color,
            defaultPipeHeightM: ofs.defaultPipeHeightM,
            fluid: ofs.fluid,
            temperatureC: ofs.temperature,

            availablePipeSizesMM: ofs.drainageProperties.availablePipeSizesMM,
            horizontalPipeSizing: ofs.drainageProperties.horizontalPipeSizing,
            stackPipeSizing: ofs.drainageProperties.stackPipeSizing,

            networks: {
              stacks: {
                material: ofs.networks.RISERS.material,
              },
              pipes: {
                material: ofs.networks.RETICULATIONS.material,
              },
            },
          };
          break;
        }
        case "ventilation": {
          // Not applicable to documents older than this.
          //
          break;
        }
      }
    }
  }

  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === "CONDUIT" && entity.conduitType === "pipe") {
        const newFs = original.metadata.flowSystems[entity.systemUid];
        switch (newFs?.type) {
          case "mechanical":
            switch (entity.conduit.network as string) {
              case "CONNECTIONS":
              case "RETICULATIONS":
                entity.conduit.network = "reticulations";
                break;
              case "RISERS":
                entity.conduit.network = "risers";
                break;
            }
            break;
          case "pressure":
          case "gas":
          case "fire":
          case undefined:
            switch (entity.conduit.network as string) {
              case "CONNECTIONS":
                entity.conduit.network = "connections";
                break;
              case "RETICULATIONS":
                entity.conduit.network = "reticulations";
                break;
              case "RISERS":
                entity.conduit.network = "risers";
                break;
            }
            break;
          case "sewer":
          case "stormwater":
            switch (entity.conduit.network as string) {
              case "CONNECTIONS":
                entity.conduit.network = "vents";
                break;
              case "RETICULATIONS":
                entity.conduit.network = "pipes";
                break;
              case "RISERS":
                entity.conduit.network = "stacks";
                break;
            }
            break;
          case "ventilation":
          // No-op - only new stuff past this version are vents.
        }
      }
    }
  }

  // SEED-925 add a new flow system called Rainwater Reuse
  original.metadata.flowSystems[StandardFlowSystemUids.RainwaterReuse] = {
    type: "pressure",
    role: "coldwater",
    name: "Rainwater Reuse",
    temperatureC: 20,
    color: { hex: "#bf00ff" },
    uid: "rainwater-reuse",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.5,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  };

  // SEED-915 add a new flow system called Reverse Osmosis
  original.metadata.flowSystems[StandardFlowSystemUids.ReverseOsmosis] = {
    type: "pressure",
    role: "coldwater",
    name: "Reverse Osmosis",
    temperatureC: 20,
    color: { hex: "#999999" },
    uid: "reverse-osmosis",
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: false,

    return: {
      color: { hex: DEFAULT_RETURN_COLOR_HEX },

      maxVelocityMS: 1,
      insulated: false,

      insulation: {
        material: InsulationMaterials.mmKemblaInsulation,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 15,
      },
      connections: {
        spareCapacityPCT: 0,
        velocityMS: 3,
        pressureDropKPAM: 0.35,
        material: "stainlessSteel",
        minimumPipeSize: 16,
      },
    },
  };

  // SEED-925 and SEED-915 add the new flow systems to the flowSystemUidsInOrder
  original.metadata.flowSystemUidsInOrder.push(
    StandardFlowSystemUids.RainwaterReuse,
    StandardFlowSystemUids.ReverseOsmosis,
  );

  // SEED-915 introduce RO Plants catalog entry
  const roPlantManufacturers: SelectedMaterialManufacturer[] = [
    {
      uid: "roPlant",
      manufacturer: locale === SupportedLocales.AU ? "southland" : "generic",
      selected: null,
    },
  ];

  if (original.metadata.catalog.roPlant === undefined) {
    original.metadata.catalog.roPlant = roPlantManufacturers;
  }
}

export function drawing_upgrade71to72(original: DrawingState) {
  // SEED-931 some mechanical nodes slipped through the cracks and are missing fields
  for (const node of original.metadata.nodes.mechanical) {
    // @ts-ignore type has changed
    if (node.type === "air-handling" || node.type === "fan-coil") {
      assertType<DualSystemMechanicalNodeProps>(node);
      // @ts-ignore type has changed
      if (node.heatingRatingKw === undefined) {
        // @ts-ignore type has changed
        node.heatingRatingKw = (node as any).ratingKw ?? 1;
      }
      // @ts-ignore type has changed
      if (node.chilledRatingKw === undefined) {
        // @ts-ignore type has changed
        node.chilledRatingKw = (node as any).ratingKw ?? 1;
      }
      // @ts-ignore type has changed
      if (node.heatingPressureDropKPA === undefined) {
        // @ts-ignore type has changed
        node.heatingPressureDropKPA = (node as any).pressureDropKPA ?? 10;
      }
      // @ts-ignore type has changed
      if (node.chilledPressureDropKPA === undefined) {
        // @ts-ignore type has changed
        node.chilledPressureDropKPA = (node as any).pressureDropKPA ?? 10;
      }
      // @ts-ignore type has changed
      if (node.heatingCapacityRateLKW === undefined) {
        // @ts-ignore type has changed
        node.heatingCapacityRateLKW = (node as any).capacityRateLKW ?? 1;
      }
      // @ts-ignore type has changed
      if (node.chilledCapacityRateLKW === undefined) {
        // @ts-ignore type has changed
        node.chilledCapacityRateLKW = (node as any).capacityRateLKW ?? 1;
      }
      // @ts-ignore type has changed
      if (node.heatingHeightAboveFloorMM === undefined) {
        // @ts-ignore type has changed
        node.heatingHeightAboveFloorMM =
          (node as any).heightAboveFloorMM ?? 2400;
      }
      // @ts-ignore type has changed
      if (node.chilledHeightAboveFloorMM === undefined) {
        // @ts-ignore type has changed
        node.chilledHeightAboveFloorMM =
          (node as any).heightAboveFloorMM ?? 2400;
      }

      delete (node as any).ratingKw;
      delete (node as any).pressureDropKPA;
      delete (node as any).capacityRateLKW;
      delete (node as any).heightAboveFloorMM;
    }
  }
}

export function drawing_upgrade72to73(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-933 add height field to mechanical nodes
  for (const node of original.metadata.nodes.mechanical) {
    if (node.heightMM === undefined) {
      node.heightMM = 1000;
    }
  }

  // SEED-932 make sure generic manufacturer is selected for GMS pipes in non AU projects
  if (locale !== SupportedLocales.AU) {
    const idx = original.metadata.catalog.pipes.findIndex(
      (p) => p.uid === "gmsMedium" && p.manufacturer !== "generic",
    );

    if (idx !== -1) {
      original.metadata.catalog.pipes[idx].manufacturer = "generic";
    }
  }

  // SEED-934 Change AS3500 from 2018 to 2021 for fixture units
  if (
    // @ts-ignore changing the old value
    original.metadata.calculationParams.drainageMethod === "AS2018FixtureUnits"
  ) {
    original.metadata.calculationParams.drainageMethod =
      SupportedDrainageMethods.AS2021FixtureUnits;
  }

  // SEED-910 Add default value for return system plant
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.ROOM) {
        // Try to remove number at the end the entityName, now room will dynamically assign reference base on room,
        // room's entityName can be duplicate
        if (e.entityName) {
          e.entityName = e.entityName.replace(/\d+$/, "");
        }
      }
    }
  }

  if (original.metadata.heatLoss.solarRadiationWPerMin["top"] === undefined) {
    original.metadata.heatLoss.solarRadiationWPerMin = {
      top: 500,
      bottom: 500,
      left: 500,
      right: 500,
      rightTop: 500,
      rightBottom: 500,
      leftTop: 500,
      leftBottom: 500,
    };
  }
}

export function drawing_upgrade73to74(original: DrawingState) {
  // SEED-897 fittings have discriminators just to be able to merge
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.FITTING) {
        if ((entity as any).fittingType === undefined) {
          entity.fittingType = "pipe";
          entity.fitting = {};
        }
      }
    }
  }
  // Not included: new vent flow systems and settings necessary.
}

export function drawing_upgrade74to75(original: DrawingState) {
  // Fix energy report upgrade that was missing

  // SEED-887 add returnSystemType field to return system plant
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        if (e.plant.type === PlantType.RETURN_SYSTEM) {
          if (!e.plant.SCOP) {
            e.plant.SCOP = null;
          }
          if (!e.plant.dairyDomesticWaterLoadL) {
            e.plant.dairyDomesticWaterLoadL = null;
          }
          if (!e.plant.legionellaPurgeTemperatureC) {
            e.plant.legionellaPurgeTemperatureC = null;
          }
          if (!e.plant.legionellaPurgeFrequency) {
            e.plant.legionellaPurgeFrequency = "Daily";
          }
          if (!e.plant.SPF) {
            e.plant.SPF = null;
          }
        }
      }
    }
  }

  if (!original.metadata.heatLoss.heatingDegreeDays) {
    original.metadata.heatLoss.heatingDegreeDays = DEFAULT_HEATING_DEGREE_DAYS;
  }

  if (!original.metadata.heatLoss.SCOP) {
    original.metadata.heatLoss.SCOP = DEFAULT_SCOP;
  }

  // Fix old projects that have conduits or fittings in shared levels. Simply delete them :grimmace:
  for (const entity of Object.values(original.shared)) {
    const e: RiserEntity | ConduitEntity | FittingEntity = entity as any;
    if (e.type === EntityType.CONDUIT) {
      delete original.shared[e.uid];
    } else if (e.type === EntityType.FITTING) {
      // need to delete with care - delete all conduits connecting to it.
      for (const lvl of Object.values(original.levels)) {
        for (const e2 of Object.values(lvl.entities)) {
          if (e2.type === EntityType.CONDUIT) {
            if (e2.endpointUid.includes(e.uid)) {
              delete lvl.entities[e2.uid];
            }
          }
        }
      }
      delete original.shared[e.uid];
    }
  }

  // Next up - bug. Custom sewer flow systems did not convert to sewer because
  // the fluid was not detected correctly.
  const needToConvertToSewer = new Set<string>();
  for (const fs of Object.values(original.metadata.flowSystems)) {
    if (fs.type === "pressure" && fs.fluid === "sewage") {
      // convert to sewage
      original.metadata.flowSystems[fs.uid] = {
        type: "sewer",
        role: "sewer",
        name: fs.name,
        color: fs.color,
        temperatureC: fs.temperatureC,
        uid: fs.uid,
        fluid: "sewage",
        defaultPipeHeightM: fs.defaultPipeHeightM,

        networks: {
          stacks: {
            material: "uPVCDrainage",
          },
          pipes: {
            material: "uPVCDrainage",
          },
          vents: {
            material: "uPVCDrainage",
          },
        },

        ...cloneSimple(initialDrainageProperties),
      };

      needToConvertToSewer.add(fs.uid);
    }
  }

  // now we need to convert all the conduits to the new network names.
  for (const lvl of Object.values(original.levels)) {
    for (const e of Object.values(lvl.entities)) {
      if (e.type === EntityType.CONDUIT && e.conduitType === "pipe") {
        if (needToConvertToSewer.has(e.systemUid)) {
          switch (e.conduit.network) {
            case "connections":
              e.conduit.network = "vents";
              break;
            case "reticulations":
              e.conduit.network = "pipes";
              break;
            case "risers":
              e.conduit.network = "stacks";
              break;
          }
        }
      }
    }
  }
}

export function drawing_upgrade75to76(original: DrawingState) {
  // @ts-ignore type has changed
  if (!original.metadata.heatLoss.airChangesDevelopmentYear) {
    // @ts-ignore type has changed
    original.metadata.heatLoss.airChangesDevelopmentYear =
      (original.metadata.heatLoss as any).developmentYear || "post2000";
  }

  // @ts-ignore type has changed
  if (!original.metadata.heatLoss.roomTempDevelopmentYear) {
    // @ts-ignore type has changed
    original.metadata.heatLoss.roomTempDevelopmentYear =
      (original.metadata.heatLoss as any).developmentYear || "post2000";
  }

  for (const [roomId, room] of Object.entries(
    original.metadata.heatLoss.customRoom,
  )) {
    if ((room as any as CustomRoomSpecV1).attributes) {
      assertType<CustomRoomSpecV1>(room);
      // @ts-ignore type has changed
      room.defaultTemperatureC = {
        pre2000: room.attributes.pre2000.defaultTemperatureC,
        post2000: room.attributes.post2000.defaultTemperatureC,
        post2006: room.attributes.post2006.defaultTemperatureC,
      };

      room.airChangeRate = {
        // @ts-ignore type has changed
        pre2000: room.attributes.pre2000.airChangeRate,
        post2000: room.attributes.post2000.airChangeRate,
        post2006: room.attributes.post2006.airChangeRate,
        partf2021: room.attributes.post2006.airChangeRate,
      };

      delete (room as any).attributes;
    }
  }

  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.ROOM) {
        if (entity.room.roomType === RoomType.ROOM) {
          // @ts-ignore type has changed
          if (entity.room.ventExtractFlowRateLS === undefined) {
            // @ts-ignore type has changed
            entity.room.ventExtractFlowRateLS = 0;
          }
        }
      }
    }
  }
  // SEED-972 let users turn off IOs on Dual System plants and AHU_VENTs
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (isDualSystemNodePlant(entity.plant)) {
          entity.plant.addHeatingIO = true;
          entity.plant.addChilledIO = true;
        }
        if (entity.plant.type === PlantType.AHU_VENT) {
          entity.plant.addSupplyIO = true;
          entity.plant.addExhaustIO = true;
          entity.plant.addExhaustIO = true;
          entity.plant.addIntakeIO = true;
        }
      }
    }
  }

  for (const riser of Object.values(original.shared)) {
    if ((riser as any).riserType == undefined) {
      assertType<RiserEntityV1>(riser);

      const newRiser = riser as RiserEntity;
      riser.riserType = "pipe";
      riser.riser = {
        diameterMM: riser.diameterMM,
        material: riser.material,
        isVent: riser.isVent,
        maximumPressureDropRateKPAM: riser.maximumPressureDropRateKPAM,
        maximumVelocityMS: riser.maximumVelocityMS,
        ventHeightM: riser.ventHeightM,
      };

      delete (riser as any).diameterMM;
      delete (riser as any).material;
      delete (riser as any).isVent;
      delete (riser as any).maximumPressureDropRateKPAM;
      delete (riser as any).maximumVelocityMS;
      delete (riser as any).ventHeightM;
      delete (riser as any).temperatureC;
    }
  }
}

export function drawing_upgrade76to77(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-1014 dutypoint pump variant
  if (locale !== SupportedLocales.UK) {
    return;
  }
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type == PlantType.PUMP
      ) {
        let entitySetting = entity.plant.manufacturer == "dutypoint";
        let defaultSetting = original.metadata.catalog.pump.some(
          (item) => item.manufacturer == "dutypoint",
        );
        if (entitySetting || defaultSetting) {
          entity.plant.customManufFields = { variant: "standard" };
        }
      }
    }
  }
}

export function drawing_upgrade77to78(original: DrawingState) {
  // SEED-964: Stelrad Radiators
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.RADIATOR &&
          entity.plant.radiatorType === "specify"
        ) {
          entity.plant.manufacturer = "generic";
        }
      }
    }
  }

  // SEED-1034 Add Room Results Options
  // @ts-ignore the type has changed
  original.metadata.roomResultsSettings = {
    // @ts-ignore the type has changed
    airChange: true,
    heatGain: true,
    heatLoss: true,
    roomArea: true,
    roomHeight: true,
    roomName: true,
    roomTemperature: true,
    ventFlowRate: true,
  };

  // SEED-1039 Fix Pre 2000 Error in Custom Rooms
  // The new custom rooms were still being created with CustomRoomSpecV1
  // hence we need to do the upgrade again
  for (const [roomId, room] of Object.entries(
    original.metadata.heatLoss.customRoom,
  )) {
    if ((room as any as CustomRoomSpecV1).attributes) {
      assertType<CustomRoomSpecV1>(room);
      // @ts-ignore type has changed
      room.defaultTemperatureC = {
        pre2000: room.attributes.pre2000.defaultTemperatureC,
        post2000: room.attributes.post2000.defaultTemperatureC,
        post2006: room.attributes.post2006.defaultTemperatureC,
      };

      room.airChangeRate = {
        // @ts-ignore type has changed
        pre2000: room.attributes.pre2000.airChangeRate,
        post2000: room.attributes.post2000.airChangeRate,
        post2006: room.attributes.post2006.airChangeRate,
        partf2021: room.attributes.post2006.airChangeRate,
      };

      delete (room as any).attributes;
    }
  }
}

export function drawing_upgrade78to79(original: DrawingState) {
  // SEED-977 Adding a new field in recirculation pump
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type == PlantType.RETURN_SYSTEM
      ) {
        for (const outlet of Object.values(entity.plant.outlets)) {
          outlet.recircPumpOnReturn = true;
        }
      }
    }
  }
}

export function drawing_upgrade79to80(original: DrawingState) {
  // SEED-1057 Room Air Changes Rate Refactor
  // update custom rooms
  for (const [roomId, room] of Object.entries(
    original.metadata.heatLoss.customRoom,
  )) {
    const roomV2 = cloneSimple(room) as any as CustomRoomSpecV2;

    room.defaultTemperatureC = roomV2.defaultTemperatureC.post2006;
    room.airChangeRate = [
      { unit: ACRUnits.ACH, coeff: roomV2.airChangeRate.post2006 },
    ];
  }

  // update room entity
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        // @ts-ignore type has changed
        entity.room.achACR = entity.room.airChangeRatePerHour ?? null;
        // @ts-ignore type has changed
        entity.room.lpsACR = entity.room.ventExtractFlowRateLS ?? null;
        // @ts-ignore type has changed
        entity.room.lpsm2ACR = null;
        // @ts-ignore type has changed
        entity.room.lpsPersonACR = null;
        // @ts-ignore type has changed
        entity.room.lpsShowerACR = null;
        // @ts-ignore type has changed
        entity.room.lpsWCACR = null;
        // @ts-ignore type has changed
        entity.room.lpsUrinalACR = null;
        // @ts-ignore type has changed
        entity.room.lpsBathACR = null;
        // @ts-ignore type has changed
        entity.room.lpsMachineACR = null;
        // @ts-ignore type has changed
        entity.room.cmsACR = null;
        // @ts-ignore type has changed
        entity.room.cfmft2ACR = null;
        // @ts-ignore type has changed
        entity.room.personCount = null;
        // @ts-ignore type has changed
        entity.room.showerCount = null;
        // @ts-ignore type has changed
        entity.room.bathCount = null;
        // @ts-ignore type has changed
        entity.room.wcCount = null;
        // @ts-ignore type has changed
        entity.room.urinalCount = null;
        // @ts-ignore type has changed
        entity.room.machineCount = null;

        // delete the old fields
        // @ts-ignore type has changed
        delete entity.room.airChangeRatePerHour;
        // @ts-ignore type has changed
        delete entity.room.ventExtractFlowRateLS;
      }
    }
  }

  // update heatloss settings
  // @ts-ignore type has changed
  original.metadata.heatLoss.airChangesRateStandard = "MCSPost2006";
  // @ts-ignore type has changed
  delete original.metadata.heatLoss.airChangesDevelopmentYear;
  // @ts-ignore type has changed
  delete original.metadata.heatLoss.roomTempDevelopmentYear;
}

export function drawing_upgrade80to81(original: DrawingState) {
  // SEED-1090 fix example drawing document that was in a broken
  // shamble after type checking was disabled
  if (typeof original.metadata.heatLoss.solarRadiationWPerMin === "number") {
    original.metadata.heatLoss.solarRadiationWPerMin = {
      top: original.metadata.heatLoss.solarRadiationWPerMin,
      bottom: original.metadata.heatLoss.solarRadiationWPerMin,
      left: original.metadata.heatLoss.solarRadiationWPerMin,
      right: original.metadata.heatLoss.solarRadiationWPerMin,
      rightTop: original.metadata.heatLoss.solarRadiationWPerMin,
      rightBottom: original.metadata.heatLoss.solarRadiationWPerMin,
      leftTop: original.metadata.heatLoss.solarRadiationWPerMin,
      leftBottom: original.metadata.heatLoss.solarRadiationWPerMin,
    };
  }

  for (const riser of Object.values(original.shared)) {
    if ((riser as any).riserType == undefined) {
      assertType<RiserEntityV1>(riser);

      const newRiser = riser as RiserEntity;
      riser.riserType = "pipe";
      riser.riser = {
        diameterMM: riser.diameterMM,
        material: riser.material,
        isVent: riser.isVent,
        maximumPressureDropRateKPAM: riser.maximumPressureDropRateKPAM,
        maximumVelocityMS: riser.maximumVelocityMS,
        ventHeightM: riser.ventHeightM,
      };

      delete (riser as any).diameterMM;
      delete (riser as any).material;
      delete (riser as any).isVent;
      delete (riser as any).maximumPressureDropRateKPAM;
      delete (riser as any).maximumVelocityMS;
      delete (riser as any).ventHeightM;
      delete (riser as any).temperatureC;
    }
  }

  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.RETURN_SYSTEM
      ) {
        entity.plant.noiseReportSetting =
          entity.plant.noiseReportSetting || null;
      }
    }
  }
}

export function drawing_upgrade81to82(original: DrawingState) {
  // SEED-978 HCAA psd method
  if (original.metadata.calculationParams.occupantNum === undefined) {
    original.metadata.calculationParams.occupantNum =
      original.metadata.calculationParams.apartmentNum = 1;
  }

  // SEED-1098 Allow users to emit heat loss/gain from mechanical systems
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type == PlantType.RETURN_SYSTEM
      ) {
        for (const outlet of Object.values(entity.plant.outlets)) {
          outlet.calculatePipeHeatLoad = true;
        }
      }
    }
  }

  // SEED-1083 Enhance Wall Configuration - Internal Thickness Setting and Type Switching
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.WALL) {
        entity.neighboringSpaceTemperatureC = null;
        entity.wallType = null;
      }
    }
  }

  // SEED-1109 fixture node type in HCAA
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.LOAD_NODE &&
        entity.node.type === NodeType.LOAD_NODE
      ) {
        entity.node.hcaaFixtureType = null;
      }
    }
  }
}

export function drawing_upgrade82to83(original: DrawingState) {
  //SEED-979 Intergrate API For Degree Days
  if (!original.metadata.heatLoss.baseTemperatureC) {
    original.metadata.heatLoss.baseTemperatureC = DEFAULT_BASE_TEMPERATURE_C;
  }
  if (!original.metadata.heatLoss.heatingDegreeDays) {
    original.metadata.heatLoss.heatingDegreeDays = DEFAULT_HEATING_DEGREE_DAYS;
  }
  if (!original.metadata.heatLoss.coolingDegreeDays) {
    original.metadata.heatLoss.coolingDegreeDays = DEFAULT_COOLING_DEGREE_DAYS;
  }

  // SEED-1094 Add a field to track marketplace items in the document
  if (!original.metadata.marketplaceItems) {
    original.metadata.marketplaceItems = [];
    // SEED-1125 Radiator catalog refactor
  }

  const shapeToName: Record<string, string> = {
    "22": "K2",
    "21": "P+",
    "33": "K3",
  };
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.RADIATOR &&
          entity.plant.radiatorType === "specify"
        ) {
          if (entity.plant.customManufFields?.range && entity.plant.shape) {
            if (!entity.plant.rangeType) {
              entity.plant.rangeType =
                entity.plant.customManufFields.range +
                " " +
                shapeToName[(entity.plant as any).radiatorShape];
              delete (entity.plant as any).radiatorShape;
              delete (entity.plant as any).customManufFields?.range;
            }
          } else {
            if (!entity.plant.rangeType) {
              entity.plant.rangeType = (entity.plant as any).radiatorShape;
              delete (entity.plant as any).radiatorShape;
            }
          }
        }
      }
    }
  }

  // SEED-1082: Heat Pump System Performance Reports
  if (!original.metadata.heatLoss.energyPerformanceCertificateInformation) {
    original.metadata.heatLoss.energyPerformanceCertificateInformation =
      DEFAULT_EPC_INFORMATION;
  }
  if (!original.metadata.heatLoss.existingHeatingSystem) {
    original.metadata.heatLoss.existingHeatingSystem =
      DEFAULT_EXISTING_HEATING_SYSTEM;
  }

  // split HEAT_PUMP to AIR_SOURCE_HEAT_PUMP and GROUND_SOURCE_HEAT_PUMP
  // existing HEAT_PUMP -> AIR_SOURCE_HEAT_PUMP
  for (const level of Object.values(original.levels)) {
    for (const e of Object.values(level.entities)) {
      if (e.type === EntityType.PLANT) {
        if (e.plant.type === PlantType.RETURN_SYSTEM) {
          // @ts-ignore due to depreciated type
          if (e.plant.returnSystemType === "HEAT_PUMP") {
            const heatPumpPlant = e.plant as HeatPumpPlant;
            heatPumpPlant.returnSystemType =
              ReturnSystemType.AIR_SOURCE_HEAT_PUMP;
            heatPumpPlant.manufacturer = null;
            heatPumpPlant.model = null;
            heatPumpPlant.MCSCertificateNumber = null;
            heatPumpPlant.SCOPRatings = Array.from(
              { length: 65 - 35 + 1 },
              (_, index) => ({
                SCOP: 0,
                temperatureC: 35 + index,
              }),
            );
          }
          if (isDHWCylinderPlant(e.plant)) {
            const dwhCylinderPlant: DHWCylinderPlant = e.plant;
            dwhCylinderPlant.capacityL = null;
            dwhCylinderPlant.hotWaterImmersionUse =
              HotWaterImmersionUse.ONCE_PER_DAY;
          }
        }
      }
    }
  }

  // SEED-1097 add spareHeatGainPrecent and spareHeatLossPrecent
  if (!original.metadata.heatLoss.spareHeatGainPrecent) {
    original.metadata.heatLoss.spareHeatGainPrecent = 0;
  }

  if (!original.metadata.heatLoss.spareHeatLossPrecent) {
    original.metadata.heatLoss.spareHeatLossPrecent = 0;
  }

  // SEED-1126 Cascade preheats
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.RETURN_SYSTEM
      ) {
        if (!entity.plant.preheats) {
          entity.plant.preheats = [];
          if ((entity.plant as any).addPreheatInlet) {
            const rating = (entity.plant as any).rating as HeatLoadRating;
            const ratingDefined =
              rating.type === "energy" ? rating.KW != null : rating.LS != null;
            entity.plant.preheats.push({
              inletUid: (entity.plant as any).preHeatNodeInletUid,
              returnUid: (entity.plant as any).preHeatNodeOutletUid,
              heightAboveFloorM: (entity.plant as any)
                .preheatInletHeightAboveFloorM,

              pressureDropKPA: (entity.plant as any).pressureDropKPA,
              ratingMode: ratingDefined ? "explicit" : "percentage",
              explicitRating: ratingDefined
                ? (rating as ExplicitHeatLoadRating)
                : {
                    type: "energy",
                    KW: 0,
                  },
              volumeL: (entity.plant as any).preHeatVolumeL,
              ratingPCT: null,
            });
          }

          delete (entity.plant as any).addPreheatInlet;
          delete (entity.plant as any).preHeatNodeInletUid;
          delete (entity.plant as any).preHeatNodeOutletUid;
          delete (entity.plant as any).preheatInletHeightAboveFloorM;
          delete (entity.plant as any).pressureDropKPA;
          delete (entity.plant as any).preHeatVolumeL;
        }
      }
    }
  }
}

export function drawing_upgrade83to84(original: DrawingState) {
  // SEED-1149 add volume and kv value field to radiators
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.PLANT) {
        if (
          entity.plant.type === PlantType.RADIATOR ||
          entity.plant.type === PlantType.MANIFOLD ||
          entity.plant.type === PlantType.UFH
        ) {
          if (entity.plant.volumeL === undefined) {
            entity.plant.volumeL = null;
          }
        }
      }
    }
  }

  // SEED-1136: Add degree days cut off temperatures
  if (!original.metadata.heatLoss.winterTempCutoffPctThreshold) {
    original.metadata.heatLoss.winterTempCutoffPctThreshold =
      DEFAULT_WINTER_TEMP_CUTOFF_PCT_THRESHOLD;
  }
  if (!original.metadata.heatLoss.summerTempCutoffPctThreshold) {
    original.metadata.heatLoss.summerTempCutoffPctThreshold =
      DEFAULT_SUMMER_TEMP_CUTOFF_PCT_THRESHOLD;
  }

  // SEED-1144 repair broken internal walls after copying
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.WALL) {
        for (const edgeUid of entity.polygonEdgeUid) {
          if (!lvl.entities[edgeUid]) {
            delete lvl.entities[entity.uid];
            break;
          }
        }
      }
    }
  }
}

export function drawing_upgrade84to85(
  original: DrawingState,
  locale: SupportedLocales,
) {
  // SEED-1091 Heat Pump Design Calculations Report
  if (!original.metadata.heatLoss.electricityCostPerUnit) {
    original.metadata.heatLoss.electricityCostPerUnit = {
      currency:
        locale === SupportedLocales.AU
          ? Currency.AUD
          : locale === SupportedLocales.UK
            ? Currency.GBP
            : Currency.USD,
      value: 0,
    };
  }
}

export function drawing_upgrade85to86(original: DrawingState) {
  // SEED-1137 Ducts Sizing Method
  if (!original.metadata.calculationParams.ductSizingMethod) {
    original.metadata.calculationParams.ductSizingMethod = "velocity";
  }

  // SEED-1183 MVHR Heat Recovery
  // @ts-ignore type has changed
  if (original.metadata.heatLoss.includeAirChangeHeatLoss === undefined) {
    // @ts-ignore type has changed
    original.metadata.heatLoss.includeAirChangeHeatLoss = true;
  }

  // Vent upgrade TODO list:
  /***
   *
   * *** needs to be done before merge (affects existing)
   *
   * 1. Add new vent flow systems to documents
   * 2. Upgrade existing AHUs to AHU_VENTs
   * 3. Upgrade existing AHU mechanical nodes to AHU_VENTs
   * 4. Add ducts[] to document.metadata.catalog
   *
   * No need to upgrade fittings since existing water fittings are unchanged.
   */

  if (
    !original.metadata.flowSystemUidsInOrder.includes(
      StandardFlowSystemUids.VentilationSupply,
    )
  ) {
    const vent_systems = VENTILATION_SYSTEM();
    for (const system of vent_systems) {
      original.metadata.flowSystems[system.uid] = system;
      original.metadata.flowSystemUidsInOrder.push(system.uid);
    }
  }
  if (!original.metadata.catalog.ducts) {
    original.metadata.catalog.ducts = [];
  }

  if (
    !original.metadata.nodes.mechanical.some(
      (n) => n.type === "air-handling-vent",
    )
  ) {
    // add back the default ones.
    original.metadata.nodes.mechanical.push(...INITIAL_VENT_AHU_NODES());
  }

  for (let i = original.metadata.nodes.mechanical.length - 1; i >= 0; i--) {
    const origNode = original.metadata.nodes.mechanical[i];
    // @ts-ignore type has changed
    if (origNode.type === "air-handling") {
      original.metadata.nodes.mechanical.splice(i, 1);
    }
  }

  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type !== EntityType.PLANT) continue;
      if (entity.plant.type !== PlantType.AHU) continue;

      // oh boy lol
      lvl.entities[entity.uid] = {
        ...entity,
        // @ts-ignore type has changed
        plant: {
          type: PlantType.AHU_VENT,

          heightMM: entity.plant.heightMM,
          widthMM: entity.plant.widthMM,
          depthMM: entity.plant.depthMM,

          addHeatingIO: entity.plant.addHeatingIO,
          heatingInletUid: entity.plant.heatingInletUid,
          heatingOutletUid: entity.plant.heatingOutletUid,
          heatingSystemUid: entity.plant.heatingSystemUid,
          heatingHeightAboveFloorM: entity.plant.heatingHeightAboveFloorM,
          heatingRating: entity.plant.heatingRating,
          heatingCapacityRateLKW: entity.plant.heatingCapacityRateLKW,
          heatingPressureLoss: entity.plant.heatingPressureLoss,

          addChilledIO: entity.plant.addChilledIO,
          chilledInletUid: entity.plant.chilledInletUid,
          chilledOutletUid: entity.plant.chilledOutletUid,
          chilledSystemUid: entity.plant.chilledSystemUid,
          chilledHeightAboveFloorM: entity.plant.chilledHeightAboveFloorM,
          chilledRating: entity.plant.chilledRating,
          chilledCapacityRateLKW: entity.plant.chilledCapacityRateLKW,
          chilledPressureLoss: entity.plant.chilledPressureLoss,

          supplyPressureDropPA: AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
          extractPressureDropPA: AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,

          supplyHeightAboveFloorM: 1,
          extractHeightAboveFloorM: 1,
          intakeHeightAboveFloorM: 1,
          exhaustHeightAboveFloorM: 1,

          addSupplyIO: false,
          addExtractIO: false,
          addIntakeIO: false,
          addExhaustIO: false,

          supplyUid: null,
          extractUid: null,
          intakeUid: null,
          exhaustUid: null,

          supplySystemUid: StandardFlowSystemUids.VentilationSupply,
          extractSystemUid: StandardFlowSystemUids.VentilationExtract,
          intakeSystemUid: StandardFlowSystemUids.VentilationIntake,
          exhaustSystemUid: StandardFlowSystemUids.VentilationExhaust,

          shape: PlantShapes.RECTANGULAR,
          diameterMM: null,

          heatRecoveryEfficiencyPct: null,
        },
      };
    }
  }
}

export function drawing_upgrade86to87(original: DrawingState) {
  // Upgrade the ventilation flow system again to the new format
  for (const fs of Object.values(original.metadata.flowSystems)) {
    if (fs.type === "ventilation" && !isUuidLength(fs.uid)) {
      delete original.metadata.flowSystems[fs.uid];
      original.metadata.flowSystemUidsInOrder =
        original.metadata.flowSystemUidsInOrder.filter((uid) => uid !== fs.uid);
    }
  }

  const vent_systems = VENTILATION_SYSTEM();
  for (const system of vent_systems) {
    original.metadata.flowSystems[system.uid] = system;
    original.metadata.flowSystemUidsInOrder.push(system.uid);
  }
}

export function drawing_upgrade87to88(original: DrawingState) {
  original.metadata.heatLoss.defaultColor["Roof"] = {
    hex: "#FFFFFF",
  };

  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOF
      ) {
        entity.color = null;
      }
    }
  }

  let manifoldNum = 0;
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.manifoldUid === undefined) {
          entity.room.underfloorHeating.manifoldUid = null;
        }
      }

      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.MANIFOLD
      ) {
        if (entity.plant.color === undefined) {
          entity.plant.color = goldenRatioColor(manifoldNum++);
        }
      }
    }
  }

  if (original.metadata.roomResultsSettings.underfloorHeating === undefined) {
    original.metadata.roomResultsSettings.underfloorHeating = true;
  }

  if (original.metadata.roomResultsSettings.heatLoadPerArea === undefined) {
    original.metadata.roomResultsSettings.heatLoadPerArea = true;
  }
}

export function drawing_upgrade88to89(original: DrawingState) {
  if (!original.metadata.units.ventilationPressureMeasurementSystem) {
    original.metadata.units.ventilationPressureMeasurementSystem =
      original.metadata.units.pressureMeasurementSystem;
    if (
      original.metadata.units.pressureMeasurementSystem ===
      PressureMeasurementSystem.METRIC
    ) {
      original.metadata.units.ventilationPressureMeasurementSystem =
        PressureMeasurementSystem.METRIC_SMALL;
    }
  }
  if (!original.metadata.units.ventilationFlowRateMeasurementSystem) {
    original.metadata.units.ventilationFlowRateMeasurementSystem =
      original.metadata.units.flowRateMeasurementSystem;
    if (
      original.metadata.units.ventilationFlowRateMeasurementSystem ===
      FlowRateMeasurementSystem.US
    ) {
      original.metadata.units.ventilationFlowRateMeasurementSystem =
        FlowRateMeasurementSystem.IMPERIAL_CFM;
    }
  }

  if (original.metadata.roomResultsSettings.roomVolume === undefined) {
    original.metadata.roomResultsSettings.roomVolume = true;
  }
}

export function drawing_upgrade89to90(original: DrawingState) {
  // SEED-1281 Add a min pressure drop field to balancing dampers
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.DAMPER) {
        if (entity.minPressureDropKPA === undefined) {
          entity.minPressureDropKPA = null;
        }
      }
    }
  }
}

export function drawing_upgrade90to91(original: DrawingState) {
  // SEED-1315 Refactoring Room Edges -> Genreic Edges as part of unheated room task
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      // @ts-ignore type has changed
      if (entity.type === "ROOM_EDGE") {
        // @ts-ignore type has changed
        entity.type = EntityType.EDGE;
        // @ts-ignore type has changed
        entity.edgeContext = "room";
        // @ts-ignore type has changed
        delete entity.wallId;
        // @ts-ignore type has changed
        delete entity.color;
        // @ts-ignore type has changed
        delete entity.thicknessMM;
        // @ts-ignore type has changed
        delete entity.lengthM;
        // @ts-ignore type has changed
        delete entity.uValueW_M2K;
      }
    }
  }

  if (original.metadata.roomResultsSettings.unheatedArea === undefined) {
    original.metadata.roomResultsSettings.unheatedArea = false;
  }

  // SEED-1316 Underfloor heating calculations fields
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      // Add new room feilds
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.manufacturer === undefined) {
          entity.room.underfloorHeating.manufacturer = null;
          entity.room.underfloorHeating.floorFinish = null;
          entity.room.underfloorHeating.pipeDiameterMM = null;
          entity.room.underfloorHeating.maxLoopLengthM = null;
          entity.room.underfloorHeating.exteriorLoopLengthM = null;
          entity.room.underfloorHeating.loopSpacingMM = null;
          entity.room.underfloorHeating.heatOutputW = null;
        }
      }

      // Add new manifld fields
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.MANIFOLD
      ) {
        if (entity.plant.ufhReturnTempC === undefined) {
          entity.plant.ufhReturnTempC = null;
          entity.plant.ufhFlowTempC = null;
        }
      }
    }
  }

  if ((original.metadata.heatLoss as any).underfloorHeating === undefined) {
    (original.metadata.heatLoss as any).underfloorHeating = {
      floorFinish:
        "Type A - 0.1 TOG - plastic sheet, ceramic tiles, stone tiles",
      pipeDiameterMM: 16,
      maxLoopLengthM: 100,
      rollLengthsM: [80, 100, 150, 200],
    } as UnderfloorHeatingSettings;
  }
}

export function drawing_upgrade91to92(original: DrawingState) {
  // SEED-1342: Add heat sources for radiators
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.RADIATOR
      ) {
        entity.plant.heatSourceOutletUid = null;
      }
    }
  }

  // SEED-1363 Separate heating and vent flow rates for rooms
  {
    if (original.metadata.heatLoss.ventAirChangesRateStandard === undefined) {
      original.metadata.heatLoss.ventAirChangesRateStandard =
        // @ts-ignore type has changed
        original.metadata.heatLoss.airChangesRateStandard;
      // @ts-ignore type has changed
      delete original.metadata.heatLoss.airChangesRateStandard;
    }

    if (
      original.metadata.heatLoss.heatingAirChangesRateStandard === undefined
    ) {
      original.metadata.heatLoss.heatingAirChangesRateStandard =
        original.metadata.heatLoss.ventAirChangesRateStandard;
    }

    if (original.metadata.roomResultsSettings.heatingAirChanges === undefined) {
      original.metadata.roomResultsSettings.heatingAirChanges = false;
    }

    if (original.metadata.roomResultsSettings.ventAirChanges === undefined) {
      original.metadata.roomResultsSettings.ventAirChanges = true;
    }

    // @ts-ignore type has changed
    if (original.metadata.roomResultsSettings.airChange !== undefined) {
      original.metadata.roomResultsSettings.ventAirChanges =
        // @ts-ignore
        original.metadata.roomResultsSettings.airChange;
      // @ts-ignore
      delete original.metadata.roomResultsSettings.airChange;
    }

    for (const lvl of Object.values(original.levels)) {
      for (const entity of Object.values(lvl.entities)) {
        if (
          entity.type === EntityType.ROOM &&
          entity.room.roomType === RoomType.ROOM
        ) {
          if (entity.room.ventACR === undefined) {
            // Move the flow rate fields into vent flow rates
            // @ts-ignore type has changed
            entity.room.ventACR = {
              // @ts-ignore
              achACR: entity.room.achACR ?? null,
              // @ts-ignore
              lpsACR: entity.room.lpsACR ?? null,
              // @ts-ignore
              lpsm2ACR: entity.room.lpsm2ACR ?? null,
              // @ts-ignore
              lpsPersonACR: entity.room.lpsPersonACR ?? null,
              // @ts-ignore
              lpsShowerACR: entity.room.lpsShowerACR ?? null,
              // @ts-ignore
              lpsWCACR: entity.room.lpsWCACR ?? null,
              // @ts-ignore
              lpsUrinalACR: entity.room.lpsUrinalACR ?? null,
              // @ts-ignore
              lpsBathACR: entity.room.lpsBathACR ?? null,
              // @ts-ignore
              lpsMachineACR: entity.room.lpsMachineACR ?? null,
              // @ts-ignore
              cmsACR: entity.room.cmsACR ?? null,
              // @ts-ignore
              cfmft2ACR: entity.room.cfmft2ACR ?? null,
              // @ts-ignore
              personCount: entity.room.personCount ?? null,
              // @ts-ignore
              showerCount: entity.room.showerCount ?? null,
              // @ts-ignore
              bathCount: entity.room.bathCount ?? null,
              // @ts-ignore
              wcCount: entity.room.wcCount ?? null,
              // @ts-ignore
              urinalCount: entity.room.urinalCount ?? null,
              // @ts-ignore
              machineCount: entity.room.machineCount ?? null,
            };
          }

          if (entity.room.heatingACR === undefined) {
            // Create the new heating flow rate fields for rooms
            // @ts-ignore type has changed
            entity.room.heatingACR = {
              // @ts-ignore
              achACR: entity.room.achACR ?? null,
              // @ts-ignore
              lpsACR: entity.room.lpsACR ?? null,
              // @ts-ignore
              lpsm2ACR: entity.room.lpsm2ACR ?? null,
              // @ts-ignore
              lpsPersonACR: entity.room.lpsPersonACR ?? null,
              // @ts-ignore
              lpsShowerACR: entity.room.lpsShowerACR ?? null,
              // @ts-ignore
              lpsWCACR: entity.room.lpsWCACR ?? null,
              // @ts-ignore
              lpsUrinalACR: entity.room.lpsUrinalACR ?? null,
              // @ts-ignore
              lpsBathACR: entity.room.lpsBathACR ?? null,
              // @ts-ignore
              lpsMachineACR: entity.room.lpsMachineACR ?? null,
              // @ts-ignore
              cmsACR: entity.room.cmsACR ?? null,
              // @ts-ignore
              cfmft2ACR: entity.room.cfmft2ACR ?? null,
              // @ts-ignore
              personCount: entity.room.personCount ?? null,
              // @ts-ignore
              showerCount: entity.room.showerCount ?? null,
              // @ts-ignore
              bathCount: entity.room.bathCount ?? null,
              // @ts-ignore
              wcCount: entity.room.wcCount ?? null,
              // @ts-ignore
              urinalCount: entity.room.urinalCount ?? null,
              // @ts-ignore
              machineCount: entity.room.machineCount ?? null,
            };
          }

          if (entity.room.heatingSpaceType === undefined) {
            entity.room.heatingSpaceType = entity.room.spaceType;
          }

          // @ts-ignore
          delete entity.room.achACR;
          // @ts-ignore
          delete entity.room.lpsACR;
          // @ts-ignore
          delete entity.room.lpsm2ACR;
          // @ts-ignore
          delete entity.room.lpsPersonACR;
          // @ts-ignore
          delete entity.room.lpsShowerACR;
          // @ts-ignore
          delete entity.room.lpsWCACR;
          // @ts-ignore
          delete entity.room.lpsUrinalACR;
          // @ts-ignore
          delete entity.room.lpsBathACR;
          // @ts-ignore
          delete entity.room.lpsMachineACR;
          // @ts-ignore
          delete entity.room.cmsACR;
          // @ts-ignore
          delete entity.room.cfmft2ACR;
          // @ts-ignore
          delete entity.room.personCount;
          // @ts-ignore
          delete entity.room.showerCount;
          // @ts-ignore
          delete entity.room.bathCount;
          // @ts-ignore
          delete entity.room.wcCount;
          // @ts-ignore
          delete entity.room.urinalCount;
          // @ts-ignore
          delete entity.room.machineCount;
        }
      }
    }
  }

  // SEED-1357 create the new room fields
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type !== EntityType.ROOM) continue;
      if (entity.room.roomType !== RoomType.ROOM) continue;

      if (entity.room.ventACR.lpsRoomACR === undefined) {
        entity.room.ventACR.lpsRoomACR = null;
      }

      if (entity.room.ventACR.roomCount === undefined) {
        entity.room.ventACR.roomCount = null;
      }

      if (entity.room.heatingACR.lpsRoomACR === undefined) {
        entity.room.heatingACR.lpsRoomACR = null;
      }

      if (entity.room.heatingACR.roomCount === undefined) {
        entity.room.heatingACR.roomCount = null;
      }
    }
  }
}

export function drawing_upgrade92to93(original: DrawingState) {
  for (let i = original.metadata.nodes.mechanical.length - 1; i >= 0; i--) {
    const origNode = original.metadata.nodes.mechanical[i];
    if (
      // @ts-ignore
      origNode.type === "air-handling" ||
      // @ts-ignore
      origNode.type === "underfloor-heating" ||
      // @ts-ignore
      origNode.type === "manifold"
    ) {
      original.metadata.nodes.mechanical.splice(i, 1);
    }
  }

  let manifoldNum = 0;
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.MANIFOLD
      ) {
        manifoldNum++;
      }
    }
  }

  let dualSystemPlantCnt = manifoldNum;
  let projHasMVHR = false;
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        (entity.plant.type === PlantType.FCU ||
          entity.plant.type === PlantType.AHU_VENT)
      ) {
        if (entity.plant.color === undefined) {
          entity.plant.color = goldenRatioColor(dualSystemPlantCnt++);
        }

        // this is so that existing FCUs still only supply to the rooms they are on top of
        const isFCU = entity.plant.type === PlantType.FCU;

        if (entity.plant.heatingRooms === undefined) {
          entity.plant.heatingRooms = isFCU ? null : [];
        }
        if (entity.plant.chilledRooms === undefined) {
          entity.plant.chilledRooms = isFCU ? null : [];
        }

        // look for MVHR
        if (
          entity.plant.type === PlantType.AHU_VENT &&
          entity.plant.supplyUid !== null &&
          entity.plant.extractUid !== null
        ) {
          projHasMVHR = true;
        }
      }
    }
  }

  original.metadata.heatLoss.ventHeatLossRecoveryPct = 0;
  if (projHasMVHR) {
    original.metadata.heatLoss.ventHeatLossRecoveryPct = 100;
  }
  // @ts-ignore type has changed
  if (!original.metadata.heatLoss.includeAirChangeHeatLoss) {
    original.metadata.heatLoss.ventHeatLossRecoveryPct = 100;
  }
  // @ts-ignore type has changed
  delete original.metadata.heatLoss.includeAirChangeHeatLoss;
}

export function drawing_upgrade93to94(original: DrawingState) {
  // SEED-1385 Rename vent dirty exhaust to fan exhaust
  for (const fs of Object.values(original.metadata.flowSystems)) {
    // @ts-ignore
    if (fs.role === "vent-dirty-exhaust") {
      // if the flow system is the standard dirty exhaust
      if (fs.uid === "ventilation-dirty-exhaust") {
        const originalFlowSystem = original.metadata.flowSystems[
          "ventilation-dirty-exhaust"
        ] as VentilationFlowSystem;
        original.metadata.flowSystems["ventilation-fan-exhaust"] = {
          ...originalFlowSystem,
          uid: "ventilation-fan-exhaust",
          name: "Fan Exhaust",
          role: "vent-fan-exhaust",
        };
        delete original.metadata.flowSystems["ventilation-dirty-exhaust"];

        const dirtyIndex = original.metadata.flowSystemUidsInOrder.indexOf(
          "ventilation-dirty-exhaust",
        );
        if (dirtyIndex >= 0) {
          original.metadata.flowSystemUidsInOrder[dirtyIndex] =
            "ventilation-fan-exhaust";
        }
      } else {
        // if the slow systme is a user defined dirty exhaust
        original.metadata.flowSystems[fs.uid] = {
          ...original.metadata.flowSystems[fs.uid],
          role: "vent-fan-exhaust",
        };
      }
    }
  }

  if (
    original.metadata.priceTable.Node &&
    "Dirty Exhaust" in original.metadata.priceTable.Node
  ) {
    original.metadata.priceTable.Node["Fan Exhaust"] = Number(
      original.metadata.priceTable.Node["Dirty Exhaust"],
    );
    delete original.metadata.priceTable.Node["Dirty Exhaust"];
  }
}

export function drawing_upgrade94to95(original: DrawingState) {
  // SEED-1385 Rename vent dirty exhaust to fan exhaust (part 2)
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === "PLANT") {
        if (entity.plant.type === "DUCT_MANIFOLD") {
          if (entity.inletSystemUid === "ventilation-dirty-exhaust") {
            entity.inletSystemUid = "ventilation-fan-exhaust";
          }
        }
        if (entity.plant.type === "CUSTOM") {
          if (entity.inletSystemUid === "ventilation-dirty-exhaust") {
            entity.inletSystemUid = "ventilation-fan-exhaust";
          }
          if (entity.plant.outletSystemUid === "ventilation-dirty-exhaust") {
            entity.plant.outletSystemUid = "ventilation-fan-exhaust";
          }
        }
      }

      if (entity.type === "LOAD_NODE") {
        if (entity.systemUidOption === "ventilation-dirty-exhaust") {
          entity.systemUidOption = "ventilation-fan-exhaust";
        }
      }

      if (entity.type === "DIRECTED_VALVE") {
        if (entity.systemUidOption === "ventilation-dirty-exhaust") {
          entity.systemUidOption = "ventilation-fan-exhaust";
        }
      }

      if (entity.type === "CONDUIT") {
        if (entity.systemUid === "ventilation-dirty-exhaust") {
          entity.systemUid = "ventilation-fan-exhaust";
        }
      }

      if (entity.type === "FITTING") {
        if (entity.systemUid === "ventilation-dirty-exhaust") {
          entity.systemUid = "ventilation-fan-exhaust";
        }
      }

      if (entity.type === "SYSTEM_NODE") {
        if (entity.systemUid === "ventilation-dirty-exhaust") {
          entity.systemUid = "ventilation-fan-exhaust";
        }
      }
    }
  }
}

export function drawing_upgrade95to96(original: DrawingState) {
  // SEED-1409 remove deprecate fcu mechanical nodes
  for (let i = original.metadata.nodes.mechanical.length - 1; i >= 0; i--) {
    const origNode = original.metadata.nodes.mechanical[i];
    // @ts-ignore
    if (origNode.type === "fan-coil") {
      original.metadata.nodes.mechanical.splice(i, 1);
    }
  }
}

export function drawing_upgrade96to97(original: DrawingState) {
  // prior to this version, the concept of workflows didn't exist. every user could do every thing, at all times.
  // de facto then, we enable all workflows here.

  const defaultWorkflows = {
    heat_loss: {
      enabled: true,
    },
    heat_gain: {
      enabled: true,
    },
    mech_heating: {
      enabled: true,
    },
    mech_ventilation: {
      enabled: true,
    },
    mech_underfloor_heating: {
      enabled: true,
    },
    mech_chilled: {
      enabled: true,
    },
    plumbing_water: {
      enabled: true,
    },
    plumbing_gas: {
      enabled: true,
    },
    plumbing_wastewater: {
      enabled: true,
    },
    plumbing_stormwater: {
      enabled: true,
    },
    fire_sprinklers: {
      enabled: true,
    },
    fire_hydrants: {
      enabled: true,
    },
    fire_hosereels: {
      enabled: true,
    },
  };

  // take default workflows and override with existing workflows (if any, somehow)
  // seems to broadly work correctly with missing fields
  // eg {...{a:{enabled:true}, b:{enabled:true}}, ...{a:{enabled:false}}} = {a:{enabled:false},b:{enabled:true}}
  // eg {...{a:{enabled:true}, b:{enabled:true}}, ...undefined} = {a:{enabled:true},b:{enabled:true}}
  const merged = { ...defaultWorkflows, ...original.metadata.workflows };

  original.metadata.workflows = merged;
}

export function drawing_upgrade97to98(original: DrawingState) {
  // SEED-1427: Add three heat pump fields - Minimum System Volume, Maximum Recirculation Pump Duty, Maximum Recirculation Pump Head
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === "PLANT") {
        if (entity.plant.type === "RETURN_SYSTEM") {
          if (
            entity.plant.returnSystemType === "AIR_SOURCE_HEAT_PUMP" ||
            entity.plant.returnSystemType === "GROUND_SOURCE_HEAT_PUMP"
          ) {
            const plant = entity.plant as HeatPumpPlant;
            if (plant.minimumSystemVolumeL === undefined) {
              plant.minimumSystemVolumeL = null;
            }
            if (plant.maximumRecirculationPumpFlowLPS === undefined) {
              plant.maximumRecirculationPumpFlowLPS = null;
            }
            if (plant.maximumRecirculationPumpPressureKPA === undefined) {
              plant.maximumRecirculationPumpPressureKPA = null;
            }
          }
        }
      }
    }
  }
}

export function drawing_upgrade98to99(original: DrawingState) {
  // SEED-1229: room entity changes
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.loopShape === undefined) {
          entity.room.underfloorHeating.loopShape = null;
        }
        if (entity.room.underfloorHeating.loopDirectionDEG === undefined) {
          entity.room.underfloorHeating.loopDirectionDEG = null;
        }
        if (entity.room.underfloorHeating.minBendRadiusMM === undefined) {
          entity.room.underfloorHeating.minBendRadiusMM = null;
        }
        if (entity.room.underfloorHeating.chirality === undefined) {
          entity.room.underfloorHeating.chirality = "clockwise";
        }
        if ((entity.room.underfloorHeating as any).loops) {
          delete (entity.room.underfloorHeating as any).loops;
        }
        // Sneaky one for SEED-1507 which I neglected to upgrade before for convenient release
        if (entity.room.ventACR.cfmACR === undefined) {
          entity.room.ventACR.cfmACR = null;
        }
        if (entity.room.heatingACR.cfmACR === undefined) {
          entity.room.heatingACR.cfmACR = null;
        }
        if ("entryFenUid" in entity.room.underfloorHeating) {
          delete entity.room.underfloorHeating.entryFenUid;
        }
      } else if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.MANIFOLD
      ) {
        if (entity.plant.hasRecirculationPump === undefined) {
          entity.plant.hasRecirculationPump = true;
        }
        if (entity.plant.internalVolumeL === undefined) {
          entity.plant.internalVolumeL = null;
        }
        if (entity.plant.ufhMode === undefined) {
          // Existing project's manifolds are to be approximate since that's the current behavior.
          entity.plant.ufhMode = "approximate";
        }
        if (entity.plant.ufhSystemUid === undefined) {
          entity.plant.ufhSystemUid = StandardFlowSystemUids.UnderfloorHeating;
        }
      }
    }
  }

  // SEED-1229 max pipe spacing field
  if (
    (original.metadata.heatLoss as any).underfloorHeating?.maxPipeSpacingMM ===
    undefined
  ) {
    // Although the default max pipe spacing is 200, we set it to 500 to avoid changing existing drawings
    (original.metadata.heatLoss as any).underfloorHeating.maxPipeSpacingMM =
      500;
  }

  if (
    (original.metadata.heatLoss as any).underfloorHeating?.pipeMaterial ===
    undefined
  ) {
    (original.metadata.heatLoss as any).underfloorHeating.pipeMaterial =
      "pexSdr74";
  }

  // SEED-1408 add heat supplied and cooling supplied
  if (original.metadata.roomResultsSettings.heatSupplied === undefined) {
    original.metadata.roomResultsSettings.heatSupplied = true;
  }
  if (original.metadata.roomResultsSettings.coolingSupplied === undefined) {
    original.metadata.roomResultsSettings.coolingSupplied = true;
  }
}

/** coerce project 'numbers' back into strings */
export function drawing_upgrade99to100(original: DrawingState) {
  const projectNumber = original.metadata.generalInfo.projectNumber;
  // typeof blah === 'number' will be false for strings, nulls and undefineds
  // so this will only proc for true numbers
  if (typeof projectNumber === "number") {
    const asNumber = assertTypePure<Number>(projectNumber);
    original.metadata.generalInfo.projectNumber = asNumber.toString();
  }
}

export function drawing_upgrade100to101(original: DrawingState) {
  // SEED-1414 introduce new fixture and load node entity fields for indian standards
  const drainageMethods = original.metadata.calculationParams.drainageMethod;
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.FIXTURE) {
        let overridenDrainageValue: number | null = null;
        switch (drainageMethods) {
          case SupportedDrainageMethods.AS2021FixtureUnits:
            // @ts-ignore deprecated type
            overridenDrainageValue = entity.asnzFixtureUnits;
            break;
          case SupportedDrainageMethods.EN1205622000DischargeUnits:
            // @ts-ignore deprecated type
            overridenDrainageValue = entity.enDischargeUnits;
            break;
          case SupportedDrainageMethods.UPC2018DrainageFixtureUnits:
            // @ts-ignore deprecated type
            overridenDrainageValue = entity.upcFixtureUnits;
            break;
        }

        if (typeof entity.drainageFixtureUnits !== "number") {
          entity.drainageFixtureUnits = overridenDrainageValue ?? null;
        }

        // @ts-ignore deprecated type
        delete entity.probabilityOfUsagePCT;
        // @ts-ignore deprecated type
        delete entity.asnzFixtureUnits;
        // @ts-ignore deprecated type
        delete entity.enDischargeUnits;
        // @ts-ignore deprecated type
        delete entity.upcFixtureUnits;
      }

      if (entity.type === EntityType.LOAD_NODE) {
        if (
          entity.node.type === NodeType.LOAD_NODE ||
          entity.node.type === NodeType.DWELLING
        ) {
          let overridenDrainageValue: number | null = null;
          switch (drainageMethods) {
            case SupportedDrainageMethods.AS2021FixtureUnits:
              // @ts-ignore deprecated type
              overridenDrainageValue = entity.node.asnzFixtureUnits;
              break;
            case SupportedDrainageMethods.EN1205622000DischargeUnits:
              // @ts-ignore deprecated type
              overridenDrainageValue = entity.node.enDischargeUnits;
              break;
            case SupportedDrainageMethods.UPC2018DrainageFixtureUnits:
              // @ts-ignore deprecated type
              overridenDrainageValue = entity.node.upcFixtureUnits;
              break;
          }

          if (typeof entity.node.drainageFixtureUnits !== "number") {
            entity.node.drainageFixtureUnits = overridenDrainageValue ?? null;
          }
          // @ts-ignore deprecated type
          delete entity.node.asnzFixtureUnits;
          // @ts-ignore deprecated type
          delete entity.node.enDischargeUnits;
          // @ts-ignore deprecated type
          delete entity.node.upcFixtureUnits;
        }
      }
    }
  }
}

export function drawing_upgrade101to102(drawing: DrawingState) {
  if (drawing.metadata.heatLoss.roomsBelowTransparentPct === undefined) {
    drawing.metadata.heatLoss.roomsBelowTransparentPct =
      DEFAULT_ROOMS_BELOW_TRANSPARENCY_PCT;
  }
}

export function drawing_upgrade102to103(drawing: DrawingState) {
  // SEED-1503: Remove Plasterboard from External and Internal Wall Material
  if ("Plasterboard" in drawing.metadata.heatLoss.material["External Wall"]) {
    delete drawing.metadata.heatLoss.material["External Wall"]["Plasterboard"];
  }

  if ("Plasterboard" in drawing.metadata.heatLoss.material["Internal Wall"]) {
    delete drawing.metadata.heatLoss.material["Internal Wall"]["Plasterboard"];
  }

  if (
    drawing.metadata.heatLoss.defaultMaterial["External Wall"] ===
    "Plasterboard"
  ) {
    drawing.metadata.heatLoss.defaultMaterial["External Wall"] =
      "Building Regs 2022 Limit";
  }

  if (
    drawing.metadata.heatLoss.defaultMaterial["Internal Wall"] ===
    "Plasterboard"
  ) {
    drawing.metadata.heatLoss.defaultMaterial["Internal Wall"] =
      "Building Regs 2022 Limit";
  }

  for (const level of Object.values(drawing.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type === EntityType.WALL) {
        if (entity.wallMaterialUid === "Plasterboard") {
          entity.wallMaterialUid = "Building Regs 2022 Limit";
        }
      }
    }
  }

  // SEED-1791 Fix max return loop velocity for mechanical systems in the US
  for (const fsUid of Object.keys(drawing.metadata.flowSystems)) {
    if (drawing.metadata.flowSystems[fsUid].type === "mechanical") {
      (
        drawing.metadata.flowSystems[fsUid] as MechanicalFlowSystem
      ).return.maxVelocityMS = 10000000000;
    }
  }
}

export function drawing_upgrade103to104(original: DrawingState) {
  // SEED-1582 add custom spare heat gain and heat loss
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.spareHeatGainPercent === undefined) {
          entity.room.spareHeatGainPercent = null;
        }
        if (entity.room.spareHeatLossPercent === undefined) {
          entity.room.spareHeatLossPercent = null;
        }
      }
    }
  }
}

export function drawing_upgrade104to105(original: DrawingState) {
  // SEED-1771 thermal conductivity -> thermal transmittance
  const heatLoadItems: HeatLoadItem[] = [
    "External Wall",
    "Internal Wall",
    "Window",
    "External Door",
    "Internal Door",
    "Roof",
    // @ts-ignore type changed
    "Floor",
  ];
  for (const heatLoadItem of heatLoadItems) {
    const customMaterials =
      original.metadata.heatLoss.customMaterial[heatLoadItem];
    if (customMaterials) {
      for (const materialName of Object.keys(customMaterials)) {
        const material = customMaterials[materialName];
        if ("thermal_conductivity_W_per_m2K" in material) {
          const conductivity = material["thermal_conductivity_W_per_m2K"];
          if (typeof conductivity === "number") {
            material["thermal_transmittance_W_per_m2K"] = conductivity;
          } else if (typeof conductivity === "string") {
            material["thermal_transmittance_W_per_m2K"] =
              parseFloat(conductivity);
          }
        } else {
          material["thermal_transmittance_W_per_m2K"] = 0;
        }
      }
    }
  }
}

export function drawing_upgrade105to106(original: DrawingState) {
  const allowed = new Set(["square", "shoe", "bell"]);
  Object.values(original.metadata.flowSystems).forEach((system) => {
    if (system.type !== "ventilation") return;
    Object.values(system.networks).forEach((network) => {
      if (
        network.rectCircTee !== undefined &&
        !allowed.has(network.rectCircTee)
      ) {
        network.rectCircTee = "square";
      }
      if (
        network.circCircTee !== undefined &&
        !allowed.has(network.circCircTee)
      ) {
        network.circCircTee = "square";
      }
    });
  });
}

export function drawing_upgrade106to107(original: DrawingState) {
  // SEED-1817 add ufh freeze layout
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.freezeLayout === undefined) {
          entity.room.underfloorHeating.freezeLayout = false;
        }
        if (entity.room.underfloorHeating.frozenLoopsStats === undefined) {
          entity.room.underfloorHeating.frozenLoopsStats = [];
        }
      }
    }
  }

  // SEED-1815 make sure thermal_transmittance_W_per_m2K is number
  const heatLoadItems: HeatLoadItem[] = [
    "External Wall",
    "Internal Wall",
    "Window",
    "External Door",
    "Internal Door",
    "Roof",
    // @ts-ignore type changed
    "Floor",
  ];
  for (const heatLoadItem of heatLoadItems) {
    const customMaterials =
      original.metadata.heatLoss.customMaterial[heatLoadItem];
    if (customMaterials) {
      for (const materialName of Object.keys(customMaterials)) {
        const material = customMaterials[materialName];
        if ("thermal_transmittance_W_per_m2K" in material) {
          material["thermal_transmittance_W_per_m2K"] = parseFloat(
            // @ts-ignore
            material["thermal_transmittance_W_per_m2K"],
          );
        }
      }
    }
  }
}

export function drawing_upgrade107to108(original: DrawingState) {
  // SEED-1407 add airTemperatureMVHR to plant entity if isPlantMVHR
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (entity.type === EntityType.PLANT && isPlantMVHR(entity.plant)) {
        if (entity.plant.airTemperatureMVHR === undefined) {
          entity.plant.airTemperatureMVHR = null;
        }
        if (entity.plant.increaseFlowRateBasedOnHeatLoad === undefined) {
          entity.plant.increaseFlowRateBasedOnHeatLoad = true;
        }
      }
    }
  }
}

export function drawing_upgrade108to109(original: DrawingState) {
  // SEED-1495: Add adjacent party wall temperature
  if (original.metadata.heatLoss.winterPartyTemperatureC === undefined) {
    original.metadata.heatLoss.winterPartyTemperatureC = 10;
  }

  if (original.metadata.heatLoss.summerPartyTemperatureC === undefined) {
    original.metadata.heatLoss.summerPartyTemperatureC = 20;
  }

  if (original.metadata.heatLoss.material["Party Wall"] === undefined) {
    original.metadata.heatLoss.material["Party Wall"] = {
      "Building Regs 2022 Limit": {
        uid: "Building Regs 2022 Limit",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Brick": {
        uid: "Solid Brick",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Brick Insulated": {
        uid: "Solid Brick Insulated",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Stone": {
        uid: "Solid Stone",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Stone Insulated": {
        uid: "Solid Stone Insulated",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Concrete": {
        uid: "Solid Concrete",
        manufacturer: "generic",
        selected: null,
      },
      "Solid Concrete Insulated": {
        uid: "Solid Concrete Insulated",
        manufacturer: "generic",
        selected: null,
      },
      "Cavity Wall Uninsulated": {
        uid: "Cavity Wall Uninsulated",
        manufacturer: "generic",
        selected: null,
      },
      "Cavity Wall Insulated": {
        uid: "Cavity Wall Insulated",
        manufacturer: "generic",
        selected: null,
      },
      Hardwood: {
        uid: "Hardwood",
        manufacturer: "generic",
        selected: null,
      },
      Softwood: {
        uid: "Softwood",
        manufacturer: "generic",
        selected: null,
      },
      Plasterboard: {
        uid: "Plasterboard",
        manufacturer: "generic",
        selected: null,
      },
      "Partition Uninsulated": {
        uid: "Partition Uninsulated",
        manufacturer: "generic",
        selected: null,
      },
      "Partition Insulated": {
        uid: "Partition Insulated",
        manufacturer: "generic",
        selected: null,
      },
    };
  }

  if (original.metadata.heatLoss.defaultMaterial["Party Wall"] === undefined) {
    original.metadata.heatLoss.defaultMaterial["Party Wall"] =
      "Building Regs 2022 Limit";
  }

  if (original.metadata.heatLoss.defaultColor["Party Wall"] === undefined) {
    original.metadata.heatLoss.defaultColor["Party Wall"] = {
      hex: "#d3d3d3",
    };
  }

  if (original.metadata.heatLoss.customMaterial["Party Wall"] === undefined) {
    original.metadata.heatLoss.customMaterial["Party Wall"] = {};
  }

  // SEED-1856 party width
  const wallSpec = original.metadata.heatLoss.wallSpec;
  if (wallSpec && !("partyWidthMM" in wallSpec)) {
    // @ts-ignore
    wallSpec.partyWidthMM = 150;
  }
}

export function drawing_upgrade109to110(original: DrawingState) {
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.PLANT &&
        entity.plant.type === PlantType.MANIFOLD
      ) {
        if (entity.plant.manifoldManufacturer === undefined) {
          entity.plant.manifoldManufacturer = null;
        }
        if (entity.plant.manifoldRange === undefined) {
          entity.plant.manifoldRange = null;
        }
        if (entity.plant.manifoldModel === undefined) {
          entity.plant.manifoldModel = null;
        }
        if (entity.plant.actuatorManufacturer === undefined) {
          entity.plant.actuatorManufacturer = null;
        }
        if (entity.plant.actuatorModel === undefined) {
          entity.plant.actuatorModel = null;
        }
        if (entity.plant.pumpPackManufacturer === undefined) {
          entity.plant.pumpPackManufacturer = null;
        }
        if (entity.plant.pumpPackModel === undefined) {
          entity.plant.pumpPackModel = null;
        }
        if (entity.plant.ballValveManufacturer === undefined) {
          entity.plant.ballValveManufacturer = null;
        }
        if (entity.plant.ballValveModel === undefined) {
          entity.plant.ballValveModel = null;
        }
      }
    }
  }

  original.metadata.catalog.underfloorHeating = [
    {
      uid: "manifold",
      manufacturer: "generic",
      selected: null,
    },
  ];
}

export function drawing_upgrade110to111(original: DrawingState) {
  // SEED-1635 Intertenancy floors

  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.floorType === undefined) {
          entity.room.floorType = null;
        }
      }
    }
  }

  {
    // update manufacturer selections for floors
    if (original.metadata.heatLoss.material["Bottom Floor"] === undefined) {
      original.metadata.heatLoss.material["Bottom Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.material["Floor"]);
    }

    if (original.metadata.heatLoss.material["Suspended Floor"] === undefined) {
      original.metadata.heatLoss.material["Suspended Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.material["Floor"]);
    }

    if (original.metadata.heatLoss.material["Party Floor"] === undefined) {
      // assign a new set of selections for party floor as its new
      original.metadata.heatLoss.material["Party Floor"] = {
        "Building Regs 2022 Limit": {
          uid: "Building Regs 2022 Limit",
          manufacturer: "generic",
          selected: null,
        },
        Concrete: {
          uid: "Concrete",
          manufacturer: "generic",
          selected: null,
        },
        Steel: {
          uid: "Steel",
          manufacturer: "generic",
          selected: null,
        },
        Screed: {
          uid: "Screed",
          manufacturer: "generic",
          selected: null,
        },
        Softwood: {
          uid: "Softwood",
          manufacturer: "generic",
          selected: null,
        },
        Hardwood: {
          uid: "Hardwood",
          manufacturer: "generic",
          selected: null,
        },
        "Wood Blocks": {
          uid: "Wood Blocks",
          manufacturer: "generic",
          selected: null,
        },
      };
    }

    // @ts-ignore old type
    delete original.metadata.heatLoss.material["Floor"];
  }

  {
    // update the default materials for floors
    if (
      original.metadata.heatLoss.defaultMaterial["Bottom Floor"] === undefined
    ) {
      original.metadata.heatLoss.defaultMaterial["Bottom Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.defaultMaterial["Floor"]);
    }

    if (
      original.metadata.heatLoss.defaultMaterial["Suspended Floor"] ===
      undefined
    ) {
      original.metadata.heatLoss.defaultMaterial["Suspended Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.defaultMaterial["Floor"]);
    }

    if (
      original.metadata.heatLoss.defaultMaterial["Party Floor"] === undefined
    ) {
      // assign a new building regs default for party floor as its new
      original.metadata.heatLoss.defaultMaterial["Party Floor"] =
        "Building Regs 2022 Limit";
    }

    // @ts-ignore old type
    delete original.metadata.heatLoss.defaultMaterial["Floor"];
  }

  {
    // update the default colors for floors
    if (original.metadata.heatLoss.defaultColor["Bottom Floor"] === undefined) {
      original.metadata.heatLoss.defaultColor["Bottom Floor"] =
        // @ts-ignore assign the existing floor selection for bottom floor
        cloneSimple(original.metadata.heatLoss.defaultColor["Floor"]);
    }

    if (
      original.metadata.heatLoss.defaultColor["Suspended Floor"] === undefined
    ) {
      original.metadata.heatLoss.defaultColor["Suspended Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.defaultColor["Floor"]);
    }

    if (original.metadata.heatLoss.defaultColor["Party Floor"] === undefined) {
      // assign a new building regs default for party floor as its new
      original.metadata.heatLoss.defaultColor["Party Floor"] = {
        hex: "#ffffff",
      };
    }

    // @ts-ignore old type
    delete original.metadata.heatLoss.defaultColor["Floor"];
  }

  {
    // update the custom materials for floors
    if (
      original.metadata.heatLoss.customMaterial["Bottom Floor"] === undefined
    ) {
      original.metadata.heatLoss.customMaterial["Bottom Floor"] =
        // @ts-ignore assign the existing floor selection for bottom floor
        cloneSimple(original.metadata.heatLoss.customMaterial["Floor"]);
    }

    if (
      original.metadata.heatLoss.customMaterial["Suspended Floor"] === undefined
    ) {
      original.metadata.heatLoss.customMaterial["Suspended Floor"] =
        // @ts-ignore assign the existing floor selection for suspended floor
        cloneSimple(original.metadata.heatLoss.customMaterial["Floor"]);
    }

    if (
      original.metadata.heatLoss.customMaterial["Party Floor"] === undefined
    ) {
      // assign a new building regs default for party floor as its new
      original.metadata.heatLoss.customMaterial["Party Floor"] = {};
    }

    // @ts-ignore old type
    delete original.metadata.heatLoss.customMaterial["Floor"];
  }
}

export function drawing_upgrade111to112(original: DrawingState) {
  const oldToNewFloorFinish = {
    "0.1 TOG - plastic sheet, ceramic tiles, stone tiles":
      "Type A - 0.1 TOG - plastic sheet, ceramic tiles, stone tiles",
    "0.5 TOG - parquet, cushioned linoleum, 75mm stone flooring":
      "Type A - 0.5 TOG - parquet, cushioned linoleum, 75mm stone flooring",
    "1 TOG - carpet tiles, hardwood flooring":
      "Type A - 1 TOG - carpet tiles, hardwood flooring",
    "1.5 TOG - carpet, 22mm timber laminate or wood block":
      "Type A - 1.5 TOG - carpet, 22mm timber laminate or wood block",
  } as any;

  // SEED-1809 New UFH system types
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.floorFinish !== null) {
          if (
            entity.room.underfloorHeating.floorFinish in oldToNewFloorFinish
          ) {
            entity.room.underfloorHeating.floorFinish =
              // @ts-ignore wtf ts
              oldToNewFloorFinish[entity.room.underfloorHeating.floorFinish];
          }
        }

        if (entity.room.underfloorHeating.pipeMaterial === undefined) {
          entity.room.underfloorHeating.pipeMaterial = null;
        }
      }

      // introducing floor finish variable for area segments
      if (entity.type === EntityType.AREA_SEGMENT) {
        if (entity.areaType === "heated-area") {
          if (entity.underfloorHeating.floorFinish === undefined) {
            entity.underfloorHeating.floorFinish = null;
          }
          if (entity.underfloorHeating.pipeMaterial === undefined) {
            entity.underfloorHeating.pipeMaterial = null;
          }
        }
      }
    }
  }

  // update settings for v2
  if (
    (original.metadata.heatLoss as any)?.underfloorHeating?.floorFinish in
    oldToNewFloorFinish
  ) {
    (original.metadata.heatLoss as any).underfloorHeating.floorFinish =
      // @ts-ignore wtf ts
      oldToNewFloorFinish[
        (original.metadata.heatLoss as any).underfloorHeating.floorFinish
      ];
  }

  // update settings for v3
  for (const fs of Object.values(original.metadata.flowSystems)) {
    if (fs.type === "underfloor") {
      if (fs.floorFinish in oldToNewFloorFinish) {
        fs.floorFinish =
          // @ts-ignore wtf ts
          oldToNewFloorFinish[fs.floorFinish];
      }
    }
  }
}

export function drawing_upgrade112to113(original: DrawingState) {
  // SEED-1858 add manifold actuators
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        if (entity.room.underfloorHeating.hasActuator === undefined) {
          entity.room.underfloorHeating.hasActuator = true;
        }
      }

      if (
        entity.type === EntityType.AREA_SEGMENT &&
        entity.areaType === "heated-area"
      ) {
        if (entity.underfloorHeating.hasActuator === undefined) {
          entity.underfloorHeating.hasActuator = true;
        }
      }
    }
  }
}

export function drawing_upgrade113to114(original: DrawingState) {
  if (!original.metadata.metaPdf) {
    original.metadata.metaPdf = {};
  }
}

export function drawing_upgrade114to115(original: DrawingState) {
  // SEED-1862 some new fields needed for pump packs.
  for (const level of Object.values(original.levels)) {
    for (const entity of Object.values(level.entities)) {
      if (entity.type !== EntityType.PLANT) {
        continue;
      }
      if (entity.plant.type !== PlantType.MANIFOLD) {
        continue;
      }
      if (entity.plant.pumpManufacturer === undefined) {
        entity.plant.pumpManufacturer = null;
      }
      if (entity.plant.pumpModel === undefined) {
        entity.plant.pumpModel = null;
      }
      if (entity.plant.blendingValveManufacturer === undefined) {
        entity.plant.blendingValveManufacturer = null;
      }
      if (entity.plant.blendingValveModel === undefined) {
        entity.plant.blendingValveModel = null;
      }
    }
  }
}

export function drawing_upgrade115to116(original: DrawingState) {
  // SEED-1908 revert ufh frozen layouts
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        entity.room.underfloorHeating.freezeLayout = false;
        entity.room.underfloorHeating.frozenLoopsStats = [];
      }
    }
  }
}

export function drawing_upgrade116to117(original: DrawingState) {
  // Remaining UFH upgrades

  // UFH flow systems
  if (
    !Object.values(original.metadata.flowSystems).some((fs) => {
      return fs.type === "underfloor";
    })
  ) {
    // missing all ufh flow systems
    // Just before drainage.
    let firstDrainageIndex = original.metadata.flowSystemUidsInOrder.findIndex(
      (uid) => {
        const fs = original.metadata.flowSystems[uid];
        return fs.type === "sewer";
      },
    );
    if (firstDrainageIndex === -1) {
      firstDrainageIndex = original.metadata.flowSystemUidsInOrder.length;
    }

    const ufhSystems = UNDERFLOOR_FLOW_SYSTEMS();
    for (const fs of ufhSystems) {
      if ("underfloorHeating" in original.metadata.heatLoss) {
        assertType<UnderfloorHeatingSettings>(
          original.metadata.heatLoss.underfloorHeating,
        );
        fs.maxPipeSpacingMM =
          original.metadata.heatLoss.underfloorHeating.maxPipeSpacingMM;
        fs.maxLoopLengthM =
          original.metadata.heatLoss.underfloorHeating.maxLoopLengthM;
        fs.rollLengthsM = cloneSimple(
          original.metadata.heatLoss.underfloorHeating.rollLengthsM,
        );
        fs.pipeMaterial =
          original.metadata.heatLoss.underfloorHeating.pipeMaterial;
        fs.floorFinish =
          original.metadata.heatLoss.underfloorHeating.floorFinish;
        fs.pipeDiameterMM =
          original.metadata.heatLoss.underfloorHeating.pipeDiameterMM;
      }
      original.metadata.flowSystems[fs.uid] = fs;
      original.metadata.flowSystemUidsInOrder.splice(
        firstDrainageIndex,
        0,
        fs.uid,
      );
      firstDrainageIndex++;
    }
  }

  if ("underfloorHeating" in original.metadata.heatLoss) {
    delete original.metadata.heatLoss.underfloorHeating;
  }
}

export function drawing_upgrade117to118(original: DrawingState) {
  for (const lvl of Object.values(original.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      if (
        entity.type === EntityType.ROOM &&
        entity.room.roomType === RoomType.ROOM
      ) {
        entity.room.underfloorHeating.edgeExpansionFoamModel = null;
        entity.room.underfloorHeating.edgeExpansionFoamManufacturer = null;
      }

      if (
        entity.type === EntityType.AREA_SEGMENT &&
        entity.areaType === "heated-area"
      ) {
        entity.underfloorHeating.edgeExpansionFoamModel = null;
        entity.underfloorHeating.edgeExpansionFoamManufacturer = null;
      }
    }
  }
}
