import Flatten from "@flatten-js/core";
import { createEntryWithUnit } from "../../../../common/src/api/calculations/heatloss/heat-loss-result-type";
import { RoofSegmentation } from "../../../../common/src/api/calculations/heatloss/roof-calculation/roof-calculation";
import { calculateCentroidCoord } from "../../../../common/src/api/calculations/heatloss/roof-calculation/utils";
import { CoreContext } from "../../../../common/src/api/calculations/types";
import {
  LoopBreakdown2D,
  fullLoopBreakdown2D,
} from "../../../../common/src/api/calculations/underfloor-heating/loop-breakdown";
import { UnderfloorHeatingCalcs } from "../../../../common/src/api/calculations/underfloor-heating/underfloor-heating";
import {
  StandardFlowSystemUids,
  isCoolingPlantSystem,
  isHeatingPlantSystem,
  isUnderfloor,
  isVentilation,
} from "../../../../common/src/api/config";
import CoreEdge from "../../../../common/src/api/coreObjects/coreEdge";
import CoreFen from "../../../../common/src/api/coreObjects/coreFenestration";
import CorePlant from "../../../../common/src/api/coreObjects/corePlant";
import CoreRoom from "../../../../common/src/api/coreObjects/coreRoom";
import CoreVertex from "../../../../common/src/api/coreObjects/coreVertex";
import CoreWall from "../../../../common/src/api/coreObjects/coreWall";
import { UnderfloorHeatingCalc } from "../../../../common/src/api/document/calculations-objects/room-calculation";
import { UnderfloorHeatingLoopCalculation } from "../../../../common/src/api/document/calculations-objects/underfloor-heating-loop-calculation";
import { INTERNAL_WALL_WIDTH_MM } from "../../../../common/src/api/document/drawing";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { isManifold } from "../../../../common/src/api/document/entities/plants/plant-entity";
import { PlantType } from "../../../../common/src/api/document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isRoomAssociatedPlant,
} from "../../../../common/src/api/document/entities/plants/utils";
import {
  RoofEntityConcrete,
  RoomEntity,
  RoomType,
  UFHLoopDesignParameters,
  UNDERFLOOR_HEATING_TAB_ID,
  fillDefaultRoomFields,
  isRoomRoomEntity,
} from "../../../../common/src/api/document/entities/rooms/room-entity";
import { getUFHLoopDesignFlowSystem } from "../../../../common/src/api/document/entities/shared-fields/ufh-fields";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { lighten } from "../../../../common/src/lib/color";
import {
  Coord,
  Coord3D,
  coordDist2,
  coordMidpoint,
  flattenPoint2Coord,
} from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { lerp } from "../../../../common/src/lib/mathUtils/mathutils";
import {
  placeContentInPolygon,
  polygonPolygonIntersects,
} from "../../../../common/src/lib/mathUtils/polygonUtils";
import {
  Units,
  UnitsContext,
  convertMeasurementSystem,
} from "../../../../common/src/lib/measurements";
import {
  EPS,
  assertUnreachable,
  assertUnreachableAggressive,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME, DEFAULT_FONT_NAME_BOLD } from "../../config";
import { lerpCSSColor, lerpColor } from "../../lib/utils";
import { DocumentState } from "../../store/document/types";
import { MainEventBus } from "../../store/main-event-bus";
import { shouldShowOnFloorAbove as shouldShowRoomPlanOnLevelAbove } from "../layers/util";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import {
  CollisionLayer,
  MagicDraggableObject,
} from "../lib/object-traits/magic-draggable-object";
import { PolygonObject } from "../lib/object-traits/polygon-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { isSelectRoomForManifoldTool } from "../tools/room-select-tool";
import { DrawingMode } from "../types";
import {
  DrawableObjectConcrete,
  PolygonObjectConcrete,
  isCenteredObject,
} from "./concrete-object";
import DrawableEdge from "./drawableEdge";
import DrawableVertex from "./drawableVertex";
import DrawableWall from "./drawableWall";
import { ROOM_PATTERNS } from "./room-patterns";
import { getLinerGradientCoords, slopeToColor } from "./utils";

import { isRoomLoopLayoutFrozen } from "../../../../common/src/api/document/entities/underfloor-heating/ufh-freeze-layouts";

const Base = CalculatedObject(
  SelectableObject(
    MagicDraggableObject(
      DraggableObject(
        HoverableObject(
          SnappableObject(PolygonObject(Core2Drawable(CoreRoom))),
        ),
      ),
    ),
  ),
);

type Content = {
  color: string;
  text: string;
  noLineBreak?: boolean;
  rotate?: number;
};
type ContentsOnRoom = Content[];

type DrawHatchResult =
  | { draw: false }
  | { draw: true; pattern: boolean; color: string };

export default class DrawableRoom extends Base {
  type: EntityType.ROOM = EntityType.ROOM;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<RoomEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  shouldSkipDrag() {
    return this.document.uiState.drawingMode !== DrawingMode.FloorPlan;
  }

  getEdgeSpacing() {
    return INTERNAL_WALL_WIDTH_MM;
  }

  // @ts-ignore 2611
  get collisionLayers() {
    return [CollisionLayer.ROOM];
  }

  getSnapTargets(_request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
    throw new Error("Method not implemented.");
  }
  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];
    result.push({
      object: this,
      cascade: false,
    });

    if (this.document.uiState.drawingMode !== DrawingMode.FloorPlan) {
      return [];
    }

    this.entity.edgeUid.forEach((edgeUid: string) => {
      const edge = this.globalStore.get<DrawableEdge>(edgeUid);

      if (edge) {
        result.push({
          object: edge,
          cascade: false,
        });
      } else {
        throw new Error("Edge not found");
      }
    });

    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = this.globalStore.get<DrawableVertex>(vertex.uid);
      if (drawableVertex) {
        result.push({
          object: drawableVertex,
          cascade: false,
        });
      } else {
        throw new Error("Vertex not found");
      }
    });

    this.collectWalls().forEach((wall: CoreWall) => {
      const drawableWall = this.globalStore.get<DrawableWall>(wall.uid);
      if (drawableWall) {
        result.push({
          object: drawableWall,
          cascade: false,
        });
      } else {
        throw new Error("Wall not found");
      }
    });

    return result;
  }

  getPopupContent(): EntityPopupContent[] | null {
    const result: EntityPopupContent[] = [];
    for (const cacheKey of Object.keys(this.lastFullContent)) {
      if (this.wasShrunk[cacheKey] && this.lastFullContent[cacheKey]) {
        result.push({
          title: "",
          description: "",
          table: this.lastFullContent[cacheKey]!.map((c) => {
            return {
              title: "",
              cells: [
                {
                  key: "",
                  value: c.text,
                  rotationDeg: c.rotate,
                },
              ],
            };
          }),
        });
      }
    }
    if (
      isRoomRoomEntity(this.entity) &&
      isRoomLoopLayoutFrozen(this.entity, this.context.featureAccess)
    ) {
      DrawableRoom.addLoopFrozenPopupContent(result);
    }
    if (result.length === 0) {
      return null;
    }
    return result;
  }

  static addLoopFrozenPopupContent(result: EntityPopupContent[]) {
    result.push({
      title: "Loop layout frozen",
      description:
        "The loop generation for this room is frozen. Too see changes, unfreeze the underfloor heating loop generation.",
      actions: [
        {
          name: "Show me how to unfreeze the loop generation",
          action: () => MainEventBus.$emit("show-ufh-unfreeze-hint"),
        },
      ],
    });
  }

  onRedrawNeeded() {
    super.onRedrawNeeded();
    this.lastBestPoint = {};
    this.lastFullContent = {};
    this.wasShrunk = {};
  }

  lastBestPoint: Record<
    string,
    {
      center: Flatten.Point;
      width: number;
      height: number;
    } | null
  > = {};
  wasShrunk: Record<string, boolean> = {};
  lastFullContent: Record<string, ContentsOnRoom> = {};

  drawRoomDisplayInfo(
    context: DrawingContext,
    args: EntityDrawingArgs,
    ctx: CanvasRenderingContext2D,
    contents: ContentsOnRoom,
    pointsWC: Coord[],
    cacheKey: string,
  ): Flatten.Polygon[] {
    this.lastFullContent[cacheKey] = cloneSimple(contents);
    if (this.lastBestPoint[cacheKey] === undefined) {
      const polygon = new Flatten.Polygon();
      polygon.addFace(pointsWC.map((p) => new Flatten.Point(p.x, p.y)));

      this.lastBestPoint[cacheKey] = placeContentInPolygon(polygon);
    }

    const getWidth = (
      ctx: CanvasRenderingContext2D,
      content: string[],
    ): number => {
      return content
        .map((str: string) => ctx.measureText(str).width)
        .reduce((a, b) => Math.max(a, b), 0);
    };
    const getHeight = (
      ctx: CanvasRenderingContext2D,
      content: string[],
    ): number => {
      return 1.4 * ctx.measureText("M").width * content.length;
    };
    const textContent = contents.map((c) => c.text);

    const bestPoint = cloneSimple(this.lastBestPoint[cacheKey])!;
    if (bestPoint) {
      const { center, width, height } = bestPoint;

      let scaleFactor = 150;

      ctx.textBaseline = "top";

      let fits = false;
      const lowLimit = args.forExport ? 10 : 90;
      let textboxWidth = 0;
      let textboxHeight = 0;
      for (; scaleFactor >= lowLimit; scaleFactor -= 10) {
        ctx.font = scaleFactor + "px " + DEFAULT_FONT_NAME;

        const thisWidth = getWidth(ctx, textContent);
        const thisHeight = getHeight(ctx, textContent);

        if (thisWidth < width && thisHeight < height) {
          fits = true;
          textboxWidth = thisWidth;
          textboxHeight = thisHeight;
          break;
        }
      }

      if (fits || args.forExport) {
        this.wasShrunk[cacheKey] = false;
      } else {
        contents = [contents[0]];
        textContent.splice(1);
        this.wasShrunk[cacheKey] = true;
      }
      center.y -= getHeight(ctx, textContent) / 2;
      const lineHeight = 1.2 * ctx.measureText("M").width;

      if (this.shouldDrawHatch(args).draw) {
        // Draw solid room coloured background
        ctx.fillStyle = this.baseColor(context, args);
        ctx.globalAlpha = 1;
        ctx.fillRect(
          center.x - textboxWidth / 2,
          center.y - lineHeight / 2,
          textboxWidth,
          textboxHeight,
        );
      }

      if (this.hasExplicitLoops()) {
        // Need background as well, but just lighter and with
        // transparency to allow the loops to be visible.
        ctx.fillStyle = lighten(this.baseColor(context, args), 0, 0.75);
        ctx.globalAlpha = 1;
        ctx.fillRect(
          center.x - textboxWidth / 2,
          center.y - lineHeight / 2,
          textboxWidth,
          textboxHeight,
        );
      }

      const contentByLine: Content[][] = [];
      let currentLine: Content[] = [];
      for (const { color, text, noLineBreak, rotate } of contents) {
        currentLine.push({ color, text, noLineBreak, rotate });
        if (!noLineBreak) {
          contentByLine.push(currentLine);
          currentLine = [];
        }
      }

      let verticalPosition = center.y;

      for (const line of contentByLine) {
        let horizontalPosition = center.x;
        const lineWidth = line
          .map((content) => ctx.measureText(content.text).width)
          .reduce((a, b) => a + b, 0);

        for (let i = 0; i < line.length; i++) {
          const { color, text, rotate } = line[i];
          const { width: textWidth } = ctx.measureText(text);
          ctx.fillStyle = color;
          horizontalPosition -= i === 0 ? lineWidth / 2 : 0;

          if (rotate && rotate !== 0) {
            const oldTransform = ctx.getTransform();
            const rotationAngle = rotate * (Math.PI / 180);

            ctx.translate(
              horizontalPosition + textWidth / 2,
              verticalPosition + lineHeight / 2,
            );
            ctx.rotate(rotationAngle);
            ctx.fillTextStable(text, -textWidth / 2, -lineHeight / 2);
            ctx.setTransform(oldTransform);
          } else {
            // Draw text normally
            ctx.fillTextStable(
              text,
              horizontalPosition,
              verticalPosition + lineHeight / 2,
            );
          }
          horizontalPosition += textWidth;
        }

        verticalPosition += lineHeight;
      }

      const textboxBorder = 100;
      const x1 = bestPoint.center.x - textboxWidth / 2 - textboxBorder;
      const y1 = bestPoint.center.y - textboxBorder;
      const x2 = bestPoint.center.x + textboxWidth / 2 + textboxBorder;
      const y2 = bestPoint.center.y + textboxHeight + textboxBorder;

      const polygon = new Flatten.Polygon();
      polygon.addFace([
        Flatten.point(x1, y1),
        Flatten.point(x2, y1),
        Flatten.point(x2, y2),
        Flatten.point(x1, y2),
      ]);
      return [polygon];
    }
    return [];
  }

  // TODO: SEED-1229 put these into a trait - UnderfloorSite
  ufhLoopToDraw(): UnderfloorHeatingCalc | null {
    const globalStore: GlobalStore = this.globalStore;
    const liveCalcs = globalStore.getOrCreateLiveCalculation<RoomEntity>(
      this.entity,
    );
    const calcs = globalStore.getOrCreateCalculation<RoomEntity>(this.entity);
    const ufh = liveCalcs.underfloorHeating || calcs.underfloorHeating;

    if (ufh && ufh.loopSpacingMM) {
      return ufh;
    }

    return null;
  }

  hasExplicitLoops() {
    return (
      this.ufhLoopToDraw()?.loopMode === "full" &&
      this.context.featureAccess.fullUnderfloorHeatingLoops
    );
  }

  shouldDrawHatch(args: EntityDrawingArgs): DrawHatchResult {
    if (this.entity.room.roomType !== RoomType.ROOM) {
      return { draw: false };
    }

    if (
      this.context.featureAccess.fullUnderfloorHeatingLoops &&
      isSelectRoomForManifoldTool(this.document.uiState.toolHandler)
    ) {
      const manifoldUid = this.document.uiState.toolHandler.manifoldUid;
      if (isRoomRoomEntity(this.entity)) {
        const roomRoom = this.entity.room;
        if (!roomRoom.underfloorHeating.manifoldUid) {
          return { draw: false };
        }
        const manifold = this.globalStore.get<CorePlant>(
          roomRoom.underfloorHeating.manifoldUid,
        );
        if (!manifold || !isManifold(manifold.entity)) {
          return { draw: false };
        }

        const isSelectedInTool =
          roomRoom.underfloorHeating.manifoldUid === manifoldUid;

        return {
          draw: true,
          color: manifold.entity.plant.color.hex,
          pattern: !isSelectedInTool,
        };
      }
    }

    if (this.hasExplicitLoops()) {
      return { draw: false };
    }

    // show hatch when the associated plant is selected
    if (this.document.uiState.selectedUids.length === 1) {
      const obj = this.globalStore.get(this.document.uiState.selectedUids[0]);

      if (
        obj.type === EntityType.PLANT &&
        isRoomAssociatedPlant(obj.entity.plant)
      ) {
        if (isDualSystemNodePlant(obj.entity.plant)) {
          const drawHeating =
            obj.entity.plant.heatingInletUid !== null &&
            obj.entity.plant.heatingRooms?.includes(this.uid);
          const drawCooling =
            obj.entity.plant.chilledInletUid !== null &&
            obj.entity.plant.chilledRooms?.includes(this.uid);

          const color = obj.entity.plant.color.hex;
          return {
            draw: Boolean(drawHeating || drawCooling),
            color,
            pattern: false,
          };
        }

        if (obj.entity.plant.type === PlantType.MANIFOLD) {
          if (obj.uid === this.entity.room.underfloorHeating.manifoldUid) {
            return {
              draw: true,
              color: obj.entity.plant.color.hex,
              pattern: !args.forExport,
            };
          }
        }
      }
    }

    const manifoldUid = this.entity.room.underfloorHeating.manifoldUid;
    if (manifoldUid) {
      const manifold = this.globalStore.get<CorePlant>(manifoldUid);
      if (manifold && manifold.entity.plant?.type === PlantType.MANIFOLD) {
        if (
          this.drawing.metadata.roomResultsSettings.underfloorHeating ||
          this.document.uiState.propertiesPath === UNDERFLOOR_HEATING_TAB_ID
        ) {
          {
            return {
              draw: true,
              color: manifold.entity.plant.color.hex,
              pattern: !args.forExport,
            };
          }
        }
      }
    }

    return { draw: false };
  }

  baseColor(context: DrawingContext, args: { selected: boolean }) {
    const filledRoom = fillDefaultRoomFields(context, this.entity);

    let defaultLerpColor = "#FFFF00";
    switch (filledRoom.room.roomType) {
      case RoomType.ROOF:
        defaultLerpColor = "#C5C5C5";
        break;
      case RoomType.ROOM:
        break;
      default:
        assertUnreachable(filledRoom.room);
    }

    let baseColor = filledRoom.color!.hex;
    if (this.isHovering) {
      baseColor = lerpColor(baseColor, defaultLerpColor, 0.05);
    } else if (args.selected) {
      baseColor = lerpColor(baseColor, defaultLerpColor, 0.1);
    }
    return baseColor;
  }

  getTextLabelAccentColor(context: CoreContext) {
    let ratio: number | null = null;
    const system =
      context.drawing.metadata.flowSystems[this.document.activeflowSystemUid];
    if (
      this.document.uiState.drawingLayout === "mechanical" &&
      this.document.uiState.drawingMode === DrawingMode.Design
    ) {
      const calcs = context.globalStore.getOrCreateLiveCalculation(this.entity);
      if (isHeatingPlantSystem(system)) {
        if (calcs.totalHeatLossWatt !== null) {
          ratio =
            (calcs.totalHeatLossAddressedWATT ?? 0) / calcs.totalHeatLossWatt;
        }
      } else if (isCoolingPlantSystem(system)) {
        if (calcs.totalHeatGainWatt !== null) {
          ratio =
            (calcs.totalHeatGainAddressedWATT ?? 0) / calcs.totalHeatGainWatt;
        }
      }
    }

    if (
      this.document.uiState.drawingLayout === "ventilation" &&
      this.document.uiState.drawingMode === DrawingMode.Design
    ) {
      const calcs = context.globalStore.getOrCreateLiveCalculation(this.entity);
      if (system.role === "vent-supply") {
        if (
          calcs.zoneSupplyVentFlowRateAddressedLS != null &&
          calcs.zoneVentFlowRateLS != null
        ) {
          ratio =
            calcs.zoneSupplyVentFlowRateAddressedLS / calcs.zoneVentFlowRateLS;
        }
      } else if (
        system.role === "vent-extract" ||
        system.role === "vent-fan-exhaust"
      ) {
        if (
          calcs.zoneExtractVentFlowRateAddressedLS != null &&
          calcs.zoneVentFlowRateLS != null
        ) {
          ratio =
            calcs.zoneExtractVentFlowRateAddressedLS / calcs.zoneVentFlowRateLS;
        }
      }
    }
    if (ratio !== null) {
      // merge the color between red and yellow for 0 to < 100, then green at 100 or above
      const a = { r: 255, g: 68, b: 68 };
      const b = { r: 255, g: 204, b: 39 };
      const color =
        ratio < 1 - EPS
          ? `rgba(${lerp(a.r, b.r, ratio)}, ${lerp(a.g, b.g, ratio)}, ${lerp(
              a.b,
              b.b,
              ratio,
            )}, 0.8)`
          : "rgba(75,181,67,0.8)";

      return color;
    }

    return null;
  }

  // null indicates loading
  hatchedPatternData: Map<string, CanvasPattern | null> = new Map();
  async ensurePatternLoaded(ctx: CanvasRenderingContext2D, color: string) {
    if (!this.hatchedPatternData.has(color)) {
      this.hatchedPatternData.set(color, null);
      const pattern = await new Promise<CanvasPattern | null>((res, rej) => {
        const img = new Image();
        img.onerror = rej;
        img.src = `data:image/svg+xml;base64,${btoa(
          ROOM_PATTERNS.HATCHED(color),
        )}`;
        img.onload = () => {
          res(ctx.createPattern(img, "repeat"));
          MainEventBus.$emit("redraw");
        };
      });

      if (pattern) {
        this.hatchedPatternData.set(color, pattern);
      }
    }
    return this.hatchedPatternData.get(color);
  }

  private isPdfSnapshotViewActive(context: DrawingContext) {
    return context.doc.uiState.toolHandlerName === "pdf-snapshot";
  }

  drawRoomEntity(context: DrawingContext, args: EntityDrawingArgs) {
    const { ctx } = context;

    const baseColor = this.baseColor(context, args);
    const baseWidth = 0.1;
    ctx.lineWidth = baseWidth;
    ctx.beginPath();

    const vertices = this.collectVerticesInOrder();

    const pointsWC: Coord[] = vertices.map((v: CoreVertex) => v.toWorldCoord());
    const hasParent = vertices.reduce(
      (acc, v) => acc || !!v.entity.parentUid,
      false,
    );
    if (pointsWC.length) pointsWC.push(pointsWC[0]);
    for (let i = 0; i < pointsWC.length; i++) {
      if (i === 0) {
        ctx.moveTo(pointsWC[i].x, pointsWC[i].y);
      } else {
        ctx.lineTo(pointsWC[i].x, pointsWC[i].y);
      }
    }

    const globalAlpha = ctx.globalAlpha;
    const filter = ctx.filter;
    const globalCompositeOperation = ctx.globalCompositeOperation;

    ctx.closePath();

    // Draw the building/floor info
    if (shouldShowRoomPlanOnLevelAbove(this.uid, context, context.doc)) {
      ctx.fillStyle = "#777777";
      ctx.strokeStyle = "#F00";
      ctx.setLineDash([5, 5]);
      if (this.isActive()) {
        ctx.globalAlpha =
          context.drawing.metadata.heatLoss.roomsBelowTransparentPct / 100;
      } else {
        ctx.globalAlpha = 0.0;
      }
      ctx.fill();

      ctx.globalAlpha = globalAlpha;
      ctx.filter = filter;
    } else {
      ctx.strokeStyle = baseColor;
      ctx.fillStyle = baseColor;

      if (hasParent) {
        if (!this.isActive()) {
          ctx.globalAlpha = 0.3;
        } else {
          if (
            this.document.uiState.toolHandlerName ||
            this.document.uiState.draggingEntities.length > 0
          ) {
            ctx.globalAlpha = 0.3;
          } else {
            ctx.globalAlpha = 0.7;
          }
        }
      } else {
        // If we aren't on a background, we ARE the background and should be solid. However, let a little bit through for the below floor.
        ctx.globalAlpha = 0.9;
      }

      if (this.isHovering || args.selected) {
        ctx.globalAlpha = 0.4;
      }

      ctx.fill();

      const drawHatch = this.shouldDrawHatch(args);
      if (drawHatch.draw) {
        const screenScale = context.vp.currToScreenScale(ctx);
        const color = drawHatch.color;

        if (screenScale < 0.03 || !drawHatch.pattern) {
          // when zoomed out, draw solid instead of the blurry pattern
          const finalColor = lerpColor(color, baseColor, 0.8);
          ctx.fillStyle = finalColor;
          ctx.fill();
        } else if (drawHatch.pattern) {
          this.ensurePatternLoaded(ctx, color);
          if (this.hatchedPatternData.get(color)) {
            ctx.fillStyle = this.hatchedPatternData.get(color)!;
            ctx.fill();
          }
        }
      }

      this.drawUFHLoops(context, args);

      this.drawRoomTextContent(context, args, pointsWC);
    }

    ctx.globalAlpha = globalAlpha;
    ctx.filter = filter;
    ctx.globalCompositeOperation = globalCompositeOperation;
    ctx.setLineDash([]);
  }

  drawRoomTextContent(
    context: DrawingContext,
    args: EntityDrawingArgs,
    pointsWC?: Coord[],
  ) {
    if (!isRoomRoomEntity(this.entity)) return;

    if (!pointsWC) {
      const vertices = this.collectVerticesInOrder();
      pointsWC = vertices.map((v: CoreVertex) => v.toWorldCoord());
    }
    const ctx = context.ctx;
    const roomResults = this.getRoomResults(context, args);
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const generateRoomContentInfo = (
      context: DrawingContext,
      filledRoom: RoomEntity,
    ): ContentsOnRoom => {
      if (filledRoom.room.roomType !== RoomType.ROOM) {
        // TODO: Roofs have a separate text drawing function which should be refactored to be here.
        return [];
      }

      const ret: { color: string; text: string }[] = [];
      const textColor = this.getTextLabelAccentColor(context) ?? "#000000";
      const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
        this.entity,
      );

      if (!this.isRoomSpaceTypeValid()) {
        ret.push(
          {
            color: "red",
            text: "Invalid Room Type!",
          },
          {
            color: "red",
            text: "Please update room properties under Flow Rates tab",
          },
        );
        return ret;
      }

      const PLACEHOLDER = "__";

      // room name and temperature
      if (roomResults.roomName || roomResults.roomTemperature) {
        let text = "";

        if (roomResults.roomName) {
          text += liveCalcs.reference ?? filledRoom.entityName + PLACEHOLDER;
        }

        if (roomResults.roomTemperature) {
          const temperature = createEntryWithUnit(
            context.drawing.metadata.units,
            filledRoom.room.roomTemperatureC ?? 0,
            Units.Celsius,
            0,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += ` ${temperature}`;
        }

        ret.push({ color: textColor, text });
      }

      // room height, area, and volume
      if (
        roomResults.roomHeight ||
        roomResults.roomArea ||
        roomResults.roomVolume
      ) {
        let text = "";

        if (roomResults.roomHeight) {
          const roomHeight = createEntryWithUnit(
            context.drawing.metadata.units,
            filledRoom.room.roomHeightM ?? 0,
            Units.Meters,
            2,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += `${roomHeight}↑`;
        }

        if (roomResults.roomArea) {
          const roomArea = createEntryWithUnit(
            context.drawing.metadata.units,
            this.getRoomAreaM2(),
            Units.SquareMeters,
            2,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += ` ${roomArea}`;
        }

        if (roomResults.roomVolume) {
          const roomVolume = createEntryWithUnit(
            context.drawing.metadata.units,
            liveCalcs.volumeM3,
            Units.CubicMeters,
            2,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += ` ${roomVolume}`;
        }

        ret.push({ color: textColor, text });
      }

      // vent air changes rate
      if (roomResults.ventAirChanges) {
        const ventAirChangeRate = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.ventAirChangeRatePerHour,
          Units.None,
          2,
          UnitsContext.NONE,
          PLACEHOLDER,
        );
        const ventFlowRate = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.ventilationFlowRateLS,
          Units.LitersPerSecond,
          2,
          UnitsContext.VENTILATION,
          PLACEHOLDER,
        );

        ret.push({
          color: textColor,
          text: `Vent: ${ventFlowRate} (${ventAirChangeRate} ACH)`,
        });
      }

      // heating air changes rate
      if (roomResults.heatingAirChanges) {
        const heatingAirChangeRate = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.heatingAirChangeRatePerHour,
          Units.None,
          2,
          UnitsContext.NONE,
          PLACEHOLDER,
        );
        const heatingFlowRate = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.heatingFlowRateLS,
          Units.LitersPerSecond,
          2,
          UnitsContext.VENTILATION,
          PLACEHOLDER,
        );

        ret.push({
          color: textColor,
          text: `Heating: ${heatingFlowRate} (${heatingAirChangeRate} ACH)`,
        });
      }

      // heat loss
      if (roomResults.heatLoss) {
        let text = "Loss: ";

        const heatLoss = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.totalHeatLossWatt,
          Units.Watts,
          2,
          UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
          PLACEHOLDER,
        );

        text += heatLoss;

        if (roomResults.heatLoadPerArea) {
          const areaM2 = this.getRoomAreaM2();
          const heatLossW_M2 = liveCalcs.totalHeatLossWatt
            ? liveCalcs.totalHeatLossWatt / areaM2
            : null;

          const heatLossArea = createEntryWithUnit(
            context.drawing.metadata.units,
            heatLossW_M2,
            Units.WattsPerSquareMeter,
            2,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += ` (${heatLossArea})`;
        }

        ret.push({ color: textColor, text });
      }

      // heat supplied
      if (roomResults.heatSupplied) {
        const heatSupplied = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.totalHeatLossAddressedWATT,
          Units.Watts,
          2,
          UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
          PLACEHOLDER,
        );

        ret.push({ color: textColor, text: `Heat Supplied: ${heatSupplied}` });
      }

      // heat gain
      if (roomResults.heatGain) {
        let text = "Gain: ";

        const heatGain = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.totalHeatGainWatt,
          Units.Watts,
          2,
          UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
          PLACEHOLDER,
        );

        text += heatGain;

        if (roomResults.heatLoadPerArea) {
          const areaM2 = this.getRoomAreaM2();
          const heatGainW_M2 = liveCalcs.totalHeatGainWatt
            ? liveCalcs.totalHeatGainWatt / areaM2
            : null;

          const heatGainedPerArea = createEntryWithUnit(
            context.drawing.metadata.units,
            heatGainW_M2,
            Units.WattsPerSquareMeter,
            2,
            UnitsContext.NONE,
            PLACEHOLDER,
          );

          text += ` (${heatGainedPerArea})`;
        }

        ret.push({ color: textColor, text });
      }

      // cooling supplied
      if (roomResults.coolingSupplied) {
        const coolingSupplied = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.totalHeatGainAddressedWATT,
          Units.Watts,
          2,
          UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
          PLACEHOLDER,
        );

        const text = `Cooling Supplied: ${coolingSupplied}`;
        ret.push({ color: textColor, text });
      }

      // unheated area
      if (roomResults.unheatedArea) {
        const unheatedArea = createEntryWithUnit(
          context.drawing.metadata.units,
          liveCalcs.underfloorHeating.unheatedAreaM2,
          Units.SquareMeters,
          2,
          UnitsContext.NONE,
          PLACEHOLDER,
        );

        ret.push({
          color: textColor,
          text: `Unheated Area: ${unheatedArea}`,
        });
      }

      return ret;
    };

    /**
     * Finished of layout of room, draw the associated content associated to room
     */
    const filledRoom = fillDefaultRoomFields(context, this.entity);
    const usedSpace = this.drawRoomDisplayInfo(
      context,
      args,
      ctx,
      generateRoomContentInfo(context, filledRoom),
      pointsWC,
      "room",
    );

    // Now do it for the loops.

    const system =
      context.drawing.metadata.flowSystems[this.document.activeflowSystemUid];

    const textColor = "#000000";
    let supplyColor = "#000000";
    let extractColor = "#000000";

    // merge the color between red and yellow for 0 to < 100, then green at 100 or above
    const red = "#ff4444";
    const yellow = "#ffcc27";
    const green = "#4bb543";

    if (
      liveCalcs.isZoneLeader &&
      liveCalcs.zoneSupplyVentFlowRateAddressedLS != null &&
      liveCalcs.zoneVentFlowRateLS != null
    ) {
      const ratio =
        liveCalcs.zoneSupplyVentFlowRateAddressedLS /
        liveCalcs.zoneVentFlowRateLS;
      supplyColor = ratio < 1 - EPS ? lerpColor(red, yellow, ratio) : green;
    }

    if (
      liveCalcs.isZoneLeader &&
      liveCalcs.zoneExtractVentFlowRateAddressedLS != null &&
      liveCalcs.zoneVentFlowRateLS != null
    ) {
      const ratio =
        liveCalcs.zoneExtractVentFlowRateAddressedLS /
        liveCalcs.zoneVentFlowRateLS;
      extractColor = ratio < 1 - EPS ? lerpColor(red, yellow, ratio) : green;
    }

    const generateBuildingContentInfo = (
      context: DrawingContext,
    ): ContentsOnRoom => {
      const buildingContent: ContentsOnRoom = [];
      if (isVentilation(system)) {
        if (!liveCalcs.isZoneLeader || !liveCalcs.zoneVentFlowRateLS) {
          return [];
        }

        buildingContent.push({
          color: textColor,
          text: `Zone Ventilation: ${createEntryWithUnit(
            context.drawing.metadata.units,
            liveCalcs.zoneVentFlowRateLS,
            Units.LitersPerSecond,
            2,
            UnitsContext.VENTILATION,
          )}`,
        });

        if (liveCalcs.zoneSupplyVentFlowRateAddressedLS != null) {
          buildingContent.push({
            color: supplyColor,
            text: `Supplied: ${createEntryWithUnit(
              context.drawing.metadata.units,
              liveCalcs.zoneSupplyVentFlowRateAddressedLS,
              Units.LitersPerSecond,
              2,
              UnitsContext.VENTILATION,
            )}`,
          });
        }

        if (liveCalcs.zoneExtractVentFlowRateAddressedLS != null) {
          buildingContent.push({
            color: extractColor,
            text: `Extracted: ${createEntryWithUnit(
              context.drawing.metadata.units,
              liveCalcs.zoneExtractVentFlowRateAddressedLS,
              Units.LitersPerSecond,
              2,
              UnitsContext.VENTILATION,
            )}`,
          });
        }
      } else {
        if (liveCalcs.isLeaderRoomPerFloor && liveCalcs.buildingHeatLoadInfo) {
          if (roomResults.roomArea) {
            buildingContent.push({
              color: textColor,
              text: `Building Total Area: ${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.buildingHeatLoadInfo.areaM2,
                Units.SquareMeters,
                2,
                UnitsContext.NONE,
              )}`,
            });
          }

          if (roomResults.roomVolume) {
            buildingContent.push({
              color: textColor,
              text: `Building Total Volume: ${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.buildingHeatLoadInfo.volumeM3,
                Units.CubicMeters,
                2,
                UnitsContext.NONE,
              )}`,
            });
          }

          if (roomResults.heatLoss) {
            let text = `Building Heat Loss: ${createEntryWithUnit(
              context.drawing.metadata.units,
              liveCalcs.buildingHeatLoadInfo.heatLossWatt,
              Units.Watts,
              2,
              UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
            )}`;

            if (roomResults.heatLoadPerArea) {
              text += ` (${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.buildingHeatLoadInfo.heatLossWatt
                  ? liveCalcs.buildingHeatLoadInfo.heatLossWatt /
                      liveCalcs.buildingHeatLoadInfo.areaM2
                  : 0,
                Units.WattsPerSquareMeter,
                2,
                UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
              )})`;
            }
            buildingContent.push({
              color: textColor,
              text,
            });
          }

          if (roomResults.heatGain) {
            let text = `Building Heat Gain: ${createEntryWithUnit(
              context.drawing.metadata.units,
              liveCalcs.buildingHeatLoadInfo.heatGainWatt,
              Units.Watts,
              2,
              UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
            )}`;

            if (roomResults.heatLoadPerArea) {
              text += ` (${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.buildingHeatLoadInfo.heatGainWatt
                  ? liveCalcs.buildingHeatLoadInfo.heatGainWatt /
                      liveCalcs.buildingHeatLoadInfo.areaM2
                  : 0,
                Units.WattsPerSquareMeter,
                2,
                UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
              )})`;
            }

            buildingContent.push({
              color: textColor,
              text,
            });
          }
        }

        if (liveCalcs.floorHeadLoadInfo) {
          if (roomResults.heatLoss) {
            buildingContent.push({
              color: textColor,
              text: `Floor Heat Loss: ${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.floorHeadLoadInfo.heatLossWatt,
                Units.Watts,
                2,
                UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
              )}`,
            });
          }

          if (roomResults.heatGain) {
            buildingContent.push({
              color: textColor,
              text: `Floor Heat Gain: ${createEntryWithUnit(
                context.drawing.metadata.units,
                liveCalcs.floorHeadLoadInfo.heatGainWatt,
                Units.Watts,
                2,
                UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
              )}`,
            });
          }
        }
      }
      return buildingContent;
    };

    const buildingContent: ContentsOnRoom =
      generateBuildingContentInfo(context);
    if (buildingContent.length > 0) {
      const box = this.shape.box;
      const startingY = box.ymax + 1000;
      const xPos = (box.xmin + box.xmax) / 2;
      ctx.textBaseline = "middle";
      const fontSize = Math.max(
        100,
        Math.sqrt(
          Math.max(
            liveCalcs.buildingHeatLoadInfo
              ? liveCalcs.buildingHeatLoadInfo.areaM2
              : 0,
            liveCalcs.floorHeadLoadInfo
              ? liveCalcs.floorHeadLoadInfo.areaM2
              : 0,
          ) * 1e6,
        ) / 40,
      );

      ctx.font = fontSize + "px " + DEFAULT_FONT_NAME;
      const lineHeight = 1.2 * ctx.measureText("M").width;

      let maxWidth = 0;
      for (const { text } of buildingContent) {
        maxWidth = Math.max(maxWidth, ctx.measureText(text).width);
      }

      buildingContent.forEach(({ text, color }, index) => {
        ctx.fillStyle = color;
        const textWidth = ctx.measureText(text).width;
        const textX = xPos - textWidth / 2;
        ctx.fillTextStable(text, textX, startingY + lineHeight * index);
      });
    }

    const shouldDrawUFHLayoutLock = (() => {
      switch (context.doc.uiState.drawingMode) {
        case DrawingMode.Calculations:
        case DrawingMode.History:
        case DrawingMode.Design:
        case DrawingMode.FloorPlan:
          if (
            !isUnderfloor(
              getFlowSystem(
                context.doc.drawing,
                context.doc.activeflowSystemUid,
              ),
            )
          ) {
            return false;
          }
          if (args.forExport) {
            return false;
          }
          if (!isRoomRoomEntity(this.entity)) {
            return false;
          }
          return isRoomLoopLayoutFrozen(this.entity, context.featureAccess);
        case DrawingMode.Export:
          return false;
        default:
          assertUnreachableAggressive(context.doc.uiState.drawingMode);
      }
    })();

    if (shouldDrawUFHLayoutLock) {
      this.drawUFHLoopLayoutLock(context, usedSpace);
    }

    if (
      isRoomRoomEntity(this.entity) &&
      DrawableRoom.isUfhFullColor(
        context,
        args,
        this.entity.room.underfloorHeating.manifoldUid,
      )
    ) {
      DrawableRoom.drawLoopText(
        context,
        liveCalcs.underfloorHeating,
        usedSpace,
      );
    }
  }

  drawUFHLoopLayoutLock(context: DrawingContext, usedSpace: Flatten.Polygon[]) {
    const { ctx } = context;

    const existingFillStyle = ctx.fillStyle;
    const existingStrokeStyle = ctx.strokeStyle;
    const existingLineWidth = ctx.lineWidth;

    const lockWidthMM = 250;
    const lockHeightMM = 250;

    const center = (() => {
      if (usedSpace.length === 0) {
        const coords = this.collectVerticesInOrder().map((p) =>
          p.toWorldCoord(),
        );
        return flattenPoint2Coord(calculateCentroidCoord(coords));
      }
      const allVertices = usedSpace
        .flatMap((poly) => poly.vertices)
        .map(flattenPoint2Coord);
      const midpoint = coordMidpoint(...allVertices);
      const maxYOfUsedSpace = Math.max(
        ...allVertices.map((vertex) => vertex.y),
      );
      return {
        x: midpoint.x,
        y: maxYOfUsedSpace,
      };
    })();

    ctx.fillStyle = "black";
    const rectangleHeightMM = lockHeightMM * 0.6;
    const rectangleWidthMM = lockWidthMM;
    const rectangleTop = center.y + lockHeightMM / 2 - rectangleHeightMM;
    const rectangleLeft = center.x - rectangleWidthMM / 2;
    ctx.fillRect(
      rectangleLeft,
      rectangleTop,
      rectangleWidthMM,
      rectangleHeightMM,
    );

    const arcRadius = lockWidthMM / 4;
    const arcBottom = rectangleTop - arcRadius / 2;
    ctx.strokeStyle = "black";
    ctx.lineWidth = arcRadius * 0.6;
    ctx.beginPath();
    ctx.moveTo(center.x - arcRadius, rectangleTop);
    ctx.lineTo(center.x - arcRadius, arcBottom);
    ctx.arc(center.x, arcBottom, arcRadius, Math.PI, 2 * Math.PI);
    ctx.moveTo(center.x + arcRadius, arcBottom);
    ctx.lineTo(center.x + arcRadius, rectangleTop);
    ctx.stroke();

    ctx.fillStyle = existingFillStyle;
    ctx.strokeStyle = existingStrokeStyle;
    ctx.lineWidth = existingLineWidth;
  }

  static drawLoopText(
    context: DrawingContext,
    ufh: UnderfloorHeatingCalc,
    blocks: Array<Flatten.Polygon>,
  ) {
    const { ctx } = context;
    ctx.font = `150px ${DEFAULT_FONT_NAME_BOLD}`;
    for (const loop of ufh.loopsStats) {
      const idealPos = UnderfloorHeatingCalcs.getLoopCenter2D(loop.roomLoop);

      if (!idealPos) {
        continue;
      }
      let actualPos = idealPos;

      const [lengthUnits, lengthNum] = convertMeasurementSystem(
        context.drawing.metadata.units,
        Units.Meters,
        loop.lengthM!,
      );
      const lengthText = `${Math.round(lengthNum)}${lengthUnits}`;
      const [spaceUnits, spaceNum] = convertMeasurementSystem(
        context.drawing.metadata.units,
        // While the number is not pipe diameter, it is using similar approximation
        // with inches.
        Units.PipeDiameterMM,
        ufh.loopSpacingMM!,
      );
      const spaceText = `${spaceNum}${spaceUnits}`;

      const isOverlength = loop.lengthM! > loop.maxLoopLengthM!;
      const loopLabel = isOverlength
        ? `!! ${lengthText} @ ${spaceText}`
        : `${lengthText} @ ${spaceText}`;

      const width = ctx.measureText(loopLabel).width;
      const height = ctx.measureText("M").width * 1.2;
      const x1 = idealPos.x - width / 2;
      const y1 = idealPos.y - height / 2;
      const x2 = idealPos.x + width / 2;
      const y2 = idealPos.y + height / 2;
      const labelPolygon = new Flatten.Polygon();
      labelPolygon.addFace([
        Flatten.point(x1, y1),
        Flatten.point(x2, y1),
        Flatten.point(x2, y2),
        Flatten.point(x1, y2),
      ]);
      for (const block of blocks) {
        if (polygonPolygonIntersects(block, labelPolygon)) {
          const box = block.box;
          const candidates = [
            Flatten.point(box.xmin - width / 2, idealPos.y),
            Flatten.point(box.xmax + width / 2, idealPos.y),
            Flatten.point(idealPos.x, box.ymin - height / 2),
            Flatten.point(idealPos.x, box.ymax + height / 2),
            // Flatten.point(box.xmin, box.ymin),
            // Flatten.point(box.xmax, box.ymax),
          ];
          let bestPoint = candidates[0];
          let bestDist = Infinity;
          for (const candidate of candidates) {
            const dist = coordDist2(idealPos, candidate);
            if (dist < bestDist) {
              bestDist = dist;
              bestPoint = candidate;
            }
          }
          actualPos = bestPoint;
        }
      }

      // this will be on top of a loop so must have a background.
      ctx.fillStyle = `rgba(255, 255, 255, 0.9)`;
      ctx.globalAlpha = 1;
      ctx.fillRect(
        actualPos.x - width / 2 - 10,
        actualPos.y - height / 2 - 10,
        width + 20,
        height + 20,
      );
      ctx.fillStyle = lerpColor(loop.color ?? "#000", "#000", 0.2);
      ctx.fillTextStable(
        loopLabel,
        actualPos.x - width / 2,
        actualPos.y,
        undefined,
        "middle",
      );

      // Draw red border if overlength
      if (isOverlength) {
        ctx.strokeStyle = "red";
        ctx.lineWidth = 10;
        ctx.strokeRect(
          actualPos.x - width / 2 - 20,
          actualPos.y - height / 2 - 20,
          width + 40,
          height + 40,
        );

        // Draw the first two !!'s red
        ctx.fillStyle = "red";
        ctx.fillTextStable(
          "!!",
          actualPos.x - width / 2,
          actualPos.y,
          undefined,
          "middle",
        );
      }
    }
  }

  private getRoomResults(context: DrawingContext, args: EntityDrawingArgs) {
    if (this.isPdfSnapshotViewActive(context) || args.forExport) {
      return context.doc.uiState.exportSettings.roomResultsSettings;
    } else {
      return context.drawing.metadata.roomResultsSettings;
    }
  }

  static isUfhFullColor(
    context: DrawingContext,
    args: EntityDrawingArgs,
    manifoldUid: string | null,
  ) {
    if (context.doc.uiState.drawingMode === DrawingMode.FloorPlan) {
      return true;
    }

    const flowSystem = getUFHLoopDesignFlowSystem(context, manifoldUid);
    if (
      flowSystem &&
      context.doc.uiState.drawingMode === DrawingMode.Design &&
      isUnderfloor(
        getFlowSystem(context.doc.drawing, context.doc.activeflowSystemUid),
      )
    ) {
      return true;
    }

    if (
      context.doc.uiState.drawingMode === DrawingMode.Calculations &&
      context.doc.uiState.drawingLayout === "mechanical"
    ) {
      return true;
    }

    if (args.forExport) {
      return true;
    }
    return false;
  }

  drawUFHLoops(context: DrawingContext, args: EntityDrawingArgs) {
    const ufh = this.ufhLoopToDraw();
    const ufhSystemHidden =
      context.doc.uiState.systemFilter.hiddenSystemUids.includes(
        StandardFlowSystemUids.UnderfloorHeating,
      );
    if (ufh && isRoomRoomEntity(this.entity) && !ufhSystemHidden) {
      const filledRoom = fillDefaultRoomFields(context, this.entity);
      DrawableRoom.drawUFHLoops(
        context,
        args,
        ufh.loopsStats,
        filledRoom.room.underfloorHeating,
        this,
      );
    }

    const calc = this.globalStore.getOrCreateCalculation(this.entity);
    const { ctx } = context;
    if (
      calc.debug &&
      context.featureAccess.fullUnderfloorHeatingLoops &&
      context.doc.uiState.xMode
    ) {
      const allRoles = ((window as any).H2X_debug_roles =
        (window as any).H2X_debug_roles || new Set<string>());
      for (const loop of calc.debug) {
        if (loop.role) {
          allRoles.add(loop.role);
        }
      }

      const roleIsGood = (role: string | undefined) =>
        !context.doc.uiState.xModeRoles ||
        (role && context.doc.uiState.xModeRoles.includes(role));

      const width = context.vp.toWorldLength(3);
      ctx.setLineDash([width * 2, width]);
      ctx.lineWidth = width;
      for (const loop of calc.debug) {
        if (!roleIsGood(loop.role)) continue;

        ctx.setLineDash(
          loop.dash ? loop.dash.map((v) => v * width) : [width * 2, width],
        );
        if (loop.thickness) {
          ctx.lineWidth = loop.thickness * width;
        } else {
          ctx.lineWidth = width;
        }
        ctx.strokeStyle = loop.color;
        ctx.fillStyle = loop.color;
        if (loop.chain.length < 2) continue;
        ctx.beginPath();
        const start = loop.chain[0];
        ctx.moveTo(start.x, start.y);
        for (const coord of loop.chain.slice(1)) {
          ctx.lineTo(coord.x, coord.y);
        }
        ctx.stroke();
        // draw dot at start
        ctx.beginPath();
        ctx.arc(start.x, start.y, width, 0, 2 * Math.PI);
        ctx.fill();

        // draw text
        if (loop.text) {
          ctx.font = `${width * 5}px Arial`;
          const dirVec = Flatten.vector(
            Flatten.point(loop.chain[0].x, loop.chain[0].y),
            Flatten.point(loop.chain[1].x, loop.chain[1].y),
          );
          const normalized = dirVec.length < 1e-6 ? dirVec : dirVec.normalize();
          ctx.fillTextStable(
            loop.text,
            start.x + normalized.x * (50 + dirVec.length * 0.05),
            start.y + normalized.y * (50 + dirVec.length * 0.05),
          );
        }
      }
      ctx.setLineDash([]);

      // Draw arrows in the midpoints
      for (const loop of calc.debug) {
        if (!roleIsGood(loop.role)) continue;

        for (let i = 0; i < loop.chain.length - 1; i++) {
          const start = loop.chain[i];
          const end = loop.chain[i + 1];
          const mid = {
            x: (start.x + end.x) / 2,
            y: (start.y + end.y) / 2,
          };
          ctx.strokeStyle = loop.color;
          ctx.fillStyle = loop.color;
          let vec = Flatten.vector(
            Flatten.point(start.x, start.y),
            Flatten.point(end.x, end.y),
          );
          if (vec.length < 1e-6) continue;
          vec = vec.normalize();
          const flange1 = vec.rotate(Math.PI * 0.75);
          const flange2 = vec.rotate(-Math.PI * 0.75);
          const arrowLength = width * 3;
          ctx.beginPath();
          ctx.moveTo(
            flange1.x * arrowLength + mid.x,
            flange1.y * arrowLength + mid.y,
          );
          ctx.lineTo(mid.x, mid.y);
          ctx.lineTo(
            flange2.x * arrowLength + mid.x,
            flange2.y * arrowLength + mid.y,
          );
          ctx.stroke();

          // Write a number as well to help debugging
          ctx.font = `${width * 5}px Arial`;
          const rotated = vec.rotate90CW();
          ctx.fillTextStable(
            i.toString(),
            mid.x + rotated.x * 10,
            mid.y + rotated.y * 10,
          );
        }
      }
    }
  }

  static drawUFHLoops(
    context: DrawingContext,
    args: EntityDrawingArgs,
    loopsStats: UnderfloorHeatingLoopCalculation[],
    designParameters: UFHLoopDesignParameters,
    object: PolygonObjectConcrete,
  ) {
    const { ctx } = context;

    if (
      context.featureAccess.fullUnderfloorHeatingLoops &&
      context.doc.uiState.xMode
    ) {
      // For debugging without turns
      object.withWorld(context, { x: 0, y: 0 }, () => {
        const width = context.vp.toWorldLength(2);
        ctx.setLineDash([]);
        ctx.strokeStyle = "blue";
        ctx.lineWidth = width;

        for (const loop of loopsStats) {
          const fullLoop = [
            ...loop.fromManifold,
            ...loop.roomLoop,
            ...loop.toManifold,
          ];
          if (fullLoop.length < 2) continue;
          ctx.beginPath();
          const start = fullLoop[0];
          ctx.moveTo(start.x, start.y);
          for (const coord of fullLoop.slice(1)) {
            ctx.lineTo(coord.x, coord.y);
          }
          ctx.stroke();

          // Label each point numerically for debugging
          for (let i = 0; i < fullLoop.length; i++) {
            const c = fullLoop[i];
            ctx.fillStyle = "purple";
            ctx.beginPath();
            ctx.arc(c.x, c.y, width, 0, 2 * Math.PI);
            ctx.fill();
            ctx.fillStyle = "black";
            ctx.font = `${width * 5}px Arial`;
            ctx.fillText(i.toString(), c.x, c.y);
          }
        }
      });
    }

    object.withWorld(context, { x: 0, y: 0 }, () => {
      const width = context.vp.surfaceToWorldLength(2);
      ctx.setLineDash([]);
      ctx.lineWidth = width;
      for (let i = 0; i < loopsStats.length; i++) {
        const loop = loopsStats[i];
        if (
          this.isUfhFullColor(context, args, designParameters.manifoldUid) &&
          loop.color
        ) {
          ctx.strokeStyle = loop.color;
        } else {
          ctx.strokeStyle = lerpCSSColor(
            object.baseColor(context, args),
            "#000",
            0.1,
          );
        }

        const loopBreakdown = fullLoopBreakdown2D(loop);
        if (object.uid.includes("313fdf4c-ffde-4e4c-93c3-520d064f9774")) {
          console.log("Target room loop", loop);
          console.log("Target room loop breakdown", loopBreakdown);
        }
        if (!loopBreakdown.length) {
          continue;
        }

        ctx.beginPath();
        let isFirst = true;
        for (const segment of loopBreakdown) {
          this.drawUfhLoopSegment(ctx, segment, isFirst);
          isFirst = false;
        }
        ctx.stroke();
      }

      ctx.lineWidth = width;
    });
  }

  static drawUfhLoopSegment(
    ctx: CanvasRenderingContext2D,
    segment: LoopBreakdown2D,
    isFirst: boolean,
  ): void {
    switch (segment.type) {
      case "straight":
        if (isFirst) {
          ctx.moveTo(segment.coords[0].x, segment.coords[0].y);
        }
        ctx.lineTo(segment.coords[1].x, segment.coords[1].y);
        break;
      case "bend":
        ctx.arc(
          segment.center.x,
          segment.center.y,
          segment.radiusMM,
          segment.startAngleRAD,
          segment.endAngleRAD,
          !segment.isCw,
        );
        break;
    }
  }

  drawRoofEntity(
    context: DrawingContext,
    args: EntityDrawingArgs,
    filledRoomConcrete: RoofEntityConcrete,
  ) {
    if (shouldShowRoomPlanOnLevelAbove(this.uid, context, context.doc)) return;
    const baseColor = this.baseColor(context, args);

    const { ctx } = context;
    const oldTransform = ctx.getTransform();
    const roofSegment = new RoofSegmentation(this, filledRoomConcrete);
    const sections = roofSegment.breakRoofToPolygonSegments();

    // Iterate over each section
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const polygon = section.polygonCw;
      if (polygon.length < 3) continue;

      ctx.strokeStyle = "#000000";
      ctx.lineWidth = 40;

      ctx.beginPath();
      ctx.moveTo(polygon[0].x, polygon[0].y);
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(polygon[i].x, polygon[i].y);
      }
      ctx.lineTo(polygon[0].x, polygon[0].y);

      ctx.closePath();
      ctx.stroke();

      // Hover select change precedence, lower globalAlpha to create transparent
      const globalAlpha = ctx.globalAlpha;
      if (this.isHovering || args.selected) {
        ctx.globalAlpha = 0.7;
      } else {
        ctx.globalAlpha = 0.5;
      }

      if (section.slopeDeg > 0) {
        const gradientCoords = getLinerGradientCoords(section);
        const gradient = ctx.createLinearGradient(
          gradientCoords.start.x,
          gradientCoords.start.y,
          gradientCoords.end.x,
          gradientCoords.end.y,
        );

        gradient.addColorStop(1, slopeToColor(section.slopeDeg));
        gradient.addColorStop(0, baseColor);
        ctx.fillStyle = gradient;
      } else {
        ctx.fillStyle = baseColor;
      }
      ctx.fill();
      ctx.globalAlpha = globalAlpha;

      const color = "#000000";
      const content: ContentsOnRoom = [
        {
          color,
          text: `Slope: ${section.slopeDeg.toFixed(2)}° `,
          noLineBreak: section.slopeDeg !== 0,
        },
        ...(section.slopeDeg !== 0
          ? [
              {
                color,
                text: `\u2191`,
                rotate: section.slopeDirectionDegCW + 90,
              },
            ]
          : []),
        {
          color,
          text: `Rooftop Area: ${createEntryWithUnit(
            context.drawing.metadata.units,
            section.roofAreaM2,
            Units.SquareMeters,
            2,
            UnitsContext.NONE,
          )}`,
        },
      ];
      if (section.externalWallAreaM2 > 0) {
        content.push({
          color,
          text: `External Wall Area: ${createEntryWithUnit(
            context.drawing.metadata.units,
            section.externalWallAreaM2,
            Units.SquareMeters,
            2,
            UnitsContext.NONE,
          )}`,
        });
      }
      if (section.volumeM3 > 0) {
        content.push({
          color,
          text: `Volume: ${createEntryWithUnit(
            context.drawing.metadata.units,
            section.volumeM3,
            Units.CubicMeters,
            2,
            UnitsContext.NONE,
          )}`,
        });
      }

      this.drawRoomDisplayInfo(
        context,
        args,
        ctx,
        content,
        section.polygonCw,
        "roof-section-" + i,
      );
    }

    ctx.setTransform(oldTransform);
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    const filledRoom = fillDefaultRoomFields(context, this.entity);
    context.vp.prepareContext(context.ctx);
    switch (filledRoom.room.roomType) {
      case RoomType.ROOF:
        this.drawRoofEntity(context, args, filledRoom.room);
        break;
      case RoomType.ROOM:
        this.drawRoomEntity(context, args);
    }
  }

  isActive(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return true;

      case DrawingMode.Design:
        if (this.document.uiState.drawingLayout === "mechanical") {
          return true;
        }
        return false;
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }

  isShapeable(): boolean {
    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
      case DrawingMode.Export:
      case DrawingMode.History:
      case DrawingMode.Calculations:
        return true;
      case DrawingMode.Design:
        return false;
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }

  inBounds(
    oc: Coord | Coord3D,
    _radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    const wc = this.toWorldCoord(oc);

    let inside = false;
    const wcPoint = new Flatten.Point(wc.x, wc.y);
    const line = new Flatten.Segment(
      wcPoint,
      new Flatten.Point(wc.x + 1e7, wc.y),
    );
    const intersections = shape.intersect(line);
    inside = intersections.length % 2 === 1;
    if (inside) {
      const vertices = shape.vertices;
      const segs: Flatten.Segment[] = [];
      for (let i = 0; i < vertices.length; i++) {
        const a = vertices[i],
          b = vertices[(i + 1) % vertices.length];
        const seg = new Flatten.Segment(a, b);
        segs.push(seg);
      }

      segs.forEach((seg) => {
        if (seg.distanceTo(wcPoint)[0] < 1) {
          inside = false;
        }
      });
    }
    return inside;
  }

  inBoundsWorld(
    wc: Coord,
    radius: number = 0,
    shape: Flatten.Polygon = this.shape,
  ): boolean {
    return this.inBounds(this.toObjectCoord(wc), radius, shape);
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [];
    this.collectFens().forEach((fen: CoreFen) => {
      const drawableFen = context.globalStore.get(fen.uid);
      if (drawableFen) {
        result.push(drawableFen);
      } else {
        throw new Error("Fen not found");
      }
    });
    this.collectWalls().forEach((wall: CoreWall) => {
      const drawableWall = context.globalStore.get(wall.uid);
      if (drawableWall) {
        result.push(drawableWall);
      } else {
        throw new Error("Wall not found");
      }
    });
    this.entity.edgeUid.forEach((edgeUid: string) => {
      if (context.globalStore.getPolygonsByEdge(edgeUid).length > 1) {
        return;
      }
      const edge = context.globalStore.get(edgeUid);

      if (edge) {
        result.push(edge);
      } else {
        throw new Error("Edge not found");
      }
    });
    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = context.globalStore.get(vertex.uid);
      if (context.globalStore.getPolygonsByVertex(vertex.uid).length > 1) {
        return;
      }
      if (drawableVertex) {
        result.push(drawableVertex);
      } else {
        throw new Error("Vertex not found");
      }
    });

    // rebase all entites that belong to me
    context.globalStore.forEach((v) => {
      if (v.entity.parentUid === this.entity.uid && isCenteredObject(v)) {
        v.debase(context);
      }
    });
    context.globalStore.getArcsByPolygon(this.uid).forEach((arc) => {
      result.push(context.globalStore.get(arc));
    });
    result.push(this);
    return result;
  }
  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) return null;
    switch (interaction.type) {
      case InteractionType.INSERT:
        return [this.entity];
      case InteractionType.SNAP_ONTO_RECEIVE:
      case InteractionType.EXTEND_NETWORK:
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.SNAP_ONTO_SEND:
        return null;
    }
    return null;
  }

  isValidIndependent() {
    // Can't have vertices that are too close together
    const vertices = this.collectVerticesInOrder();
    for (let i = 0; i < vertices.length; i++) {
      for (let j = i + 1; j < vertices.length; j++) {
        const a = vertices[i],
          b = vertices[j];
        if (coordDist2(a.toWorldCoord(), b.toWorldCoord()) < 1) {
          return false;
        }
      }
    }

    // needs at least 3 vertices
    if (vertices.length < 3) {
      return false;
    }

    return true;
  }

  isValid() {
    if (this.isOverlapping()) {
      return false;
    }
    if (!this.isValidIndependent()) {
      return false;
    }
    return true;
  }

  isOverlapping(
    pointArr: { point: Flatten.Point; uid: string }[] = [],
  ): boolean {
    return this.invalidOverlappingAreaM2(pointArr) > 0;
  }
  magicDragPolygon() {
    return this;
  }
  magicDragVertices() {
    return this.collectVerticesInOrder().map((v) => v.uid);
  }

  shouldShowDimension(context: DrawingContext) {
    if (this.document.uiState.drawingMode !== DrawingMode.FloorPlan) {
      return false;
    }

    if (context.selectedUids.has(this.uid)) return true;

    const ret =
      this.collectEdgesInOrder().some((coreEdge: CoreEdge) => {
        const entityUid = coreEdge.entity.uid;
        const drawableEdge = context.globalStore.get<DrawableEdge>(entityUid);
        if (context.selectedUids.has(drawableEdge.entity.uid)) {
          return true;
        }
        return false;
      }) ||
      this.collectVerticesInOrder().some((coreVertex: CoreVertex) => {
        const entityUid = coreVertex.entity.uid;
        const drawableEdge = context.globalStore.get<DrawableEdge>(entityUid);
        if (context.selectedUids.has(drawableEdge.entity.uid)) {
          return true;
        }
        return false;
      });
    return ret;
  }
  performMove(vec: Flatten.Vector) {
    const result: { point: Flatten.Point; uid: string }[] = [];
    this.collectVerticesInOrder().forEach((vertex: CoreVertex) => {
      const drawableVertex = this.globalStore.get<DrawableVertex>(vertex.uid);
      if (drawableVertex) {
        const wc = drawableVertex.toWorldCoord();
        const newPoint = new Flatten.Point(wc.x + vec.x, wc.y + vec.y);
        result.push({
          point: newPoint,
          uid: vertex.uid,
        });
      } else {
        throw new Error("Vertex not found");
      }
    });
    return result;
  }
  getCopiedObjects(): DrawableObjectConcrete[] {
    return this.collectEdgesInOrder().map((e) =>
      this.globalStore.get<DrawableEdge>(e.uid),
    );
  }

  get isInteractable(): boolean {
    const document: DocumentState = this.document;
    if (document.uiState.drawingMode === DrawingMode.FloorPlan) {
      return true;
    }

    return (
      document.uiState.drawingMode === DrawingMode.Design &&
      isUnderfloor(
        getFlowSystem(this.document.drawing, this.document.activeflowSystemUid),
      )
    );
  }
}
