import { Currency } from "../../lib/currency";
import {
  EN12056FrequencyFactor,
  PipePhysicalMaterial,
  SupportedPsdStandards,
} from "../config";
import {
  FilterConfiguration,
  GenericRadiatorRangeType,
  HotWaterPlantManufacturers,
  PumpConfiguration,
  RheemVariant,
  RheemVariantValues,
} from "../document/entities/plants/plant-types";
import { HeatLoadSpec } from "./heatload/types";
import { FixturesTable, PipesTable } from "./price-table";
import HCAAEquation from "./psd-standard/hcaaEquation";
import LoadingUnitHotColdTable from "./psd-standard/loading-unit-hot-cold-table";
import LoadingUnitMaxTable from "./psd-standard/loading-unit-max-table";
import LoadingUnitTable from "./psd-standard/loading-unit-table";
import PsdEquation from "./psd-standard/psdEquation";
import { DwellingStandardType } from "./psd-standard/types";
import { UnderfloorHeatingSpec } from "./underfloor-heating/ufh-types";
import { DuctFittingsCatalogPage } from "./ventilation/duct-fittings";
import { DuctCatalogPage } from "./ventilation/ducts";

export interface CatalogPrice {
  value: number;
  currency: Currency;
}

export interface LoadCatalogAPIResult {
  catalog: Catalog;
  upgradeCount: number;
}

export interface DwellingUnitHotColdTable {
  type: DwellingStandardType.DWELLING_HOT_COLD_LOOKUP_TABLE;
  name: string;
  hotColdTable: { [key: string]: { cold: string; hot: string } };
}

export interface DwellingEquation {
  type: DwellingStandardType.EQUATION;
  name: string;
  equation: string;
  variables: { [key: string]: string };
}

export type Diameter = number | string;
export type PSDSpec =
  | LoadingUnitTable
  | LoadingUnitHotColdTable
  | PsdEquation
  | LoadingUnitMaxTable
  | HCAAEquation;

export type DwellingSpec = DwellingUnitHotColdTable | DwellingEquation;

export interface UnderfloorHeatingSpecTable {
  type: "ufh-spec-table";
  name: string;
  uid: "ufh";
  manufacturer: Manufacturer<"Underfloor Heating">[];
  datasheet: {
    [key: ManufacturerUid]: UnderfloorHeatingData;
  };
}

export type FloorFinish =
  | "Type A - 0.1 TOG - plastic sheet, ceramic tiles, stone tiles"
  | "Type A - 0.5 TOG - parquet, cushioned linoleum, 75mm stone flooring"
  | "Type A - 1 TOG - carpet tiles, hardwood flooring"
  | "Type A - 1.5 TOG - carpet, 22mm timber laminate or wood block"
  | "Type B or Type B1 - 0 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors"
  | "Type B or Type B1 - 1 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors"
  | "Type B or Type B1 - 2 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors"
  | "Type B2 - 0 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors"
  | "Type B2 - 1 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors"
  | "Type B2 - 2 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors"
  | "Type B3 - 0 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors"
  | "Type B3 - 1 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors"
  | "Type B3 - 2 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors";

export type MeanWaterTempC = number;
export type RoomTempC = number;
export type PipeDiameterMM = number;
export type OutputWperM2 = number;
export type UnderfloorHeatingData = {
  [key in FloorFinish]: Partial<Record<PipePhysicalMaterial, UFHFloorData>>;
  // TODO: internal pipe diameter. Currently this is gotten from
  // the pipe material catalog which does not match.
};

export interface UFHFloorData {
  [key: PipeDiameterMM]: {
    [key: RoomTempC]: {
      [key: MeanWaterTempC]: {
        pipeSpacingMM: number;
        outputWperM2: OutputWperM2;
        floorTempC: number;
      }[];
    };
  };
}

export type HeatEmitterSpec = {
  radiators: RadiatorSpecTable;
  ufh: UnderfloorHeatingSpecTable;
};

export interface RadiatorSpecTable {
  type: "radiator-spec-table";
  name: string;
  uid: "radiators";
  manufacturer: RadiatorManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: RadiatorData[];
  } & {
    generic?: GenericRadiatorData[];
  };
}

export type GenericRadiatorData = {
  rangeType: GenericRadiatorRangeType;
  ratingDataType: "KWperM2";
  KWperM2ByDeltaT: Record<DeltaTC, number>;
  depthMM: number;
  model: string;
};

export type DeltaTC = number;

export type ManufacturerRadiatorData = {
  rangeType: string;
  model: string;
  widthMM: number;
  heightMM: number;
  depthMM: number;
  kvValue?: number;
  productKey?: string;
  volumeL?: number;

  price?: CatalogPrice;

  customManufFields?: {
    [key: string]: number | string;
  };
} & (
  | {
      ratingDataType: "kW";
      kWByDeltaT: Record<DeltaTC, number>;
    }
  | {
      ratingDataType: "correction-factor";
      correctionFactorByDeltaT: Record<DeltaTC, number>;
      nominalDeltaT50KW: number;
    }
  | {
      ratingDataType: "n-coefficient";
      nCoefficient: number;
      nominalDeltaT50KW: number;
    }
);

export type RadiatorData = ManufacturerRadiatorData | GenericRadiatorData;

export type RadiatorManufacturer = Manufacturer<"Radiator">;

export type ManufacturerUid = string;
export type FlowRateLS = number;
export type PumpPressureKPA = number;
export type ModelId = string;
export type TankCapacityL = number;
export type Reference = string;

export interface BackflowValveSize {
  sizeMM: string;
  minInletPressureKPA: string | null;
  maxInletPressureKPA: string | null;
  minFlowRateLS: string | null;
  maxFlowRateLS: string | null;
  pressureLossKPAByFlowRateLS: { [key: string]: string };
}

export type BackflowValveManufacturer = Manufacturer<"RPZD">;

export interface BackflowValveSpec {
  name: string;
  uid: string;
  abbreviation: string;
  manufacturer: BackflowValveManufacturer[];
  valvesBySize: { [key: string]: { [key: string]: BackflowValveSize } };
}

export type GreaseInterceptorTrapManufacturer = Manufacturer<
  "Generic" | "Viking"
>;

export enum GreaseInterceptorTrapLocationCategory {
  "belowGround" = "Below Ground",
  "aboveGround" = "Above Ground",
}

export interface GreaseInterceptorTrap {
  manufacturer: GreaseInterceptorTrapManufacturer[];
  location: {
    name: string;
    uid: string;
  }[];
  size: {
    [key: string]: {
      [key: string]: {
        [key in keyof typeof GreaseInterceptorTrapLocationCategory | string]: {
          [key: string]: {
            [key: string]: any;
            capacity: number;
            lengthMM: number;
            widthMM: number;
            heightMM: number;
            product?: string;
            brand?: string;
          };
        };
      };
    };
  };
}

export interface Catalog {
  fixtures: { [key: string]: FixtureSpec };
  pipes: { [key: string]: PipeMaterial };
  valves: { [key: string]: ValveSpec };
  mixingValves: { [key: string]: MixingValveSpec };
  backflowValves: { [key: string]: BackflowValveSpec };
  psdStandards: { [key: string]: PSDSpec };
  dwellingStandards: { [key: string]: DwellingSpec };
  en12056FrequencyFactor: { [key in EN12056FrequencyFactor]: number };
  gasDiversification: DwellingDiversificationTable;
  fluids: { [key: string]: FluidsSpec };
  prv: PRVSpec;
  balancingValves: BalancingValveSpec;
  hotWaterPlant: HotWaterPlant;
  greaseInterceptorTrap?: GreaseInterceptorTrap;
  floorWaste: FloorWasteSpec;
  inspectionOpening: InspectionOpeningSpec;
  pump: PumpSpec;
  pumpTank: PumpTankSpec;
  heatLoad: HeatLoadSpec;
  heatEmitters: HeatEmitterSpec;
  underfloorHeating: UnderfloorHeatingSpec;
  filters: { [key in FilterSpecKeys]: FilterSpec };
  roPlant: RoPlantSpec;
  ductFittings: DuctFittingsCatalogPage;
  ducts: DuctCatalogPage;
}

export interface DegreeDays {
  HDD: number;
  CDD: number;
  EWT: number;
  EST: number;
}

export type DwellingDiversificationTable = { [key: number]: number };

export interface PumpManufacturer extends Manufacturer<"Pump"> {
  componentName: string;
}

export interface PumpTankManufacturer extends Manufacturer<"Pump/Tank"> {
  componentName: string;
}

export type PumpLookupTable = {
  [key: PumpPressureKPA]: {
    // by pressure
    [key: FlowRateLS]: // by flow rate
    ModelId; // model
  };
};

export interface PumpTankModelCapacityDependent {
  type: "model-capacity-dependent";
  sizes: {
    [key: ModelId]: {
      widthMM: number;
      heightMM: number;
      depthMM?: number;
    };
  };

  configuration: Partial<
    Record<
      PumpConfiguration,
      {
        referenceLookup: {
          [key: PumpPressureKPA]: {
            [key: FlowRateLS]: Reference;
          };
        };
        capacityLookup: {
          [key: TankCapacityL]: {
            [key: Reference]: ModelId;
          };
        };
      }
    >
  >;
}

export interface PumpTankModelCapacityIndependent {
  type: "model-capacity-independent";

  sizes: {
    [key: TankCapacityL]: {
      // by capacity
      tankSize: number; // note: manufacturer specific.
      widthMM: number;
      heightMM: number;
      lengthMM: number;
    };
  };

  configuration: Partial<
    Record<
      PumpConfiguration,
      {
        [key: PumpPressureKPA]: {
          [key: FlowRateLS]: ModelId;
        };
      }
    >
  >;
}

export type PumpTankData =
  | PumpTankModelCapacityDependent
  | PumpTankModelCapacityIndependent
  | { type: "none" };

export interface PumpTableData {
  type: "sparse-table" | "dense-table";

  // data representation of sparse and dense table are the same. The
  // difference is only in the display.
  table: Partial<Record<PumpConfiguration, PumpLookupTable>>;
  customManufFields?: {
    variant?: "standard" | "premium";
  };
  // optional size look up table by model
  sizes?: {
    [key: ModelId]: {
      widthMM: number;
      heightMM: number;
    };
  };
}

export interface PumpNoData {
  type: "none";
}

export type PumpData = PumpTableData | PumpNoData;

export interface PumpSpec {
  manufacturer: PumpManufacturer[];

  datasheet: {
    [key: ManufacturerUid]: PumpData[];
  };
}

export interface PumpTankSpec {
  manufacturer: PumpTankManufacturer[];

  datasheet: {
    [key: ManufacturerUid]: PumpTankData;
  };
}

export type RoPlantManufacturer = Manufacturer<"RO Plant">;
export interface RoPlantSpec {
  manufacturer: RoPlantManufacturer[];

  datasheet: {
    [key: ManufacturerUid]: RoPlantData[];
  };
}

export interface RoPlantData {
  widthMM: number;
  depthMM: number;
  connectionHeightMM: number;
  model?: string;
  customManufFields?: {
    [key: string]: number | string;
  };
}

export type FilterSpecKeys =
  | "softener"
  | "backwash"
  | "cartridge"
  | "uv"
  | "backwashRainwater";
export interface FilterData {
  configuration: FilterConfiguration;
  model?: string;
  minFlowRateLS: number;
  maxFlowRateLS: number;
  minPressureKPA: number;
  maxPressureKPA: number;
  minFlowPressureDropKPA: number;
  maxFlowPressureDropKPA: number;
  widthMM: number;
  depthMM: number;
  connectionHeightMM: number;
  customManufFields?: {
    [key: string]: number | string;
  };
}

export type FiltersManufacturer = Manufacturer<"Filter">;
export type FilterSpec =
  | WaterSoftenerSpec
  | BackWashSpec
  | CartridgeSpec
  | UvSpec
  | BackWashRainwaterSpec;
export interface WaterSoftenerSpec {
  type: "water-softener-spec";
  name: string;
  uid: "softener";
  manufacturer: FiltersManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: FilterData[];
  };
}

export interface BackWashSpec {
  type: "backwash-spec";
  name: string;
  uid: "backwash";
  manufacturer: FiltersManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: FilterData[];
  };
}

export interface BackWashRainwaterSpec {
  type: "backwash-rainwater-spec";
  name: string;
  uid: "backwashRainwater";
  manufacturer: FiltersManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: FilterData[];
  };
}

export interface UvSpec {
  type: "uv-spec";
  name: string;
  uid: "uv";
  manufacturer: FiltersManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: FilterData[];
  };
}

export interface CartridgeSpec {
  type: "cartridge-spec";
  name: string;
  uid: "cartridge";
  manufacturer: FiltersManufacturer[];
  datasheet: {
    [key: ManufacturerUid]: FilterData[];
  };
}

export type BalancingValveManufacturer = Manufacturer<"Balancing Valve">;

export interface BalancingValveSpec {
  manufacturer: BalancingValveManufacturer[];
}

export enum HotWaterPlantName {
  HOT_WATER_PLANT = "Hot Water Plant",
  RECIRCULATION_PUMP = "Recirculation Pump",
}

export type HotWaterManufacturer =
  Manufacturer<HotWaterPlantName.HOT_WATER_PLANT> & {
    isCirculatingPump?: false;
  };

export type RecirculationManufacturer =
  Manufacturer<HotWaterPlantName.RECIRCULATION_PUMP> & {
    isCirculatingPump?: true;
  };

export type HotWaterPlantManufacturer =
  | HotWaterManufacturer
  | RecirculationManufacturer;

export type HotWaterPlantSize = Partial<
  Record<HotWaterPlantManufacturers, HotWaterPlantSizeManufacturerProps>
>;

export type HotWaterPlantSizeManufacturerProps = Record<
  RheemVariantValues,
  HotWaterPlantSizeManufacturerVariantProps
>;

export type HotWaterPlantSizeManufacturerVariantProps = Record<
  number,
  HotWaterPlantSizeProps
>;

export type HotWaterPlantSizeProps =
  | HotWaterPlantSizePropsTankPak
  | HotWaterPlantSizePropsElectric
  | HotWaterPlantSizePropsHeatPump
  | HotWaterPlantSizePropsContinuousFlow
  | HotWaterPlantSizePropsEclipse;

export type HotWaterPlantFlowRateTemperature =
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50
  | 55
  | 60
  | 65
  | 70;

export interface HotWaterPlantSizePropsAbstract {
  model?: string;
  heaters?: number;
  widthMM: number;
  depthMM: number;
  flowRate: Partial<Record<HotWaterPlantFlowRateTemperature, number>>;
  gas: {
    requirement: number;
    pressure: number;
  };
}

export type HotWaterPlantSizePropsContinuousFlow =
  HotWaterPlantSizePropsAbstract;

export interface HotWaterPlantSizePropsTankPak
  extends HotWaterPlantSizePropsAbstract {
  tanks: number;
  tanksCategoryL: "325" | "410";
}

export interface HotWaterPlantSizePropsElectric
  extends HotWaterPlantSizePropsAbstract {
  minimumInitialDelivery: number;
}

export interface HotWaterPlantSizePropsHeatPump
  extends HotWaterPlantSizePropsAbstract {
  kW: number;
  roomTemperature: number;
}

export type HotWaterPlantSizePropsEclipse = HotWaterPlantSizePropsAbstract;

export interface StorageTank {
  model: string;
  capacity: number;
  widthMM: number;
  depthMM: number;
}

export interface HotWaterPlant {
  manufacturer: HotWaterPlantManufacturer[];
  rheemVariants?: {
    name: RheemVariant;
    uid: RheemVariantValues;
  }[];
  grundfosPressureDrop: {
    [key: string]: {
      [key: string]: string;
    };
  };
  size: HotWaterPlantSize;
  storageTanks: Record<number, StorageTank>;
}
export type PRVManufacturer = Manufacturer<"PRV">;

export interface PRVSpec {
  manufacturer: PRVManufacturer[];
  size: { [key: string]: { [key: string]: PRVSize } };
}

export interface PRVSize {
  minInletPressureKPA: string | null;
  maxInletPressureKPA: string | null;
  minFlowRateLS: string | null;
  maxFlowRateLS: string | null;
  maxPressureDropRatio: string | null;
  diameterNominalMM: Diameter;
}

export interface FixtureSpec {
  name: string;
  priceTableName: string;
  abbreviation: string;
  uid: string;

  loadingUnits: {
    [SupportedPsdStandards.as35002021LoadingUnits]: LoadingUnit;
    [SupportedPsdStandards.barriesBookLoadingUnits]: LoadingUnit;
    [SupportedPsdStandards.bs806]: LoadingUnit;
    [SupportedPsdStandards.cibseGuideG]: LoadingUnit;
    [SupportedPsdStandards.ipc2018FlushTanks]: LoadingUnit;
    [SupportedPsdStandards.ipc2018Flushometer]: LoadingUnit;
    [SupportedPsdStandards.upc2018FlushTanks]: LoadingUnit;
    [SupportedPsdStandards.upc2018Flushometer]: LoadingUnit;
    [SupportedPsdStandards.bs8558]: LoadingUnit;
    [SupportedPsdStandards.indianStandardFlushTankPrivate]: LoadingUnit;
    [SupportedPsdStandards.indianStandardFlushTankPublic]: LoadingUnit;
    [SupportedPsdStandards.indianStandardFlushValvePrivate]: LoadingUnit;
    [SupportedPsdStandards.indianStandardFlushValvePublic]: LoadingUnit;
  };
  qLS: FlowRateSpec;
  continuousFlowLS?: ContinuousFlowRateSpec;
  roughIns: string[];

  maxInletPressureKPA: string | null;
  minInletPressureKPA: string | null;

  // Drainage
  asnzFixtureUnits: string | null;
  enDischargeUnits: string | null;
  upcFixtureUnits: string | null;
  // DFU == Drainage Fixture Unit
  nbcIndiaFlushTankPrivateDFU: string | null;
  nbcIndiaFlushTankPublicDFU: string | null;
  nbcIndiaFlushValvePrivateDFU: string | null;
  nbcIndiaFlushValvePublicDFU: string | null;

  outletAboveFloorM: string | null;

  warmTempC: string | null;

  manufacturer: FixtureManufacturer[];
  enDrainageSystem: {
    drainageSystem1: number;
    drainageSystem2: number;
    drainageSystem3: number;
    drainageSystem4: number;
  };
}

export type FixtureManufacturer = Manufacturer<keyof FixturesTable>;

export interface LoadingUnit {
  [key: string]: string | null;
}

export interface ContinuousFlowRateSpec {
  [key: string]: string | null;
}

export interface FlowRateSpec {
  [key: string]: {
    [key: string]: { [key: string]: string };
  };
}

export interface ValveSpec {
  name: string;
  uid: string;
  abbreviation: string | null;
  valvesBySize: { [key: string]: ValveSize };
}

export interface ValveSize {
  valveUid: string;
  symbol: string | null;
  diameterNominalMM: Diameter | null;
  kValue: string | null;
}

export type PipeManufacturer = Manufacturer<keyof PipesTable>;

export interface PipeMaterial {
  name: string;
  uid: string;
  abbreviation: string;
  manufacturer: PipeManufacturer[];
  pipesBySize: { [key: string]: { [key: string]: PipeSpec } };
}

export interface Manufacturer<ManufacturerEntryNames> {
  name: string;
  abbreviation: string;
  priceTableName: ManufacturerEntryNames;
  uid: string;
  overrideMaterialName?: string;
  overridBrandName?: string;
  option?: string[];
  displayOnWizard?: boolean;
  isGeneric?: boolean;
}

export interface PipeSpec {
  pipeUid: string;
  diameterNominalMM: Diameter | null;
  diameterInternalMM: Diameter | null;
  diameterOutsideMM: Diameter | null;
  colebrookWhiteCoefficient: string | null;
  safeWorkingPressureKPA: string | null;
}

// TODO: add more specs (when adding more dashboard sub-sections)
export interface FlatPartSpec {
  key: string;
  spec: PipeMaterial | FixtureSpec | FilterSpec;
}

export type MixingValveManufacturer = Manufacturer<"Tempering Valve" | "TMV">;

export interface MixingValveSpec {
  name: string;
  uid: string;
  minInletPressureKPA: {
    generic: string;
    caleffi: string;
    [key: string]: string;
  };
  maxInletPressureKPA: {
    generic: string;
    caleffi: string;
    [key: string]: string;
  };
  minFlowRateLS: {
    generic: string;
    caleffi: string;
    [key: string]: string;
  };
  maxFlowRateLS: {
    generic: string;
    caleffi: string;
    [key: string]: string;
  };
  pressureLossKPAbyFlowRateLS: {
    generic: { [key: string]: string };
    caleffi: { [key: string]: string };
    [key: string]: { [key: string]: string };
  };
  manufacturer: MixingValveManufacturer[];
}

export enum State {
  GAS = "gas",
  LIQUID = "liquid",
  SEWAGE = "sewage",
  AIR = "air",
}

export interface FluidsSpec {
  name: string;
  densityKGM3: string;
  state: State;
  dynamicViscosityByTemperature: { [key: string]: string };
  specificHeatByTemperatureKJ_KGK: { [key: string]: string };
}

export type FloorWasteManufacturer = Manufacturer<"Floor Waste">;
export interface FloorWasteSpec {
  manufacturer: FloorWasteManufacturer[];
  size: { [key: string]: { [key: string]: FloorWasteSize } };
}

export interface FloorWasteSize {
  size: string;
  options: string;
}

export type InspectionOpeningManufacturer = Manufacturer<"Inspection Opening">;
export interface InspectionOpeningSpec {
  manufacturer: InspectionOpeningManufacturer[];
  size: { [key: string]: { [key: string]: IOSize } };
}

export interface IOSize {
  size: string;
  pipeSize: string;
}

export type ManufacturerConcrete =
  | BackflowValveManufacturer
  | GreaseInterceptorTrapManufacturer
  | PRVManufacturer
  | PumpManufacturer
  | PumpTankManufacturer
  | BalancingValveManufacturer
  | HotWaterPlantManufacturer
  | PRVManufacturer
  | FixtureManufacturer
  | PipeManufacturer
  | FloorWasteManufacturer
  | InspectionOpeningManufacturer
  | FiltersManufacturer
  | RoPlantManufacturer;

// Should this be a property of FloorFinish? Yes! Do we have time to create an object for it. Noooo....
export function doesFloorFinishNeedExpansionFoam(
  floorFinish: FloorFinish,
): boolean {
  switch (floorFinish) {
    case "Type A - 0.1 TOG - plastic sheet, ceramic tiles, stone tiles":
    case "Type A - 0.5 TOG - parquet, cushioned linoleum, 75mm stone flooring":
    case "Type A - 1 TOG - carpet tiles, hardwood flooring":
    case "Type A - 1.5 TOG - carpet, 22mm timber laminate or wood block":
      return true;
    default:
      return false;
  }
}
